import { Card, Form, Row, Col, Container, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { userSignal } from '#signals/User.signals';
import { vendorListDataSignal, vendorsFormSignal } from '#signals/pages/Vendors.signals';
import { addVendor } from '../Vendors.helpers';
import { validateLength } from '#utils/validateInput';
import { triggerWarningAlert } from '#global/Alert/Alert';

const AddVendorManual = () => {
  const formData = vendorsFormSignal?.value;
  const history = useHistory();

  const handleAddVendor = async () => {
    try {
      Object.keys(formData).forEach(input => {
        let cleanedInput = input.toString().replaceAll('_', ' ');
        cleanedInput = cleanedInput.charAt(0).toUpperCase() + cleanedInput.slice(1);
        validateLength(formData[input], cleanedInput, 1, 100);
      });
      const organizationId = userSignal?.value?.user?.organizations?.[0]?.organization_id;
      const { name, email } = formData;
      const newVendor = await addVendor({
        organizationId,
        data: {
          name,
          email,
          phone_number: `+1${formData.phone_number.replace(/[^0-9]+/g, '')}`,
          is_active: true,
        },
      });

      if (newVendor?.data?.userOrganizationVendorCreate) {
        vendorListDataSignal.update({ edges: [{ node: newVendor.data.userOrganizationVendorCreate }, ...vendorListDataSignal.value.edges] });
        history.push('/vendors');
      }
    } catch (e) {
      console.error('error:', e);
      return triggerWarningAlert(e.message);
    }
  };

  return (
    <>
      <Row>
        <Col className="text-center">
          <h1 className="text-primary fw-700 pt-16 pb-16">Add a Vendor</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 className="pb-56">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h2>
        </Col>
      </Row>
      <Container className="pb-48">
        <Row>
          <Col>
            <Card style={{ maxWidth: 620 }} className="mb-24 px-80 py-56 justify-content-left mx-auto text-start">
              <Form>
                <Form.Group className="form-group text-start mb-24">
                  <Form.Label className="col-form-label fw-800 pb-0 small" htmlFor="inputDefault">Vendor name</Form.Label>
                  <Form.Control onChange={(e) => vendorsFormSignal.update({ ...formData, name: e.target.value })} type="text" className="form-control" id="inputDefault" />
                </Form.Group>
                <Form.Group className="form-group text-start mb-24">
                  <Form.Label className="col-form-label fw-800 pb-0 small" htmlFor="inputDefault">Email</Form.Label>
                  <Form.Control onChange={(e) => vendorsFormSignal.update({ ...formData, email: e.target.value })} type="text" className="form-control" id="inputDefault" />
                </Form.Group>
                <Form.Group className="form-group text-start mb-24">
                  <Form.Label className="col-form-label fw-800 pb-0 small" htmlFor="inputDefault">Phone number</Form.Label>
                  <Form.Control onChange={(e) => vendorsFormSignal.update({ ...formData, phone_number: e.target.value })} type="text" className="form-control" id="inputDefault" />
                </Form.Group>
                <Button
                  type="button"
                  variant="primary"
                  className="w-100 py-16 mb-24"
                  onClick={handleAddVendor}
                >
                  SAVE VENDOR
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddVendorManual;
