/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect } from 'react';
import { Card, Row, Col, Image, Button } from 'react-bootstrap';
import { updateCustomer } from '../../views/Customers/Customers.helpers';
import { updateVendor } from '../../views/Vendors/Vendors.helpers';
import editWithBlueTriangle from '#images/editWithBlueTriangle.svg';
import closeButton from '#images/closeButton.svg';
import { userSignal } from '#signals/User.signals';
import { vendorClientDetailSignal } from '#signals/pages/Vendors.signals';
import UniversalInput from '../Inputs/UniversalInput';
import PhoneNumberInput from '../Inputs/PhoneNumberInput';

const VendorClientDetails = ({ organization, onUpdateCustomer = null, onUpdateVendor = null }) => {
  const { formData, isEditing } = vendorClientDetailSignal.value;

  useEffect(() => {
    vendorClientDetailSignal.update({
      formData: {
        name: organization.node.name,
        email: organization.node.email,
        phoneNo: organization.node.phone_number || '',
      },
    });
  }, [organization]);

  const handleSubmit = async () => {
    const { name, email, phoneNo } = formData;
    const formattedPhone = phoneNo.includes('+1') ? phoneNo : `+1${phoneNo.replace(/[^0-9]+/g, '')}`;
    if (onUpdateCustomer) {
      const updatedCustomer = await updateCustomer({
        organizationId: userSignal?.value?.user?.organizations?.[0]?.organization_id,
        organizationCustomerId: organization.node.id,
        data: {
          name,
          email,
          phone_number: phoneNo ? formattedPhone : null,
          is_active: true,
        },
      });
      onUpdateCustomer(updatedCustomer);
    } else {
      const updatedVendor = await updateVendor({
        organizationId: userSignal?.value?.user?.organizations?.[0]?.organization_id,
        organizationVendorId: organization.node.id,
        data: {
          name,
          email,
          phone_number: phoneNo ? formattedPhone : null,
          is_active: true,
        },
      });
      onUpdateVendor(updatedVendor);
    }

    vendorClientDetailSignal.reset();
  };

  return (
    <Card className="bg-gray-250 px-16 px-md-56 py-32">
      <Row className="mx-0">
        <Col className="d-flex justify-content-end">
          <div
            className="p-8 cursor-pointer"
            onClick={() => vendorClientDetailSignal.update({
              isEditing: !isEditing,
            })}
          >
            <Image src={isEditing ? closeButton : editWithBlueTriangle} height={isEditing ? 16 : 28} />
          </div>
        </Col>
      </Row>
      {isEditing ? (
        <>
          <Row>
            <Col>
              <small className="fw-800">
                {onUpdateCustomer ? 'Client/Company name' : 'Vendor/Company name'}
              </small>
            </Col>
          </Row>
          <Row>
            <Col className="mt-8">
              <UniversalInput
                value={formData.name}
                placeholder="Name"
                customOnChange={(e) => vendorClientDetailSignal.update({
                  formData: {
                    ...formData, name: e.target.value,
                  },
                })}
              />
            </Col>
          </Row>
          <Row>
            <Col className="mt-8">
              <UniversalInput
                value={formData.name}
                placeholder="Name"
                customOnChange={(e) => vendorClientDetailSignal.update({
                  formData: {
                    ...formData, name: e.target.value,
                  },
                })}
              />
            </Col>
          </Row>
          <Row className="mt-40">
            <Col>
              <small className="fw-800">
                Email address
              </small>
            </Col>
          </Row>
          <Row>
            <Col className="mt-8">
              <UniversalInput
                value={formData.email}
                placeholder="Email"
                customOnChange={(e) => vendorClientDetailSignal.update({
                  formData: {
                    ...formData, email: e.target.value,
                  },
                })}
              />
            </Col>
          </Row>
          <Row className="mt-40">
            <Col>
              <small className="fw-800">
                Phone number
              </small>
            </Col>
          </Row>
          <Row>
            <Col className="mt-8">
              <PhoneNumberInput
                name="phoneNumber"
                placeholder="Phone number (optional)"
                value={formData.phoneNo}
                customOnChange={(e) => vendorClientDetailSignal.update({
                  formData: {
                    ...formData, phoneNo: e.target.value,
                  },
                })}
              />
            </Col>
          </Row>
          <Row className="mt-32">
            <Col className="col-4 offset-4">
              <Button className="w-100 py-8" onClick={handleSubmit}>SAVE</Button>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <Col>
              <small className="fw-800">
                Client/Company name
              </small>
            </Col>
          </Row>
          <Row>
            <Col className="mt-8">
              <h3>{organization?.node?.name}</h3>
            </Col>
          </Row>
          <Row className="mt-40">
            <Col>
              <small className="fw-800">
                Email address
              </small>
            </Col>
          </Row>
          <Row>
            <Col className="mt-8">
              <h3>{organization?.node?.email}</h3>
            </Col>
          </Row>
          <Row className="mt-40">
            <Col>
              <small className="fw-800">
                Phone number
              </small>
            </Col>
          </Row>
          <Row>
            <Col className="mt-8">
              <h3>{organization?.node?.phone_number || 'N/A'}</h3>
            </Col>
          </Row>
        </>
      )}
    </Card>
  );
};

export default VendorClientDetails;
