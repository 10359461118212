import { Button, Col, Form, Row } from 'react-bootstrap';
import { userSignal } from '#signals/User.signals';
import { onboardingSignal } from '#signals/pages/Onboarding.signals';

import { createOrganizationSubmit } from '../Onboarding.helpers';
import { numberOfPeopleArray, companyIndstryArray } from '#utils/selectArrays';
import SelectInput from '../../../global/Inputs/SelectInput';
import UniversalInput from '../../../global/Inputs/UniversalInput';

const CompanyInfoForm = () => {
  const { companyName, companyIndustry, numberOfPeople } = onboardingSignal.value;
  return (
    <Form.Group>
      <h2 className="fw-700 text-center mb-40">Please tell us a little about yourself</h2>
      <Form.Label htmlFor="CompanyName"><strong>Company Name</strong></Form.Label>
      <UniversalInput
        value={companyName}
        className="mb-40"
        name="companyName"
        signal={onboardingSignal}
      />
      <Form.Label htmlFor="companyIndustry"><strong>Company Industry</strong></Form.Label>
      <SelectInput
        name="companyIndustry"
        value={companyIndustry}
        options={companyIndstryArray}
        className="mb-40"
        signal={onboardingSignal}
      />
      <Row>
        <Col xs={8}>
          <Form.Label
            className="p-0 d-flex align-items-center h-100"
            htmlFor="Number of people in your company"
          ><strong>Number of people in your company</strong>
          </Form.Label>
        </Col>
        <Col xs={4}>
          <SelectInput
            name="numberOfPeople"
            value={numberOfPeople}
            options={numberOfPeopleArray}
            signal={onboardingSignal}
          />
          {/* HERE */}
        </Col>
      </Row>
      <Button
        variant="primary"
        type="submit"
        onClick={() => createOrganizationSubmit(userSignal.value.user.email)}
        className="w-100 btn-lg my-48"
        disabled={companyName.length === 0 || companyIndustry.length === 0 || numberOfPeople === 'Select'}
      >
        NEXT
      </Button>
    </Form.Group>
  );
};

export default CompanyInfoForm;
