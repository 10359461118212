import { Card, Col, Container, Image, Modal, Row, Tab } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import ContentWrapper from '#global/ContentWrapper';
import profileSettings from '#images/profileSettings.svg';
import organizationSettings from '#images/organizationSettings.svg';
import invoiceReminderSettings from '#images/invoiceReminderSettings.svg';
import paymentSettings from '#images/paymentSettings.svg';
import logsSettings from '#images/logsSettings.svg';
import { settingsSignal, settingsLogsSignal, settingsProfileSignal, settingsReminderSignal, settingsPaymentSignal, settingsOrganizationSignal } from '#signals/pages/Settings.signals';
import useWindowSize from '../../../hooks/useWindowSize';
import PaymentSettings from './views/Payment/PaymentSettings';
import LogsSettings from './views/Logs/LogsSettings';
import ProfileSettings from './views/Profile/ProfileSettings';
import OrganizationSettings from './views/Organization/OrganizationSettings';
import ReminderSettings from './views/Reminders/ReminderSettings';

const settingTabs = [
  {
    id: 1,
    route: 'profile',
    tab: 'profile-settings',
    icon: profileSettings,
    title: 'Profile Settings',
    description: 'Update name, email, contact, password...',
    component: <ProfileSettings />,
  },
  {
    id: 2,
    route: 'organization',
    tab: 'organization-settings',
    icon: organizationSettings,
    title: 'Organization Settings',
    description: 'Update company info, users...',
    component: <OrganizationSettings />,
  },
  {
    id: 3,
    route: 'reminders',
    tab: 'invoice-reminder-settings',
    icon: invoiceReminderSettings,
    title: 'Invoice Reminder Settings',
    description: 'Update invoice reminders, overdue notifications...',
    component: <ReminderSettings />,
  },
  {
    id: 4,
    route: 'payments',
    tab: 'payment-settings',
    icon: paymentSettings,
    title: 'Payment Settings',
    description: 'Update payment methods...',
    component: <PaymentSettings />,
  },
  {
    id: 5,
    route: 'sync',
    tab: 'sync-settings',
    icon: logsSettings,
    title: 'Accounting Software sync',
    description: 'Update Sync Settings',
    component: <h1>Accounting Software sync: <br /> 1. Disconnect <br /> 2. Connect <br /> 3. IF Connect Sync Now </h1>,
  },
  {
    id: 6,
    route: 'logs',
    tab: 'logs-settings',
    icon: logsSettings,
    title: 'Logs',
    description: 'View sync history',
    component: <LogsSettings />,
  },
];

const Settings = () => {
  const history = useHistory();
  const { isBreakpointDown } = useWindowSize();
  const { currentTab, isMobileModalOpen } = settingsSignal.value;

  useEffect(() => {
    const found = settingTabs.find(obj => obj.route === history.location.pathname.replace('/settings/', ''));
    if (found) {
      settingsSignal.update({
        ...settingsSignal.value,
        currentTab: found.tab,
        isMobileModalOpen: isBreakpointDown('md') ? !settingsSignal.value.isMobileModalOpen : false,
      });
    }
  }, []);

  return (
    <ContentWrapper>
      <Container fluid className="min-vh-100">
        <Container fluid>
          <Row>
            <Col xs={12} lg={5} className="min-vh-100 left-panel-border pe-lg-48 py-32 pt-lg-88">
              <Row className="me-24 mb-md-32">
                <Col className="d-flex justify-content-between align-items-center">
                  <h1 className="fw-400 mb-0">Settings</h1>
                </Col>
              </Row>
              {settingTabs && settingTabs.map((obj, idx) => (
                <Card
                  key={idx}
                  className={`p-16 w-100 h-auto ${currentTab === obj.tab ? 'border border-primary-active custom-shadow' : 'row-hover-effect'} ${obj.id !== 1 ? 'mt-24' : ''}`}
                  onClick={() => {
                    settingsSignal.update({
                      ...settingsSignal.value,
                      currentTab: obj.tab,
                      isMobileModalOpen: isBreakpointDown('md') ? !settingsSignal.value.isMobileModalOpen : false,
                    });
                    history.push(`/settings/${obj.route}`);
                  }}
                >
                  <Row>
                    <Col xs={2} className="d-flex justify-content-center">
                      <Image src={obj.icon} height={28} width={28} />
                    </Col>
                    <Col xs={10} className="ps-0">
                      <h3 className="fw-700 text-dark mb-16">{obj.title}</h3>
                      <h5 className="fw-400 text-dark-900 mb-0">{obj.description}</h5>
                    </Col>
                  </Row>
                </Card>
              ))}
            </Col>
            <Modal show={isMobileModalOpen} fullscreen className="modal-white d-xl-none">
              <Modal.Header
                closeButton
                onClick={() => {
                  settingsSignal.update({
                    ...settingsSignal.value,
                    isMobileModalOpen: false,
                  });
                  history.push('/settings');
                }}
              />
              <Container>
                <Row className="d-flex justify-content-between align-items-center mt-8">
                  <h1 className="fw-400 mb-0 text-center">Settings</h1>
                </Row>
                <Row className="d-flex align-items-center">
                  <Col className="bg-white">
                    <Tab.Container
                      activeKey={currentTab}
                      onSelect={(t) => {
                        settingsSignal.update({
                          ...settingsSignal.value,
                          currentTab: t,
                        });
                        settingsLogsSignal.reset();
                        settingsProfileSignal.reset();
                        settingsReminderSignal.reset();
                        settingsPaymentSignal.reset();
                        settingsOrganizationSignal.reset();
                        history.push(`/settings/${t}`);
                      }}
                    >
                      {settingTabs && settingTabs.map((obj, idx) => {
                        if (obj.tab === currentTab) {
                          return (
                            <h3 key={idx} className="fw-400 mb-0 text-center text-primary">{obj.title}</h3>
                          );
                        }
                        return '';
                      })}
                      <Tab.Content>
                        {settingTabs && settingTabs.map((obj, idx) => (
                          <Tab.Pane key={idx} eventKey={obj.tab} title={obj.tab} className="ms-0">
                            {obj.component}
                          </Tab.Pane>
                        ))}
                      </Tab.Content>
                    </Tab.Container>
                  </Col>
                </Row>
              </Container>
            </Modal>
            <Col lg={7} className="ps-48 mt-88 d-none d-lg-block">
              {settingTabs && settingTabs.map((obj, idx) => {
                if (obj.tab === currentTab) {
                  return (
                    <div key={idx}>
                      {obj.component}
                    </div>
                  );
                }
                return '';
              })}
            </Col>
          </Row>
        </Container>
      </Container>
    </ContentWrapper>
  );
};

export default Settings;
