import { gql } from '@apollo/client';
import { setUpPersonalInfoSignal } from '#signals/pages/Onboarding.signals';
import { triggerWarningAlert } from '#global/Alert/Alert';
import formatPhoneNumber from '#utils/formatPhoneNumber';
import { apolloClient } from '#api';

export const handleSetupPersonalInfoChange = (e) => setUpPersonalInfoSignal.update({ [e.target.name]: e.target.value });

const UPDATE_PERSONAL_INFO_MUTATION = gql`
  mutation UserUpdateData($data: UserUpdateDataSchema!){
    userUpdateData(data: $data) {
      id
      email
      first_name
      last_name
    }
  }
`;

export const performPersonalInfoUpdatePartial = async () => {
  try {
    const { firstName, lastName, phoneNumber } = setUpPersonalInfoSignal.value;

    if (firstName === '') {
      throw new Error('First name cannot be empty');
    }

    if (lastName === '') {
      throw new Error('Last name cannot be empty');
    }

    if (!formatPhoneNumber(phoneNumber)) {
      throw new Error('Please enter a valid phone number.');
    }

    const variables = {
      data: {
        first_name: firstName,
        last_name: lastName,
        timezone: 'America/Los_Angeles',
        language: 'en-US',
      },
    };

    // TODO map phoneNumber field in mutation
    await apolloClient.mutate({ mutation: UPDATE_PERSONAL_INFO_MUTATION, variables });
  } catch (err) {
    return triggerWarningAlert(err.message);
  }
};
