import { Button, Col, Form, Row } from 'react-bootstrap';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import GoogleLogo from '#images/google.svg';
import IntuitLogo from '#images/intuit.svg';
import { handleEmailSubmitPartial } from '../Signup.helpers';
import { partialGoogleLoginHandler, handleIntuitStartLogin } from '#views/Auth/Auth.helpers';
import ReturnToLoginRow from './ReturnToLoginRow';
import { signUpSignal } from '#signals/Authentication.signals';
import { performIntuitCallbackHandoff } from '../../Auth/Auth.helpers';
import UniversalInput from '../../../global/Inputs/UniversalInput';

const EmailSignupForm = ({ isEmbed }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.search.includes('code') && location.search.includes('state')) {
      performIntuitCallbackHandoff(`${window.location.origin}${'/signup'}${location.search}`);
    }
  }, [location.search]);
  return (
    <Form.Group>
      <h2 className="fw-700 text-center mb-24">Sign up</h2>
      <Form.Label htmlFor="EmailAddress"><strong>Email address</strong></Form.Label>
      <UniversalInput
        placeholder="Enter email"
        name="email"
        value={signUpSignal.value.formData.email}
        className="mb-24"
        customOnChange={(e) => signUpSignal.update({
          ...signUpSignal.value,
          formData: {
            ...signUpSignal.value.formData,
            email: e.target.value,
          },
        })}
      />
      <Button
        variant="primary"
        type="submit"
        onClick={() => {
          handleEmailSubmitPartial(signUpSignal.value.formData.email);
        }}
        disabled={signUpSignal.value.formData.email === ''}
        className="w-100 btn-lg mb-16"
      >
        GET STARTED
      </Button>
      {!isEmbed && (
      <ReturnToLoginRow />
      )}
      {!isEmbed && (
      <Row>
        <Col xs={4}>
          <hr />
        </Col>
        <Col xs={4} className="d-flex justify-content-center align-items-center">
          Or sign up with
        </Col>
        <Col xs={4}>
          <hr />
        </Col>
      </Row>
      )}
      {!isEmbed && (
      <Row className="d-flex align-items-center justify-content-center mt-16 mb-24">
        <Col xs={6} className="d-flex justify-content-around">
          <Button
            variant="white"
            type="submit"
            onClick={() => handleIntuitStartLogin()}
            className="w-100 btn-lg h-100 border-black"
          ><img
            src={IntuitLogo}
            height="25"
            alt="Intuit Logo"
          />
          </Button>
        </Col>
        <Col xs={6} className="d-flex justify-content-around">
          <Button
            variant="white"
            type="submit"
            onClick={partialGoogleLoginHandler}
            className="w-100 btn-lg h-100 p-0 border-black"
          ><img
            src={GoogleLogo}
            height="25"
            alt="Google Logo"
          />
          </Button>
        </Col>
      </Row>
      )}
    </Form.Group>
  );
};

export default EmailSignupForm;
