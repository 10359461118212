import { Tab, Tabs } from 'react-bootstrap';
import { AuthLayout } from '#global/Layout';
import AccountTypeForm from './components/AccountTypeForm';
import PersonalInfoForm from './components/PersonalInfoForm';
import CompanyInfoForm from './components/CompanyInfoForm';
import InviteMembersForm from './components/InviteMembersForm';
import { onboardingSignal } from '#signals/pages/Onboarding.signals';
import { loaderSignal } from '#signals/Global.signals';
import ComponentLoader from '../../global/ComponentLoader';

const Onboarding = () => {
  const { step } = onboardingSignal.value;
  return (
    <AuthLayout>
      {loaderSignal.value.isLoading ? (
        <ComponentLoader />
      ) : (
        <Tabs activeKey={step} className="border-0 d-none">
          <Tab eventKey="accountType" title="Account Type">
            <AccountTypeForm />
          </Tab>
          <Tab eventKey="personalInfo" title="Personal Info">
            <PersonalInfoForm />
          </Tab>
          <Tab eventKey="companyInfo" title="Company Info">
            <CompanyInfoForm />
          </Tab>
          <Tab eventKey="invitations" title="Invitations">
            <InviteMembersForm />
          </Tab>
        </Tabs>
      )}
    </AuthLayout>
  );
};

export default Onboarding;
