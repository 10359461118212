import { Form } from 'react-bootstrap';

const DatePicker = ({ name, value, placeholder, signal }) => (
  <Form.Control
    className="w-100 text-start bg-transparent form-control mb-16"
    type="date"
    placeholder={placeholder}
    value={value}
    onChange={(e) => signal.update({ [name]: e })}
  />
);

export default DatePicker;
