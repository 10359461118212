import { Container, Row, Col, Button, Image, Tab, InputGroup, Nav, Modal } from 'react-bootstrap';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import ContentWrapper from '#global/ContentWrapper';
import filter from '#images/filter-alt.svg';
import magnifying from '#images/magnifying.svg';
import confetti from '#images/confetti.svg';
import twoPeople from '#images/twoPeople.svg';
import VendorClientContact from '#global/VendorClientSidePanel/VendorClientContact';
import VendorClientDetails from '#global/VendorClientSidePanel/VendorClientDetails';
import VendorClientBills from '#global/VendorClientSidePanel/VendorClientBills';
import CustomerCard from './CustomerCard';
import VendorCustomerProjects from '#global/VendorClientSidePanel/VendorCustomerProjects';
import { getAllCustomers } from './Customers.helpers';
import { customerListDataSignal, customerListSignal } from '#signals/pages/Customers.signals';
import { userSignal } from '#signals/User.signals';
import Money from '../../global/Money';
import vendorsArrow from '#images/vendorsArrow.svg';
import useWindowSize from '../../../hooks/useWindowSize';
import arrowLeft from '#images/arrowLeft.svg';
import VendorCustomerConnections from '../../global/VendorClientSidePanel/VendorCustomerConnections';
import UniversalInput from '../../global/Inputs/UniversalInput';

const CustomersList = () => {
  const history = useHistory();
  const { isBreakpointDown } = useWindowSize();

  const {
    openTab,

    searchTerm,

    selectedSorting,
    take,

    selectedCustomer,

    isMobileModalOpen,
  } = customerListSignal.value;

  const organizationId = useMemo(() => userSignal?.value?.user?.organizations?.[0]?.organization_id, [userSignal?.value?.user?.organizations]);
  if (!organizationId) return null; // WHAT?

  const list = useMemo(() => customerListDataSignal.value, [customerListDataSignal.value]);
  const items = useMemo(() => list?.edges || [], [list]);

  const loadCustomers = useCallback(async ({
    nameSearchTerm,
  } = {}) => {
    customerListDataSignal.update({
      is_loading: true,
    });

    try {
      const response = await getAllCustomers({
        organizationId,

        ...(!!nameSearchTerm && { name: nameSearchTerm }),

        ...(items.length ? {
          after: items[items.length - 1].cursor,
          first: take,
        } : {
          skip: 0,
          take,
        }),

        sorting: selectedSorting,
      });

      const data = response.data.userOrganizationCustomers;

      customerListDataSignal.update({
        ...data,
        is_loading: false,
      });
    } catch (error) {
      customerListDataSignal.update({ is_loading: false });
    }
  }, [organizationId, items, selectedSorting, take]);

  useEffect(() => {
    customerListSignal.reset();
    customerListDataSignal.reset();
    loadCustomers();
  }, []);

  useEffect(() => {
    customerListDataSignal.update({
      edges: [],
      selectedCustomer: null,
      isMobileModalOpen: false,
    });

    const timeout = setTimeout(() => {
      if (searchTerm) {
        loadCustomers({
          nameSearchTerm: searchTerm,
        });
      }
    },
    1000);

    return () => clearTimeout(timeout);
  }, [searchTerm]);

  const handleSelectCustomer = (customer) => {
    customerListSignal.update({
      selectedCustomer: customer,
    });
    if (isBreakpointDown('md')) {
      customerListSignal.update({
        isMobileModalOpen: true,
      });
    }
  };

  const handleUpdateCustomer = async (updatedCustomer) => {
    const prevCustomers = [...customerListDataSignal.value.edges];
    const updatedCustomers = prevCustomers.map(c => ((c.node.id === updatedCustomer?.data?.userOrganizationCustomerUpdate?.id) ? { cursor: Buffer.from(c.node.id, 'base64'), node: { ...c, ...updatedCustomer?.data?.userOrganizationCustomerUpdate } } : c));
    customerListDataSignal.update({ edges: updatedCustomers });
    customerListSignal.update({
      selectedCustomer: prevCustomers.find(c => c.node.id === updatedCustomer?.data?.userOrganizationCustomerUpdate?.id),
    });
  };

  return (
    <ContentWrapper>
      <Container fluid className="px-lg-40">
        <Row>
          <Col xs={12} lg={5} className="min-vh-100 left-panel-border pt-32 pt-lg-88 px-0">
            <Row className="mr-24 mb-md-32 pe-lg-24">
              <Col className="d-flex justify-content-between align-items-center">
                <h1 style={{ fontSize: '40px' }} className="fw-400 mb-0">Customers</h1>
                <Button className="mb-8" onClick={() => history.push('/customers/add')} variant="primary btn-circle d-flex justify-content-center">
                  <span style={{ font: 'black !important' }}>+</span>
                </Button>
              </Col>
            </Row>
            <Row className="d-flex justify-content-between align-items-center mb-md-24 mx-0 pe-lg-24">
              <Col xs={11} className="p-0">
                <div className="w-100">
                  <InputGroup className="custom-searchbox py-8">
                    <InputGroup.Text id="basic-addon1" className="ps-32"><Image src={magnifying} /></InputGroup.Text>
                    <UniversalInput
                      value={searchTerm}
                      name="searchTerm"
                      signal={customerListSignal}
                    />
                  </InputGroup>
                </div>
              </Col>
              <Col xs={1} className="ps-0">
                <div className="d-flex justify-content-end">
                  <Image src={filter} height={17} />
                </div>
              </Col>
            </Row>
            {!items.length && (
              <Row className="mx-0 p-0">
                <Image className="w-100 px-0" src={confetti} />
              </Row>
            )}
            <div className="overflow-scroll-y vh-100">
              {items.map(customer => (
                <Row id={customer.node.id} className="mx-0 pe-24">
                  <CustomerCard
                    item={customer}
                    onSelect={(v) => handleSelectCustomer(v)}
                    handleClickInvoices={() => customerListSignal.update({
                      openTab: 'invoices',
                    })}
                    handleClickProjects={() => customerListSignal.update({
                      openTab: 'projects',
                    })}
                  />
                </Row>
              ))}
              {!!list?.pageInfo?.hasNextPage && (
                <Row className="mx-0 pe-24">
                  <Col className="d-flex justify-content-center">
                    <Button variant="primary" onClick={() => loadCustomers()} className="px-40">Load More</Button>
                  </Col>
                </Row>
              )}
            </div>

          </Col>
          <Modal show={isMobileModalOpen} fullscreen className="modal-white d-xl-none">
            {selectedCustomer && (
              <Container>
                <Row className="sticky-top bg-white py-8">
                  <Col>
                    <Button
                      variant="transparent"
                      className="border-0 p-0 mx-auto fw-800 text-primary"
                      onClick={() => customerListSignal.update({
                        isMobileModalOpen: false,
                      })}
                    >
                      <Image src={arrowLeft} />
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col className="pt-16">
                    <h1 className="mb-0">{selectedCustomer.node.name}</h1>
                  </Col>
                </Row>
                <Row className="py-16 my-16 bg-gray-250" style={{ borderRadius: '15px' }}>
                  <Col className="d-flex justify-content-between align-items-center px-24">
                    <div>
                      <p className="lead fw-800 mb-0">Balance</p>
                      <h3><Money amount={selectedCustomer.node.invoice_statement.total_amount - selectedCustomer.node.invoice_statement.paid_amount} /></h3>
                    </div>
                    <div>
                      <p className="lead fw-800 mb-0">Overdue</p>
                      <h3><Money amount={selectedCustomer.node.invoice_statement.overdue_amount} /></h3>
                    </div>
                    {/* <div>
                      <Button variant="primary" onClick={() => history.push('/payment')} className="px-32">PAY BILLS</Button>
                    </div> */}
                  </Col>
                </Row>
                <Row className="d-flex align-items-center">
                  <Col className="bg-white">
                    <Tab.Container
                      activeKey={openTab}
                      onSelect={(t) => customerListSignal.update({
                        openTab: t,
                      })}
                    >
                      <Nav variant="tabs" className="custom-tabs">
                        <Nav.Item style={{ width: '20%' }}>
                          <Nav.Link className="text-center pt-24" eventKey="details">Details</Nav.Link>
                        </Nav.Item>
                        <Nav.Item style={{ width: '20%' }}>
                          <Nav.Link className="text-center pt-24" eventKey="contact">Contacts</Nav.Link>
                        </Nav.Item>
                        <Nav.Item style={{ width: '20%' }}>
                          <Nav.Link className="text-center pt-24" eventKey="bills">Bills</Nav.Link>
                        </Nav.Item>
                        <Nav.Item style={{ width: '20%' }}>
                          <Nav.Link className="text-center pt-24" eventKey="projects">Projects</Nav.Link>
                        </Nav.Item>
                        <Nav.Item style={{ width: '20%' }}>
                          <Nav.Link className="text-center pt-24" eventKey="connections">Connections</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="details" title="Details" className="ms-0">
                          <VendorClientDetails
                            organization={selectedCustomer}
                            onUpdateCustomer={handleUpdateCustomer}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="contact" title="Contact">
                          <VendorClientContact selectedObj={selectedCustomer} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="bills" title="Bills">
                          <VendorClientBills items={selectedCustomer.node.invoice_statement.data.edges} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="projects" title="Projects">
                          <VendorCustomerProjects />
                        </Tab.Pane>
                        <Tab.Pane eventKey="connections" title="Connections">
                          <VendorCustomerConnections />
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Col>
                </Row>
              </Container>
            )}
          </Modal>
          <Col lg={7} className="ps-24 mt-40 d-none d-lg-block">
            {selectedCustomer ? (
              <div>
                <Row className="mx-0 mt-48">
                  <Col className="d-flex justify-content-between">
                    <h1>{selectedCustomer.node.name}</h1>
                  </Col>
                </Row>
                <Row className="mx-0 px-56 py-32 mb-24 mt-48 mb-56 bg-gray-250" style={{ borderRadius: '15px' }}>
                  <Col className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="lead fw-800 text-primary">Balance:</p>
                      <h1><Money amount={selectedCustomer.node.invoice_statement.total_amount - selectedCustomer.node.invoice_statement.paid_amount} /></h1>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <p className="lead fw-800 text-primary">Overdue:</p>
                      <h1><Money amount={selectedCustomer.node.invoice_statement.overdue_amount} /></h1>
                    </div>
                  </Col>
                </Row>
                <Row className="d-flex align-items-center">
                  <Col>
                    <Tab.Container
                      activeKey={openTab}
                      onSelect={(t) => customerListSignal.update({
                        openTab: t,
                      })}
                    >
                      <Nav variant="tabs" className="custom-tabs">
                        <Nav.Item style={{ width: '20%' }}>
                          <Nav.Link className="text-center pt-24" eventKey="details">Details</Nav.Link>
                        </Nav.Item>
                        <Nav.Item style={{ width: '20%' }}>
                          <Nav.Link className="text-center pt-24" eventKey="contact">Contacts</Nav.Link>
                        </Nav.Item>
                        <Nav.Item style={{ width: '20%' }}>
                          <Nav.Link className="text-center pt-24" eventKey="invoices">Invoices</Nav.Link>
                        </Nav.Item>
                        <Nav.Item style={{ width: '20%' }}>
                          <Nav.Link className="text-center pt-24" eventKey="projects">Projects</Nav.Link>
                        </Nav.Item>
                        <Nav.Item style={{ width: '20%' }}>
                          <Nav.Link className="text-center pt-24" eventKey="connections">Connections</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="details" title="Details" className="ms-0">
                          <VendorClientDetails
                            organization={selectedCustomer}
                            onUpdateCustomer={handleUpdateCustomer}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="contact" title="Contact">
                          <VendorClientContact selectedObj={selectedCustomer} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="invoices" title="Invoices">
                          <VendorClientBills items={selectedCustomer.node.invoice_statement.data.edges} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="projects" title="Projects">
                          <VendorCustomerProjects />
                        </Tab.Pane>
                        <Tab.Pane eventKey="connections" title="Connections">
                          <VendorCustomerConnections />
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Col>
                </Row>
              </div>
            ) : (
              <>
                <Image src={vendorsArrow} height="150" />
                <h2 className="text-primary fw-700 text-center">Let&apos;s get some customers added so that you can get paid!</h2>
                <Image src={twoPeople} className="h-auto w-75 mt-24 d-flex mx-auto" />
              </>
            )}
          </Col>
        </Row>
      </Container>
    </ContentWrapper>
  );
};

export default CustomersList;
