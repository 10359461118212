import { Col, Row } from 'react-bootstrap';
import { settingsReminderSignal } from '#signals/pages/Settings.signals';
import { loaderSignal } from '#signals/Global.signals';
import ComponentLoader from '../../../../global/ComponentLoader';
import InvoiceReminders from './components/InvoiceReminders';
import OverdueNotifications from './components/OverdueNotifications';

const reminderTabs = [
  {
    id: 1,
    tab: 'invoice-reminders',
    title: 'Invoice Reminders',
    component: <InvoiceReminders />,
  },
  {
    id: 2,
    tab: 'overdue-notifications',
    title: 'Overdue Notifications',
    component: <OverdueNotifications />,
  },
];

const ReminderSettings = () => {
  const { currentTab } = settingsReminderSignal.value;

  return (
    <div>
      <h1 className="d-none d-lg-block d-xl-block">Profile Settings</h1>
      <Row className="mx-0 mt-24">
        {reminderTabs && reminderTabs.map((obj, idx) => (
          <Col
            key={idx}
            className={`text-center mt-auto px-0 w-100 border-bottom  ${currentTab === obj.tab ? 'border-3 border-secondary text-primary fw-bold' : 'text-dark-900'}`}
            role="button"
            tabIndex={0}
            onClick={() => settingsReminderSignal.update({
              ...settingsReminderSignal.value,
              currentTab: obj.tab,
            })}
          >
            <p className="mb-0">{obj.title}</p>
          </Col>
        ))}
      </Row>
      <Row className="mx-0">
        {reminderTabs && reminderTabs.map((obj, idx) => {
          if (obj.tab === currentTab) {
            if (loaderSignal.value?.isLoading) {
              return <ComponentLoader key={idx} />;
            }
            return (
              <div key={idx} className="px-0">
                {obj.component}
              </div>
            );
          }
          return '';
        })}
      </Row>
    </div>
  );
};

export default ReminderSettings;
