import { Form } from 'react-bootstrap';

const zipFormatter = (value) => {
  const cleanedZip = value.replace(/\D/g, '');
  if (cleanedZip.length > 5) {
    const formattedZIP = `${cleanedZip.slice(0, 5)}-${cleanedZip.slice(5)}`;
    return formattedZIP;
  }
  return cleanedZip;
};

const ZipInput = ({ name, value, signal }) => (
  <Form.Control
    className="mb-16 bg-transparent"
    id={name}
    type="text"
    placeholder="Zip Code"
    name={name}
    value={zipFormatter(value)}
    onChange={(e) => {
      if (e.target.value.length < 11) {
        signal.update({
          [name]: e.target.value,
        });
      }
    }}
  />
);

export default ZipInput;
