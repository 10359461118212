import { Card, Form, Row, Col, Container, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { userSignal } from '#signals/User.signals';
import { customersFormSignal, customerListDataSignal } from '#signals/pages/Customers.signals';
import { addCustomer } from '../Customers.helpers';
import UniversalInput from '../../../global/Inputs/UniversalInput';
import PhoneNumberInput from '../../../global/Inputs/PhoneNumberInput';

const AddCustomerManual = () => {
  const formData = customersFormSignal?.value;
  const history = useHistory();

  const handleAddCustomer = async () => {
    try {
      const organizationId = userSignal?.value?.user?.organizations?.[0]?.organization_id;
      const { name, email } = formData;
      const newCustomer = await addCustomer({
        organizationId,
        data: {
          name,
          email,
          phone_number: formData.phone_number ? `+1${formData.phone_number.replace(/[^0-9]+/g, '')}` : null,
          is_active: true,
        },
      });
      if (newCustomer?.data?.userOrganizationCustomerCreate) {
        customerListDataSignal.update({ edges: [{ node: newCustomer.data.userOrganizationCustomerCreate }, ...customerListDataSignal.value.edges] });
        history.push('/customers');
      }
    } catch (e) {
      console.error('error creating customer:', e);
    }
  };

  return (
    <>
      <Row>
        <Col className="text-center">
          <h1 className="text-primary fw-700 pt-16 pb-16">Add a Customer</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 className="pb-56">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h2>
        </Col>
      </Row>

      <Container className="pb-48">
        <Row>
          <Col>
            <Card style={{ maxWidth: 620 }} className="mb-24 px-80 py-56 justify-content-left mx-auto text-start">
              <Form>
                <Form.Group className="form-group text-start mb-24">
                  <Form.Label className="col-form-label fw-800 pb-0 small" htmlFor="inputDefault">Customer/Company name</Form.Label>
                  <UniversalInput
                    value={formData.name}
                    name="name"
                    signal={customersFormSignal}
                  />
                </Form.Group>
                <Form.Group className="form-group text-start mb-24">
                  <Form.Label className="col-form-label fw-800 pb-0 small" htmlFor="inputDefault">Email</Form.Label>
                  <UniversalInput
                    value={formData.email}
                    name="email"
                    signal={customersFormSignal}
                  />
                </Form.Group>
                <Form.Group className="form-group text-start mb-24">
                  <Form.Label className="col-form-label fw-800 pb-0 small" htmlFor="inputDefault">Phone number (optional)</Form.Label>
                  <PhoneNumberInput
                    name="phone_number"
                    value={formData.phone_number}
                    signal={customersFormSignal}
                  />
                </Form.Group>
                <Button
                  type="button"
                  variant="primary"
                  className="w-100 py-16 mb-24"
                  onClick={handleAddCustomer}
                >
                  SAVE CUSTOMER
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddCustomerManual;
