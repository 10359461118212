import { Container, Card } from 'react-bootstrap';
import AutopayMethod from './AutopayMethod';
import AutopayACH from './AutopayACH';
import AutopayCard from './AutopayCard';
import AutopaySchedule from './AutopaySchedule';
import AutopayConfirm from './AutopayConfirm';
import AutopaySuccess from './AutopaySuccess';
import { autoPaySignal } from '#signals/pages/Payment.signals';

const AutopayContainer = () => {
  const { autopayStep, backButtonHistory } = autoPaySignal.value;

  const handleNext = ({ current, next }) => {
    const navigationHistory = [...backButtonHistory];
    navigationHistory.push(current);
    autoPaySignal.update({
      autopayStep: next,
      backButtonHistory: navigationHistory,
    });
  };

  return (
    <Container className="text-center">
      <Card className="bg-light py-56 px-64">
        {{
          method: <AutopayMethod handleNext={handleNext} />,
          ach: <AutopayACH handleNext={handleNext} />,
          card: <AutopayCard handleNext={handleNext} />,
          schedule: <AutopaySchedule handleNext={handleNext} />,
          confirm: <AutopayConfirm handleNext={handleNext} />,
          success: <AutopaySuccess handleNext={handleNext} />,
        }[autopayStep]}
      </Card>
    </Container>
  );
};

export default AutopayContainer;
