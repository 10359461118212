import { gql } from '@apollo/client';
import { apolloClient } from '#api';
import { triggerWarningAlert, triggerSuccessAlert } from '#global/Alert/Alert';

const GET_CONTACTS_QUERY = gql`
  query userOrganizationVendorContacts($organizationVendorId: String!, $organizationId: String!) {
    userOrganizationVendorContacts(
      organization_vendor_id: $organizationVendorId, 
      organization_id: $organizationId
    ) {
      email
      id
      is_default
      name
      phone_number
    }
  }
`;

const GET_CUSTOMERS_CONTACTS_QUERY = gql`
  query UserOrganizationCustomerContacts($organizationCustomerId: String!, $organizationId: String!) {
    userOrganizationCustomerContacts(organization_customer_id: $organizationCustomerId, organization_id: $organizationId) {
      id
      name
      email
      phone_number
      is_default
      send_invoice_reminders
      created_at
      updated_at
      organization_customer_id
    }
  }
`;

const CREATE_CONTACT_QUERY = gql`
  mutation userOrganizationVendorContactCreate($organizationId: String!, $organizationVendorId: String!, $data: UserOrganizationVendorContactCreateSchema!) {
    userOrganizationVendorContactCreate(
      organization_id: $organizationId
      organization_vendor_id: $organizationVendorId
      data: $data
    ) {
      id
      name
      email
      phone_number
      is_default
      organization_vendor_id
    }
  }
`;

const CREATE_CUSTOMER_CONTACT_QUERY = gql`
  mutation UserOrganizationCustomerContactCreate($data: UserOrganizationCustomerContactCreateSchema!, $organizationCustomerId: String!, $organizationId: String!) {
    userOrganizationCustomerContactCreate(data: $data, organization_customer_id: $organizationCustomerId, organization_id: $organizationId) {
      id
      name
      email
      phone_number
      is_default
      send_invoice_reminders
      created_at
      updated_at
      organization_customer_id
    }
  }
`;

const UPDATE_CONTACT_QUERY = gql`
  mutation UserOrganizationVendorContactUpdate($data: UserOrganizationVendorContactUpdateSchema!, $organizationVendorContactId: String!, $organizationVendorId: String!, $organizationId: String!) {
    userOrganizationVendorContactUpdate(
      data: $data, 
      organization_vendor_contact_id: $organizationVendorContactId, 
      organization_vendor_id: $organizationVendorId, 
      organization_id: $organizationId
    ) {
      email
      id
      is_default
      name
      phone_number
    }
  }
`;

const UPDATE_CUSTOMER_CONTACT_QUERY = gql`
  mutation UserOrganizationCustomerContactUpdate($data: UserOrganizationCustomerContactUpdateSchema!, $organizationCustomerContactId: String!, $organizationCustomerId: String!, $organizationId: String!) {
    userOrganizationCustomerContactUpdate(data: $data, organization_customer_contact_id: $organizationCustomerContactId, organization_customer_id: $organizationCustomerId, organization_id: $organizationId) {
      id
      name
      email
      phone_number
      is_default
      send_invoice_reminders
      created_at
      updated_at
      organization_customer_id
    }
  }
`;

const DELETE_CONTACT_QUERY = gql`
  mutation UserOrganizationVendorContactDelete($organizationVendorContactId: String!, $organizationVendorId: String!, $organizationId: String!) {
    userOrganizationVendorContactDelete(
      organization_vendor_contact_id: $organizationVendorContactId,
      organization_vendor_id: $organizationVendorId,
      organization_id: $organizationId
    ) {
      email
      id
      name
      organization_vendor_id
      phone_number
      is_default
    }
  }
`;

const DELETE_CUSTOMER_CONTACT_MUTATION = gql`
  mutation UserOrganizationCustomerContactDelete($organizationCustomerContactId: String!, $organizationCustomerId: String!, $organizationId: String!) {
    userOrganizationCustomerContactDelete(organization_customer_contact_id: $organizationCustomerContactId, organization_customer_id: $organizationCustomerId, organization_id: $organizationId) {
      id
      name
      email
      phone_number
      is_default
      send_invoice_reminders
      created_at
      updated_at
      organization_customer_id
    }
  }
`;

export const createContact = async ({ organizationId, organizationVendorId, data }) => {
  try {
    const newContact = await apolloClient.mutate({
      mutation: CREATE_CONTACT_QUERY,
      variables: {
        organizationId,
        organizationVendorId,
        data,
      },
    });
    if (newContact) {
      triggerSuccessAlert('Contact Created!');
    }
    return newContact;
  } catch (e) {
    console.error('Error when creating new contact:', e);
    triggerWarningAlert(e.message);
  }
};

export const createCustomerContact = async ({ organizationId, organizationCustomerId, data }) => {
  try {
    const newContact = await apolloClient.mutate({
      mutation: CREATE_CUSTOMER_CONTACT_QUERY,
      variables: {
        organizationId,
        organizationCustomerId,
        data,
      },
    });
    if (newContact) {
      triggerSuccessAlert('Contact Created!');
    }
    return newContact;
  } catch (e) {
    console.error('Error when creating new contact:', e);
    triggerWarningAlert(e.message);
  }
};

export const updateContact = async ({ organizationId, organizationVendorId, organizationVendorContactId, data }) => {
  try {
    const updatedContact = await apolloClient.mutate({
      mutation: UPDATE_CONTACT_QUERY,
      variables: {
        organizationId,
        organizationVendorId,
        organizationVendorContactId,
        data,
      },
    });
    if (updatedContact) {
      triggerSuccessAlert('Contact updated!');
    }
    return updatedContact;
  } catch (e) {
    console.error('Error when updating contact:', e);
    triggerWarningAlert(e.message);
  }
};

export const updateCustomerContact = async ({ organizationId, organizationCustomerId, organizationCustomerContactId, data }) => {
  try {
    const updatedContact = await apolloClient.mutate({
      mutation: UPDATE_CUSTOMER_CONTACT_QUERY,
      variables: {
        organizationId,
        organizationCustomerId,
        organizationCustomerContactId,
        data,
      },
    });
    if (updatedContact) {
      triggerSuccessAlert('Contact updated!');
    }
    return updatedContact;
  } catch (e) {
    console.error('Error when updating contact:', e);
    triggerWarningAlert(e.message);
  }
};

export const deleteContact = async ({ organizationId, organizationVendorId, organizationVendorContactId }) => {
  try {
    const deletedContact = await apolloClient.mutate({
      mutation: DELETE_CONTACT_QUERY,
      variables: {
        organizationId,
        organizationVendorId,
        organizationVendorContactId,
      },
    });
    if (deletedContact) {
      triggerSuccessAlert('Contact deleted!');
    }
    return deletedContact;
  } catch (e) {
    console.error('Error when deleting contact:', e);
    triggerWarningAlert(e.message);
  }
};

export const deleteCustomerContact = async ({ organizationId, organizationCustomerId, organizationCustomerContactId }) => {
  try {
    const deletedContact = await apolloClient.mutate({
      mutation: DELETE_CUSTOMER_CONTACT_MUTATION,
      variables: {
        organizationId,
        organizationCustomerId,
        organizationCustomerContactId,
      },
    });
    if (deletedContact) {
      triggerSuccessAlert('Contact deleted!');
    }
    return deletedContact;
  } catch (e) {
    console.error('Error when deleting contact:', e);
    triggerWarningAlert(e.message);
  }
};

export const getContacts = async ({ organizationId, organizationVendorId }) => {
  try {
    const allContacts = await apolloClient.query({
      query: GET_CONTACTS_QUERY,
      variables: {
        organizationId,
        organizationVendorId,
      },
    });
    return allContacts;
  } catch (e) {
    console.error('Error when getting contacts:', e);
    triggerWarningAlert(e.message);
  }
};

export const getCustomerContacts = async ({ organizationId, organizationCustomerId }) => {
  try {
    const allContacts = await apolloClient.query({
      query: GET_CUSTOMERS_CONTACTS_QUERY,
      variables: {
        organizationId,
        organizationCustomerId,
      },
    });
    return allContacts;
  } catch (e) {
    console.error('Error when getting contacts:', e);
    triggerWarningAlert(e.message);
  }
};
