/* eslint-disable no-unused-vars */
import { gql } from '@apollo/client';
import { apolloClient } from '#api';
import { triggerWarningAlert, triggerSuccessAlert } from '#global/Alert/Alert';
import { loaderSignal } from '#signals/Global.signals';
import { settingsReminderSignal } from '#signals/pages/Settings.signals';
import { timeArray } from '#utils/selectArrays';

const SETTINGS_REMINDERS_CREATE_MUTATION = gql`
  mutation UserOrganizationReminderSettingCreate($data: UserOrganizationReminderSettingCreateSchema!, $organizationId: String!) {
    userOrganizationReminderSettingCreate(data: $data, organization_id: $organizationId) {
      id
      due_date_mode
      days_amount
      repeat_mode
      repeat_value
      selected_hour
      created_at
      updated_at
      organization_id
      organization_customer_id
      organization_project_id
      organization_invoice_id
    }
  }
`;

const SETTINGS_REMINDS_DELETE_MUTATION = gql`
mutation UserOrganizationReminderSettingCreate($organizationReminderSettingId: String!, $organizationId: String!) {
  userOrganizationReminderSettingDelete(organization_reminder_setting_id: $organizationReminderSettingId, organization_id: $organizationId) {
    id
  }
}
`;

const SETTINGS_REMINDS_FETCH_QUERY = gql`
  query UserOrganizationReminderSettings($organizationId: String!) {
    userOrganizationReminderSettings(organization_id: $organizationId) {
      id
      due_date_mode
      days_amount
      repeat_mode
      repeat_value
      selected_hour
      created_at
      updated_at
      organization_id
      organization_customer_id
      organization_project_id
      organization_invoice_id
    }
  }
`;

// ------ REMINDERS FUNCITONLAITY ------- //

export const fetchReminderSettings = async (organizationId) => {
  try {
    const reminderRes = await apolloClient.query({
      query: SETTINGS_REMINDS_FETCH_QUERY,
      variables: {
        organizationId,
      },
    });
    const data = reminderRes?.data?.userOrganizationReminderSettings;
    if (data) {
      const tempArray = [];
      data.forEach(obj => {
        if (obj.due_date_mode === 'BEFORE') {
          tempArray.push({
            days: obj.days_amount,
            reminderId: obj.id,
          });
        }
      });
      settingsReminderSignal.update({
        invoiceRemindersArray: tempArray,
        deleteInvoiceRemindersArray: [],
        automaticInvoiceReminders: tempArray.length > 0,
      });
    }
  } catch (e) {
    console.error('Error fetching Reminder Info:', e);
    triggerWarningAlert(e.message);
  }
};

export const saveChangesReminder = async (orgId) => {
  const {
    invoiceRemindersArray,
    deleteInvoiceRemindersArray,
  } = settingsReminderSignal.value;
  try {
    loaderSignal.update({
      isLoading: true,
      isLoadingMessage: 'Loading...',
    });
    // CREATE
    const tempArray = [];
    if (invoiceRemindersArray && invoiceRemindersArray.length > 0) {
      await invoiceRemindersArray.forEach(async (obj) => {
        if (!obj?.reminderId) { // Create
          const res = await apolloClient.mutate({
            mutation: SETTINGS_REMINDERS_CREATE_MUTATION,
            variables: {
              organizationId: orgId,
              data: {
                due_date_mode: 'BEFORE',
                days_amount: obj.days,
                repeat_mode: null,
                repeat_value: null,
                selected_hour: 10,
                organization_customer_id: null,
                organization_project_id: null,
                organization_invoice_id: null,
              },
            },
          });
          if (res.data.userOrganizationReminderSettingCreate) {
            tempArray.push({
              days: res.data.userOrganizationReminderSettingCreate.days_amount,
              reminderId: res.data.userOrganizationReminderSettingCreate.id,
            });
          }
        } else {
          tempArray.push(obj);
        }
        settingsReminderSignal.update({
          invoiceRemindersArray: tempArray,
          automaticInvoiceReminders: tempArray?.length > 0,
        });
      });
    }
    // DELETE
    if (deleteInvoiceRemindersArray.length > 0) {
      await deleteInvoiceRemindersArray.forEach(async (id) => {
        await apolloClient.mutate({
          mutation: SETTINGS_REMINDS_DELETE_MUTATION,
          variables: {
            organizationId: orgId,
            organizationReminderSettingId: id,
          },
        });
        settingsReminderSignal.update({
          invoiceRemindersArray: tempArray,
          deleteInvoiceRemindersArray: [],
        });
      });
    }
    triggerSuccessAlert('Successfully updated reminders');
    loaderSignal.reset();
  } catch (e) {
    console.error('Error creating or updating reminder', e);
    loaderSignal.reset();
    triggerWarningAlert(e.message);
  }
};

// ------ OVERDUE NOTIFICATIONS FUNCITONLAITY ------- //

export const fetchNotificationSettings = async (organizationId) => {
  try {
    const reminderRes = await apolloClient.query({
      query: SETTINGS_REMINDS_FETCH_QUERY,
      variables: {
        organizationId,
      },
    });
    const data = reminderRes?.data?.userOrganizationReminderSettings;
    if (data) {
      const tempArray = [];
      data.forEach(obj => {
        if (obj.due_date_mode === 'AFTER') {
          if (obj.repeat_mode === 'DAILY') {
            tempArray.push({
              type: 'daily',
              daily: Number(obj.days_amount),
              reminderId: obj.id,
            });
          } else if (obj.repeat_mode === 'WEEKLY') {
            tempArray.push({
              type: 'weekly',
              weekly: Number(obj.repeat_value),
              reminderId: obj.id,
            });
          } else if (obj.repeat_mode === 'MONTHLY') {
            tempArray.push({
              type: 'monthly',
              monthly: Number(obj.repeat_value),
              reminderId: obj.id,
            });
          }
        }
      });
      settingsReminderSignal.update({
        overdueRemindersArray: tempArray,
        deleteNotificationArray: [],
        automaticOverdueReminders: tempArray.length > 0,
      });
    }
  } catch (e) {
    console.error('Error fetching notification Info:', e);
    triggerWarningAlert(e.message);
  }
};

export const saveChangesNotification = async (orgId) => {
  const {
    overdueRemindersArray,
    deleteNotificationArray,
  } = settingsReminderSignal.value;
  try {
    loaderSignal.update({
      isLoading: true,
      isLoadingMessage: 'Loading...',
    });
    // CREATE
    const tempArray = [];
    if (overdueRemindersArray && overdueRemindersArray.length > 0) {
      await overdueRemindersArray.forEach(async (obj) => {
        if (!obj?.reminderId) { // Create
          if (obj.type === 'daily') {
            const res = await apolloClient.mutate({
              mutation: SETTINGS_REMINDERS_CREATE_MUTATION,
              variables: {
                organizationId: orgId,
                data: {
                  due_date_mode: 'AFTER',
                  days_amount: 0,
                  repeat_mode: 'DAILY',
                  repeat_value: Number(obj.daily),
                  selected_hour: Number(obj.daily),
                  organization_customer_id: null,
                  organization_project_id: null,
                  organization_invoice_id: null,
                },
              },
            });
            if (res.data.userOrganizationReminderSettingCreate) {
              tempArray.push({
                type: 'daily',
                daily: res.data.userOrganizationReminderSettingCreate.days_amount,
                reminderId: res.data.userOrganizationReminderSettingCreate.id,
              });
            }
          }
          if (obj.type === 'weekly') {
            const res = await apolloClient.mutate({
              mutation: SETTINGS_REMINDERS_CREATE_MUTATION,
              variables: {
                organizationId: orgId,
                data: {
                  due_date_mode: 'AFTER',
                  days_amount: 0,
                  repeat_mode: 'WEEKLY',
                  repeat_value: Number(obj.weekly),
                  selected_hour: 10,
                  organization_customer_id: null,
                  organization_project_id: null,
                  organization_invoice_id: null,
                },
              },
            });
            if (res.data.userOrganizationReminderSettingCreate) {
              tempArray.push({
                type: 'weekly',
                weekly: res.data.userOrganizationReminderSettingCreate.repeat_value,
                reminderId: res.data.userOrganizationReminderSettingCreate.id,
              });
            }
          }
          if (obj.type === 'monthly') {
            const res = await apolloClient.mutate({
              mutation: SETTINGS_REMINDERS_CREATE_MUTATION,
              variables: {
                organizationId: orgId,
                data: {
                  due_date_mode: 'AFTER',
                  days_amount: 0,
                  repeat_mode: 'MONTHLY',
                  repeat_value: Number(obj.monthly),
                  selected_hour: 10,
                  organization_customer_id: null,
                  organization_project_id: null,
                  organization_invoice_id: null,
                },
              },
            });
            if (res.data.userOrganizationReminderSettingCreate) {
              tempArray.push({
                type: 'monthly',
                monthly: res.data.userOrganizationReminderSettingCreate.repeat_value,
                reminderId: res.data.userOrganizationReminderSettingCreate.id,
              });
            }
          }
        } else {
          tempArray.push(obj);
        }
        settingsReminderSignal.update({
          overdueRemindersArray: tempArray,
          automaticOverdueReminders: tempArray?.length > 0,
        });
      });
    }
    // DELETE
    if (deleteNotificationArray.length > 0) {
      await deleteNotificationArray.forEach(async (id) => {
        await apolloClient.mutate({
          mutation: SETTINGS_REMINDS_DELETE_MUTATION,
          variables: {
            organizationId: orgId,
            organizationReminderSettingId: id,
          },
        });
        settingsReminderSignal.update({
          overdueRemindersArray: tempArray,
          deleteNotificationArray: [],
        });
      });
    }
    triggerSuccessAlert('Successfully updated notifications');
    loaderSignal.reset();
  } catch (e) {
    console.error('Error creating or updating notifications', e);
    loaderSignal.reset();
    triggerWarningAlert(e.message);
  }
};

export const saveCustomRecurrence = () => {
  const {
    overdueRemindersArray,
    tempReminderObj,
  } = settingsReminderSignal.value;
  const updatedArray = [...overdueRemindersArray];
  if (tempReminderObj.type === 'daily') {
    updatedArray.push({
      type: 'daily',
      daily: tempReminderObj.daily,
    });
  }
  if (tempReminderObj.type === 'weekly') {
    updatedArray.push({
      type: 'weekly',
      weekly: tempReminderObj.weekly,
    });
  }
  if (tempReminderObj.type === 'monthly') {
    updatedArray.push({
      type: 'monthly',
      monthly: tempReminderObj.monthly,
    });
  }
  settingsReminderSignal.update({
    ...settingsReminderSignal.value,
    isCustomRecurrenceOpen: false,
    overdueRemindersArray: updatedArray,
    tempReminderObj: {
      type: 'daily',
      daily: 1,
      weekly: null,
      monthly: 15,
    },
  });
};

export const addOrdinalSuffix = (number) => {
  if (number % 100 >= 11 && number % 100 <= 13) {
    return `${number}th`;
  }
  const lastDigit = number % 10;
  switch (lastDigit) {
    case 1:
      return `${number}st`;
    case 2:
      return `${number}nd`;
    case 3:
      return `${number}rd`;
    default:
      return `${number}th`;
  }
};

export const findTimeFromValue = (value) => {
  const res = timeArray.find(obj => obj.value === Number(value));
  return res.label;
};

export default {
  fetchReminderSettings,
  saveChangesReminder,
  saveCustomRecurrence,
  fetchNotificationSettings,
  saveChangesNotification,
  findTimeFromValue,
  addOrdinalSuffix,
};
