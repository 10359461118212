import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { signal } from '@preact/signals-react';
import Money from '#global/Money';
import { setActiveForm } from '#views/Statement/Statement.helpers';
import formatCardExpirationDate from '#utils/formatExpirationDate';
import UniversalInput from '../../../global/Inputs/UniversalInput';

const newCardInfoSignal = signal({
  holderName: '',
  cardNumber: '',
  expDate: '',
  cvc: '',
});

const cardsArraySignal = signal([]);
const currentCardSignal = signal({});

const CardInfo = ({ className }) => {
  const handleFormChange = (e) => {
    const inputId = e.target.name;
    newCardInfoSignal.value = {
      ...newCardInfoSignal.value,
      [inputId]: inputId === 'expDate' ? formatCardExpirationDate(e.target.value) : e.target.value,
    };
  };

  const handleSubmit = () => {
    currentCardSignal.value = newCardInfoSignal.value;
    cardsArraySignal.value = [...cardsArraySignal.value, [newCardInfoSignal.value]];
    setActiveForm('Processing');
  };
  const handleCancel = () => {
    setActiveForm('ChoosePayment');
  };

  return (
    <Container className={className}>
      <Row className="p-24 pb-8 border-2 border-gray-800 mb-8 border-bottom">
        <h2 className="mt-24 fw-700 text-primary">Payment Details</h2>
      </Row>
      <div className="d-flex flex-column px-80 pt-40 pb-72">
        <Form className="mb-0 pb-16">
          <Form.Group className="form-group mb-24">
            <Form.Label className="d-flex justify-content-start" htmlFor="cardHolder">
              <p className="lead fw-800 mb-0">Card Holder</p>
            </Form.Label>
            <UniversalInput
              placeholder="Full Name"
              name="holderName"
              value={newCardInfoSignal?.value?.holderName}
              className="mt-8"
              customOnChange={handleFormChange}
            />
          </Form.Group>
          <Form.Group className="form-group mb-16">
            <Form.Label className="d-flex justify-content-start" htmlFor="cardNumber"><p className="lead fw-800 mb-0">Card Number</p></Form.Label>
            <UniversalInput
              placeholder="#### #### #### ####"
              name="cardNumber"
              value={newCardInfoSignal?.value?.cardNumber}
              className="mt-8 mb-32"
              customOnChange={handleFormChange}
            />
          </Form.Group>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="form-group mb-16">
                <Form.Label className="d-flex justify-content-start" htmlFor="expDate">
                  <p className="lead fw-800 mb-0">Exp date</p>
                </Form.Label>
                <UniversalInput
                  placeholder="MM/YY"
                  name="expDate"
                  value={newCardInfoSignal?.value?.expDate}
                  customOnChange={handleFormChange}
                  className="mt-8"
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="form-group mb-16">
                <Form.Label className="d-flex justify-content-start" htmlFor="cvc">
                  <p className="lead fw-800 mb-0">CVC</p>
                </Form.Label>
                <UniversalInput
                  placeholder="***"
                  name="cvc"
                  type="password"
                  value={newCardInfoSignal?.value?.cvc}
                  customOnChange={handleFormChange}
                  className="mt-8"
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <Row className="d-flex justify-content-start align-content-center6 pt-16">
          <Col sm={8} xs={8} className="d-flex justify-content-start align-items-center">
            <Button
              variant="primary"
              className="flex-grow-1 px-40 py-8"
              onClick={() => handleSubmit()}
              disabled={
                !newCardInfoSignal?.value?.holderName ||
                !newCardInfoSignal?.value?.cardNumber ||
                !newCardInfoSignal?.value?.expDate ||
                !newCardInfoSignal?.value?.cvc
              }
            >
              <small className="text-primary-100 me-8">PAY</small>
              <Money amount={25000} className="text-primary-100" />
            </Button>
          </Col>
          <Col sm={4} xs={4} className="d-flex justify-content-end ps-0">
            <Button
              variant="outline-primary"
              label="CANCEL"
              className="text-dark flex-grow-1 py-8"
              onClick={handleCancel}
            >
              <small className="text-dark">CANCEL</small>
            </Button>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default CardInfo;
