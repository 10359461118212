/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Row, Col, Container, Card, Image, Modal, Button } from 'react-bootstrap';
import edit from '#images/edit.svg';
import closeButton from '#images/closeButton.svg';
import billCsvExample from '#images/billCsvExample.svg';
import { addBillManualSignal } from '../../../../signals/pages/Bills.signals';

const AddBillManualSelect = ({ handleNext }) => {
  const { infoModalOpen } = addBillManualSignal.value;
  return (
    <>
      <Row>
        <Col className="text-center">
          <h1 className="text-primary fw-700 pt-16 pb-24">Upload or add bills manually</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 className="pb-56 fw-400">Select your preferred way of adding bills</h2>
        </Col>
      </Row>
      <Container className="pb-48">
        <Card onClick={() => handleNext({ current: 'manualSelect', next: 'form' })} style={{ maxWidth: 620 }} className="cursor-pointer py-32 mb-24 justify-content-left mx-auto">
          <Container>
            <Row className="d-flex justify-content-left align-items-center px-16">
              <Col lg={2}>
                <Image width={54} src={edit} />
              </Col>
              <Col lg={9} className="text-start text-dark-900">
                <h5 className="fw-700">Enter bill details</h5>
                <p className="base mb-0">Add bill details manually</p>
              </Col>
            </Row>
          </Container>
        </Card>
      </Container>
      <Modal show={infoModalOpen} data-cy="info-modal" className="modal-white">
        <Modal.Header className="bg-secondary-300 w-100 d-block p-0">
          <Modal.Title>
            <Row className="pt-24 px-56">
              <Col className="d-flex justify-content-end cursor-pointer" onClick={() => addBillManualSignal.update({ infoModalOpen: false })}>
                <Image src={closeButton} height={12} />
              </Col>
            </Row>
            <Row className="text-center">
              <Col className="pb-24">
                <h4 className="fw-800 text-dark-900">Required Details for upload</h4>
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row className="mt-8 pb-48">
              <Col>
                <lead className="fw-400 text-dark-900">Don’t worry if you spreadsheet has other details, but please make sure it contains the following columns</lead>
              </Col>
            </Row>
            <Row>
              <Image src={billCsvExample} />
            </Row>
            <Row className="mt-40">
              <Col className="mx-72">
                <Button onClick={() => handleNext({ current: 'manualSelect', next: 'upload' })} type="primary" className="w-100 py-8">
                  UPLOAD .CSV FILE
                </Button>
              </Col>
            </Row>
            <Row className="mt-16">
              <Col className="mx-72 mb-48">
                <Button onClick={() => {}} type="button" className="w-100 py-8" variant="outline-primary border border-2 border-primary">
                  DOWNLOAD TEMPLATE
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddBillManualSelect;
