import { Row, Col, Image, Form, Button } from 'react-bootstrap';
import SecureFooter from '../SecureFooter';
import paymentCredDeb from '#images/paymentCredDeb.svg';
import paymentBank from '#images/paymentBank.svg';

const AutopayMethod = ({ handleNext }) => (
  <>
    <Row>
      <Col>
        <h2 className="mx-24 mb-48 fw-700 text-dark">First, let&apos;s determine your preferred payment method</h2>
      </Col>
    </Row>
    <Row>
      <Col>
        <Button
          onClick={() => handleNext({ current: 'method', next: 'card' })}
          className="p-16 w-100 mb-16 py-24 btn-card"
          variant="gray-250"
          style={{ borderRadius: 10 }}
        >
          <Row className="d-flex align-items-center">
            <Col xs={2} className="ms-auto">
              <Form.Check
                type="radio"
                id="credit"
              />
            </Col>
            <Col xs={2} className="d-flex justify-content-center align-items-center">
              <div className="d-flex justify-content-center align-items-center text-center">
                <Image src={paymentCredDeb} width={60} style={{ marginLeft: '-12px' }} />
              </div>
            </Col>
            <Col xs={8} className="text-start ps-0">
              <h5 className="fw-700">Credit Card/Debit Card</h5>
              <small>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</small>
            </Col>
          </Row>
        </Button>
      </Col>
    </Row>
    <Row>
      <Col>
        <Button
          onClick={() => handleNext({ current: 'method', next: 'ach' })}
          className="p-16 w-100 mb-24 py-24 btn-card"
          variant="gray-250"
          style={{ borderRadius: 10 }}
        >
          <Row className="d-flex align-items-center">
            <Col xs={2} className="ms-auto">
              <Form.Check
                type="radio"
                id="ach"
              />
            </Col>
            <Col xs={2} className="d-flex justify-content-center align-items-center">
              <div className="d-flex justify-content-center align-items-center text-center">
                <Image src={paymentBank} width={45} />
              </div>
            </Col>
            <Col xs={8} className="text-start ps-0">
              <h5 className="fw-700">ACH/Bank</h5>
              <small>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</small>
            </Col>
          </Row>
        </Button>
      </Col>
    </Row>
    <SecureFooter />
  </>
);

export default AutopayMethod;
