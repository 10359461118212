import { Col, Row } from 'react-bootstrap';
import { settingsOrganizationSignal } from '#signals/pages/Settings.signals';
import CompanyInfo from './components/CompanyInfo';
import OrganizationUsers from './components/OrganizationUsers';

const organizationTabs = [
  {
    id: 1,
    tab: 'company-info',
    title: 'Company Info',
    component: <CompanyInfo />,
  },
  {
    id: 2,
    tab: 'users',
    title: 'Users',
    component: <OrganizationUsers />,
  },
];

const OrganizationSettings = () => {
  const { currentTab } = settingsOrganizationSignal.value;
  return (
    <div>
      <h1 className="d-none d-lg-block d-xl-block">Organization Settings</h1>
      <Row className="mx-0 mt-24">
        {organizationTabs && organizationTabs.map((obj, idx) => (
          <Col
            key={idx}
            className={`text-center mt-auto px-0 w-100 border-bottom  ${currentTab === obj.tab ? 'border-3 border-secondary text-primary fw-bold' : 'text-dark-900'}`}
            role="button"
            tabIndex={0}
            onClick={() => settingsOrganizationSignal.update({
              ...settingsOrganizationSignal.value,
              currentTab: obj.tab,
            })}
          >
            <p className="mb-0">{obj.title}</p>
          </Col>
        ))}
      </Row>
      <Row className="mx-0">
        {organizationTabs && organizationTabs.map((obj, idx) => {
          if (obj.tab === currentTab) {
            return (
              <div key={idx} className="px-0">
                {obj.component}
              </div>
            );
          }
          return '';
        })}
      </Row>
    </div>
  );
};

export default OrganizationSettings;
