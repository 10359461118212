/* eslint-disable no-unused-vars */
import { Col, Modal, Row, Image } from 'react-bootstrap';
import comingSoonBody from '../../../images/comingSoonBody.svg';

// EXAMPLES
// { /* Video Coming Soon Modal */ }
// { /* <ComingSoonModal show={isComingSoonOpen} handleClose={() => setIsComingSoonOpen(!isComingSoonOpen)} videoLink="https://www.youtube.com/embed/EQZHGCo3Q2s" /> */ }
// { /* Items Coming Soon Modal */ }
// { /* <ComingSoonModal show={isComingSoonOpen} handleClose={() => setIsComingSoonOpen(!isComingSoonOpen)} items={['item1', 'item2', 'item3']} /> */ }
const ComingSoonModal = ({
  show,
  handleClose,
  videoLink,
  items,
}) => {
  if (videoLink) {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="coming-soon-modal-header-image" />
        <Modal.Title className="coming-soon-modal-title">
          <h2 className="text-center text-primary pb-0 mb-0" style={{ zIndex: 990 }}>Coming Soon!</h2>
          <small className="text-center text-black pb-0 fw-400" style={{ zIndex: 990 }}>This is going to be so cool!</small>
        </Modal.Title>
        <Modal.Body className="coming-soon-modal-body">
          <iframe
            className="rounded mt-16"
            width="auto"
            height="auto"
            src={videoLink}
            frameBorder="0"
            allowFullScreen
            title="Coming Soon Video"
          />
        </Modal.Body>
      </Modal>
    );
  }
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton className="coming-soon-modal-header" />
      <Modal.Title className="coming-soon-modal-title">
        <h2 className="text-center text-primary pb-0 mb-0">Coming Soon!</h2>
        <small className="text-center text-black pb-0 fw-400">This is going to be so cool!</small>
      </Modal.Title>
      <Modal.Body className="coming-soon-modal-body">
        <Row>
          <Col sm={4}>
            <Image src={comingSoonBody} width="auto" height="auto" />
          </Col>
          <Col sm={8} className="text-start my-auto">
            <small>This will be where we talk about the new feature and all of the things it will do.</small>
            <ul className="mt-16 custom-bullet">
              {items && items.map((item, idx) => (
                <li key={idx}>{item}</li>
              ))}
            </ul>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ComingSoonModal;
