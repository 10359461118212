import { Placeholder, Row } from 'react-bootstrap';

const Loadable = ({ signal, options, children, template, className }) => {
  if (signal.value.isLoading) {
    return (
      <Placeholder as={options?.as || 'p'} animation={options?.animation || 'wave'} className="mb-0">
        {template === 'name-nav' && (
          <>
            <Row>
              <Placeholder xs={5} {...options?.breakpoints} as="h3" />
              <Placeholder xs={5} {...options?.breakpoints} className="ms-8" as="h3" />
            </Row>
            <Placeholder xs={12} {...options?.breakpoints} as="p" className="mb-0" />
          </>
        )}
        {template === 'profilePic-nav' && (
          <div className="h-100 d-flex align-items-center justify-content-around">
            <Placeholder {...options?.breakpoints} className="rounded-circle border border-primary position-relative" style={{ height: '45px', width: '45px' }} />
          </div>
        )}
        {!template && (
          <Placeholder xs={12} {...options?.breakpoints} className={className} />
        )}
      </Placeholder>
    );
  }

  return (<div>{children}</div>);
};

export default Loadable;
