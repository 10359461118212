import { gql } from '@apollo/client';
import { apolloClient } from '#api';
import { triggerSuccessAlert, triggerWarningAlert } from '#global/Alert/Alert';
import { forgotPasswordSignal } from '#signals/Authentication.signals';
import { validateEmail } from '#utils/validateInput';

const RESET_PASSWORD_START_MUTATION = gql`
  mutation UserPasswordResetStart($data: UserPasswordResetStartSchema!){
    userPasswordResetStart(data: $data)
  }
`;

export const handleFormChange = (e) => forgotPasswordSignal.update({ [e.target.name]: e.target.value });

export const handleForgotPasswordPartial = async () => {
  try {
    forgotPasswordSignal.update({ submitting: true });

    const { email } = forgotPasswordSignal.value;
    validateEmail(email);

    await apolloClient.mutate({ mutation: RESET_PASSWORD_START_MUTATION, variables: { data: { email } } });
    forgotPasswordSignal.reset();
    return triggerSuccessAlert('Reset link requested successfully!');
  } catch (err) {
    return triggerWarningAlert(err.message);
  } finally {
    forgotPasswordSignal.update({ submitting: false });
  }
};
