import LoginForm from './components/LoginForm';
import { AuthLayout } from '#global/Layout';

const Login = () => (
  <AuthLayout>
    <LoginForm />
  </AuthLayout>
);

export default Login;
