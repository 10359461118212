import { Button, Form, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AuthLayout } from '#global/Layout';
import DontHaveAnAccountLink from '#views/Auth/components/DontHaveAnAccountLink';
import { forgotPasswordSignal } from '#signals/Authentication.signals';
import { handleForgotPasswordPartial } from './ForgotPassword.helpers';
import UniversalInput from '#global/Inputs/UniversalInput';

const ForgotPassword = () => (
  <AuthLayout>
    <Form.Group className="px-40">
      <h2 className="fw-700 text-center mb-32">Password Reset</h2>
      <Form.Label htmlFor="EmailAddress"><strong>Email address</strong></Form.Label>
      <UniversalInput
        className="mb-40"
        aria-describedby="emailHelp"
        placeholder="Enter email address"
        value={forgotPasswordSignal.value.email}
        name="email"
        signal={forgotPasswordSignal}
      />
      <Button variant="primary" type="submit" onClick={handleForgotPasswordPartial} className="w-100 btn-lg mb-16" disabled={forgotPasswordSignal.value.submitting}>SEND PASSWORD RESET</Button>
      <LinkContainer to="/login">
        <Nav.Link className="mb-32 text-decoration-underline p-0 text-primary fw-700 text-center">Back to Login</Nav.Link>
      </LinkContainer>
      <DontHaveAnAccountLink />
    </Form.Group>
  </AuthLayout>
);

export default ForgotPassword;
