import { Container, Row, Col, Button, Image, Tab, InputGroup, Nav, Modal } from 'react-bootstrap';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import ContentWrapper from '#global/ContentWrapper';
import filter from '#images/filter-alt.svg';
import magnifying from '#images/magnifying.svg';
import vendorsArrow from '#images/vendorsArrow.svg';
import vendorsPeople from '#images/vendorsPeople.svg';
import vendorsEmpty from '#images/vendorsEmpty.svg';
import VendorClientContact from '#global/VendorClientSidePanel/VendorClientContact';
import VendorClientDetails from '#global/VendorClientSidePanel/VendorClientDetails';
import VendorClientBills from '#global/VendorClientSidePanel/VendorClientBills';
import VendorCard from './VendorCard';
import VendorCustomerProjects from '#global/VendorClientSidePanel/VendorCustomerProjects';
import { getAllVendors } from './Vendors.helpers';
import { vendorListSignal, vendorListDataSignal } from '#signals/pages/Vendors.signals';
import { userSignal } from '#signals/User.signals';
import Money from '../../global/Money';
import useWindowSize from '../../../hooks/useWindowSize';
import arrowLeft from '#images/arrowLeft.svg';
import UniversalInput from '../../global/Inputs/UniversalInput';

const Vendors = () => {
  const history = useHistory();
  const { isBreakpointDown } = useWindowSize();

  const {
    openTab,

    searchTerm,

    selectedSorting,
    take,

    selectedVendor,

    isMobileModalOpen,
  } = vendorListSignal.value;

  const organizationId = useMemo(() => userSignal?.value?.user?.organizations?.[0]?.organization_id, [userSignal?.value?.user?.organizations]);

  const list = useMemo(() => vendorListDataSignal.value, [vendorListDataSignal.value]);
  const items = useMemo(() => list?.edges || [], [list]);

  const loadVendors = useCallback(async ({
    nameSearchTerm,
  } = {}) => {
    vendorListDataSignal.update({
      is_loading: true,
    });

    try {
      const response = await getAllVendors({
        organizationId,

        ...(!!nameSearchTerm && { name: nameSearchTerm }),

        ...(items.length ? {
          after: items[items.length - 1].cursor,
          first: take,
        } : {
          skip: 0,
          take,
        }),

        sorting: selectedSorting,
      });

      const data = response.data.userOrganizationVendors;

      vendorListDataSignal.update({
        ...data,
        is_loading: false,
      });
    } catch (error) {
      vendorListDataSignal.update({ is_loading: false });
    }
  }, [organizationId, items, selectedSorting, take]);

  useEffect(() => {
    vendorListSignal.reset();
    vendorListDataSignal.reset();
    loadVendors();
  }, []);

  useEffect(() => {
    vendorListDataSignal.update({
      edges: [],
      selectedVendor: null,
      isMobileModalOpen: false,
    });

    const timeout = setTimeout(() => {
      if (searchTerm) {
        loadVendors({
          nameSearchTerm: searchTerm,
        });
      }
    },
    1000);

    return () => clearTimeout(timeout);
  }, [searchTerm]);

  const handleSelectVendor = (vendor) => {
    vendorListSignal.update({
      selectedVendor: vendor,
    });
    if (isBreakpointDown('md')) {
      vendorListSignal.update({
        isMobileModalOpen: true,
      });
    }
  };

  const handleUpdateVendor = async (updatedVendor) => {
    const prevVendors = [...vendorListDataSignal.value.edges];
    const updatedVendors = prevVendors.map(c => ((c.node.id === updatedVendor?.data?.userOrganizationVendorUpdate?.id) ? { cursor: Buffer.from(c.node.id, 'base64'), node: { ...c, ...updatedVendor?.data?.userOrganizationVendorUpdate } } : c));
    vendorListDataSignal.update({ edges: updatedVendors });
    vendorListSignal.update({
      selectedVendor: prevVendors.find(c => c.node.id === updatedVendor?.data?.userOrganizationVendorUpdate?.id),
    });
  };

  return (
    <ContentWrapper>
      <Container fluid className="px-lg-40">
        <Row>
          <Col xs={12} lg={5} className="min-vh-100 left-panel-border pt-32 pt-lg-88 px-0">
            <Row className="mr-24 mb-md-32 pe-lg-24">
              <Col className="d-flex justify-content-between align-items-center">
                <h1 style={{ fontSize: '40px' }} className="fw-400 mb-0">Vendors</h1>
                <Button className="mb-8" onClick={() => history.push('/vendors/add')} variant="primary btn-circle d-flex justify-content-center align-items-center">
                  <span data-cy="add-vendor" style={{ font: 'black !important' }}>+</span>
                </Button>
              </Col>
            </Row>
            <Row className="d-flex justify-content-between align-items-center mb-md-24 mx-0 pe-lg-24">
              <Col xs={11} className="ps-0">
                <div className="w-100">
                  <InputGroup className="custom-searchbox py-8">
                    <InputGroup.Text id="basic-addon1" className="ps-32"><Image src={magnifying} /></InputGroup.Text>
                    <UniversalInput
                      name="searchTerm"
                      value={searchTerm}
                      signal={vendorListSignal}
                    />
                  </InputGroup>
                </div>
              </Col>
              <Col xs={1} className="ps-0">
                <div className="d-flex justify-content-end">
                  <Image src={filter} height={17} />
                </div>
              </Col>
            </Row>
            {!items.length && (
              <Row className="mx-0 p-0">
                <Image className="w-100 px-0" src={vendorsEmpty} />
              </Row>
            )}
            {items.map(vendor => (
              <Row key={vendor.node.id} className="mx-0 pe-24">
                <VendorCard item={vendor} onSelect={(v) => handleSelectVendor(v)} handleClickInvoices={() => vendorListSignal.update({ openTab: 'bills' })} handleClickProjects={() => vendorListSignal.update({ openTab: 'projects' })} />
              </Row>
            ))}
            {!!list?.pageInfo?.hasNextPage && (
              <Row className="mx-0 pe-24">
                <Col className="d-flex justify-content-center">
                  <Button variant="primary" onClick={() => loadVendors()} className="px-40">Load More</Button>
                </Col>
              </Row>
            )}
          </Col>
          <Modal show={isMobileModalOpen} fullscreen className="modal-white d-xl-none">
            {selectedVendor && (
              <Container>
                <Row className="sticky-top bg-white py-8">
                  <Col>
                    <Button
                      variant="transparent"
                      className="border-0 p-0 mx-auto fw-800 text-primary"
                      onClick={() => vendorListSignal.update({ isMobileModalOpen: false })}
                    >
                      <Image src={arrowLeft} />
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col className="pt-16">
                    <h1 className="mb-0">{selectedVendor.name}</h1>
                  </Col>
                </Row>
                <Row className="py-16 my-16 bg-gray-250" style={{ borderRadius: '15px' }}>
                  <Col className="d-flex justify-content-between align-items-center px-24">
                    <div>
                      <p className="lead fw-800 mb-0">Balance</p>
                      <h3><Money amount={selectedVendor.node.bill_statement.total_amount - selectedVendor.node.bill_statement.paid_amount} /></h3>
                    </div>
                    <div>
                      <p className="lead fw-800 mb-0">Overdue</p>
                      <h3><Money amount={selectedVendor.node.bill_statement.overdue_amount} /></h3>
                    </div>
                    {/* <div>
                      <Button variant="primary" onClick={() => history.push('/payment')} className="px-32">PAY BILLS</Button>
                    </div> */}
                  </Col>
                </Row>
                <Row className="d-flex align-items-center">
                  <Col className="bg-white">
                    <Tab.Container
                      activeKey={openTab}
                      onSelect={(t) => vendorListSignal.update({ openTab: t })}
                    >
                      <Nav variant="tabs" className="custom-tabs">
                        <Nav.Item className="w-25">
                          <Nav.Link className="text-center pt-24" eventKey="details">Details</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="w-25">
                          <Nav.Link className="text-center pt-24" eventKey="contact">Contacts</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="w-25">
                          <Nav.Link className="text-center pt-24" eventKey="bills">Bills</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="w-25">
                          <Nav.Link className="text-center pt-24" eventKey="projects">Projects</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="details" title="Details" className="ms-0">
                          <VendorClientDetails organization={selectedVendor} onUpdateVendor={handleUpdateVendor} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="contact" title="Contact">
                          <VendorClientContact selectedObj={selectedVendor} isVendor />
                        </Tab.Pane>
                        <Tab.Pane eventKey="bills" title="Bills">
                          <VendorClientBills items={selectedVendor.node.bill_statement.data.edges} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="projects" title="Projects">
                          <VendorCustomerProjects />
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Col>
                </Row>
              </Container>
            )}
          </Modal>
          <Col lg={7} className="ps-24 mt-40 d-none d-lg-block">
            {selectedVendor ? (
              <>
                <Row className="mx-0 mt-48">
                  <Col className="d-flex justify-content-between">
                    <h1>{selectedVendor.node.name}</h1>
                  </Col>
                </Row>
                <Row className="mx-0 px-56 py-32 mb-24 mt-48 mb-56 bg-gray-250" style={{ borderRadius: '15px' }}>
                  <Col className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="lead fw-800">Balance</p>
                      <h1><Money amount={selectedVendor.node.bill_statement.total_amount - selectedVendor.node.bill_statement.paid_amount} /></h1>
                    </div>
                    <div>
                      <p className="lead fw-800">Overdue</p>
                      <h1><Money amount={selectedVendor.node.bill_statement.overdue_amount} /></h1>
                    </div>
                    {/* <div>
                      <Button variant="primary" onClick={() => history.push('/payment')} className="px-40">PAY BILLS</Button>
                    </div> */}
                  </Col>
                </Row>
                <Row className="d-flex align-items-center">
                  <Col>
                    <Tab.Container
                      activeKey={openTab}
                      onSelect={(t) => vendorListSignal.update({ openTab: t })}
                    >
                      <Nav variant="tabs" className="custom-tabs">
                        <Nav.Item className="w-25">
                          <Nav.Link className="text-center pt-24" eventKey="details">Details</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="w-25">
                          <Nav.Link className="text-center pt-24" eventKey="contact">Contacts</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="w-25">
                          <Nav.Link className="text-center pt-24" eventKey="bills">Bills</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="w-25">
                          <Nav.Link className="text-center pt-24" eventKey="projects">Projects</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="details" title="Details" className="ms-0">
                          <VendorClientDetails organization={selectedVendor} onUpdateVendor={handleUpdateVendor} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="contact" title="Contact">
                          <VendorClientContact selectedObj={selectedVendor} isVendor />
                        </Tab.Pane>
                        <Tab.Pane eventKey="bills" title="Bills">
                          <VendorClientBills items={selectedVendor.node.bill_statement.data.edges} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="projects" title="Projects">
                          <VendorCustomerProjects />
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Image src={vendorsArrow} height="150" />
                <h2 className="text-primary fw-700 text-center">Let&apos;s get some vendors added so that you can start working together!</h2>
                <Image src={vendorsPeople} className="h-auto w-75 mt-24 d-flex mx-auto" />
              </>
            )}
          </Col>
        </Row>
      </Container>
    </ContentWrapper>
  );
};

export default Vendors;
