import { Row, Col, Container, Card, Image, Modal, Button, Dropdown, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { getIntuitOathUrl, connectIntuit, getIntuitPushOptions, setIntuitPushOptions } from '#views/Invoices/Invoices.helpers';
import { userSignal } from '#signals/User.signals';
import { syncingSignal } from '#signals/Global.signals';
import { intuitSignal } from '#signals/Authentication.signals';
import { addCustomerSyncSignal } from '#signals/pages/Customers.signals';
import intuit from '#images/intuit.svg';
import freshbooks from '#images/freshbooks.svg';
import smileIcon from '#images/smileIcon.svg';
import quickbooksLogo from '#images/quickbooksLogo.svg';
import waveLogo from '#images/wavelogo.svg';
import zohoLogo from '#images/zohologo.svg';
import sync from '#images/sync.svg';

const AddCustomerSync = () => {
  const {
    isQuickbooksConnected,
    connectingModalOpen,
    isConfiguringOptions,
    pushOptions,
    selectedPushItem,
    selectedPushAccount,
    pullToggle,
    pushToggle,
  } = addCustomerSyncSignal.value;
  const history = useHistory();

  const organizationId = userSignal?.value?.user?.organizations?.[0]?.organization_id;
  const { redirectUrl } = intuitSignal.value;

  const startIntuitAuth = async () => {
    const { data } = await getIntuitOathUrl({ organizationId });
    const url = data.userOrganizationAcctProviderGetOAuthUrl;
    window.location.href = url;
  };

  useEffect(() => {
    const connect = async () => {
      addCustomerSyncSignal.update({
        connectingModalOpen: true,
      });
      const connectRes = await connectIntuit({ organizationId, url: redirectUrl });
      if (connectRes?.data) {
        intuitSignal.update({ redirectUrl: '' });
        const pushOptionsRes = await getIntuitPushOptions({ organizationId });
        addCustomerSyncSignal.update({
          pushOptions: pushOptionsRes.data.userOrganizationAcctProviderConnConfigurationPushOptionsGet,
          isConfiguringOptions: true,
        });
      }
    };

    if (redirectUrl) {
      connect();
    }
  }, [redirectUrl]);

  const saveOptions = async () => {
    await setIntuitPushOptions({ organizationId, selectedPushItem, selectedPushAccount });
    addCustomerSyncSignal.update({
      isQuickbooksConnected: true,
      isConfiguringOptions: false,
    });
    syncingSignal.update({ isSyncing: true });
  };

  return (
    <>
      <Row>
        <Col className="text-center">
          <h1 className="text-primary fw-700 pt-16 pb-24">Connect your accounting software</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 className="pb-64 pb-lg-112">We will automatically import all of your customers.</h2>
        </Col>
      </Row>
      <Container>
        <Row>
          <Col xs={0} lg={1} />
          <Col lg={2}>
            <Card onClick={startIntuitAuth} style={{ border: '1px solid #92DEE0' }} className="cursor-pointer custom-shadow pt-48 pb-48 align-items-center justify-content-center mb-16 mb-lg-0">
              <Image width={94} src={intuit} />
            </Card>
          </Col>
          <Col lg={2}>
            <Card style={{ maxHeight: '120px' }} className="custom-shadow pt-40 pb-40 align-items-center justify-content-center mb-16 mb-lg-0">
              <Image width={130} src={freshbooks} />
              <p className="lead text-dark-500 fw-800 mb-0 pt-8">coming soon</p>
            </Card>
          </Col>
          <Col lg={2}>
            <Card style={{ maxHeight: '120px' }} className="custom-shadow pt-40 pb-40 align-items-center justify-content-center mb-16 mb-lg-0">
              <Image width={130} src={zohoLogo} />
              <p className="lead text-dark-500 fw-800 mb-0 pt-8">coming soon</p>
            </Card>
          </Col>
          <Col lg={2}>
            <Card style={{ maxHeight: '120px' }} className="custom-shadow pt-40 pb-40 align-items-center justify-content-center mb-16 mb-lg-0">
              <Image width={130} src={freshbooks} />
              <p className="lead text-dark-500 fw-800 mb-0 pt-8">coming soon</p>
            </Card>
          </Col>
          <Col lg={2}>
            <Card style={{ maxHeight: '120px' }} className="custom-shadow pt-40 pb-40 align-items-center justify-content-center mb-16 mb-lg-0">
              <Image width={130} src={waveLogo} />
              <p className="lead text-dark-500 fw-800 mb-0 pt-8">coming soon</p>
            </Card>
          </Col>
          <Col lg={1} />
        </Row>
        <Modal show={connectingModalOpen} className="modal-white">
          <Modal.Body>
            <Container fluid className="my-40">
              <Row className="mt-24">
                <Col className="d-flex justify-content-center align-items-center">
                  <div className="mx-8">
                    <Image width={76} src={smileIcon} />
                  </div>
                  <div className="mx-8">
                    <Image width={40} src={sync} />
                  </div>
                  <div className="mx-8">
                    <Image width={76} src={quickbooksLogo} />
                  </div>
                </Col>
              </Row>
              <Row className="mt-16">
                <h2 className="fw-700">{isQuickbooksConnected ? 'Success!' : 'Connecting to your quickbooks account'}</h2>
              </Row>
              <Row className="mt-16">
                <h4 className="fw-400 text-dark-900">{isQuickbooksConnected ? 'Quickbooks has been successfully connected. Your customers are currently syncing.' : 'Please stay on your accounting browser to finish connecting your account software to accru.'}</h4>
              </Row>
              {isConfiguringOptions && (
                <>
                  <Row className="mt-16">
                    <h4 className="fw-400 text-dark-900">Configure your quickbooks connection</h4>
                  </Row>
                  <Row>
                    <h5 className="text-start">Select default invoice item:</h5>
                    <Dropdown
                      className=""
                      onSelect={(e) => addCustomerSyncSignal.update({
                        selectedPushItem: e,
                      })}
                    >
                      <Dropdown.Toggle data-cy="filter-select" variant="primary-hover" id="dropdown-basic" className="rounded w-100 text-start bg-white d-flex align-items-center">
                        <h4 className="me-auto fw-400 mb-0">{pushOptions?.default_invoice_item?.find(i => i.value === selectedPushItem)?.label || 'Select'}</h4>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="p-8 w-100">
                        {pushOptions?.default_invoice_item?.map(item => (
                          <Dropdown.Item eventKey={item.value} className="rounded">{item.label}</Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Row>
                  <Row className="mt-16">
                    <h5 className="text-start">Select default expense account:</h5>
                    <Dropdown
                      className=""
                      onSelect={(e) => addCustomerSyncSignal.update({
                        selectedPushAccount: e,
                      })}
                    >
                      <Dropdown.Toggle data-cy="filter-select" variant="primary-hover" id="dropdown-basic" className="rounded w-100 text-start bg-white d-flex align-items-center">
                        <h4 className="me-auto fw-400 mb-0">{pushOptions?.default_bill_expense_account?.find(i => i.value === selectedPushAccount)?.label || 'Select'}</h4>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="p-8 w-100">
                        {pushOptions?.default_bill_expense_account?.map(acc => (
                          <Dropdown.Item eventKey={acc.value} className="rounded">{acc.label}</Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Row>
                  <Row>
                    <Form className="text-start mt-16">
                      <Form.Check
                        type="radio"
                        label="Automatically pull from Quickbooks"
                        checked={pullToggle}
                        onClick={() => addCustomerSyncSignal.update({
                          pullToggle: !pullToggle,
                        })}
                      />
                      <Form.Check
                        type="radio"
                        label="Automatically sync to quickbooks (Coming soon!)"
                        disabled
                        checked={pushToggle}
                        onClick={() => addCustomerSyncSignal.update({
                          pushToggle: !pushToggle,
                        })}
                      />
                    </Form>
                  </Row>
                </>
              )}
              <Row className="mt-16">
                <Col>
                  {isConfiguringOptions ? (
                    <Button onClick={() => saveOptions()} type="primary" disabled={!selectedPushItem || !selectedPushAccount} className="px-64">
                      CONFIRM SETTINGS
                    </Button>
                  ) : (
                    <Button onClick={() => history.push('/customers')} type="primary" disabled={!isQuickbooksConnected} className="px-64">
                      BACK TO CUSTOMER LIST
                    </Button>
                  )}
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
};

export default AddCustomerSync;
