import { Card, Row, Col, Image, Button, Form } from 'react-bootstrap';
import projectsConnections from '#images/projectsConnections.svg';
import checkCircleIcon from '#images/checkCircle.svg';
import { customerConnectionsSignal } from '#signals/pages/Customers.signals';

const VendorCustomerConnections = () => {
  const { syncContactInfo, connected } = customerConnectionsSignal.value;

  return (
    <Card className="bg-gray-250">
      {!connected ? (
        <Row className="m-0">
          <Col sm={6} className="my-auto ps-40 py-16">
            <h3 className="text-dark fw-700">Connect with this Organization</h3>
            <p className="text-dark-900 fw-350">This will enable you to sync their contact information, fostering smoother communication, while also streamlining your financial workflow and reducing manual data entry efforts.</p>
            <Button
              className="px-16"
              onClick={() => customerConnectionsSignal.update({
                connected: true,
              })}
            >LET&apos;S CONNECT
            </Button>
          </Col>
          <Col sm={6} className="pe-0">
            <Image src={projectsConnections} width="100%" height="100%" />
          </Col>
        </Row>
      ) : (
        <Row className="m-0">
          <Col sm={12} className="text-center p-40">
            <Image src={checkCircleIcon} className="d-flex justify-content-center mx-auto mb-16" />
            <h3 className="text-primary fw-700">You are Connected with this Organization </h3>
            <hr className="border-gray-100" />
            <Row>
              <Col sm={10} className="text-start">
                <h5 className="fw-500 text-dark">Sync Contact Info</h5>
                <p className="text-dark-900 fw-350">Syncing your bills in the accounting app streamlines your financial transactions and ensures accurate record-keeping, making it easier to track and manage your expenses.</p>
              </Col>
              <Col sm={2}>
                <Form.Check
                  className="form-check-radio-custom"
                  type="switch"
                  name="syncContactInfo"
                  checked={syncContactInfo}
                  onChange={() => customerConnectionsSignal.update({
                    syncContactInfo: !syncContactInfo,
                  })}
                />
              </Col>
            </Row>
            <hr className="border-gray-100" />
            <Button
              className="px-24 mt-16"
              variant="outline-primary"
              onClick={() => customerConnectionsSignal.update({
                connected: false,
              })}
            >
              DISCONNECT
            </Button>
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default VendorCustomerConnections;
