import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Card, Col, Container, Form, Row, Image } from 'react-bootstrap';
import moment from 'moment/moment';
import makeid from '#utils/makeId';
import ContentWrapper from '#global/ContentWrapper';
import Money from '#global/Money';
import StatusPills from '#global/StatusPills';
import ComponentLoader from '#global/ComponentLoader';
import { userSignal } from '#signals/User.signals';
import { billStatementSignal, billStatementSignalInitialState, billsFunctionalitySignal } from '#signals/pages/Bills.signals';
import { getBillStatement } from './Bills.helpers';
import noBills from '#images/noInvoices.svg';
import Pagination from '#global/Pagination';
import DatePicker from '../../global/Inputs/DatePicker';
import SelectInput from '../../global/Inputs/SelectInput';
import { batchActionsArray } from '#utils/selectArrays';
import { statusArray } from '../../../utils/selectArrays';

const QUERY_TAKE_AMOUNT = 20;

const Bills = () => {
  const location = useLocation();
  const history = useHistory();

  const {
    batchActions,
    selectedVendorId,
    selectedStartDate,
    selectedEndDate,
    selectedStatus,
    selectedCurrency,

    selectedSorting,

    selectedBills,

    page,
    perPage,
  } = billsFunctionalitySignal.value;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const organizationId = useMemo(() => userSignal?.value?.user?.organizations?.[0]?.organization_id, [userSignal?.value?.user?.organizations]);

  const statement = useMemo(() => billStatementSignal?.value, [billStatementSignal?.value]);
  const isLoading = useMemo(() => statement.is_loading, [statement]);
  const items = useMemo(() => statement?.data?.edges || [], [statement]);

  const totalOpen = useMemo(() => (typeof statement.total_amount === 'number' && typeof statement.paid_amount === 'number' ? statement.total_amount - statement.paid_amount : 0), [statement]);
  const totalOverdue = useMemo(() => (typeof statement.overdue_amount === 'number' ? statement.overdue_amount : null), [statement]);
  const totalCount = useMemo(() => statement?.data?.totalCount, [statement]);

  useEffect(() => {
    const loadBills = async (params) => {
      const { skip = 0, take = QUERY_TAKE_AMOUNT } = params || {};

      billStatementSignal.update({
        ...(skip === 0 && billStatementSignalInitialState),
        is_loading: true,
      });

      try {
        const response = await getBillStatement({
          organizationId,
          ...(selectedVendorId && { organizationVendorId: selectedVendorId }),

          ...(selectedCurrency && { currency: selectedCurrency }),
          ...(selectedStartDate && { startDate: selectedStartDate }),
          ...(selectedEndDate && { endDate: selectedEndDate }),
          ...(selectedStatus.toUpperCase() !== 'ALL' && { status: selectedStatus.toUpperCase() }),

          skip: (page - 1) * take,
          take,

          sorting: selectedSorting,
        });

        const data = response.data.userOrganizationBillStatement;

        billStatementSignal.update({
          ...data,
          is_loading: false,
        });
      } catch (error) {
        billStatementSignal.update({ is_loading: false });
      }
    };

    billsFunctionalitySignal.reset();
    billStatementSignal.reset();
    loadBills();
  }, [
    selectedVendorId,

    selectedStartDate,
    selectedEndDate,

    selectedStatus,
    selectedCurrency,

    selectedSorting,

    page,
    perPage,
  ]);

  const handleBillClickOpen = useCallback((bill) => {
    history.push(`/bills/${bill.id}`, { bill });
  }, []);

  const handleBillSelectToggle = useCallback((id) => {
    billsFunctionalitySignal.update({ selectedBills: selectedBills.includes(id) ? selectedBills.filter(i => i !== id) : [...selectedBills, id] });
  }, [selectedBills]);

  const handleSelectAllBills = useCallback((checked) => {
    billsFunctionalitySignal.update({ selectedBills: checked ? items.map(({ node }) => node.id) : [] });
  }, [selectedBills]);

  return (
    <ContentWrapper fluid className="min-vh-100 w-100 p-lx-auto p-0">
      <Container fluid className="bg-gray-250 p-0 min-vh-100">
        <Container fluid className="p-0 pt-80">
          <div className="d-flex justify-content-center align-items-center text-center">
            <div className="me-2 pe-2">
              <h1 className="text-center me-2">Bills</h1>
            </div>
            <Button className="mb-8" onClick={() => history.push('/bills/add')} variant="primary btn-circle">
              <span data-cy="add-bill" style={{ font: 'black !important' }}>+</span>
            </Button>
          </div>
          <Container>
            <Row className="my-lg-48 mx-16">
              <Col md={6} className="my-16 my-md-0">
                <Card className="p-16 custom-shadow">
                  <h5 className="text-primary fw-600">Open:</h5>
                  <h1>{typeof totalOpen === 'number' ? <Money amount={totalOpen} /> : '$ --.--'}</h1>
                </Card>
              </Col>
              <Col md={6} className="mb-16 mb-md-0">
                <Card className="p-16 custom-shadow">
                  <h5 className="text-primary fw-600">Overdue:</h5>
                  <h1>{typeof totalOverdue === 'number' ? <Money amount={totalOverdue} /> : '$ --.--'}</h1>
                </Card>
              </Col>
            </Row>
          </Container>
        </Container>
        {isLoading ? <ComponentLoader /> : (
          <Container fluid className="p-0">
            <Container>
              <Row className="ms-24 mx-lg-24">
                <Col>
                  <SelectInput
                    name="batchActions"
                    value={batchActions}
                    disabled={isLoading || selectedBills.length}
                    options={batchActionsArray}
                    customOnChange={() => console.log('todo')}
                    signal={billsFunctionalitySignal}
                  />
                </Col>
                <Col>
                  <SelectInput
                    name="selectedStatus"
                    value={selectedStatus}
                    disabled={isLoading}
                    options={statusArray}
                    signal={billsFunctionalitySignal}
                  />
                </Col>
                <Col>
                  <Form>
                    <Form.Group>
                      <DatePicker
                        name="selectedStartDate"
                        placeholder="Start Date"
                        value={selectedStartDate}
                        signal={billsFunctionalitySignal}
                      />
                    </Form.Group>
                  </Form>
                </Col>
                <Col>
                  <Form>
                    <Form.Group>
                      <DatePicker
                        name="selectedEndDate"
                        placeholder="End Date"
                        value={selectedEndDate}
                        signal={billsFunctionalitySignal}
                      />
                    </Form.Group>
                  </Form>
                </Col>
                <Col>
                  <Form>
                    <Form.Group>
                      <SelectInput
                        name="selectedVendorId"
                        value={selectedVendorId}
                        options={[
                          {
                            value: 'todo',
                            label: 'todo',
                            disabled: false,
                          },
                        ]}
                        signal={billsFunctionalitySignal}
                      />
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
            </Container>
            <div className="bg-gray text-dark p-0 mt-24 w-100">
              <Container>
                <Row style={{ minHeight: '5.188rem' }} className="d-none d-lg-flex align-items-center justify-content-center ms-24 mx-lg-24">
                  <div style={{ width: '20%' }}>
                    <div className="d-inline d-flex flex-row py-xl-16 py-sm-8 my-16">
                      <Form.Check
                        disabled={isLoading}
                        className="fw-800 d-flex align-items-center ms-16"
                        type="checkbox"
                        checked={!!items.length && items.every(i => selectedBills.includes(i.node.id))}
                        onChange={(e) => handleSelectAllBills(e.target.checked)}
                      />
                      <h5 className="fw-800 mb-0 ms-24">Vendor</h5>
                    </div>
                  </div>
                  <div style={{ width: '10%' }}>
                    <h5 className="fw-800 mb-0">Bill Date</h5>
                  </div>
                  <div style={{ width: '10%' }}>
                    <h5 className="fw-800 mb-0">Due Date</h5>
                  </div>
                  <div style={{ width: '10%' }}>
                    <h5 className="fw-800 mb-0">Number</h5>
                  </div>
                  <div style={{ width: '12.5%' }}>
                    <h5 className="fw-800 mb-0">Status</h5>
                  </div>
                  <div style={{ width: '12.5%' }}>
                    <h5 className="fw-800 mb-0">Amt Due</h5>
                  </div>
                  <div style={{ width: '12.5%' }}>
                    <h5 className="fw-800 mb-0">Amt Overdue</h5>
                  </div>
                  <div style={{ width: '12.5%' }} />
                </Row>
              </Container>
            </div>
            <Container className="pb-24">
              {statement?.data?.edges?.length ? (
                <>
                  {!items?.length ? (
                    <Row>
                      <Col className="my-80 col-md-6 offset-md-3 d-flex justify-content-center">
                        <div className="fw-400 lead text-dark-900">No bills to display</div>
                      </Col>
                    </Row>
                  ) : (
                    <>
                      {items.map(({ node }) => (
                        <>
                          <Row className="d-none d-lg-flex text-dark my-16 border border-2 rounded-4 py-16 mx-24 row-hover-effect" key={node.id}>
                            <div style={{ width: '20%' }} className="d-flex align-items-center">
                              <div className="d-inline d-flex flex-row py-xl-16 py-sm-8 my-16">
                                <Form.Check
                                  disabled={isLoading}
                                  className="fw-800 d-flex align-items-center ms-16"
                                  type="checkbox"
                                  checked={selectedBills.includes(node.id)}
                                  onChange={() => handleBillSelectToggle(node.id)}
                                />
                                <div className="lead fw-500 d-flex align-items-center text-dark ms-24">{node.organization_vendor?.name}</div>
                              </div>
                            </div>
                            <div style={{ width: '10%' }} className="d-flex align-items-center">
                              <small className="base fw-400 d-flex align-items-center text-dark-900 py-xl-16 py-sm-8 my-16">{moment(node.bill_date).format('l')}</small>
                            </div>
                            <div style={{ width: '10%' }} className="d-flex align-items-center">
                              <small className="base fw-400 d-flex align-items-center text-dark-900 py-xl-16 py-sm-8 my-16">{moment(node.due_date).format('l')}</small>
                            </div>
                            <div style={{ width: '10%' }} className="d-flex align-items-center">
                              <small className="base fw-400 d-flex align-items-center text-dark-900 py-xl-16 py-sm-8 my-16">{node.number}</small>
                            </div>
                            <div style={{ width: '12.5%' }} className="d-flex align-items-center">
                              <small className="fwbase fw-400 d-flex align-items-center text-dark-900 py-xl-16 py-sm-8 my-16"><StatusPills status={node.status || 'open'} isOverdue={moment(node.due_date).isBefore(moment(), 'day')} className="px-16" /></small>
                            </div>
                            <div style={{ width: '12.5%' }} className="d-flex align-items-center">
                              <small className="lead fw-500 d-flex align-items-center py-xl-16 py-sm-8 my-16"><Money amount={node.total_amount} /></small>
                            </div>
                            <div style={{ width: '12.5%' }} className="d-flex align-items-center">
                              {/* placeholder until payments module */}
                              <small className="lead fw-500 d-flex align-items-center py-xl-16 py-sm-8 my-16"><Money amount={moment(node.due_date).isBefore(moment(), 'day') ? node.total_amount : 0} /></small>
                            </div>
                            <div style={{ width: '10%' }} className="d-flex align-items-center p-0">
                              {node.status === 'paid' ? (
                                <Button disabled={isLoading} type="button" variant="outline-primary" className="w-100" onClick={() => handleBillClickOpen(node)}>
                                  <small className="fw-800">VIEW</small>
                                </Button>
                              ) : (
                                <Button disabled={isLoading} type="button" variant="primary" className="w-100" onClick={() => handleBillClickOpen(node)}>
                                  <small className="fw-800">VIEW/PAY</small>
                                </Button>
                              )}
                            </div>
                          </Row>
                          <Row disabled={isLoading} className="d-lg-none text-dark my-16 border border-2 rounded-4 py-16 mx-16 row-hover-effect align-items-center" key={makeid(15)} onClick={() => handleBillSelectToggle(node.id)} role="button">
                            <Col xs={2}>
                              <div className="d-inline d-flex flex-row py-sm-8 my-16">
                                <Form.Check
                                  disabled={isLoading}
                                  className="fw-800 d-flex align-items-center ms-16"
                                  type="checkbox"
                                  checked={selectedBills.includes(node.id)}
                                  onChange={() => handleBillSelectToggle(node.id)}
                                  onClick={(e) => e.stopPropagation()}
                                />
                              </div>
                            </Col>
                            <Col xs={9}>
                              <Row>
                                <Col className="d-flex justify-content-between">
                                  <div className="fw-500 text-dark lead">{node.organization_vendor?.name}</div>
                                  <div>
                                    <h5 className=""><Money amount={node.total_amount} /></h5>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col className="d-flex justify-content-between align-items-end">
                                  <div>
                                    <small className="text-dark-900"><span><strong>Due Date: </strong></span>{moment(node.due_date).format('l')}</small>
                                  </div>
                                  <div>
                                    <small className=" text-dark-900 py-xl-16 py-sm-8 my-16"><StatusPills status={node.status || 'open'} isOverdue={moment(node.due_date).isBefore(moment(), 'day')} className="px-16" /></small>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </>
                      ))}
                    </>
                  )}
                </>
              ) : (
                <Row className="d-flex justify-content-center align-items-center">
                  <Row className="px-auto mt-40">
                    <h3 className="text-primary text-center w-50 mx-auto">Doesn’t look like you have any Bills.</h3>
                  </Row>
                  <Image src={noBills} className="w-50 mt-40" />
                </Row>
              )}
              <Pagination itemsPerPageAmount={perPage} paginationMaxButtonAmount={5} totalItemsCount={totalCount} currentPageItemsCount={items.length} currentPage={page} setCurrentPage={(value) => billsFunctionalitySignal.update({ page: value })} />
            </Container>
          </Container>
        )}
      </Container>
    </ContentWrapper>
  );
};

export default Bills;
