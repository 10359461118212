import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { AuthLayout } from '#global/Layout';
import { handleResetPasswordPartial } from './ResetPassword.helpers';
import { resetPasswordSignal } from '#signals/Authentication.signals';
import UniversalInput from '../../global/Inputs/UniversalInput';

const ResetPassword = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const verificationCode = urlParams.get('token');
  const email = urlParams.get('email');
  const userId = urlParams.get('user_id');
  const { passwordVisible } = resetPasswordSignal.value;

  return (
    <AuthLayout>
      <Form.Group className="px-40">
        <Form.Label htmlFor="EmailAddress"><strong>New Password</strong></Form.Label>
        <UniversalInput
          placeholder="Enter New Password"
          className="mb-16"
          type={passwordVisible ? 'text' : 'password'}
          name="password"
          value={resetPasswordSignal.value.password}
          signal={resetPasswordSignal}
        />
        <Form.Label htmlFor="Password"><strong>Confirm New Password</strong></Form.Label>
        <Row className="m-0">
          <Col sm={11} className="p-0">
            <UniversalInput
              placeholder="Confirm Password"
              className="mb-16"
              type={passwordVisible ? 'text' : 'password'}
              name="password"
              value={resetPasswordSignal.value.confirmPassword}
              signal={resetPasswordSignal}
            />
          </Col>
          <Col sm={1} className="p-0 mb-16 border-bottom border-light">
            <Button
              role="button"
              tabIndex="0"
              onClick={() => resetPasswordSignal.update({ passwordVisible: !passwordVisible })}
              variant="eye"
              className="text-primary px-8 rounded-0"
            >
              <FontAwesomeIcon className="ps-8 d-flex justify-content-center" icon={passwordVisible ? faEye : faEyeSlash} />
            </Button>
          </Col>
        </Row>
        <Form.Label>Password must:</Form.Label>
        <ul className="text-dark-500">
          <li>Be 12-36 characters in length</li>
          <li>Contain at least one number</li>
          <li>Contain at least one uppercase letter</li>
        </ul>
        <Button variant="primary" type="submit" onClick={() => handleResetPasswordPartial(email, verificationCode, userId)} className="w-100 btn-lg mb-32">SUBMIT</Button>
      </Form.Group>
    </AuthLayout>
  );
};

export default ResetPassword;
