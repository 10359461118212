import { Row, Col, Button, Image } from 'react-bootstrap';
import sneakPeak from '#images/sneakPeak.svg';
import ComingSoonModal from '../../../../../global/ComingSoonModal';
import { settingsPaymentSignal } from '#signals/pages/Settings.signals';

const SneakPeak = () => {
  const { comingSoonModalOpen } = settingsPaymentSignal.value;
  return (
    <Row className="mx-0">
      <ComingSoonModal
        show={comingSoonModalOpen}
        handleClose={() => settingsPaymentSignal.update({
          ...settingsPaymentSignal.value,
          comingSoonModalOpen: false,
        })}
        videoLink="https://www.youtube.com/embed/EQZHGCo3Q2s"
      />
      <Row className="mx-0">
        <Col className="px-40 mt-40">
          <h2 className="text-center text-primary fw-700">We&apos;re working on something really cool that will elevate your experience.</h2>
          <h5 className="text-center text-primary fw-400">While you wait, how about a sneak peek?</h5>
          <Button
            className="w-auto m-auto d-flex mt-24"
            variant="primary btn-sm px-24"
            onClick={() => settingsPaymentSignal.update({
              ...settingsPaymentSignal.value,
              comingSoonModalOpen: !comingSoonModalOpen,
            })}
          >
            <small>
              SNEAK PEAK
            </small>
          </Button>
        </Col>
        <Image src={sneakPeak} height="auto" width="100%" className="my-40" />
      </Row>
    </Row>
  );
};

export default SneakPeak;
