import { Col, Row } from 'react-bootstrap';
import { settingsPaymentSignal } from '#signals/pages/Settings.signals';
import SneakPeak from './components/SneakPeak';

const paymentTabs = [
  {
    id: 1,
    tab: 'receiving-methods',
    title: 'Receiving Methods',
    component: <SneakPeak />,
  },
  {
    id: 2,
    tab: 'payment-methods',
    title: 'Payments Methods',
    component: <SneakPeak />,
  },
  {
    id: 3,
    tab: 'auto-pay',
    title: 'Auto Pay',
    component: <SneakPeak />,
  },
  {
    id: 4,
    tab: 'accru-pay',
    title: 'Accru Pay',
    component: <SneakPeak />,
  },
];

const PaymentSettings = () => {
  const { currentTab } = settingsPaymentSignal.value;
  return (
    <div>
      <h1 className="d-none d-lg-block d-xl-block">Payment Settings</h1>
      <Row className="mx-0 mt-24">
        {paymentTabs && paymentTabs.map((obj, idx) => (
          <Col
            key={idx}
            className={`text-center mt-auto px-0 w-100 border-bottom  ${currentTab === obj.tab ? 'border-3 border-secondary text-primary fw-bold' : 'text-dark-900'}`}
            role="button"
            tabIndex={0}
            onClick={() => settingsPaymentSignal.update({
              ...settingsPaymentSignal.value,
              currentTab: obj.tab,
            })}
          >
            <p className="mb-0">{obj.title}</p>
          </Col>
        ))}
      </Row>
      <Row className="mx-0">
        {paymentTabs && paymentTabs.map((obj, idx) => {
          if (obj.tab === currentTab) {
            return (
              <div key={idx}>
                {obj.component}
              </div>
            );
          }
          return '';
        })}
      </Row>
    </div>
  );
};

export default PaymentSettings;
