import { useCallback, useEffect, useMemo } from 'react';
import { faArrowLeft, faCheckCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Button, Image, Card } from 'react-bootstrap';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import moment from 'moment/moment';
import Money from '#global/Money';
import personWheelchair from '#images/person-wheelchair.svg';
import personStanding from '#images/person-standing.svg';
import fileIcon from '#images/file.svg';
import ContentWrapper from '#global/ContentWrapper';
import trash from '#images/trash.svg';
import { invoiceSignal, invoiceSignalInitialState, invoiceDetailSignal } from '#signals/pages/Invoices.signals';
import { userSignal } from '#signals/User.signals';
import { getInvoice, sendInvoiceEmail } from './Invoices.helpers';
import ComponentLoader from '#global/ComponentLoader';
import noBills from '#images/noInvoices.svg';
import { triggerSuccessAlert } from '#global/Alert/Alert';

const InvoiceDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleBackToInvoices = useCallback(() => {
    if (history?.location?.state?.from === 'invoices') { history.goBack(); } else { history.replace('/invoices'); }
  }, [history]);

  const organizationId = useMemo(() => userSignal?.value?.user?.organizations?.[0]?.organization_id, [userSignal?.value?.user?.organizations]);

  const invoice = useMemo(() => invoiceSignal?.value, [invoiceSignal?.value]);
  const isLoading = useMemo(() => invoice?.is_loading, [invoice]);
  const error = useMemo(() => invoice?.error, [invoice]);

  useEffect(() => {
    const loadInvoice = async () => {
      invoiceSignal.update({
        ...invoiceSignalInitialState,
        is_loading: true,
        error: null,
      });

      try {
        const response = await getInvoice({
          organizationId,
          organizationInvoiceId: id,
        });

        const data = response.data.userOrganizationInvoice;

        invoiceSignal.update({
          ...data,
          is_loading: false,
          error: null,
        });
      } catch (err) {
        invoiceSignal.update({
          ...invoiceSignalInitialState,
          is_loading: false,
          error: err.message,
        });
      }
    };

    loadInvoice();
  }, [id, organizationId]);

  const handleSendInvoiceEmail = useCallback(async () => {
    invoiceDetailSignal.update({
      sending_email: true,
    });

    try {
      const response = await sendInvoiceEmail({
        organizationId,
        organizationInvoiceId: id,
        sendToPrimaryContact: true,
        contactIds: [],
      });

      const targets = response.data.userOrganizationCustomerSendInvoiceEmail;

      invoiceDetailSignal.update({
        sending_email: false,
      });

      triggerSuccessAlert(`Invoice sent to ${targets.join(', ')}`);
    } catch {
      invoiceDetailSignal.update({
        sending_email: false,
      });
    }
  }, [organizationId, invoice]);

  if (isLoading) return <ComponentLoader />;

  if (error) {
    return (
      <ContentWrapper fluid footer className="min-vh-100 w-100 p-lx-auto p-0">
        <Container fluid className="bg-gray-250 p-0 min-vh-100">
          <Row>
            <Col className="mt-80 col-md-6 offset-md-3">
              <Image src={noBills} height={640} />
            </Col>
          </Row>
        </Container>
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper fluid className="min-vh-100 w-100 p-lx-auto p-0">
      <Container>
        <Row>
          <Button variant="eye" className="d-flex border-0 shadow-none align-items-center justify-content-start position-relative p-32 ps-0 pb-24" onClick={() => handleBackToInvoices()}>
            <FontAwesomeIcon icon={faArrowLeft} className="text-primary" />
            <p className="text-primary fw-400 ms-8 mb-0">Back to invoices</p>
          </Button>
          {/* <Row className="d-flex justify-content-center mb-16">
            <Image src={ellipse277} className="w-auto h-auto" />
          </Row> */}
          <Row className="d-flex justify-content-center text-center mb-32">
            <h1>{invoice.title}</h1>
          </Row>
          <Col lg={2} className="d-flex align-items-end mb-48">
            <Image height={330} src={personWheelchair} />
          </Col>
          <Col lg={8} className=" mb-sm-48">
            <Container className="pb-0 mb-0 position-relative bill-summary-container">
              <Card className="bg-gray-250 pt-40 pb-16 border w-100 h-auto">
                <div className={`px-56 my-16 w-100 ${invoice?.file ? 'border-2 border-gray-800 border-bottom' : ''}`}>
                  <Row>
                    <Col lg={9}>
                      <p className="lead fw-800 text-primary mb-8">Balance Due:</p>
                    </Col>
                    <Col lg={3} className="d-flex justify-content-end pe-8">
                      <p className="fw-800 text-dark-900">id:{invoice.number}</p>
                    </Col>
                  </Row>
                  <Row>
                    <h1 className="fw-400 text-dark p-0 ps-16">
                      <Money amount={invoice.total_amount} />
                    </h1>
                    <hr />
                  </Row>
                  {invoice.status === 'PAID' && (
                    <Row>
                      <small className="text-success fw-500">
                        <FontAwesomeIcon icon={faCheckCircle} className="text-suc me-8" />
                        Paid
                      </small>
                    </Row>
                  )}
                  {(invoice.status === 'OPEN' || invoice.status === 'OVERDUE') && (
                    <Row>
                      <small className="text-danger fw-500">
                        <FontAwesomeIcon icon={faXmark} className="text-danger me-8" />
                        Overdue {Math.abs(moment(invoice.due_date).diff(moment(), 'days'))} days
                      </small>
                    </Row>
                  )}
                  <Row className="mt-32 mb-16">
                    <Col sm={7} lg={6} className="pe-64 pe-lg-16 w-auto">
                      <Row className="mb-16">
                        <small className="fw-800 text-dark-900">Bill Date</small>
                      </Row>
                      <h4 className="fw-400 text-dark-900">
                        {moment(invoice.invoice_date).format('l')}
                      </h4>
                    </Col>
                    <Col sm={5} lg={6}>
                      <Row className="mb-16">
                        <small className="fw-800 text-dark-900">Due Date</small>
                      </Row>
                      <h4 className="fw-400 text-dark-900">
                        {moment(invoice.due_date).format('l')}
                      </h4>
                    </Col>
                  </Row>
                  <Row className="mb-16">
                    <small className="fw-800 text-dark-900">Sent to</small>
                  </Row>
                  <Row className="mb-16">
                    <h4 className="fw-400 text-dark-900">{invoice.customer_email}</h4>
                  </Row>
                  <Row>
                    <small className="text-light-gray-200 fw-400">Please note that the displayed invoice has been synchronized from QuickBooks and cannot be edited on this platform. To make any changes or modifications, kindly access and edit the invoice directly within your QuickBooks account.</small>
                  </Row>
                  <Row className="w-auto mb-16 mt-40">
                    <Button
                      variant="primary-active"
                      className="py-16"
                      disabled={invoiceDetailSignal?.value?.sending_email}
                      onClick={handleSendInvoiceEmail}
                    >
                      RESEND INVOICE
                    </Button>
                  </Row>
                  {/* <Row className="w-auto mb-48 mt-16">
                    <Button
                      variant="outline-primary-active"
                      className="py-16 border border-2 border-primary-active text-dark"
                      onClick={() => console.log('MVP?')}
                    >
                      RECEIVED PAYMENT
                    </Button>
                  </Row> */}
                </div>
                {invoice?.file && (
                  <Row className="px-56">
                    <Col className="d-flex align-items-center p-8 cursor-pointer" style={{ border: '1px solid #F2F2F2', borderRadius: '15px' }}>
                      <div className="p-8" style={{ borderRadius: '10px', width: 45, height: 45 }}>
                        <Image src={fileIcon} height={24} />
                      </div>
                      <div className="d-flex flex-column ms-8">
                        <small>document.csv</small>
                        <small className="mb-0 text-start">kb</small>
                      </div>
                      <div className="ms-auto px-24">
                        <Button
                          variant="eye"
                          className=""
                          onClick={() => console.log('MVP?')}
                        >
                          <Image src={trash} height={16} />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                )}

              </Card>
            </Container>
          </Col>
          <Col lg={2} className="d-flex align-items-end mb-48">
            <Image src={personStanding} height={420} />
          </Col>
        </Row>
      </Container>
    </ContentWrapper>
  );
};

export default InvoiceDetail;
