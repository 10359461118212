import { Form } from 'react-bootstrap';

const UniversalInput = ({
  type,
  name,
  placeholder,
  value,
  className,
  customOnChange,
  signal,
}) => (
  <Form.Control
    type={type || 'text'}
    value={value || value === '' ? value : signal.value[name]}
    placeholder={placeholder}
    className={`form-control ${className || ''}`}
    name={name}
    onChange={customOnChange || ((e) => signal.update({
      [name]: e.target.value,
    }))}
  />
);

export default UniversalInput;
