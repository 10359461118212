import { gql } from '@apollo/client';
import { apolloClient } from '#api';
import { triggerWarningAlert } from '#global/Alert/Alert';

const MANUAL_QUICKBOOKS_PULL = gql`
  mutation userOrganizationAcctProviderSynchronize ($organizationId: String!, $accountProvider: ACCT_PROVIDER!, $push: Boolean!, $pull: Boolean!) {
    userOrganizationAcctProviderSynchronize(
      organization_id: $organizationId
      acct_provider_code: $accountProvider
      pull: $pull
      push: $push
    ) {
      id
      name
    }
  }
`;

const VENDOR_QUERY_FRAGMENT = gql`
  fragment OrganizationVendorFields on OrganizationVendor {
    id
    is_active
    email
    name
    unique_code
    phone_number
    tax_code_type
    tax_code
    created_at
    updated_at

    contacts {
      id
      name
      email
      phone_number
      is_default
      created_at
      updated_at
    }

    bill_statement {
      data {
        totalCount
        edges {
            cursor
            node {
              id
              file_id
              file {
                public_url
              }
              unique_code
              number
              currency_code
              description
              amount
              tax_amount
              discount_amount
              total_amount
              paid_amount
              bill_date
              due_date
              vendor_email
              created_at
              updated_at
              status
            }
        }
        pageInfo {
            startCursor
            endCursor
            hasPreviousPage
            hasNextPage
        }
      }
      total_amount
      paid_amount
      overdue_amount
      start_date
      end_date
      currency
      status
    }
  }
`;

const GET_ALL_VENDORS_QUERY = gql`
  query userOrganizationVendors (
    $organizationId: String!,

    $name: String,
    
    $after: ConnectionCursor,
    $first: Int,

    $before: ConnectionCursor,
    $last: Int,

    $skip: Int,
    $take: Int,

    $sorting: [SortingFieldSchema!]
    ) {
    userOrganizationVendors(
      organization_id: $organizationId

      name: $name

      after: $after
      first: $first

      before: $before
      last: $last

      skip: $skip
      take: $take

      sorting: $sorting
    ) {
        totalCount
        edges {
          cursor
          node {
            ...OrganizationVendorFields
          }
        }
        pageInfo {
            startCursor
            endCursor
            hasPreviousPage
            hasNextPage
        }
      }
    }

  ${VENDOR_QUERY_FRAGMENT}
`;

const CREATE_VENDOR_MUTATION = gql`
  mutation userOrganizationVendorCreate ($organizationId: String!, $data: UserOrganizationVendorSchema!) {
    userOrganizationVendorCreate(
      organization_id: $organizationId
      data: $data
    ) {
      ...OrganizationVendorFields
    }
  }

  ${VENDOR_QUERY_FRAGMENT}
`;

const UPDATE_VENDOR_MUTATION = gql`
  mutation userOrganizationVendorUpdate ($organizationId: String!, $organizationVendorId: String!, $data: UserOrganizationVendorSchema!) {
    userOrganizationVendorUpdate(
      organization_id: $organizationId
      organization_vendor_id: $organizationVendorId
      data: $data
    ) {
      ...OrganizationVendorFields
    }
  }

  ${VENDOR_QUERY_FRAGMENT}
`;
export const pullQuickbooksVendors = async ({ organizationId }) => {
  try {
    const synchronization = await apolloClient.mutate({
      mutation: MANUAL_QUICKBOOKS_PULL,
      variables: {
        organizationId,
        accountProvider: 'QUICKBOOKS',
        pull: true,
        push: false,
      },
    });
    return synchronization;
  } catch (e) {
    console.error('Error when syncing vendors:', e);
    triggerWarningAlert(e.message);
  }
};

/**
 * @param {Object} params
 * @param {String} params.organizationId
 * @param {String} params.name
 * @param {String} params.status
 * @param {String} params.after
 * @param {Number} params.first
 * @param {String} params.before
 * @param {Number} params.last
 * @param {Number} params.skip
 * @param {Number} params.take
 * @param {Array} params.sorting
 * @param {String} params.sorting.field
 * @param {String} params.sorting.order
 * @returns {Promise<*>}
 */
export const getAllVendors = async (params) => {
  try {
    const allVendors = await apolloClient.query({
      query: GET_ALL_VENDORS_QUERY,
      variables: {
        organizationId: params.organizationId,

        name: params.name,

        after: params.after,
        first: params.first,

        before: params.before,
        last: params.last,

        skip: params.skip,
        take: params.take,

        sorting: params.sorting,
      },
    });
    return allVendors;
  } catch (e) {
    console.error('Error when getting all vendors:', e);
    triggerWarningAlert(e.message);
  }
};

export const addVendor = async ({ organizationId, data }) => {
  try {
    const newVendor = await apolloClient.mutate({
      mutation: CREATE_VENDOR_MUTATION,
      variables: {
        organizationId,
        data,
      },
    });
    return newVendor;
  } catch (e) {
    console.error('Error creating vendor:', e);
    triggerWarningAlert(e.message);
  }
};

export const updateVendor = async ({ organizationId, organizationVendorId, data }) => {
  try {
    const updatedVendor = await apolloClient.mutate({
      mutation: UPDATE_VENDOR_MUTATION,
      variables: {
        organizationId,
        organizationVendorId,
        data,
      },
    });
    return updatedVendor;
  } catch (e) {
    console.error('Error updating vendor:', e);
    triggerWarningAlert(e.message);
  }
};
