import { Button, Col, Dropdown, Form, Image, Row } from 'react-bootstrap';
import { useEffect } from 'react';
import addUserPlus from '#images/addUserPlus.svg';
import threeVerticalDots from '#images/threeVerticalDots.svg';
import { settingsOrganizationSignal } from '#signals/pages/Settings.signals';
import { userSignal } from '#signals/User.signals';
import { loaderSignal } from '#signals/Global.signals';
import { deleteCollaborator, fetchOrganizationUsers, inviteUserToOrganziation, updateUserRole } from '../OrganizationSettings.helpers';
import ComponentLoader from '#global/ComponentLoader';
import SelectInput from '#global/Inputs/SelectInput';
import { createRoleArray, sendRemindersArray, updateRoleArray } from '#utils/selectArrays';
import UniversalInput from '#global/Inputs/UniversalInput';

const OrganizationUsers = () => {
  const {
    users,
    usersPage,
    selectedUser,
    newRole,
    newSendInvoiceReminders,
    newUserEmail,
    newUserRole,
  } = settingsOrganizationSignal.value;

  useEffect(async () => {
    const { user } = userSignal.value;
    if (user) {
      await fetchOrganizationUsers(user.organizations[0].organization_id);
    }
  }, []);

  return (
    <Row className="mx-0">
      <Row className="mx-0 my-24 py-24 bg-gray-250 rounded">
        {loaderSignal.value?.isLoading ? (
          <ComponentLoader />
        ) : (
          <>
            {usersPage === 'users' && (
              <>
                <Row className="mx-0">
                  <Col sm={6} className="p-0">
                    <h2 className="fw-700 mb-0">Users</h2>
                  </Col>
                  <Col sm={6} className="p-0 d-flex justify-content-end">
                    <Button
                      variant="eye"
                      onClick={() => settingsOrganizationSignal.update({
                        usersPage: 'add',
                        newUserEmail: '',
                        newUserRole: 'Select',
                      })}
                      className="w-auto mb-16 ms-auto fw-350 d-flex align-items-center"
                    >
                      <Image src={addUserPlus} height="22" width="22" className="me-8" />
                      Add User
                    </Button>
                  </Col>
                </Row>
                <Row className="mx-0 px-0 border-top border-gray-100 pt-16">
                  {users && users.map((obj, idx) => (
                    <Row className="mx-0 px-0 border-bottom border-gray-100 pb-16 px-8 mb-16" key={idx}>
                      <Col sm={2} className="p-0">
                        {obj?.profilePicture ? (
                          <Image src={obj?.profilePicture} className="rounded-circle position-relative" height="68px" width="68px" />
                        ) : (
                          <div
                            className="rounded-circle d-flex justify-content-center align-items-center border border-primary position-relative"
                            style={{ height: '68px', width: '68px' }}
                          >
                            <h2 className="fw-700 mb-0 text-primary position-absolute">{obj.firstName.charAt(0)}{obj.lastName.charAt(0)}</h2>
                          </div>
                        )}
                      </Col>
                      <Col sm={6} className="p-0 my-auto ps-8">
                        <h4 className="mb-0 w-100">
                          {obj.firstName}{' '}{obj.lastName}
                        </h4>
                        <small className="m-0 p-0 w-100 text-dark-900">{obj.email}</small>
                      </Col>
                      <Col sm={2} className="p-0 my-auto">
                        {obj.role === 'OWNER' && (
                          <div data-cy="status-pill" className="w-auto d-flex justify-content-center align-items-center text-center fw-700 rounded-pill status-pill border bg-primary text-primary-active">
                            Owner
                          </div>
                        )}
                        {obj.role === 'ADMIN' && (
                          <div data-cy="status-pill" className="w-auto d-flex justify-content-center align-items-center text-center fw-700 rounded-pill status-pill border border-primary bg-primary-active text-primary">
                            Admin
                          </div>
                        )}
                        {obj.role === 'EDITOR' && (
                          <div data-cy="status-pill" className="w-auto d-flex justify-content-center align-items-center text-center fw-700 rounded-pill status-pill border border-secondary bg-secondary-300 text-secondary">
                            Editor
                          </div>
                        )}
                        {obj.role === 'VIEWER' && (
                          <div data-cy="status-pill" className="w-auto d-flex justify-content-center align-items-center text-center fw-700 rounded-pill status-pill border border-warning bg-info-active text-warning">
                            View Only
                          </div>
                        )}
                      </Col>
                      <Col sm={2} className="p-0 d-flex align-items-center justify-content-end">
                        <Dropdown
                          className="p-0"
                          onSelect={(e) => settingsOrganizationSignal.update({
                            usersPage: e,
                            selectedUser: obj,
                            newRole: obj.role,
                            newSendInvoiceReminders: obj.sendInvoiceReminders,
                          })}
                        >
                          <Dropdown.Toggle data-cy="filter-select" variant="eye" id="dropdown-basic" className="custom-dropdown-toggle border-none w-100 text-start bg-white d-flex align-items-center">
                            <Image height={20} src={threeVerticalDots} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="w-100">
                            <Dropdown.Item eventKey="edit" className="rounded">Edit</Dropdown.Item>
                            <Dropdown.Item eventKey="users" className="rounded" disabled={obj.role === 'OWNER'} onClick={() => deleteCollaborator(obj.userId, obj.orgId)}>Delete</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                  ))}
                </Row>
              </>
            )}
            {usersPage === 'edit' && (
              <>
                <Row className="mx-0 px-0 pb-16 px-8">
                  <Col sm={2} className="p-0">
                    {selectedUser?.profilePicture ? (
                      <Image src={selectedUser?.profilePicture} className="rounded-circle position-relative" height="68px" width="68px" />
                    ) : (
                      <div
                        className="rounded-circle d-flex justify-content-center align-items-center border border-primary position-relative"
                        style={{ height: '68px', width: '68px' }}
                      >
                        <h2 className="fw-700 mb-0 text-primary position-absolute">{selectedUser.firstName.charAt(0)}{selectedUser.lastName.charAt(0)}</h2>
                      </div>
                    )}
                  </Col>
                  <Col sm={10} className="p-0 my-auto ps-8">
                    <h4 className="mb-0 w-100">
                      {selectedUser.firstName}{' '}{selectedUser.lastName}
                    </h4>
                    <small className="m-0 p-0 w-100 text-dark-900">{selectedUser.email}</small>
                  </Col>
                </Row>
                <Row className="mx-0 px-8">
                  <Col sm={12} className="p-0 my-auto">
                    <Form.Label htmlFor="newRole"><strong>Update Role</strong></Form.Label>
                    <SelectInput
                      name="newRole"
                      value={newRole}
                      options={updateRoleArray}
                      signal={settingsOrganizationSignal}
                    />
                  </Col>
                </Row>
                <Row className="mx-0 px-8 mt-8">
                  <Col sm={12} className="p-0">
                    <Form.Label htmlFor="newSendInvoiceReminders"><strong>Do you want Invoice Reminders to be sent to this user?</strong></Form.Label>
                    <SelectInput
                      name="newSendInvoiceReminders"
                      value={newSendInvoiceReminders}
                      options={sendRemindersArray}
                      signal={settingsOrganizationSignal}
                    />
                  </Col>
                </Row>
                <Row className="mx-0 px-0">
                  <Col className="ps-0">
                    <Button
                      variant="outline-primary"
                      onClick={() => settingsOrganizationSignal.update({
                        usersPage: 'users',
                        selectedUser: null,
                        newRole: 'Select',
                        newSendInvoiceReminders: false,
                      })}
                      className="w-100 mb-16 mt-24"
                    >
                      CANCEL
                    </Button>
                  </Col>
                  <Col className="pe-0">
                    <Button
                      variant="primary"
                      onClick={() => updateUserRole(selectedUser.userId, selectedUser.orgId)}
                      className="w-100 mb-16 mt-24"
                      disabled={selectedUser.role === newRole && selectedUser.sendInvoiceReminders === newSendInvoiceReminders}
                    >
                      SAVE CHANGES
                    </Button>
                  </Col>
                </Row>
              </>
            )}
            {usersPage === 'add' && (
              <>
                <h2 className="fw-700 mb-8 px-8">Invite with Email</h2>
                <Row className="mx-0 px-8 mt-8">
                  <Col sm={12} className="p-0">
                    <Form.Label htmlFor="newUserEmail"><strong>Email</strong></Form.Label>
                    <UniversalInput
                      placeholder="Email"
                      className="mb-16"
                      name="newUserEmail"
                      value={newUserEmail}
                      signal={settingsOrganizationSignal}
                    />
                  </Col>
                </Row>
                <Row className="mx-0 px-8">
                  <Col sm={12} className="p-0 my-auto">
                    <Form.Label htmlFor="TeamMember"><strong>Role</strong></Form.Label>
                    <SelectInput
                      name="newUserRole"
                      value={newUserRole}
                      options={createRoleArray}
                      signal={settingsOrganizationSignal}
                    />
                  </Col>
                </Row>
                <Row className="mx-0 px-0">
                  <Col className="ps-0">
                    <Button
                      variant="outline-primary"
                      onClick={() => settingsOrganizationSignal.update({
                        usersPage: 'users',
                        newUserEmail: '',
                        newUserRole: 'Select',
                      })}
                      className="w-100 mb-16 mt-24"
                    >
                      CANCEL
                    </Button>
                  </Col>
                  <Col className="pe-0">
                    <Button
                      variant="primary"
                      onClick={() => inviteUserToOrganziation(userSignal.value?.user?.organizations[0]?.organization_id)}
                      className="w-100 mb-16 mt-24"
                      disabled={newUserRole === 'Select' || newUserEmail === ''}
                    >
                      SEND INVITE
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}

      </Row>
    </Row>
  );
};

export default OrganizationUsers;
