import { useCallback } from 'react';
import moment from 'moment';
import { Image, Button, Card, Col, Container, Row } from 'react-bootstrap';
import Money from '#global/Money';
import personAndDog from '#images/personAndDog.svg';

const BillSummary = ({ data }) => {
  const handleButton = useCallback(() => {});

  return (
    <Col md={12} lg={6} className="z-index-n1 mb-sm-48">
      <Container className="pb-0 mb-0 e-lg-0 position-relative bill-summary-container">
        <div className="w-auto d-none d-lg-block h-auto position-absolute translate-middle top-50" style={{ zIndex: 10, right: '-170px' }}>
          <Image src={personAndDog} />
        </div>
        <Card className="bg-gray-250 pt-40 pb-16 border w-100 h-auto">
          <div className="px-56 my-16 w-100">
            <Row>
              <Col lg={9}>
                <p className="lead fw-800 text-primary mb-8">Balance Due:</p>
              </Col>
              <Col lg={3} className="d-flex justify-content-end pe-8">
                <p className="fw-800 text-dark-900">id:{data.number}</p>
              </Col>
            </Row>
            <Row>
              <h1 className="fw-400 text-dark p-0 ps-16">
                <Money amount={data.total_amount - data.paid_amount} />
              </h1>
            </Row>
            <Row className="mt-32 mb-16">
              <Col sm={7} lg={6} className="pe-64 pe-lg-16 w-auto">
                <Row className="mb-16">
                  <small className="fw-800 text-dark-900">Bill Date</small>
                </Row>
                <h4 className="fw-400 text-dark-900">
                  {moment().format('l')}
                </h4>
              </Col>
              <Col sm={5} lg={6}>
                <Row className="mb-16">
                  <small className="fw-800 text-dark-900">Due Date</small>
                </Row>
                <h4 className="fw-400 text-dark-900">
                  {moment(data.due_date).format('l')}
                </h4>
              </Col>
            </Row>
            <Row className="mb-16">
              <small className="fw-800 text-dark-900">Contact</small>
            </Row>
            <Row>
              <h4 className="fw-400 text-dark-900">{data.organization_vendor.email}</h4>
            </Row>
            <Row className="w-auto mb-16 mt-40">
              <Button
                variant="primary-active"
                className="py-16"
                disabled
                onClick={() => handleButton()}
              >
                PAY THIS BILL
              </Button>
            </Row>
            <Row className="w-auto mb-48 mt-16">
              <Button
                variant="outline-primary-active"
                className="py-16 border border-2 border-primary-active text-dark"
                disabled
                onClick={() => handleButton()}
              >
                PAY TOTAL BALANCE: insert total balance data
              </Button>
            </Row>
          </div>
          {/* <Row className="mt-16 ms-32">
            <p className="fw-800 text-dark-900">
              <FontAwesomeIcon icon={faPaperclip} className="me-8" />
              View invoice (TODO)
            </p>
          </Row> */}
        </Card>
      </Container>
    </Col>
  );
};

export default BillSummary;
