/* eslint-disable no-unused-vars */
import { Button, Col, Form, Image, Row } from 'react-bootstrap';
import { useEffect } from 'react';
import camera from '#images/camera.svg';
import { settingsOrganizationSignal } from '#signals/pages/Settings.signals';
import { userSignal } from '#signals/User.signals';
import { fetchOrganizationData, handleBrowse, uploadCompanyLogo, updateCompanyData, deleteCompanyLogo, fetchOrganizationUsers } from '../OrganizationSettings.helpers';
import ZipInput from '#global/Inputs/ZipInput';
import PhoneNumberInput from '#global/Inputs/PhoneNumberInput';
import SelectInput from '#global/Inputs/SelectInput';
import { timeZoneArray } from '#utils/selectArrays';
import UniversalInput from '#global/Inputs/UniversalInput';
import Loadable from '#global/Loadable';

const CompanyInfo = () => {
  const {
    companyLogo,
    companyName,
    companyAddressLine1,
    companyAddressLine2,
    companyAddressCity,
    companyAddressState,
    companyAddressZipCode,
    phoneNumber,
    timeZone,
    legalBusinessName,
    legalBusinessAddressLine1,
    legalBusinessAddressLine2,
    legalBusinessAddressCity,
    legalBusinessAddressState,
    legalBusinessAddressZipCode,
    taxIdType,
    taxIdCode,
  } = settingsOrganizationSignal.value;
  useEffect(async () => {
    const { user } = userSignal.value;
    if (user) {
      await fetchOrganizationData(user.organizations[0].organization_id);
      await fetchOrganizationUsers(user.organizations[0].organization_id);
    }
  }, []);

  return (
    <Row className="mx-0">
      <Row className="mx-0 mt-24 bg-gray-250 rounded">
        <Col sm={3} className="p-0 h-100">
          <div className="cursor-pointer h-100 my-16" role="button" tabIndex={0} onClick={() => handleBrowse()}>
            <input
              id="file-input"
              className="d-none"
              type="file"
              accept="image/*"
              onChange={(e) => uploadCompanyLogo(e, userSignal?.value?.user?.organizations[0]?.organization_id)}
            />
            {companyLogo ? (
              <Image src={companyLogo} className="rounded-circle border border-primary position-relative" height="80px" width="80px" />
            ) : (
              <Button
                variant="secondary-900"
                className="rounded-circle py-24 px-auto d-flex position-relative"
              >
                <h2 className="fw-700 mb-0 text-primary">Logo</h2>
                <div
                  className="rounded-circle position-absolute bg-secondary-300 d-flex justify-content-center align-items-center custom-shadow"
                  style={{
                    height: '30px',
                    width: '30px',
                    bottom: '-4px',
                    right: '-4px',
                  }}
                >
                  <Image src={camera} />
                </div>
              </Button>
            )}
          </div>
        </Col>
        <Col sm={9} className="p-0 my-auto py-32">
          <h2 className="fw-700 mb-8">Company Logo</h2>
          <p className="fw-400 mb-0">Upload your logo here</p>
          {companyLogo && (
            <Button
              variant="outline-danger"
              onClick={() => deleteCompanyLogo(userSignal?.value?.user?.organizations[0]?.organization_id)}
              className="w-auto mt-8 btn-sm"
            >
              Delete Logo
            </Button>
          )}
        </Col>
      </Row>
      <Row className="mx-0 mt-24 py-24 bg-gray-250 rounded">
        <Form.Group>
          <h2 className="fw-700 mb-16">Company Info</h2>
          <Form.Label htmlFor="companyName"><strong>Company Name</strong></Form.Label>
          <UniversalInput
            placeholder="Company Name"
            className="mb-16"
            name="companyName"
            value={companyName}
            signal={settingsOrganizationSignal}
          />
          <Form.Label htmlFor="companyAddressLine1"><strong>Address</strong></Form.Label>
          <UniversalInput
            placeholder="Address"
            className="mb-16"
            name="companyAddressLine1"
            value={companyAddressLine1}
            signal={settingsOrganizationSignal}
          />
          <Row className="m-0">
            <Col sm={6} className="p-0 pe-8">
              <Form.Label htmlFor="companyAddressLine2"><strong>Suite # (optional)</strong></Form.Label>
              <UniversalInput
                placeholder="Suite #"
                className="mb-16"
                name="companyAddressLine2"
                value={companyAddressLine2}
                signal={settingsOrganizationSignal}
              />
            </Col>
            <Col sm={6} className="p-0 ps-8">
              <Form.Label htmlFor="companyAddressCity"><strong>City</strong></Form.Label>
              <UniversalInput
                placeholder="City"
                className="mb-16"
                name="companyAddressCity"
                value={companyAddressCity}
                signal={settingsOrganizationSignal}
              />
            </Col>
          </Row>
          <Row className="m-0">
            <Col sm={6} className="p-0 pe-8">
              <Form.Label htmlFor="companyAddressState"><strong>State</strong></Form.Label>
              <UniversalInput
                placeholder="State"
                className="mb-16"
                name="companyAddressState"
                value={companyAddressState}
                signal={settingsOrganizationSignal}
              />
            </Col>
            <Col sm={6} className="p-0 ps-8">
              <Form.Label htmlFor="companyAddressZipCode"><strong>Zip code</strong></Form.Label>
              <ZipInput
                name="companyAddressZipCode"
                value={companyAddressZipCode}
                signal={settingsOrganizationSignal}
              />
            </Col>
          </Row>
          <Form.Label htmlFor="phoneNumber"><strong>Phone Number</strong></Form.Label>
          <PhoneNumberInput
            name="phoneNumber"
            value={phoneNumber}
            signal={settingsOrganizationSignal}
          />
          <Form.Label htmlFor="primaryContact"><strong>Time Zone</strong></Form.Label>
          <SelectInput
            name="timeZone"
            placeholder="Time zone"
            value={timeZone}
            options={timeZoneArray()}
            className="mb-40"
            signal={settingsOrganizationSignal}
          />
        </Form.Group>
      </Row>
      <Row className="mx-0 mt-24 py-24 bg-gray-250 rounded">
        <Form.Group>
          <h2 className="fw-700 mb-16">Company Legal Information</h2>
          <Form.Label htmlFor="primaryContact"><strong>Name</strong></Form.Label>
          <UniversalInput
            placeholder="Name"
            className="mb-16"
            name="legalBusinessName"
            value={legalBusinessName}
            signal={settingsOrganizationSignal}
          />
          <Form.Label htmlFor="legalBusinessAddressLine1"><strong>Address</strong></Form.Label>
          <UniversalInput
            placeholder="Address"
            className="mb-16"
            name="legalBusinessAddressLine1"
            value={legalBusinessAddressLine1}
            signal={settingsOrganizationSignal}
          />
          <Row className="m-0">
            <Col sm={6} className="p-0 pe-8">
              <Form.Label htmlFor="legalBusinessAddressLine2"><strong>Suite # (optional)</strong></Form.Label>
              <UniversalInput
                placeholder="Suite #"
                className="mb-16"
                name="legalBusinessAddressLine2"
                value={legalBusinessAddressLine2}
                signal={settingsOrganizationSignal}
              />
            </Col>
            <Col sm={6} className="p-0 ps-8">
              <Form.Label htmlFor="legalBusinessAddressCity"><strong>City</strong></Form.Label>
              <UniversalInput
                placeholder="City"
                className="mb-16"
                name="legalBusinessAddressCity"
                value={legalBusinessAddressCity}
                signal={settingsOrganizationSignal}
              />
            </Col>
          </Row>
          <Row className="m-0">
            <Col sm={6} className="p-0 pe-8">
              <Form.Label htmlFor="legalBusinessAddressState"><strong>State</strong></Form.Label>
              <UniversalInput
                placeholder="State"
                className="mb-16"
                name="legalBusinessAddressState"
                value={legalBusinessAddressState}
                signal={settingsOrganizationSignal}
              />
            </Col>
            <Col sm={6} className="p-0 ps-8">
              <Form.Label htmlFor="legalBusinessAddressZipCode"><strong>Zip code</strong></Form.Label>
              <ZipInput
                name="legalBusinessAddressZipCode"
                value={legalBusinessAddressZipCode}
                signal={settingsOrganizationSignal}
              />
            </Col>
          </Row>
          <Form.Label htmlFor="taxIdType"><strong>Tax Id Type</strong></Form.Label>
          <Row className="mx-0 d-flex justify-content-between mb-16">
            <Col className="p-0 d-flex align-content-center">
              <Form.Check
                className="fw-350 w-auto"
                type="checkbox"
                label="EIN"
                checked={taxIdType === 'EIN'}
                onChange={() => settingsOrganizationSignal.update({
                  ...settingsOrganizationSignal.value,
                  taxIdType: 'EIN',
                  taxIdCode: '',
                })}
              />
            </Col>
            <Col className="p-0 d-flex align-content-center">
              <Form.Check
                className="fw-350 w-auto"
                type="checkbox"
                label="SSN"
                checked={taxIdType === 'SSN'}
                onChange={() => settingsOrganizationSignal.update({
                  ...settingsOrganizationSignal.value,
                  taxIdType: 'SSN',
                  taxIdCode: '',
                })}
              />
            </Col>
            <Col className="p-0 d-flex align-content-center">
              <Form.Check
                className="fw-350 w-auto"
                type="checkbox"
                label="ITIN"
                checked={taxIdType === 'ITIN'}
                onChange={() => settingsOrganizationSignal.update({
                  ...settingsOrganizationSignal.value,
                  taxIdType: 'ITIN',
                  taxIdCode: '',
                })}
              />
            </Col>
          </Row>
          <Form.Label htmlFor="taxIdCode"><strong>Tax ID Number</strong></Form.Label>
          <UniversalInput
            placeholder="Tax Id number"
            className="mb-16"
            name="taxIdCode"
            value={taxIdCode || 'he'}
            disabled={!taxIdType}
            customOnChange={(e) => {
              if ((taxIdType === 'SSN' || taxIdType === 'EIN') && e.target.value.length < 10) {
                const res = e.target.value.replace(/\D/g, '');
                settingsOrganizationSignal.update({
                  ...settingsOrganizationSignal.value,
                  taxIdCode: res,
                });
              }
              if (taxIdType === 'ITIN' && e.target.value.length < 10 && e.target.value.startsWith('9')) {
                const res = e.target.value.replace(/\D/g, '');
                settingsOrganizationSignal.update({
                  ...settingsOrganizationSignal.value,
                  taxIdCode: res,
                });
              }
            }}
          />
        </Form.Group>
      </Row>
      <Row className="mx-0 px-0 my-24">
        <Col className="ps-0">
          <Button
            variant="outline-primary"
            className="w-100 mb-16"
            onClick={() => fetchOrganizationData(userSignal?.value?.user?.organizations[0]?.organization_id)}
          >
            CANCEL
          </Button>
        </Col>
        <Col className="pe-0">
          <Button
            variant="primary"
            className="w-100 mb-16"
            onClick={() => updateCompanyData(userSignal?.value?.user?.organizations[0]?.organization_id)}
          >
            SAVE CHANGES
          </Button>
        </Col>
      </Row>
    </Row>
  );
};

export default CompanyInfo;
