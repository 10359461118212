import { useMemo, useEffect, useCallback } from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import noBills from '#images/noInvoices.svg';
import ContentWrapper from '#global/ContentWrapper';
import ComponentLoader from '#global/ComponentLoader';

import { billSignal, billSignalInitialState } from '#signals/pages/Bills.signals';
import { getBill } from './Bills.helpers';
import { userSignal } from '#signals/User.signals';
// import AboutYourBill from './AboutYourBill';
import BillHero from './BillHero';
import BillSummary from './BillSummary';

const BillDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleBackToBills = useCallback(() => {
    if (history?.location?.state?.from === 'bills') { history.goBack(); } else { history.replace('/bills'); }
  }, [history]);

  const bill = useMemo(() => billSignal?.value, [billSignal?.value]);
  const isLoading = useMemo(() => bill?.is_loading, [bill]);
  const error = useMemo(() => bill?.error, [bill]);

  const organizationId = useMemo(() => userSignal?.value?.user?.organizations?.[0]?.organization_id, [userSignal?.value?.user?.organizations]);

  useEffect(() => {
    const loadBill = async () => {
      billSignal.update({
        ...billSignalInitialState,
        is_loading: true,
        error: null,
      });

      try {
        const response = await getBill({
          organizationId,
          organizationBillId: id,
        });

        const data = response.data.userOrganizationBill;

        billSignal.update({
          ...data,
          is_loading: false,
          error: null,
        });
      } catch (err) {
        billSignal.update({
          ...billSignalInitialState,
          is_loading: false,
          error: err.message,
        });
      }
    };

    loadBill();
  }, [id, organizationId]);

  if (isLoading) return <ComponentLoader />;

  if (error) {
    return (
      <ContentWrapper fluid footer className="min-vh-100 w-100 p-lx-auto p-0">
        <Container fluid className="bg-gray-250 p-0 min-vh-100">
          <Row>
            <Col className="mt-80 col-md-6 offset-md-3">
              <Image src={noBills} height={640} />
            </Col>
          </Row>
        </Container>
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper className="min-vh-100 w-100 p-lx-auto p-0 overflow-hidden mb-56">
      <Container>
        <Button variant="transparent border-0 shadow-none" className="d-flex align-items-center justify-content-start position-relative p-32 ps-0" onClick={() => handleBackToBills()}>
          <FontAwesomeIcon icon={faArrowLeft} className="text-primary" />
          <p className="text-primary fw-400 ms-8 mb-0">Back to bills</p>
        </Button>
        <Row className="position-relative">
          <BillHero data={bill} />
          <BillSummary data={bill} />
        </Row>
        {/* <AboutYourBill /> */}
      </Container>
    </ContentWrapper>
  );
};

export default BillDetail;
