import Signal from './Signal';

export const alertSignal = Signal({
  message: null,
  type: null,
  detailedMessages: [],
  isShown: false,
});

export const navBarSignal = Signal({
  isOpen: false,
  isComingSoonOpen: false,
});

export const searchableDropdownSignal = Signal({
  searchString: '',
});

export const windowSizeSignal = Signal({
  windowSize: {
    breakPoint: null,
    height: null,
  },
});

export const globalSignal = Signal({
  isLoading: true,
  isLoadingMessage: 'Loading...',
  isSignedIn: false,
});

export const loaderSignal = Signal({
  isLoading: false,
  isLoadingMessage: 'Loading...',
});

export const notificationSignal = Signal({
  exiting: false,
});

export const getCMSDataSignal = Signal({
  loading: true,
  data: null,
});

export const toastrSignal = Signal({
  toasts: [],
});

export const syncingSignal = Signal({
  isSyncing: false,
  syncData: [],
});

export default { alertSignal, globalSignal, toastrSignal, loaderSignal, notificationSignal, navBarSignal, searchableDropdownSignal, windowSizeSignal, getCMSDataSignal };
