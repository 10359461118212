import { Form } from 'react-bootstrap';

const SelectInput = ({ name, value, disabled, options, className, customOnChange, signal }) => (
  <Form.Select
    className={`form-control form-select bg-transparent ${className || ''}`}
    onChange={customOnChange || ((e) => signal.update({
      [name]: e.target.value,
    }))}
    id={name}
    value={value}
    disabled={disabled}
  >
    {options && options.map((obj, idx) => (
      <option key={`${name}-${idx}`} value={obj.value} disabled={obj.disabled}>{obj.label}</option>
    ))}
  </Form.Select>
);

export default SelectInput;
