import { useEffect } from 'react';
import { Button, Col, Dropdown, Image, Row } from 'react-bootstrap';
import { userSignal } from '#signals/User.signals';
import { settingsLogsSignal } from '#signals/pages/Settings.signals';
import { getSyncHistory, filterSync, sortFlip } from './LogsSettings.helpers';
import sortAlt from '#images/sort-alt.svg';
import filterAlt from '#images/filter-alt.svg';
import successCircle from '#images/successCircle.svg';
import alertCircle from '#images/alertCircle.svg';
import failedCircle from '#images/failedCircle.svg';
import isOddOrEven from '#utils/offOrEven';
import formatDate from '#utils/formatDate';

const LogsSettings = () => {
  const organizationId = userSignal.value.user.organizations[0].organization_id;
  const { logs, issues, lastSynced, lastSyncType } = settingsLogsSignal.value;

  useEffect(() => {
    const loadLogs = async () => {
      const QUERY_TAKE_AMOUNT = 20;
      const syncHistoryReq = await getSyncHistory({
        organizationId,
        skip: 0,
        take: QUERY_TAKE_AMOUNT,
      });

      if (syncHistoryReq && syncHistoryReq?.data?.userOrganizationAcctProviderSynchronizations) {
        const tempLogsArray = [];
        const { organizations } = userSignal.value.user;
        let counter = 0;
        syncHistoryReq?.data?.userOrganizationAcctProviderSynchronizations.forEach((obj) => {
          const lastItem = obj.errors[obj.errors.length - 1];
          const foundValue = organizations.find(org => org.organization_id === obj.organization_id);
          const orgName = foundValue.organization.name;

          // STATUS
          let status = '';
          if (obj.finished_at && !obj.failed_at) {
            status = 'PASS';
          } else if (obj.failed_at) {
            status = 'FAIL';
            counter += 1;
          } else {
            status = 'ERROR';
            counter += 1;
          }
          tempLogsArray.push({
            id: obj.id,
            status,
            errorMessage: !lastItem ? obj.status_description : lastItem,
            name: orgName,
            originalDate: obj.failed_at || obj.finished_at,
            date: formatDate(obj.failed_at || obj.finished_at),
          });
        });
        const lastObj = tempLogsArray[0];
        settingsLogsSignal.update({
          logs: tempLogsArray,
          originalLogs: tempLogsArray,
          issues: counter,
          lastSynced: lastObj.date || 'n/a',
          lastSyncType: lastObj.status || 'ERROR',
        });
      }
    };
    loadLogs();
  }, []);

  return (
    <div>
      <h1 className="d-none d-lg-block d-xl-block">Logs</h1>
      <Row className="mx-0 bg-gray-250 rounded mt-24 pb-16">
        <Row className="mx-8 mb-24 mt-8">
          <Col sm={10} className="p-0">
            <h2 className="mb-0 fw-700 fw-bold">Sync History</h2>
          </Col>
          <Col sm={1} className="p-0 my-auto">
            <Button type="button" variant="eye" className="p-0 bg-white text-primary" onClick={() => sortFlip()}>
              <Image src={sortAlt} alt="sort-alt" />
            </Button>
          </Col>
          <Col sm={1} className="p-0 my-auto">
            <Dropdown>
              <Button
                variant="eye"
                className="p-0 bg-white text-primary my-auto"
              >
                <Dropdown.Toggle variant="eye" className="custom-dropdown-toggle">
                  <Image src={filterAlt} alt="filter-alt" />
                </Dropdown.Toggle>
              </Button>
              <Dropdown.Menu className="custom-nav-dropdown p-0">
                <Dropdown.Item className="rounded" onClick={() => filterSync('PASS')}>Passed</Dropdown.Item>
                <Dropdown.Item className="rounded" onClick={() => filterSync('ERROR')}>Errors</Dropdown.Item>
                <Dropdown.Item className="rounded" onClick={() => filterSync('FAIL')}>Failed</Dropdown.Item>
                <Dropdown.Item className="rounded" onClick={() => filterSync('NONE')}>Reset</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row className="bg-gray-800 py-8 px-24 mx-0">
          <Col sm={1} className="px-0">
            {lastSyncType === 'PASS' && (
              <Image src={successCircle} alt="successCircle" />
            )}
            {lastSyncType === 'ERROR' && (
              <Image src={alertCircle} alt="alertCircle" />
            )}
            {lastSyncType === 'FAIL' && (
              <Image src={failedCircle} alt="failedCircle" />
            )}
          </Col>
          <Col sm={3} className="px-0">
            Synced {lastSynced}
          </Col>
          <Col sm={8} className="px-0 text-dark-900 texr-underline">
            {issues} issue found
          </Col>
        </Row>
        {logs && logs.map((obj, idx) => (
          <Row key={idx} className={`${isOddOrEven(idx) === 'Odd' ? 'bg-gray-800' : 'bg-none'} py-8 px-24 mx-0`}>
            <Col sm={4} className="px-0 my-auto">
              {obj.name}
            </Col>
            <Col sm={1} className="px-0 my-auto">
              {obj.status === 'PASS' && (
                <Image src={successCircle} alt="successCircle" />
              )}
              {obj.status === 'ERROR' && (
                <Image src={alertCircle} alt="alertCircle" />
              )}
              {obj.status === 'FAIL' && (
                <Image src={failedCircle} alt="failedCircle" />
              )}
            </Col>
            <Col sm={5} className="px-0 text-dark-900 my-auto">
              {obj.errorMessage}
            </Col>
            <Col sm={2} className="px-0 text-end text-dark-900 my-auto">
              {obj.date}
            </Col>
          </Row>
        ))}
      </Row>
    </div>
  );
};

export default LogsSettings;
