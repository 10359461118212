import { Route } from 'react-router-dom';
import NavBar from '#global/NavBar/NavBar';
import ContentWrapper from '#global/ContentWrapper';
import { globalSignal } from '#signals/Global.signals';
import Redirect from '#views/Redirect';

const PrivateRoute = ({ component: Component, noNavbar, ...rest }) => {
  if (!globalSignal.value.isSignedIn && !globalSignal.value.isLoading) {
    return <Redirect />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <ContentWrapper fluid className="min-vh-100 px-0">
          {!noNavbar && (
          <div className="pb-40">
            <NavBar />
          </div>
          )}
          <Component {...props} />
        </ContentWrapper>
      )}
    />
  );
};

export default PrivateRoute;
