import ContentWrapper from '#global/ContentWrapper';
import PayType from './PayType';

const Payment = () => (
  <ContentWrapper fluid className="min-vh-100 w-100 p-lx-auto p-0 overflow-hidden">
    <PayType
      className="px-0 position-relative min-vh-100"
    />
  </ContentWrapper>
);

export default Payment;
