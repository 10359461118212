import { Button, Col, Form, Nav, Row } from 'react-bootstrap';
import { signUpSignal } from '#signals/Authentication.signals';
import { acceptInviteSignal } from '#signals/pages/Onboarding.signals';
import {
  handleVerifyFormChange,
  handleEmailSubmitPartial,
  handleCodeVerificationPartial,
} from '../Signup.helpers';
import ReturnToLoginRow from './ReturnToLoginRow';
import { triggerAlertFromFnResult } from '#global/Alert/Alert';

// this email already exists in the system so we can simply propagate it and resubmit it
function handleResendSubmit(email) {
  return handleEmailSubmitPartial(email).then(triggerAlertFromFnResult(
    { onSuccess: () => 'Verification code successfully sent.' },
  ));
}

const VerificationForm = ({ invitedUser, isEmbed }) => {
  const verificationSignal = invitedUser ? acceptInviteSignal?.value : signUpSignal?.value;

  return (
    <Form.Group>
      <h2 className="fw-700 text-center mb-32">Verify it&apos;s you</h2>
      <p className="base fw-400 text-dark-900 text-center">
        For your security, we just need to make sure this is you. We sent you a 6 digit code to {verificationSignal?.formData?.email}
      </p>
      <Row>
        <div className="px-0 me-8 d-flex justify-content-around">
          {verificationSignal?.formData?.verificationCode ? verificationSignal?.formData?.verificationCode.map((code, index) => (
            <Form.Control
              key={index}
              className="mb-40 h1 mx-8 text-center p-0 py-16 validation-form"
              type="text"
              max="1"
              value={code}
              name="verificationCode"
              onChange={(e) => handleVerifyFormChange(e, index, verificationSignal.formData.verificationCode, invitedUser)}
            />
          )) : ''}
        </div>
      </Row>
      <Button
        variant="primary"
        type="submit"
        onClick={() => handleCodeVerificationPartial(invitedUser)}
        className="w-100 btn-lg mb-16"
        disabled={verificationSignal?.formData?.verificationCode.includes('')}
      >
        SUBMIT
      </Button>
      {!invitedUser && !isEmbed && (
        <ReturnToLoginRow />
      )}
      <Row className="d-flex align-items-center justify-content-center">
        <Col xs={12} className="d-flex justify-content-center align-items-center text-dark mb-24 fw-400">
          <small>Didn&apos;t receive verification code?</small>
          <Nav.Link className="ms-8 me-8 text-decoration-underline text-primary p-0" href="#" onClick={() => handleResendSubmit(verificationSignal.email)}><small>Resend</small></Nav.Link>
          {!invitedUser && (
          <>
            <small>or</small>
            <Nav.Link className="ms-8 text-decoration-underline text-primary p-0" onClick={() => window.location.reload()}><small>Use different email</small></Nav.Link>
          </>
          )}
        </Col>
      </Row>
    </Form.Group>
  );
};

export default VerificationForm;
