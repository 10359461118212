import { Row, Col, Container, Card, Form, InputGroup, Button } from 'react-bootstrap';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import SearchableDropdown from '#global/SearchableDropdown/SearchableDropdown';
import { getAllCustomers } from '#views/Invoices/Invoices.helpers';
import { invoiceFormSignal, invoiceSignal, invoiceManualFormSignal } from '#signals/pages/Invoices.signals';
import { validateLength } from '#utils/validateInput';
import { createInvoice } from '../Invoices.helpers';
import { triggerWarningAlert } from '#global/Alert/Alert';
import { userSignal } from '#signals/User.signals';
import UniversalInput from '../../../global/Inputs/UniversalInput';
import DatePicker from '../../../global/Inputs/DatePicker';

const InvoiceManualForm = ({ handleNext }) => {
  const { allCustomers, selectedCompany } = invoiceManualFormSignal.value;
  const organizationId = userSignal?.value?.user?.organizations?.[0]?.organization_id;
  const formData = invoiceFormSignal?.value;
  const history = useHistory();

  useEffect(() => {
    const getCustomers = async () => {
      const customers = await getAllCustomers({ organizationId });
      const labeledCustomers = customers?.data?.userOrganizationCustomers?.map(c => ({
        name: c.name,
        value: c.id,
        metaData: {
          email: c.email,
        },
      }));
      invoiceManualFormSignal.update({
        allCustomers: labeledCustomers || [],
      });
    };

    getCustomers();
  }, []);

  const handleAddInvoice = async () => {
    try {
      const discountAmount = 0; // hardcoded until discount feature
      const totalAmount = Number(formData.amount) - discountAmount;

      validateLength(totalAmount, 'Amount', 0, 100);
      validateLength(formData.description, 'Description', 0, 100);
      validateLength(formData.due_date, 'Due date', 0, 100);
      validateLength(formData.organization_customer_id, 'Customer', 0, 100);
      const newInvoice = await createInvoice({
        organizationId,
        data: {
          currency_code: 'USD',
          description: formData.description,
          discount_amount: discountAmount,
          amount: Number(formData.amount) * 100,
          total_amount: totalAmount,
          invoice_date: moment(),
          due_date: formData.due_date,
          organization_project_id: null,
          organization_customer_id: formData.organization_customer_id,
        },
      });
      if (newInvoice?.data?.userOrganizationInvoiceCreate) {
        invoiceSignal.update({ allInvoices: [...invoiceSignal.value.allInvoices, newInvoice?.data?.userOrganizationInvoiceCreate] });
      }

      history.push('/invoices');
    } catch (e) {
      triggerWarningAlert(e.message);
    }
  };

  return (
    <>
      <Row>
        <Col className="text-center">
          <h1 className="text-primary fw-700 pt-16 pb-24">Invoice Details</h1>
        </Col>
      </Row>
      <Container>
        <Row>
          <Col>
            <Card style={{ maxWidth: 620 }} className="mb-24 px-80 justify-content-left mx-auto">
              <Form className="mt-80 mb-64">
                <Form.Group className="form-group text-start mb-24">
                  <Form.Label className="col-form-label fw-800 pb-0 small" htmlFor="inputDefault">Customer/Company name</Form.Label>
                  <SearchableDropdown
                    selected={selectedCompany}
                    options={allCustomers}
                    onSelect={(e) => {
                      invoiceFormSignal.update({
                        ...formData,
                        organization_customer_id: e.value,
                        metadata: e.metaData,
                      });
                      invoiceManualFormSignal.update({
                        selectedCompany: e,
                      });
                    }}
                  />
                </Form.Group>
                <Form.Group className="form-group text-start mb-24">
                  <Form.Label className="control-label fw-800 pb-0 small">Amount</Form.Label>
                  <Form.Group className="form-group">
                    <InputGroup className="input-group mb-24">
                      <InputGroup.Text className="input-group-text border-0 ps-0 pe-1 bg-transparent">$</InputGroup.Text>
                      <UniversalInput
                        placeholder="Amount (to the nearest dollar)"
                        value={formData.amount}
                        name="amount"
                        signal={invoiceFormSignal}
                      />
                    </InputGroup>
                  </Form.Group>
                </Form.Group>
                <Row>
                  <Col>
                    <Form.Group className="form-group text-start mb-24">
                      <Form.Label className="col-form-label fw-800 pb-0 small" htmlFor="inputDefault">Invoice number</Form.Label>
                      <UniversalInput
                        value={formData.number}
                        name="number"
                        type="number"
                        signal={invoiceFormSignal}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="form-group text-start mb-24">
                      <Form.Label className="control-label fw-800 pb-0 small">Due Date</Form.Label>
                      <Form.Group className="form-group">
                        <InputGroup className="input-group mb-24">
                          <DatePicker
                            name="due_date"
                            placeholder="Due Date"
                            value={formData.due_date}
                            signal={invoiceFormSignal}
                          />
                        </InputGroup>
                      </Form.Group>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="form-group text-start mb-24">
                  <Form.Label className="col-form-label fw-800 pb-0 small" htmlFor="inputDefault">Additional Details</Form.Label>
                  <UniversalInput
                    value={formData.description}
                    name="description"
                    signal={invoiceFormSignal}
                  />
                </Form.Group>
                <Button
                  type="button"
                  variant="primary"
                  className="w-100 py-16 mb-24"
                  onClick={() => handleNext({ current: 'form', next: 'preview' })}
                >
                  SAVE & SEND
                </Button>
                <Button
                  type="button"
                  variant="outline-primary border border-2 border-primary"
                  className="w-100 py-16"
                  onClick={() => handleAddInvoice()}
                >
                  SAVE & CLOSE
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default InvoiceManualForm;
