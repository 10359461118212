import React from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import NotificationToast from './NotificationToast';
import SyncToast from './SyncToast';
import { toastrSignal, syncingSignal } from '#signals/Global.signals';

const collapseToast = (id) => {
  const currentToasts = [...toastrSignal.value.toasts];
  const targetIdx = currentToasts.findIndex(t => t.id === id);
  currentToasts[targetIdx].expanded = !currentToasts[targetIdx].expanded;
  toastrSignal.update({
    toasts: currentToasts,
  });
};

const dismissToast = (id) => {
  const currentToasts = [...toastrSignal.value.toasts];
  const newToasts = currentToasts.filter(t => t.id !== id);
  toastrSignal.update({
    toasts: newToasts,
  });
};

function Toastr() {
  return (
    <>
      <ToastContainer position="bottom-end" className="p-8 mb-16 position-fixed" style={{ zIndex: 9999 }}>
        {toastrSignal.value.toasts?.map(t => (
          <Toast>
            <Toast.Header closeButton={false} className="bg-secondary-300">
              <FontAwesomeIcon icon={t.expanded ? faChevronDown : faChevronRight} role="button" onClick={() => collapseToast(t.id)} />
              <FontAwesomeIcon
                icon={faXmark}
                role="button"
                className="ms-auto"
                onClick={() => {
                  dismissToast(t.id);
                  syncingSignal.update({ isSyncing: false });
                }}
              />
            </Toast.Header>
            <div>
              {t.type === 'sync' ? (
                <SyncToast
                  id={t.id}
                  expanded={t.expanded}
                />
              ) : (
                <NotificationToast
                  message={t.message}
                  expanded={t.expanded}
                />
              )}
            </div>
          </Toast>
        ))}
      </ToastContainer>
    </>
  );
}

export default Toastr;
