import { Row, Col, Form, Button, Dropdown } from 'react-bootstrap';

const AutopaySchedule = ({ handleNext }) => (
  <>
    <Row>
      <Col>
        <h2 className="mx-24 mb-56 fw-700 text-dark">Let&apos;s schedule out your payments</h2>
      </Col>
    </Row>
    <div className="d-flex flex-column">
      <Form className="mb-0 px-32">
        <Form.Group className="form-group mb-24 mt-16">
          <Form.Label className="d-flex justify-content-start" htmlFor="sendOn">
            <p className="lead fw-800 mb-0">Send on</p>
          </Form.Label>
          <div className="d-flex align-items-end">
            {/* TODO */}
            <Form.Control
              type="text"
              id="sendOn"
              placeholder=""
              name="sendOn"
              className="w-25"
            />
            <p className="lead mb-0 ms-8">days before due date</p>
          </div>
        </Form.Group>
        <Form.Group className="form-group mb-16">
          <Form.Label className="d-flex justify-content-start mb-0" htmlFor="duration"><p className="lead fw-800 mb-0">Duration</p></Form.Label>
          <Dropdown className="mt-16">
            <Dropdown.Toggle variant="primary-hover" id="dropdown-basic" className="rounded w-100 text-start bg-white d-flex align-items-center border-0 border-bottom">
              <div className="lead me-auto fw-400 mb-0 text-dark-900">Number of payments</div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-8 w-100" />
          </Dropdown>
        </Form.Group>
        <Form.Group className="form-group mb-16">
          <Form.Label className="d-flex justify-content-start mb-0" htmlFor="amount"><p className="lead fw-800 mb-0">Amount</p></Form.Label>
          <Dropdown className="mt-16">
            <Dropdown.Toggle variant="primary-hover" id="dropdown-basic" className="rounded w-100 text-start bg-white d-flex align-items-center border-0 border-bottom">
              <div className="lead me-auto fw-400 mb-0 text-dark-900">Full amount</div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-8 w-100" />
          </Dropdown>
        </Form.Group>
        <Form.Group className="form-group mb-16">
          <Form.Label className="d-flex justify-content-start mb-0" htmlFor="next"><p className="lead fw-800 mb-0">Next payment</p></Form.Label>
          <Dropdown className="mt-16">
            <Dropdown.Toggle variant="primary-hover" id="dropdown-basic" className="rounded w-100 text-start bg-white d-flex align-items-center border-0 border-bottom">
              <div className="lead me-auto fw-400 mb-0 text-dark-900">Oct 01, 2022</div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-8 w-100" />
          </Dropdown>
        </Form.Group>
      </Form>
      <Row className="d-flex justify-content-start align-content-center mt-88 mb-32 px-32">
        <Col className="d-flex justify-content-start align-items-center">
          <Button
            variant="primary"
            className="flex-grow-1 px-40 px-16 py-8"
            onClick={() => handleNext({ current: 'schedule', next: 'confirm' })}
          >
            <small className="text-primary-100 me-8">
              NEXT
            </small>
          </Button>
        </Col>
      </Row>
    </div>
  </>
);

export default AutopaySchedule;
