import { Button, Col, Form, Image, Row } from 'react-bootstrap';
import addButton from '#images/addButton.svg';
import deleteIcon from '#images/deleteIcon.svg';
import { invitationsSignal } from '#signals/pages/Onboarding.signals';
import {
  addMember,
  deleteMember,
  handleMemberChange,
  inviteTeamMemberPartial,
} from '../Onboarding.helpers';
import { createRoleArray } from '../../../../utils/selectArrays';
import SelectInput from '../../../global/Inputs/SelectInput';
import UniversalInput from '../../../global/Inputs/UniversalInput';

const InviteMembersForm = () => {
  const members = invitationsSignal.value;

  return (
    <Form.Group>
      <h2 className="fw-700 text-center mb-24">Let&apos;s invite your team members</h2>
      <h2 className="fw-700 text-center mb-24">Invite with Email</h2>
      <>
        <div style={{ maxHeight: '300px', overflowY: 'auto', overflowX: 'hidden' }}>
          {members.map((member, index) => (
            <Row key={index} className="m-0">
              <Col xs={6} className="p-0">
                <Form.Label htmlFor="TeamMember"><strong>Team member email</strong></Form.Label>
                <UniversalInput
                  value={member.email}
                  className="mb-40"
                  customOnChange={(e) => handleMemberChange(e, index, true)}
                />
              </Col>
              <Col xs={4} className="px-8">
                <Form.Label htmlFor="TeamMember"><strong>Role</strong></Form.Label>
                <SelectInput
                  name="memberRole"
                  value={member.role}
                  options={createRoleArray}
                  className="mb-40"
                  customOnChange={(e) => handleMemberChange(e, index, false)}
                />
              </Col>
              <Col xs={2} className="p-0 d-flex justify-content-center align-items-center">
                <Button
                  variant="eye"
                  type="submit"
                  onClick={(e) => deleteMember(e, index)}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Image src={deleteIcon} alt="delete icon" />
                </Button>
              </Col>
            </Row>
          ))}
          <Button
            variant="white"
            type="submit"
            onClick={() => addMember()}
            className="mb-8 border-0 d-flex align-items-center p-0"
          >
            <Image src={addButton} alt="add button with plus sign" className="me-16" />
            Add More
          </Button>
        </div>
        <Button
          variant="primary"
          type="submit"
          onClick={() => inviteTeamMemberPartial()}
          className="w-100 btn-lg mt-48 mb-8"
          disabled={members.length === 0}
        >
          SEND INVITES
        </Button>
        <Button
          variant="transparent"
          className="w-100 btn-lg mb-16 border-0"
          onClick={() => window.location.reload()}
        >
          <span className="text-decoration-underline fw-800 text-primary">Skip for now</span>
        </Button>
      </>
    </Form.Group>
  );
};

export default InviteMembersForm;
