import { Image, Container, Row, Col } from 'react-bootstrap';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import arrowLeft from '#images/arrowLeft.svg';
import closeButton from '#images/closeButton.svg';
import triangleBg from '#images/yellowtrianglebackground.svg';
import squareBg from '#images/bluesquarebackground.svg';
import AddBillMethod from './AddBillMethod';
import AddBillManualSelect from './AddBillManualSelect';
import AddBillManualForm from './AddBillManualForm';
import AddBillManualUpload from './AddBillManualUpload';
import AddBillSuccessfulUpload from './AddBillSuccessfulUpload';
import AddBillSyncSelection from './AddBillSyncSelection';
import { intuitSignal } from '#signals/Authentication.signals';
import { addBillSignal } from '#signals/pages/Bills.signals';

const AddBill = () => {
  const { currentScreen, backButtonHistory } = addBillSignal.value;
  const history = useHistory();

  useEffect(() => {
    addBillSignal.update({
      currentScreen: intuitSignal?.value?.redirectUrl ? 'syncSelect' : 'method',
    });
  }, []);

  const handleBackButton = () => {
    const navigationHistory = [...backButtonHistory];
    if (navigationHistory.length) {
      const current = navigationHistory.pop();
      addBillSignal.update({
        currentScreen: current,
        backButtonHistory: navigationHistory,
      });
    } else {
      history.push('/bills');
    }
  };

  const handleNext = ({ current, next }) => {
    const navigationHistory = [...backButtonHistory];
    navigationHistory.push(current);
    addBillSignal.update({
      currentScreen: next,
      backButtonHistory: navigationHistory,
    });
  };

  return (
    <Container fluid className="min-vh-100 px-0 w-100 text-center position-relative" style={{ backgroundColor: 'rgb(231, 192, 170, 0.4)' }}>
      <Image style={{ right: 0, top: 0, zIndex: 0 }} className="position-absolute" src={triangleBg} />
      <Image style={{ bottom: 0, left: 0, zIndex: 0 }} className="position-absolute" src={squareBg} />
      <Container className="pt-80 pt-lg-152">
        <Row>
          <Col sm={{ span: 10, offset: 1 }} offset={1} className="d-flex justify-content-between">
            <Image src={arrowLeft} className="cursor-pointer" data-cy="back-button" onClick={() => handleBackButton()} />
            <Image src={closeButton} style={{ zIndex: 100 }} className="cursor-pointer" onClick={() => history.push('/bills')} />
          </Col>
        </Row>
      </Container>
      <Container fluid style={{ zIndex: 100 }} className="position-relative pb-72">
        {{
          method: <AddBillMethod handleNext={handleNext} />,
          syncSelect: <AddBillSyncSelection handleNext={handleNext} />,
          manualSelect: <AddBillManualSelect handleNext={handleNext} />,
          form: <AddBillManualForm handleNext={handleNext} />,
          upload: <AddBillManualUpload handleNext={handleNext} />,
          success: <AddBillSuccessfulUpload />,
        }[currentScreen]}
      </Container>
    </Container>
  );
};

export default AddBill;
