import { Button, Card, Col, Form, Image, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { settingsReminderSignal } from '#signals/pages/Settings.signals';
import plusSign from '#images/plusSign.svg';
import { addOrdinalSuffix, fetchNotificationSettings, findTimeFromValue, saveChangesNotification } from '../ReminderSettings.helpers';
import { userSignal } from '#signals/User.signals';
import AutomaticOverdueRemindersModal from './AutomaticOverdueRemindersModal';

const OverdueNotifications = () => {
  const {
    isCustomRecurrenceOpen,
    automaticOverdueReminders,
    overdueRemindersArray,
    deleteNotificationArray,
  } = settingsReminderSignal.value;

  useEffect(async () => {
    const { user } = userSignal.value;
    if (user) {
      await fetchNotificationSettings(user.organizations[0].organization_id);
    }
  }, []);

  return (
    <Row className="mx-0">
      <Row className="mx-0 mt-24 bg-gray-250 rounded py-16">
        <Row className="m-0 px-0">
          <Col sm={10} className="p-0">
            <h2 className="mb-0 fw-700">Automatic Overdue Notifications</h2>
          </Col>
          <Col sm={2} className="p-0 d-flex justify-content-center align-items-center">
            <Form>
              <OverlayTrigger
                key="overlay-trigger"
                placement="bottom"
                overlay={(
                  <Tooltip id="tooltip-bottom" className={`${automaticOverdueReminders && overdueRemindersArray?.length > 0 ? 'd-block' : 'd-none'}`}>
                    You must remove all notifications to disable Automatic Overdue Notifications
                  </Tooltip>
                )}
              >
                <Form.Check
                  className="form-check-radio-custom"
                  type="switch"
                  name="automaticOverdueReminders"
                  checked={automaticOverdueReminders}
                  onChange={() => settingsReminderSignal.update({
                    ...settingsReminderSignal.value,
                    automaticOverdueReminders: automaticOverdueReminders && overdueRemindersArray?.length > 0 ? automaticOverdueReminders : !automaticOverdueReminders,
                  })}
                />
              </OverlayTrigger>
            </Form>
          </Col>
        </Row>
        {!automaticOverdueReminders && (
          <Row className="m-0 pt-8">
            <p className="p-0 m-0 text-dark-900">Automatic overdue notifications apply to all new invoices. Turning off automatic overdue notifications removes them from all invoices</p>
          </Row>
        )}
      </Row>
      {automaticOverdueReminders && (
        <>
          <AutomaticOverdueRemindersModal />
          <Row className="mx-0 mt-24 bg-gray-250 rounded py-16">
            <h2 className="mb-0 p-0 fw-700">Notifications</h2>
            {overdueRemindersArray && overdueRemindersArray.map((obj, idx) => (
              <Card className="border-gray-100 p-16 mt-16" key={idx}>
                <Row className="m-0 d-flex justify-content-between">
                  <Col className="p-0 d-flex justify-content-start align-items-center">
                    <h3 className="mb-0 fw-700">Notification {idx + 1}</h3>
                  </Col>
                  <Col className="p-0 d-flex justify-content-end align-items-center">
                    <Button
                      className="mb-0 text-danger"
                      variant="eye"
                      onClick={() => {
                        const updatedArray = [...overdueRemindersArray];
                        updatedArray.splice(idx, 1);
                        const deleteArray = [...deleteNotificationArray];
                        if (obj?.reminderId) {
                          deleteArray.push(obj?.reminderId);
                        }
                        settingsReminderSignal.update({
                          ...settingsReminderSignal.value,
                          overdueRemindersArray: updatedArray,
                          deleteNotificationArray: deleteArray,
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faXmark} /> Remove
                    </Button>
                  </Col>
                </Row>
                <Row className="m-0">
                  <Col sm={12} className="p-0 py-8">
                    {obj.type === 'daily' && (
                      <p className="mb-0 px-8">
                        Repeats daily at
                        {' '}
                        <strong>{findTimeFromValue(obj.daily)}</strong>
                        {' '}
                        on business day(s) after invoice due date
                      </p>
                    )}
                    {obj.type === 'weekly' && (
                      <p className="mb-0 px-8">
                        Repeats weekly every
                        {' '}
                        <strong>
                          {obj.weekly === 0 && 'Sunday'}
                          {obj.weekly === 1 && 'Monday'}
                          {obj.weekly === 2 && 'Tuesday'}
                          {obj.weekly === 3 && 'Wednesday'}
                          {obj.weekly === 4 && 'Thursday'}
                          {obj.weekly === 5 && 'Friday'}
                          {obj.weekly === 6 && 'Saturday'}
                        </strong>
                        {' '}
                        after invoice due date
                      </p>
                    )}
                    {obj.type === 'monthly' && (
                      <p className="mb-0 px-8">
                        Repeats monthly on the
                        {' '}
                        <strong>{addOrdinalSuffix(obj.monthly)}</strong>
                        {' '}
                        after invoice due date
                      </p>
                    )}
                  </Col>
                </Row>
              </Card>
            ))}
            <Row className="mx-0 mt-8 d-flex justify-content-end">
              <Button
                className="mb-0 w-auto m-0"
                variant="eye"
                onClick={() => {
                  settingsReminderSignal.update({
                    ...settingsReminderSignal.value,
                    isCustomRecurrenceOpen: !isCustomRecurrenceOpen,
                    tempReminderObj: {
                      type: 'daily',
                      daily: 12,
                      weekly: null,
                      monthly: 15,
                    },
                  });
                }}
              >
                <Image src={plusSign} alt="plus-sign" className="me-8" />{overdueRemindersArray && overdueRemindersArray.length > 0 ? 'Add another' : 'Create new'} notification
              </Button>
            </Row>
          </Row>
          {/* <Row className="mx-0 mt-24 bg-gray-250 rounded py-16">
            <Row className="m-0 p-0">
              <Col sm={10} className="p-0">
                <h2 className="mb-0 fw-700">Allow Customers to Snooze</h2>
              </Col>
              <Col sm={2} className="p-0 d-flex justify-content-center align-items-center">
                <Form>
                  <Form.Check
                    className="form-check-radio-custom"
                    type="switch"
                    name="allowCustomersToSnooze"
                    checked={allowCustomersToSnoozeOverdue}
                    onChange={() => settingsReminderSignal.update({
                      ...settingsReminderSignal.value,
                      allowCustomersToSnoozeOverdue: !allowCustomersToSnoozeOverdue,
                    })}
                  />
                </Form>
              </Col>
            </Row>
            <Row className="m-0 px-0 pt-8">
              <p className="p-0 m-0 text-dark-900">Accru will not send any reminders for invoices where your customers have opted to snooze notifications for 14 days.</p>
              <p className="p-0 m-0 mt-16 text-dark-900">You will still be able to manually send reminders from the invoices interface</p>
            </Row>
          </Row> */}
          <Row className="mx-0 px-0 mb-40">
            <Col className="ps-0">
              <Button
                variant="outline-primary"
                onClick={() => window.location.reload()}
                className="w-100 mb-16 mt-24"
              >
                CANCEL
              </Button>
            </Col>
            <Col className="pe-0">
              <Button
                variant="primary"
                onClick={() => saveChangesNotification(userSignal.value?.user?.organizations[0]?.organization_id)}
                className="w-100 mb-16 mt-24"
                disabled={overdueRemindersArray.length === 0 && deleteNotificationArray.length === 0}

              >
                SAVE CHANGES
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Row>
  );
};

export default OverdueNotifications;
