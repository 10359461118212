import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import AlertExclamation from './AlertExclamation';
import SuccessSmile from './SuccessSmile';
import { notificationSignal, alertSignal } from '#signals/Global.signals';

// eslint-disable-next-line no-unused-vars
const Notification = ({ alert }) => {
  const { exiting } = notificationSignal.value;

  const closeWithAnimation = () => {
    notificationSignal.update({
      exiting: true,
    });
    setTimeout(() => {
      alertSignal.reset();
      notificationSignal.update({
        exiting: false,
      });
    }, 6000);
  };

  useEffect(() => {
    setTimeout(() => closeWithAnimation(), alert.timeout || 6000);
  }, []);

  const exclamationColor = alert.type === 'error' ? '#d23b32' : '#b88c1a';

  if (!alert.isShown) {
    return null;
  }

  return (
    <div className={`container-fluid fixed-top w-50 mt-24 slide ${exiting ? 'slide-out' : 'slide-in'}`}>
      <div className={`notification notification-${alert.type || 'warning'} shadow-sm pt-24 rounded-4`}>
        <div className="d-flex justify-content-center align-items-center pb-24">
          <div className="decoration-icon">
            { alert.type === 'success' ?
              <SuccessSmile /> :
              <AlertExclamation color={exclamationColor} />}
          </div>
          <div className="text-center">
            {alert.message}
          </div>
        </div>
        {alert.detailedMessages && alert.detailedMessages.length > 0 && (
        <ul className="text-center py-24">
          {alert.detailedMessages.map(message => <li key={message}>{message}</li>)}
        </ul>
        )}
      </div>
      <div className="position-absolute" style={{ right: '30px', top: '16px' }} role="button">
        <FontAwesomeIcon
          style={{ height: '15px', width: '15px' }}
          icon={faTimes}
          onClick={closeWithAnimation}
        />
      </div>
    </div>
  );
};

const Modal = ({ alert }) => (
  <div
    className="modal fade show modal-background"
    style={{ display: 'block', zIndex: 9999 }}
    role="dialog"
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content rounded-0 border-dark">
        <div className="modal-header bg-danger text-white">
          An Error has occured!
        </div>
        <div className="modal-body">
          {alert.message}
        </div>
        <div className="modal-footer">
          <Button
            className="btn btn-dark"
            onClick={alertSignal.reset()}
            type="button"
          >
            Close
          </Button>
        </div>
      </div>
    </div>
  </div>
);

const Alert = () => {
  const alert = alertSignal.value;

  if (!alert.message) {
    return null;
  }

  if (alert.type === 'warning' || alert.type === 'success' || alert.type === 'error') {
    return <Notification alert={alert} />;
  }

  if (alert.type === 'alert') {
    console.warn(alert.error);
    return <Modal alert={alert} />;
  }

  if (alert.type === 'critical') {
    // eslint-disable-next-line no-alert
    window.alert(alert.message);
    alertSignal.reset();
    console.error(alert.error);
    return null;
  }

  if (alert.type === 'passive') {
    console.error(alert.error);
    return null;
  }

  return <div />;
};

export const triggerAlert = (message, opts = {}) => alertSignal.update({ isShown: true, message, ...opts });
export const triggerErrorAlert = (message, opts = {}) => triggerAlert(message, { ...opts, type: 'error' });
export const triggerWarningAlert = (message, opts = {}) => triggerAlert(message, { ...opts, type: 'warning' });
export const triggerSuccessAlert = (message, opts = {}) => triggerAlert(message, { ...opts, type: 'success' });

export const triggerAlertFromFnResult = (opts = {}) => (d) => {
  if (Object.prototype.toString.call(d) === '[object Error]') {
    if (opts.onError) {
      const message = opts.onError(d);
      triggerAlert(message, { type: 'error' });
    } else {
      triggerAlert(d.message, { type: 'error' });
    }
  } else if (opts.onSuccess) {
    const message = opts.onSuccess(d);
    triggerAlert(message, { type: 'success' });
  }
  return d;
};

export default Alert;
