import { Modal, Button, Nav, Image } from 'react-bootstrap';

const AppModal = ({
  show,
  handleClose,
  headerText,
  bodyText,
  buttonText,
  handleSubmit,
  hrefLink,
  hrefText,
  image,
  varient,
}) => (
  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton />
    <Modal.Title>
      {headerText && (
        headerText
      )}
      {image && (
      <Image className="w-100" src={image} alt="img" height="40px" />
      )}
    </Modal.Title>
    <Modal.Body>
      {bodyText && (
        bodyText
      )}
      {hrefLink && hrefText && (
      <Nav.Link href={hrefLink} className={`text-decoration-underline border-${varient} text-${varient}`}>{hrefText}</Nav.Link>
      )}
    </Modal.Body>
    {handleSubmit && (
      <Modal.Footer>
        <Button variant={varient} onClick={handleSubmit} className={`bg-${varient}`}>
          {buttonText}
        </Button>
      </Modal.Footer>
    )}
  </Modal>
);

export default AppModal;
