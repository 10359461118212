import { Container, Button, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const Pagination = ({
  itemsPerPageAmount,
  paginationMaxButtonAmount,

  totalItemsCount,
  currentPageItemsCount,

  currentPage,
  setCurrentPage,
}) => {
  const pagesCount = Math.ceil(totalItemsCount / itemsPerPageAmount);

  const firstPageButton = Math.max(
    1,
    Math.min(
      pagesCount - paginationMaxButtonAmount + 1,
      currentPage - Math.floor(paginationMaxButtonAmount / 2),
    ),
  );

  const lastPageButton = Math.min(
    pagesCount,
    firstPageButton + paginationMaxButtonAmount - 1,
  );

  const pages = Array.from({ length: lastPageButton - firstPageButton + 1 }, (_, i) => i + firstPageButton);

  console.log({
    itemsPerPageAmount,
    paginationMaxButtonAmount,

    totalItemsCount,
    currentPageItemsCount,

    currentPage,
    setCurrentPage,

    pages,
  });

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center m-16">
      {!!pages.length && (
        <ListGroup horizontal>
          <ListGroup.Item variant="transparent" className={`bg-transparent border-0 ${currentPage === 1 ? 'invisible' : ''}`}>
            <Button
              variant="transparent"
              className="border-0 p-0"
              disabled={currentPage <= 1}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              <FontAwesomeIcon className="text-primary" icon={faChevronLeft} />
            </Button>
          </ListGroup.Item>

          {pages.map((page) => (
            <ListGroup.Item
              variant="transparent"
              key={page}
              className="bg-transparent border-0"
            >
              <Button
                variant="transparent"
                className="border-0 p-0"
                disabled={currentPage === page}
                onClick={() => setCurrentPage(page)}
              >
                <span className={`${currentPage === page ? 'fw-900 text-primary' : 'fw-350 text-dark'}`}>{page}</span>
              </Button>
            </ListGroup.Item>
          ))}

          <ListGroup.Item variant="transparent" className={`bg-transparent border-0 ${currentPage >= pagesCount ? 'invisible' : ''}`}>
            <Button
              variant="transparent"
              className="border-0 p-0"
              disabled={currentPage >= pagesCount}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              <FontAwesomeIcon className="text-primary" icon={faChevronRight} />
            </Button>
          </ListGroup.Item>
        </ListGroup>
      )}
      {!!pages.length && !!currentPageItemsCount && (
        <div className="ml-16">
          <span className="text-muted">
            Showing {currentPageItemsCount} of {totalItemsCount} results
          </span>
        </div>
      )}
    </Container>
  );
};

export default Pagination;
