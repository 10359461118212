/* eslint-disable jsx-a11y/control-has-associated-label */
import { Tab, Tabs } from 'react-bootstrap';
import EmailSignupForm from './components/EmailSignupForm';
import VerificationForm from './components/VerificationForm';
import PasswordForm from './components/PasswordForm';
import { AuthLayout } from '#global/Layout';
import { signUpSignal } from '#signals/Authentication.signals';
import ComponentLoader from '../../global/ComponentLoader';
import { loaderSignal } from '#signals/Global.signals';

const SignUp = () => {
  const { currentPage } = signUpSignal.value;

  return (
    <AuthLayout>
      {loaderSignal.value?.isLoading ? (
        <ComponentLoader />
      ) : (
        <Tabs activeKey={currentPage} className="border-0 d-none">
          <Tab eventKey="emailForm" title="Email Form">
            <EmailSignupForm />
          </Tab>
          <Tab eventKey="verify" title="Verify">
            <VerificationForm
              invitedUser={false}
            />
          </Tab>
          <Tab eventKey="createPassword" title="Create Password">
            <PasswordForm
              invitedUser={false}
            />
          </Tab>
        </Tabs>
      )}

    </AuthLayout>
  );
};

export default SignUp;
