import { Navbar, Nav, Row, Image, Col, Dropdown, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import AccruLogo from '#images/accruLogo.svg';
import Users from '#images/users.svg';
import UsersActive from '#images/users-active.svg';
import Store from '#images/store.svg';
import StoreActive from '#images/store-active.svg';
import Folders from '#images/folders.svg';
import FoldersActive from '#images/folders-active.svg';
import CreditCard from '#images/credit-card.svg';
import CreditCardActive from '#images/credit-card-active.svg';
import FileInvoiceDollar from '#images/file-invoice-dollar.svg';
import FileInvoiceDollarActive from '#images/file-invoice-dollar-active.svg';
import NavActive from '#images/nav-active-polygon.svg';
import NavUser from '#images/nav-user.svg';

import { handleLogout } from '#views/Auth/Auth.helpers';
import { userSignal } from '#signals/User.signals';
import ComingSoonModal from '../ComingSoonModal';
import { navBarSignal } from '#signals/Global.signals';
import Loadable from '../Loadable';

const NavBar = () => {
  const { pathname } = useLocation();
  const { isOpen, isComingSoonOpen } = navBarSignal.value;

  return (
    <div>
      <ComingSoonModal show={isComingSoonOpen} handleClose={() => navBarSignal.update({ isComingSoonOpen: !isComingSoonOpen })} videoLink="https://www.youtube.com/embed/EQZHGCo3Q2s" />
      {/* LG & XL */}
      <Navbar bg="secondary-300" expand="lg" className="p-0 d-lg-block d-xl-block d-none position-fixed vw-100" style={{ zIndex: 999 }}>
        <Row className="w-100">
          <Col lg={3} className="my-auto d-flex justify-content-center pe-0">
            <Navbar.Brand><Image src={AccruLogo} alt="AccruLogo" /></Navbar.Brand>
          </Col>
          <Col lg={5} xl={6}>
            <Navbar.Toggle aria-controls="basic-navbar-nav" className="border-0" />
            <Navbar.Collapse>
              <Nav className="flex-grow-1 justify-content-evenly">
                <Nav.Item className="my-auto">
                  <LinkContainer to="/customers">
                    <Nav.Link>
                      <Image src={pathname === '/customers' ? UsersActive : Users} alt={`${pathname === '/customers' ? UsersActive : Users}`} className="d-flex mx-auto" height="19px" />
                      <h5 className={`${pathname === '/customers' ? 'text-primary' : ''} mb-0 mt-8`}>Customers</h5>
                      {pathname === '/customers' && (
                      <Image src={NavActive} alt={`${NavActive}`} className="d-flex mx-auto" height="10px" />
                      )}
                    </Nav.Link>
                  </LinkContainer>
                </Nav.Item>
                <Nav.Item className="my-auto">
                  <LinkContainer to="/vendors">
                    <Nav.Link>
                      <Image src={pathname === '/vendors' ? StoreActive : Store} alt={`${pathname === '/vendors' ? StoreActive : Store}`} className="d-flex mx-auto" height="19px" />
                      <h5 className={`${pathname === '/vendors' ? 'text-primary' : ''} mb-0 mt-8`}>Vendors</h5>
                      {pathname === '/vendors' && (
                        <Image src={NavActive} alt={`${NavActive}`} className="d-flex mx-auto" height="10px" />
                      )}
                    </Nav.Link>
                  </LinkContainer>
                </Nav.Item>
                <Nav.Item className="my-auto">
                  <LinkContainer to="/invoices">
                    <Nav.Link>
                      <Image src={pathname === '/invoices' ? FileInvoiceDollarActive : FileInvoiceDollar} alt={`${pathname === '/invoices' ? FileInvoiceDollarActive : FileInvoiceDollar}`} className="d-flex mx-auto" height="19px" />
                      <h5 className={`${pathname === '/invoices' ? 'text-primary' : ''} mb-0 mt-8`}>Invoices</h5>
                      {pathname === '/invoices' && (
                        <Image src={NavActive} alt={`${NavActive}`} className="d-flex mx-auto" height="10px" />
                      )}
                    </Nav.Link>
                  </LinkContainer>
                </Nav.Item>
                <Nav.Item className="my-auto">
                  <LinkContainer to="/bills">
                    <Nav.Link>
                      <Image src={pathname === '/bills' ? CreditCardActive : CreditCard} alt={`${pathname === '/bills' ? CreditCardActive : CreditCard}`} className="d-flex mx-auto" height="19px" />
                      <h5 className={`${pathname === '/bills' ? 'text-primary' : ''} mb-0 mt-8`}>Bills</h5>
                      {pathname === '/bills' && (
                      <Image src={NavActive} alt={`${NavActive}`} className="d-flex mx-auto" height="10px" />
                      )}
                    </Nav.Link>
                  </LinkContainer>
                </Nav.Item>
                <Nav.Item className="my-auto">
                  <Nav.Link onClick={() => navBarSignal.update({ isComingSoonOpen: !isComingSoonOpen })}>
                    <Image src={pathname === '/projects' ? FoldersActive : Folders} alt={`${pathname === '/projects' ? FoldersActive : Folders}`} className="d-flex mx-auto" height="19px" />
                    <h5 className={`${pathname === '/projects' ? 'text-primary' : ''} mb-0 mt-8`}>Projects</h5>
                    {pathname === '/projects' && (
                      <Image src={NavActive} alt={`${NavActive}`} className="d-flex mx-auto" height="10px" />
                    )}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Col>
          <Col lg={{ span: 3, offset: 1 }} xl={{ span: 2, offset: 1 }} className="border-start border-secondary-900 d-flex align-items-center">
            <Col sm={12} className="h-100 d-flex align-items-center justify-content-around">
              {userSignal.value?.user?.profile_picture_file?.public_url ? (
                <Loadable signal={userSignal} template="profilePic-nav">
                  <Image
                    src={userSignal.value?.user?.profile_picture_file?.public_url}
                    alt="Profile Photo"
                    height="45px"
                    width="45px"
                    className="rounded-circle border border-primary position-relative me-8"
                  />
                </Loadable>
              ) : (
                <Image src={NavUser} alt={`${NavUser}`} height="33px" />
              )}
              <div className="d-row my-auto">
                <Loadable signal={userSignal} template="name-nav">
                  <h3 className="d-flex align-items-center m-0">{userSignal.value?.user?.first_name || ''} {userSignal.value?.user?.last_name || ''}</h3>
                  <p className="d-flex align-items-center m-0 text-dark">
                    {userSignal.value?.user?.organizations?.[0]?.organization?.name || ''}
                  </p>
                </Loadable>
              </div>
              <Dropdown>
                <Button
                  variant="eye"
                  onClick={() => navBarSignal.update({ isOpen: !isOpen })}
                  className="p-0 m-0 text-secondary h-100"
                >
                  <Dropdown.Toggle variant="eye" className="custom-dropdown-toggle text-secondary p-0 m-0 h-100">
                    <FontAwesomeIcon icon={isOpen ? faChevronDown : faChevronUp} />
                  </Dropdown.Toggle>
                </Button>
                <Dropdown.Menu className="custom-nav-dropdown p-0">
                  <Dropdown.Item href="/settings" className="rounded">Settings</Dropdown.Item>
                  <Dropdown.Item className="rounded" onClick={handleLogout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Col>
        </Row>
      </Navbar>
      {/* MD & SM */}
      <Navbar bg="secondary-300" expand="lg" className="p-0 d-lg-none d-xl-none py-8 position-fixed vw-100" style={{ zIndex: 999 }}>
        <Row className="w-100 m-0">
          <Col xs={8} className="my-auto d-flex justify-content-start pe-0">
            <Navbar.Brand><Image src={AccruLogo} alt="AccruLogo" /></Navbar.Brand>
          </Col>
          <Col xs={4} className="my-auto d-flex justify-content-end">
            <Navbar.Toggle className="border-0 my-auto d-flex justify-content-end" />
          </Col>
        </Row>
        <Navbar.Collapse>
          <Nav>
            <hr className="m-0 mt-8" />
            <Nav.Item className="my-auto" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.collapse">
              <LinkContainer to="/customers">
                <Nav.Link className="d-flex justify-content-center">
                  <Image src={pathname === '/customers' ? UsersActive : Users} alt={`${pathname === '/customers' ? UsersActive : Users}`} height="19px" />
                  <h5 className={`${pathname === '/customers' ? 'text-primary fw-500' : ''} mb-0 ms-8`}>Customers</h5>
                </Nav.Link>
              </LinkContainer>
            </Nav.Item>
            <Nav.Item className="my-auto">
              <LinkContainer to="/vendors">
                <Nav.Link className="d-flex justify-content-center">
                  <Image src={pathname === '/vendors' ? StoreActive : Store} alt={`${pathname === '/vendors' ? StoreActive : Store}`} height="19px" />
                  <h5 className={`${pathname === '/vendors' ? 'text-primary fw-500' : ''} mb-0 ms-8`}>Vendors</h5>
                </Nav.Link>
              </LinkContainer>
            </Nav.Item>
            <Nav.Item className="my-auto">
              <LinkContainer to="/statement">
                <Nav.Link className="d-flex justify-content-center">
                  <Image src={pathname === '/statement' ? FileInvoiceDollarActive : FileInvoiceDollar} alt={`${pathname === '/statement' ? FileInvoiceDollarActive : FileInvoiceDollar}`} height="19px" />
                  <h5 className={`${pathname === '/statement' ? 'text-primary fw-500' : ''} mb-0 ms-8`}>Invoices</h5>
                </Nav.Link>
              </LinkContainer>
            </Nav.Item>
            <Nav.Item className="my-auto">
              <LinkContainer to="/bills">
                <Nav.Link className="d-flex justify-content-center">
                  <Image src={pathname === '/bills' ? CreditCardActive : CreditCard} alt={`${pathname === '/bills' ? CreditCardActive : CreditCard}`} height="19px" />
                  <h5 className={`${pathname === '/bills' ? 'text-primary fw-500' : ''} mb-0 ms-8`}>Bills</h5>
                </Nav.Link>
              </LinkContainer>
            </Nav.Item>
            <Nav.Item className="my-auto">
              <LinkContainer to="/projects">
                <Nav.Link className="d-flex justify-content-center">
                  <Image src={pathname === '/projects' ? FoldersActive : Folders} alt={`${pathname === '/projects' ? FoldersActive : Folders}`} height="19px" />
                  <h5 className={`${pathname === '/projects' ? 'text-primary fw-500' : ''} mb-0 ms-8`}>Projects</h5>
                </Nav.Link>
              </LinkContainer>
            </Nav.Item>
            <hr className="m-0 mt-8" />
            <Nav.Item className="my-auto">
              <LinkContainer to="/settings">
                <Nav.Link className="d-flex justify-content-center">
                  <h5 className="mb-0">Settings</h5>
                </Nav.Link>
              </LinkContainer>
            </Nav.Item>
            <Nav.Item className="my-auto">
              <Nav.Link onClick={handleLogout} className="d-flex justify-content-center">
                <h5 className="mb-0">Logout</h5>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default NavBar;
