import { effect } from '@preact/signals-react';
import { io } from 'socket.io-client';
import { getConfig } from '#config/config';
import { syncingSignal, toastrSignal } from '#signals/Global.signals';
import { userSignal } from '#signals/User.signals';
import { authTokenSignal } from '#signals/Authentication.signals';

export const onOrganizationSync = (eventName) => {
  const syncHistory = [...syncingSignal.value.syncData];
  syncHistory.push(eventName);
  syncingSignal.update({ syncData: syncHistory });
};

export const handleCreateSyncToaster = (syncId) => {
  const allToasts = toastrSignal.value.toasts;
  allToasts.push({
    id: syncId,
    expanded: true,
    type: 'sync',
  });

  toastrSignal.update({
    toasts: allToasts,
  });
};

effect(() => {
  const REACT_APP_API_ENDPOINT = getConfig('BACKEND_GRAPHQL_ENDPOINT');
  const handleListenToSync = async () => {
    const organizationId = userSignal?.value?.user?.organizations?.[0]?.organization_id;

    if (syncingSignal.value.isSyncing && organizationId && !toastrSignal.value.toasts.length) {
      const socket = io(`${REACT_APP_API_ENDPOINT.replace('/graphql', '')}`, {
        auth: { token: `Bearer ${authTokenSignal.value}` },
        path: '/websocket',
        reconnectionDelay: 10000,
      });

      socket.on('organizationSynchronization', onOrganizationSync);
      handleCreateSyncToaster(organizationId); // temp id until discussion of potential multiple syncs
    }
  };
  handleListenToSync();
});
