import Signal from '../Signal';

export const vendorsFormSignal = Signal({
  name: '',
  email: '',
  phone_number: '',
});

export const vendorListDataSignalInitialState = {
  is_loading: true,
  error: null,
};
export const vendorListDataSignal = Signal(vendorListDataSignalInitialState);

export const vendorListSignal = Signal({
  openTab: 'details',

  searchTerm: '',

  selectedSorting: [{ field: 'created_at', order: 'DESC' }],
  take: 20,

  selectedVendor: null,

  isMobileModalOpen: false,
});

export const vendorClientContactSignal = Signal({
  contacts: [],
  isModalOpen: false,
  editingContact: null,
  formData: {},
});

export const vendorClientDetailSignal = Signal({
  isEditing: false,
  formData: {},
});

export const vendorAddSignal = Signal({
  currentScreen: 'method',
  backButtonHistory: [],
});

export const vendorAddSyncSignal = Signal({
  isQuickbooksConnected: false,
  connectingModalOpen: false,
  isConfiguringOptions: false,
  pushOptions: null,
  selectedPushItem: null,
  selectedPushAccount: null,
  pullToggle: false,
  pushToggle: false,
});

export const vendorAddUploadSignal = Signal({
  uploadedFiles: [],
});

export default {
  vendorsFormSignal,
  vendorListDataSignal,
  vendorListSignal,
  vendorClientContactSignal,
  vendorClientDetailSignal,
  vendorAddSignal,
  vendorAddUploadSignal,
  vendorAddSyncSignal,
};
