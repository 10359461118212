import { Col, Row, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const DontHaveAnAccountLink = () => (
  <Row className="d-flex align-items-center justify-content-center">
    <Col className="d-flex justify-content-center align-items-center mb-16">
      <small className="p-0 pt-8">
        Don&apos;t have an account?
      </small>
      <LinkContainer to="/signup">
        <Nav.Link className="ms-8 text-decoration-underline text-primary p-0">
          <small>Sign Up</small>
        </Nav.Link>
      </LinkContainer>
    </Col>
  </Row>
);

export default DontHaveAnAccountLink;
