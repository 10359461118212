import { Button, Col, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { settingsProfileSignal } from '#signals/pages/Settings.signals';
import { handleVerifyFormChange, resetPasswordStart, resetPasswordFinish } from '../ProfileSettings.helpers';
import { userSignal } from '#signals/User.signals';
import UniversalInput from '../../../../../global/Inputs/UniversalInput';

const UpdatePassword = () => {
  const {
    currentPassword,
    newPassword,
    confirmPassword,
    passwordVisible,
    confirmPasswordVisible,
    passwordRequirements,
    passwordPage,
    verificationCode,
  } = settingsProfileSignal.value;

  useEffect(() => {
    if (newPassword) {
      const { ...requirements } = passwordRequirements;
      requirements.reqLength = newPassword.length >= 12 && newPassword.length <= 36;
      requirements.uppercase = /[A-Z]/.test(newPassword);
      requirements.number = /\d/.test(newPassword);
      requirements.symbol = /[^A-Za-z0-9]/.test(newPassword);
      settingsProfileSignal.update({
        ...settingsProfileSignal.value,
        passwordRequirements: requirements,
      });
    }
  }, [newPassword]);

  return (
    <Row className="mx-0">
      <Row className="mx-0 mt-24 py-24 bg-gray-250 rounded">
        {passwordPage === 'password' && (
          <Form.Group>
            <h2 className="fw-700 mb-8">Update Password</h2>
            <Form.Label htmlFor="CurrentPassword"><strong>Current Password</strong></Form.Label>
            <Row className="m-0">
              <Col xs={11} className="p-0">
                <UniversalInput
                  className="mb-16"
                  type={passwordVisible ? 'text' : 'password'}
                  placeholder="Confirm Password"
                  name="currentPassword"
                  value={currentPassword}
                  signal={settingsProfileSignal}
                />
              </Col>
              <Col xs={1} className="p-0 mb-16 border-bottom border-gray border-1">
                <Button
                  role="button"
                  tabIndex="0"
                  onClick={() => settingsProfileSignal.update({
                    ...settingsProfileSignal.value,
                    passwordVisible: !passwordVisible,
                  })}
                  variant="eye"
                  className="p-0 w-100 h-100 text-primary m-auto rounded-0 border-dark d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon className="d-flex justify-content-center" icon={passwordVisible ? faEye : faEyeSlash} />
                </Button>
              </Col>
            </Row>
            <Form.Label htmlFor="NewPassword"><strong>New Password</strong></Form.Label>
            <UniversalInput
              className="mb-16"
              type="password"
              placeholder="Enter New Password"
              name="newPassword"
              value={newPassword}
              signal={settingsProfileSignal}
            />
            <Form.Label htmlFor="Password"><strong>Confirm New Password</strong></Form.Label>
            <Row className="m-0">
              <Col xs={11} className="p-0">
                <UniversalInput
                  className="mb-16"
                  placeholder="Confirm Password"
                  type={confirmPasswordVisible ? 'text' : 'password'}
                  name="confirmPassword"
                  value={confirmPassword}
                  signal={settingsProfileSignal}
                />
              </Col>
              <Col xs={1} className="p-0 mb-16 border-bottom border-gray border-1">
                <Button
                  role="button"
                  tabIndex="0"
                  onClick={() => settingsProfileSignal.update({
                    ...settingsProfileSignal.value,
                    confirmPasswordVisible: !confirmPasswordVisible,
                  })}
                  variant="eye"
                  className="p-0 w-100 h-100 text-primary m-auto rounded-0 border-dark d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon className="d-flex justify-content-center" icon={confirmPasswordVisible ? faEye : faEyeSlash} />
                </Button>
              </Col>
            </Row>
            <Form.Label>Password must:</Form.Label>
            <div className="text-dark-500">
              <div className="ms-16"><FontAwesomeIcon className="me-8" icon={faCheckCircle} color={passwordRequirements.reqLength ? '#41696C' : '#CDCDCD'} />Be 12-36 characters in length</div>
              <div className="ms-16"><FontAwesomeIcon className="me-8" icon={faCheckCircle} color={passwordRequirements.number ? '#41696C' : '#CDCDCD'} />Contain at least one number</div>
              <div className="ms-16"><FontAwesomeIcon className="me-8" icon={faCheckCircle} color={passwordRequirements.uppercase ? '#41696C' : '#CDCDCD'} />Contain at least one uppercase letter</div>
              <div className="ms-16"><FontAwesomeIcon className="me-8" icon={faCheckCircle} color={passwordRequirements.symbol ? '#41696C' : '#CDCDCD'} />Contain at least one special character</div>
            </div>
          </Form.Group>
        )}
        {passwordPage === 'validation' && (
          <Form.Group>
            <h2 className="fw-700 text-center mb-32">Verify it&apos;s you</h2>
            <p className="base fw-400 text-dark-900 text-center">
              For your security, we just need to make sure this is you. We sent you a 6 digit code to {userSignal.value.user.email}
            </p>
            <Row>
              <div className="px-0 me-8 d-flex justify-content-around">
                {verificationCode.map((value, index) => (
                  <Form.Control
                    key={index}
                    className={`mb-40 h3 mx-8 text-center p-0 py-16 validation-form ${value === '' ? 'active' : ''}`}
                    type="text"
                    max="1"
                    value={value}
                    name="verificationCode"
                    onChange={(e) => handleVerifyFormChange(e, index, verificationCode, false)}
                  />
                ))}
              </div>
            </Row>
          </Form.Group>
        )}
      </Row>
      <Row className="mx-0">
        <Col className="ps-0">
          <Button
            variant="outline-primary"
            onClick={() => settingsProfileSignal.update({
              ...settingsProfileSignal.value,
              passwordPage: 'password',
              passwordVisible: false,
              confirmPasswordVisible: false,
              currentPassword: '',
              newPassword: '',
              confirmPassword: '',
              passwordRequirements: {
                reqLength: false,
                number: false,
                uppercase: false,
                symbol: false,
              },
              verificationCode: ['', '', '', '', '', ''],
            })}
            className="w-100 btn-lg mb-16 mt-24"
            disabled={!newPassword && !currentPassword && !confirmPassword}
          >
            Cancel
          </Button>
        </Col>
        <Col className="pe-0">
          {passwordPage === 'password' ? (
            <Button
              variant="primary"
              onClick={() => resetPasswordStart()}
              className="w-100 btn-lg mb-16 mt-24"
              disabled={!newPassword || !currentPassword || confirmPassword !== newPassword}
            >
              Next
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={() => resetPasswordFinish()}
              className="w-100 btn-lg mb-16 mt-24"
              disabled={verificationCode.includes('')}
            >
              Confirm
            </Button>
          )}
        </Col>
      </Row>
    </Row>
  );
};

export default UpdatePassword;
