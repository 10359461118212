import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Form, Nav, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import GoogleLogo from '#images/google.svg';
import IntuitLogo from '#images/intuit.svg';

import DontHaveAnAccountLink from '#views/Auth/components/DontHaveAnAccountLink';
import {
  partialEmailPasswordLoginHandler,
  partialGoogleLoginHandler,
  performIntuitCallbackHandoff,
  handleIntuitStartLogin,
} from '#views/Auth/Auth.helpers';
import { loginSignal } from '#signals/Authentication.signals';
import UniversalInput from '../../../global/Inputs/UniversalInput';

const LoginForm = () => {
  const { passwordVisible } = loginSignal.value;
  const location = useLocation();

  useEffect(() => {
    if (location.search.includes('code') && location.search.includes('state')) {
      performIntuitCallbackHandoff(`${window.location.origin}${'/login'}${location.search}`);
    }
  }, [location.search]);

  return (
    <Form.Group>
      <Form.Label htmlFor="EmailAddress"><strong>Email address</strong></Form.Label>
      <UniversalInput
        value={loginSignal.value.email}
        name="email"
        className="mb-16"
        signal={loginSignal}
        placeholder="Enter email"
      />
      <Form.Label htmlFor="Password"><strong>Password</strong></Form.Label>
      <Row className="m-0">
        <Col xs={11} className="p-0">
          <UniversalInput
            value={loginSignal.value.password}
            className="mb-16"
            type={passwordVisible ? 'text' : 'password'}
            name="password"
            signal={loginSignal}
            placeholder="Enter Password"
          />
        </Col>
        <Col xs={1} className="p-0 mb-16 border-bottom border-gray border-1">
          <Button
            role="button"
            tabIndex="0"
            onClick={() => loginSignal.update({ passwordVisible: !passwordVisible })}
            variant="eye"
            className="text-primary p-8 rounded-0 border-dark"
          >
            <FontAwesomeIcon
              className="p-0 d-flex justify-content-center align-content-center"
              icon={passwordVisible ? faEye : faEyeSlash}
            />
          </Button>
        </Col>
      </Row>
      <LinkContainer to="/forgot-password">
        <Nav.Link className="mb-16 text-decoration-underline p-0 text-primary">
          <small className="fw-500">
            Having trouble signing in?
          </small>
        </Nav.Link>
      </LinkContainer>
      <Form.Group className="mb-16" controlId="formBasicCheckbox">
        <Form.Check
          type="checkbox"
          label="Remember this device"
          className=""
          name="rememberDevice"
          value={loginSignal.value.rememberDevice}
          onChange={() => loginSignal.update({ rememberDevice: !loginSignal.value.rememberDevice })}
        />
      </Form.Group>
      <Button
        variant="primary"
        type="submit"
        onClick={() => partialEmailPasswordLoginHandler()}
        className="w-100 btn-lg mb-16"
        disabled={!loginSignal.value.password || !loginSignal.value.email}
      >
        Login
      </Button>
      <Row>
        <Col xs={4}>
          <hr />
        </Col>
        <Col xs={4} className="d-flex justify-content-center align-items-center">
          Or sign in with
        </Col>
        <Col xs={4}>
          <hr />
        </Col>
      </Row>
      <Row className="d-flex align-items-center justify-content-center mt-16 mb-24 mx-0">
        <Col xs={6} className="d-flex justify-content-around pt-0 ps-0">
          <Button
            variant="white"
            type="submit"
            onClick={() => handleIntuitStartLogin()}
            className="w-100 btn-lg h-100 border-dark"
          ><img
            src={IntuitLogo}
            width={60}
            alt="Intuit Logo"
          />
          </Button>
        </Col>
        <Col xs={6} className="d-flex justify-content-around pe-0">
          <Button
            variant="white"
            type="submit"
            onClick={() => partialGoogleLoginHandler()}
            className="w-100 btn-lg h-100 border-dark"
          ><img
            src={GoogleLogo}
            height="25"
            alt="Google Logo"
          />
          </Button>
        </Col>
      </Row>
      <DontHaveAnAccountLink />
    </Form.Group>
  );
};

export default LoginForm;
