import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { memo, useEffect } from 'react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { skipToDetailsSignal } from '#signals/pages/Statement.signals';

const SkipToDetails = () => {
  const { isVisible } = skipToDetailsSignal.value;
  const handleOnClick = () => {
    window.location.href = '#details';
  };

  useEffect(() => {
    const checkScrollPosition = () => {
      const detailsSection = document.getElementById('details');
      if (!detailsSection) return;

      const detailsSectionTop = detailsSection.getBoundingClientRect().top;

      skipToDetailsSignal.update({
        isVisible: detailsSectionTop > window.innerHeight,
      });
    };

    window.addEventListener('scroll', checkScrollPosition);

    checkScrollPosition();

    return () => {
      window.removeEventListener('scroll', checkScrollPosition);
    };
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <section className="w-100 position-fixed bottom-0 end-0 shadow-lg" style={{ background: '#161616', opacity: '50%' }}>
      <Button
        variant="eye"
        className="w-100 text-primary"
        onClick={handleOnClick}
      >
        <p className="fw-900 text-white lead w-100 mb-0">View Full Statement</p>
        <FontAwesomeIcon icon={faChevronDown} className="text-primary h2 w-100 mb-0" />
      </Button>
    </section>
  );
};

export default memo(SkipToDetails);
