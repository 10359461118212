import Signal from '../Signal';

export const customersFormSignal = Signal({
  name: '',
  email: '',
  phone_number: '',
});

export const customerListDataSignalInitialState = {
  is_loading: true,
  error: null,
};
export const customerListDataSignal = Signal(customerListDataSignalInitialState);

export const customerListSignal = Signal({
  openTab: 'details',

  searchTerm: '',

  selectedSorting: [{ field: 'created_at', order: 'DESC' }],
  take: 20,

  selectedCustomer: null,

  isMobileModalOpen: false,
});

export const addCustomerSignal = Signal({
  currentScreen: 'method',
  backButtonHistory: [],
});

export const addCustomerSyncSignal = Signal({
  isQuickbooksConnected: false,
  connectingModalOpen: false,
  isConfiguringOptions: false,
  pushOptions: null,
  selectedPushItem: null,
  selectedPushAccount: null,
  pullToggle: false,
  pushToggle: false,
});

export const addCustomerUploadSignal = Signal({
  uploadedFiles: [],
});

export const customerConnectionsSignal = Signal({
  connected: false,
  syncContactInfo: false,
});

export default {
  customersFormSignal,
  customerListDataSignal,
  customerListSignal,
  addCustomerSignal,
  addCustomerSyncSignal,
  customerConnectionsSignal,
};
