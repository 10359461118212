/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Row, Col, Container, Card, Image } from 'react-bootstrap';
import cycle from '#images/cycle.svg';
import userIcon from '#images/user-icon.svg';

const AddCustomerMethod = ({ handleNext }) => (
  <>
    <Row>
      <Col className="text-center">
        <h1 className="text-primary fw-700 pt-16 pb-24">How would you like to add your customer(s)?</h1>
      </Col>
    </Row>
    <Row>
      <Col>
        <h2 className="pb-56 mx-104">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Natoque mauris arcu tristique malesuada.</h2>
      </Col>
    </Row>
    <Container className="pb-48">
      <Card onClick={() => handleNext({ current: 'method', next: 'sync' })} style={{ maxWidth: 620 }} className="cursor-pointer py-32 mb-24 justify-content-left mx-auto">
        <Container>
          <Row className="d-flex justify-content-left align-items-center px-16">
            <Col lg={2}>
              <Image width={40} src={cycle} />
            </Col>
            <Col lg={9} className="text-start text-dark-900">
              <h5 className="fw-700">Sync with Quickbooks</h5>
              <p className="base mb-0">Connect you customer list in quickbooks with accru</p>
              {/* <small
                onClick={(e) => {
                  e.stopPropagation();
                  // setInfoModalOpen(true);
                }}
                className="mb-0 mt-8 text-primary fw-500"
              >
                <u>Why sync with Quickbooks?</u>
              </small> */}
            </Col>
          </Row>
        </Container>
      </Card>
      <Card onClick={() => handleNext({ current: 'method', next: 'manual' })} style={{ maxWidth: 620 }} className="cursor-pointer py-32 mb-24 justify-content-left mx-auto">
        <Container>
          <Row className="d-flex justify-content-left align-items-center px-16">
            <Col lg={2}>
              <Image width={40} src={userIcon} />
            </Col>
            <Col lg={9} className="text-start text-dark-900">
              <h5 className="fw-700">Add one customer</h5>
              <p className="base mb-0">Manually add a customer into our system</p>
            </Col>
          </Row>
        </Container>
      </Card>
    </Container>
  </>
);

export default AddCustomerMethod;
