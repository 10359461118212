import { Button, Col, Modal, Row } from 'react-bootstrap';
import { settingsReminderSignal } from '#signals/pages/Settings.signals';
import { saveCustomRecurrence } from '../ReminderSettings.helpers';
import { repeatArray, timeArray, weekValues } from '#utils/selectArrays';
import SelectInput from '#global/Inputs/SelectInput';
import UniversalInput from '../../../../../global/Inputs/UniversalInput';

const AutomaticOverdueRemindersModal = () => {
  const {
    isCustomRecurrenceOpen,
    tempReminderObj,
  } = settingsReminderSignal.value;
  return (
    <Modal
      show={isCustomRecurrenceOpen}
      onHide={() => settingsReminderSignal.update({
        ...settingsReminderSignal.value,
        isCustomRecurrenceOpen: !isCustomRecurrenceOpen,
      })}
    >
      <Modal.Title>
        <h2 className="text-center text-primary pb-0 mb-0 py-16">Custom Recurrence</h2>
      </Modal.Title>
      <Modal.Body className="coming-soon-modal-body">
        <div className="w-75 mx-auto">
          <Row className="mx-0 px-0 mb-16">
            <Col sm={6} className="p-0 d-flex align-items-bottom">
              <p className="p-0 mt-auto mb-0">
                Repeat
              </p>
              <SelectInput
                name="Type"
                value={tempReminderObj.type}
                options={repeatArray}
                customOnChange={(e) => {
                  settingsReminderSignal.update({
                    ...settingsReminderSignal.value,
                    tempReminderObj: {
                      ...tempReminderObj,
                      type: e.target.value,
                    },
                  });
                }}
              />
              {tempReminderObj.type === 'weekly' && (
                <p className="p-0 mt-auto mb-0">
                  on:
                </p>
              )}
            </Col>
          </Row>
          {tempReminderObj.type === 'daily' && (
            <Row className="mx-0 px-0">
              <Col sm={12} className="p-0 d-flex align-items-bottom">
                <p className="p-0 mt-auto mb-0">
                  At {' '}
                </p>
                <SelectInput
                  name="days"
                  value={tempReminderObj.daily}
                  options={timeArray}
                  customOnChange={(e) => {
                    settingsReminderSignal.update({
                      ...settingsReminderSignal.value,
                      tempReminderObj: {
                        ...tempReminderObj,
                        daily: e.target.value,
                      },
                    });
                  }}
                />
                <p className="p-0 mt-auto mb-0">
                  on business days
                </p>
              </Col>
            </Row>
          )}
          {tempReminderObj.type === 'weekly' && (
            <Row className="mx-0 px-0">
              <Col sm={12} className="p-0 d-flex align-items-bottom justify-content-around">
                {weekValues && weekValues.map((obj, idx) => (
                  <Button
                    key={idx}
                    className="mb-8"
                    onClick={() => {
                      settingsReminderSignal.update({
                        ...settingsReminderSignal.value,
                        tempReminderObj: {
                          ...tempReminderObj,
                          weekly: obj.value,
                        },
                      });
                    }}
                    variant={`${tempReminderObj.weekly === obj.value ? 'primary' : 'eye'} btn-circle`}
                  >
                    {obj.label}
                  </Button>
                ))}

              </Col>
            </Row>
          )}
          {tempReminderObj.type === 'monthly' && (
            <Row className="mx-0 px-0">
              <Col sm={12} className="p-0 d-flex align-items-bottom">
                <p className="p-0 mt-auto mb-0">
                  On the
                </p>
                <UniversalInput
                  className="mx-8 w-25"
                  type="number"
                  name="monthly"
                  value={tempReminderObj.monthly}
                  customOnChange={(e) => {
                    if (e.target.value > 0 && e.target.value < 29) {
                      settingsReminderSignal.update({
                        ...settingsReminderSignal.value,
                        tempReminderObj: {
                          ...tempReminderObj,
                          monthly: e.target.value,
                        },
                      });
                    }
                  }}
                />
                <p className="p-0 mt-auto mb-0">
                  of every month
                </p>
              </Col>
            </Row>
          )}
          <Row className="mx-0 px-0 mt-16">
            <Col sm={{ offset: 6, span: 6 }} className="p-0 d-flex justify-content-end">
              <Button
                variant="eye"
                onClick={() => settingsReminderSignal.update({
                  ...settingsReminderSignal.value,
                  isCustomRecurrenceOpen: !isCustomRecurrenceOpen,
                })}
              >
                Cancel
              </Button>
              <Button
                variant="eye"
                className="text-primary text-decoration-underline"
                disabled={
                  (tempReminderObj.type === 'daily' && !tempReminderObj.daily && tempReminderObj.daily !== 0) ||
                  (tempReminderObj.type === 'weekly' && tempReminderObj.weekly === null) ||
                  (tempReminderObj.type === 'monthly' && !tempReminderObj.monthly)
                }
                onClick={() => saveCustomRecurrence()}
              >
                Done
              </Button>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AutomaticOverdueRemindersModal;
