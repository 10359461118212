import Signal from '../Signal';

export const settingsSignal = Signal({
  currentTab: 'profile-settings',
  isMobileModalOpen: false,
});

export const settingsLogsSignal = Signal({
  logs: [],
  originalLogs: [],
  issues: 0,
  lastSynced: null,
  lastSyncType: null,
});

export const settingsProfileSignal = Signal({
  currentTab: 'info',
  profilePhoto: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  contactPage: 'contact',
  contactVerificationCode: ['', '', '', '', '', ''],
  passwordPage: 'password',
  passwordVisible: false,
  confirmPasswordVisible: false,
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
  passwordRequirements: {
    reqLength: false,
    number: false,
    uppercase: false,
    symbol: false,
  },
  verificationCode: ['', '', '', '', '', ''],
});

export const settingsPaymentSignal = Signal({
  currentTab: 'receiving-methods',
  comingSoonModalOpen: false,
});

export const settingsReminderSignal = Signal({
  currentTab: 'invoice-reminders',
  automaticInvoiceReminders: false,
  invoiceRemindersArray: [],
  deleteInvoiceRemindersArray: [],
  allowCustomersToSnooze: false,
  isCustomRecurrenceOpen: false,
  automaticOverdueReminders: false,
  overdueRemindersArray: [],
  deleteNotificationArray: [],
  tempReminderObj: {
    type: 'daily',
    daily: 12,
    weekly: null,
    monthly: 15,
  },
  allowCustomersToSnoozeOverdue: false,
});

export const settingsOrganizationSignal = Signal({
  currentTab: 'company-info',
  companyLogo: null,
  companyName: '',
  companyAddressLine1: '',
  companyAddressLine2: '',
  companyAddressCity: '',
  companyAddressState: '',
  companyAddressZipCode: '',
  phoneNumber: '',
  primaryContact: '',
  timeZone: '',
  legalBusinessName: '',
  legalBusinessAddressLine1: '',
  legalBusinessAddressLine2: '',
  legalBusinessAddressCity: '',
  legalBusinessAddressState: '',
  legalBusinessAddressZipCode: '',
  taxIdType: '',
  taxIdCode: '',
  usersPage: 'users',
  selectedUser: null,
  users: [],
  newRole: 'Select',
  newSendInvoiceReminders: false,
  newUserEmail: '',
  newUserRole: 'Select',
});

export default {
  settingsSignal,
  settingsLogsSignal,
  settingsProfileSignal,
  settingsReminderSignal,
  settingsPaymentSignal,
  settingsOrganizationSignal,
};
