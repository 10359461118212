/* eslint-disable react/jsx-props-no-spreading */
import { Route } from 'react-router-dom';
import ContentWrapper from '#global/ContentWrapper';
import { globalSignal } from '#signals/Global.signals';
import Redirect from '#views/Redirect';

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  if (globalSignal.value.isSignedIn && !globalSignal.value.isLoading) {
    return <Redirect />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <ContentWrapper fluid className="min-vh-100 px-0">
          <Component {...props} />
        </ContentWrapper>
      )}
    />
  );
};

export default PublicRoute;
