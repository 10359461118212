import { Row, Col, Button, Image } from 'react-bootstrap';
import edit from '#images/editNoSquare.svg';

const AutopayConfirm = ({ handleNext }) => (
  <>
    <Row>
      <Col>
        <h2 className="mx-24 mb-56 fw-700 text-dark">Does this information look good to you?</h2>
      </Col>
    </Row>
    <Row>
      <Col>
        <Button
          onClick={() => handleNext({ current: 'method', next: 'ach' })}
          className="p-16 w-100 mb-24 py-24 btn-card"
          variant="gray-250"
          style={{ borderRadius: 10 }}
        >
          <Row className="d-flex text-start text-dark-900">
            <Col>
              <Row>
                <div className="d-flex justify-content-between">
                  <h5 className="fw-700">Payment Method</h5>
                  <div>
                    <Image src={edit} height={20} />
                  </div>
                </div>
              </Row>
              <Row>
                <div className="base">Credit Card (5678)</div>
              </Row>
            </Col>
          </Row>
        </Button>
      </Col>
    </Row>
    <Row>
      <Col>
        <Button
          onClick={() => handleNext({ current: 'method', next: 'ach' })}
          className="p-16 w-100 mb-24 py-24 btn-card"
          variant="gray-250"
          style={{ borderRadius: 10 }}
        >
          <Row className="d-flex text-start text-dark-900">
            <Col>
              <Row>
                <div className="d-flex justify-content-between">
                  <h5 className="fw-700">Payment details</h5>
                  <div>
                    <Image src={edit} height={20} />
                  </div>
                </div>
              </Row>
              <Row>
                <div className="base mb-8">Frequency- <span className="fw-500">Monthly</span></div>
              </Row>
              <Row>
                <div className="base mb-8">Send on- <span className="fw-500">the 1st of each month</span></div>
              </Row>
              <Row>
                <div className="base mb-8">Duration- <span className="fw-500">12 payements</span></div>
              </Row>
              <Row>
                <div className="base mb-8">Amount- <span className="fw-500">$150</span></div>
              </Row>
              <Row>
                <div className="base mb-8">Next payment- <span className="fw-500">Oct 1, 2022</span></div>
              </Row>
            </Col>
          </Row>
        </Button>
      </Col>
    </Row>
    <Row className="d-flex justify-content-start align-content-center my-32 px-32">
      <Col className="d-flex justify-content-start align-items-center">
        <Button
          variant="primary"
          className="flex-grow-1 px-40 px-16 py-8"
          onClick={() => handleNext({ current: 'confirm', next: 'success' })}
        >
          <small className="text-primary-100 me-8">
            CONFIRM & START AUTOPAY
          </small>
        </Button>
      </Col>
    </Row>
  </>
);

export default AutopayConfirm;
