import { gql } from '@apollo/client';
import api, { apolloClient } from '#api';
import { triggerWarningAlert, triggerSuccessAlert } from '#global/Alert/Alert';
import { settingsProfileSignal } from '#signals/pages/Settings.signals';
import { userSignal } from '#signals/User.signals';
import { authTokenSignal } from '#signals/Authentication.signals';
import { validateEmail } from '#utils/validateInput';
import { auth } from '#utils/firebase';

// ---- PROFILE SETTINGS ---- //
// - basic info tab - //
const SETTINGS_PROFILE_UPDATE_MUTATION = gql`
  mutation UserUpdateData($data: UserUpdateDataSchema!) {
    userUpdateData(data: $data) {
      first_name
      last_name
      language
      timezone
    }
  }
`;

const SETTINGS_PROFILE_PICTURE_REMOVE_MUTATION = gql`
  mutation {
    userProfilePictureRemove {
        profile_picture_file_id
    }
  }
`;

export const updateProfileData = async () => {
  settingsProfileSignal.loadingStart();
  userSignal.loadingStart();
  try {
    const {
      firstName,
      lastName,
    } = settingsProfileSignal.value;

    const { timezone, language } = userSignal.value.user;

    await apolloClient.mutate({
      mutation: SETTINGS_PROFILE_UPDATE_MUTATION,
      variables: {
        data: {
          first_name: firstName,
          last_name: lastName,
          timezone,
          language,
        },
      },
    });

    triggerSuccessAlert('Your Profile info has been Updated!');
    userSignal.update({
      ...userSignal.value,
      user: {
        ...userSignal.value.user,
        first_name: firstName,
        last_name: lastName,
      },
    });
  } catch (e) {
    console.error('Error updating profile:', e);
    triggerWarningAlert(e.message);
  } finally {
    settingsProfileSignal.loadingEnd();
    userSignal.loadingEnd();
  }
};

export const cancelProfileUpdate = async () => {
  const { user } = userSignal.value;
  if (user) {
    settingsProfileSignal.update({
      firstName: user.first_name,
      lastName: user.last_name,
    });
  }
};

export const uploadProfilePicture = async (event) => {
  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB

  const file = event.target.files[0];
  if (!file) {
    settingsProfileSignal.update({
      profilePhoto: null,
    });
    return;
  }

  if (file.size > MAX_FILE_SIZE) {
    throw new Error('File size too large. Maximum size allowed is 10 MB.');
  }

  if (!file.type.startsWith('image/')) {
    throw new Error('Only image files are allowed.');
  }

  if (file) {
    const formData = new FormData();
    formData.append('picture', file);
    const res = await api.apiFormDataFetch({
      path: '/user/profile-picture',
      method: 'POST',
      formData,
      token: authTokenSignal.value,
    });
    await triggerSuccessAlert('Your Profile Photo has been Updated!');
    if (res.profile_picture_file.public_url) {
      settingsProfileSignal.update({
        profilePhoto: res.profile_picture_file.public_url,
      });
    }
  } else {
    settingsProfileSignal.update({
      profilePhoto: null,
    });
  }
};

export const deleteProfilePicture = async () => {
  try {
    const removeProfilePicture = await apolloClient.mutate({
      mutation: SETTINGS_PROFILE_PICTURE_REMOVE_MUTATION,
      variables: null,
    });
    if (removeProfilePicture) {
      triggerSuccessAlert('Your Profile Photo has been Deleted!');
      userSignal.update({
        ...userSignal.value,
        user: {
          ...userSignal.value.user,
          profile_picture_file: null,
        },
      });
      settingsProfileSignal.update({
        profilePhoto: null,
      });
    }
  } catch (e) {
    console.error('Error updating Email:', e);
    triggerWarningAlert(e.message);
  }
};

export const handleBrowse = async () => {
  const fileInput = document.getElementById('file-input');
  fileInput.click();
};

// - contact info tab - //
const SETTINGS_UPDATE_EMAIL_START_MUTATION = gql`
  mutation UserEmailVerifyOrChangeStart($data: UserEmailVerifyOrChangeStartSchema!) {
    userEmailVerifyOrChangeStart(data: $data)
  }
`;

const SETTINGS_UPDATE_EMAIL_FINISH_MUTATION = gql`
  mutation UserEmailVerifyOrChangeFinish($data: UserEmailVerifyOrChangeFinishSchema!) {
    userEmailVerifyOrChangeFinish(data: $data) {
      email
    }
  }
`;

const SETTINGS_UPDATE_PHONE_START_MUTATION = gql`
  mutation UserPhoneNumberVerifyOrChangeStart($data: UserPhoneNumberVerifyOrChangeStartSchema!) {
    userPhoneNumberVerifyOrChangeStart(data: $data)
  }
`;

const SETTINGS_UPDATE_PHONE_FINISH_MUTATION = gql`
  mutation UserPhoneNumberVerifyOrChangeFinish($data: UserPhoneNumberVerifyOrChangeFinishSchema!) {
    userPhoneNumberVerifyOrChangeFinish(data: $data) {
      phone_number
    }
  }
`;

export const updateEmailStart = async () => {
  try {
    const {
      email,
    } = settingsProfileSignal.value;
    validateEmail(email);
    const updateEmailStartRes = await apolloClient.mutate({
      mutation: SETTINGS_UPDATE_EMAIL_START_MUTATION,
      variables: {
        data: {
          email,
        },
      },
    });
    if (updateEmailStartRes) {
      triggerSuccessAlert(`Verification code has been sent to ${email}`);
      settingsProfileSignal.update({
        ...settingsProfileSignal.value,
        contactPage: 'validation',
      });
    }
  } catch (e) {
    console.error('Error updating Email:', e);
    triggerWarningAlert(e.message);
  }
};

export const updateEmailFinish = async () => {
  try {
    const { contactVerificationCode, email } = settingsProfileSignal.value;
    const resetComplete = await apolloClient.mutate({
      mutation: SETTINGS_UPDATE_EMAIL_FINISH_MUTATION,
      variables: {
        data: {
          email,
          verification_code: contactVerificationCode.join(''),
        },
      },
    });
    if (resetComplete) {
      userSignal.update({
        ...userSignal.value,
        user: {
          ...userSignal.value.user,
          email,
        },
      });
      settingsProfileSignal.reset();
      triggerSuccessAlert('Your email has been updated!');
      settingsProfileSignal.update({
        ...settingsProfileSignal.value,
        currentTab: 'contact',
      });
    }
    return resetComplete;
  } catch (e) {
    console.error('Error resetting password:', e);
    triggerWarningAlert(e.message);
  }
};

export const updatePhoneStart = async () => {
  try {
    const {
      phoneNumber,
    } = settingsProfileSignal.value;
    const formattedPhone = phoneNumber.includes('+1') ? phoneNumber : `+1${phoneNumber.replace(/[^0-9]+/g, '')}`;
    const updateEmailStartRes = await apolloClient.mutate({
      mutation: SETTINGS_UPDATE_PHONE_START_MUTATION,
      variables: {
        data: {
          phone_number: formattedPhone,
        },
      },
    });
    if (updateEmailStartRes) {
      triggerSuccessAlert(`Verification code has been sent to ${formattedPhone}`);
      settingsProfileSignal.update({
        ...settingsProfileSignal.value,
        contactPage: 'validation',
      });
    }
  } catch (e) {
    console.error('Error updating Email:', e);
    triggerWarningAlert(e.message);
  }
};

export const updatePhoneFinish = async () => {
  try {
    const { contactVerificationCode, phoneNumber } = settingsProfileSignal.value;
    const formattedPhone = phoneNumber.includes('+1') ? phoneNumber : `+1${phoneNumber.replace(/[^0-9]+/g, '')}`;
    const resetComplete = await apolloClient.mutate({
      mutation: SETTINGS_UPDATE_PHONE_FINISH_MUTATION,
      variables: {
        data: {
          phone_number: formattedPhone,
          verification_code: contactVerificationCode.join(''),
        },
      },
    });
    if (resetComplete) {
      userSignal.update({
        ...userSignal.value,
        user: {
          ...userSignal.value.user,
          phone_number: phoneNumber,
        },
      });
      settingsProfileSignal.reset();
      triggerSuccessAlert('Your Phone Number has been updated!');
      settingsProfileSignal.update({
        ...settingsProfileSignal.value,
        currentTab: 'contact',
      });
    }
    return resetComplete;
  } catch (e) {
    console.error('Error resetting password:', e);
    triggerWarningAlert(e.message);
  }
};

export const handleVerifyContactFormChange = (event, index, verificationCode) => {
  if (index <= 5) {
    const oldFormData = [...verificationCode];
    if (event.target.value.length > 1) {
      const tempArray = event.target.value.split('');
      tempArray.forEach((value, key) => {
        if (key < 6) {
          // eslint-disable-next-line prefer-destructuring
          oldFormData[key] = value;
          event.target.nextElementSibling?.focus();
        }
      });
    } else {
      oldFormData[index] = event.target.value;
      if (index !== 0 && event.target.value === '') {
        event.target.previousElementSibling.focus();
      }
      if (index < 5 && event.target.value !== '') {
        event.target.nextElementSibling.focus();
      }
    }
    settingsProfileSignal.update({
      ...settingsProfileSignal.value,
      contactVerificationCode: oldFormData,
    });
  }
};

export const cancelContactUpdate = () => {
  const { user } = userSignal.value;
  if (user) {
    settingsProfileSignal.update({
      email: user.email,
      phoneNumber: user.phone_number,
      contactPage: 'contact',
      contactVerificationCode: ['', '', '', '', '', ''],
    });
  }
};

// - password tab - //
const SETTINGS_PASSWORD_START_MUTATION = gql`
  mutation UserPasswordChangeStart($data: UserPasswordChangeStartSchema!) {
    userPasswordChangeStart(data: $data)
  }
`;

const SETTINGS_PASSWORD_FINISH_MUTATION = gql`
  mutation Mutation($data: UserPasswordChangeFinishSchema!) {
    userPasswordChangeFinish(data: $data)
  }
`;

export const resetPasswordStart = async () => {
  try {
    const { currentPassword } = settingsProfileSignal.value;
    await auth.signInWithEmailAndPassword(userSignal.value.user.email, currentPassword);
    const resetStarted = await apolloClient.mutate({
      mutation: SETTINGS_PASSWORD_START_MUTATION,
      variables: { data: { current_password: currentPassword } },
    });
    if (resetStarted) {
      triggerSuccessAlert(`Verification code has been sent to ${userSignal.value.user.email}`);
      settingsProfileSignal.update({
        ...settingsProfileSignal.value,
        passwordPage: 'validation',
      });
    }
    return resetStarted;
  } catch (e) {
    console.error('Error resetting password:', e);
    triggerWarningAlert(e.message);
  }
};

export const handleVerifyFormChange = (event, index, verificationCode) => {
  if (index <= 5) {
    const oldFormData = [...verificationCode];
    if (event.target.value.length > 1) {
      const tempArray = event.target.value.split('');
      tempArray.forEach((value, key) => {
        if (key < 6) {
          // eslint-disable-next-line prefer-destructuring
          oldFormData[key] = value;
          event.target.nextElementSibling?.focus();
        }
      });
    } else {
      oldFormData[index] = event.target.value;
      if (index !== 0 && event.target.value === '') {
        event.target.previousElementSibling.focus();
      }
      if (index < 5 && event.target.value !== '') {
        event.target.nextElementSibling.focus();
      }
    }
    settingsProfileSignal.update({
      ...settingsProfileSignal.value,
      verificationCode: oldFormData,
    });
  }
};

export const resetPasswordFinish = async () => {
  try {
    const { verificationCode, newPassword } = settingsProfileSignal.value;
    const resetComplete = await apolloClient.mutate({
      mutation: SETTINGS_PASSWORD_FINISH_MUTATION,
      variables: { data: { new_password: newPassword, verification_code: verificationCode.join('') } },
    });
    if (resetComplete) {
      settingsProfileSignal.reset();
      triggerSuccessAlert('Your password has been updated!');
      settingsProfileSignal.update({
        ...settingsProfileSignal.value,
        currentTab: 'password',
      });
    }
    return resetComplete;
  } catch (e) {
    console.error('Error resetting password:', e);
    triggerWarningAlert(e.message);
  }
};

export default {
  updateProfileData,
  cancelProfileUpdate,
  uploadProfilePicture,
  deleteProfilePicture,
  handleBrowse,
  updateEmailStart,
  updateEmailFinish,
  updatePhoneStart,
  updatePhoneFinish,
  handleVerifyContactFormChange,
  cancelContactUpdate,
  resetPasswordStart,
  handleVerifyFormChange,
  resetPasswordFinish,
};
