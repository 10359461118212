import { gql } from '@apollo/client';
import { globalSignal } from '#signals/Global.signals';
import { acceptInviteSignal, onboardingSignal } from '#signals/pages/Onboarding.signals';
import { apolloClient } from '#api';
import { triggerErrorAlert, triggerWarningAlert, triggerSuccessAlert } from '#global/Alert/Alert';
import { validateEmail } from '#utils/validateInput';
import { handleFirebaseLogin, getUserData } from '#views/Auth/Auth.helpers';
import { auth } from '#utils/firebase';
import containsLetter from '#utils/containsLetter';
import containsNumber from '#utils/containsNumber';
import history from '../../../history';

// Initial invite Page
export const fetchInviteData = async (value) => {
  const USER_ORGANIZATION_USER_INVITE_QUERY = gql`
    query UnauthorizedUserOrganizationUserInvite($code: String!, $email: String!, $organizationId: String!, $organizationInviteId: String!) {
      unauthorizedUserOrganizationUserInvite(code: $code, email: $email, organization_id: $organizationId, organization_invite_id: $organizationInviteId) {
        invited_by
        organization_name
        organization_id
      }
    }
  `;
  try {
    const result = await apolloClient.query({
      query: USER_ORGANIZATION_USER_INVITE_QUERY,
      variables: {
        code: value.code,
        email: value.email,
        organizationId: value.organization_id,
        organizationInviteId: value.organization_invite_id,
      },
    });
    const res = result.data.unauthorizedUserOrganizationUserInvite;
    acceptInviteSignal.update({
      invited_by: res?.invited_by,
      organization_id: res?.organization_id,
      organization_name: res?.organization_name,
      code: value.code,
      email: value.email,
      organization_invite_id: value.organization_invite_id,
    });
  } catch (err) {
    return triggerErrorAlert(err.message);
  }
};

// Verify functionality
export const softAcceptOrgInvite = async () => {
  const EMAIL_SIGNUP_MUTATION = gql`
    mutation UserSignUpWithEmailStart($data: UserSignUpWithEmailStartSchema!){
      userSignUpWithEmailStart(data: $data)
    }
  `;
  try {
    const { email } = acceptInviteSignal.value.formData;
    validateEmail(email);
    const res = await apolloClient.mutate({ mutation: EMAIL_SIGNUP_MUTATION, variables: { data: { email } } });
    if (res) {
      triggerSuccessAlert(`Verification code has been sent to ${email}`);
      acceptInviteSignal.update({ step: 'verify' });
    }
  } catch (err) {
    return triggerWarningAlert(err.message);
  }
};
export const handleAcceptInviteVerifyFormChange = (event, index) => {
  if (index <= 5) {
    const oldFormData = { ...acceptInviteSignal.value };
    if (event.target.value.length > 1) {
      const tempArray = event.target.value.split('');
      tempArray.forEach((value, key) => {
        if (key < 6) {
          // eslint-disable-next-line prefer-destructuring
          oldFormData.verificationCode[key] = value.split('')[0];
          event.target.nextElementSibling?.focus();
        }
      });
    } else {
      oldFormData.verificationCode[index] = event.target.value;
      if (index !== 0 && event.target.value === '') {
        event.target.previousElementSibling.focus();
      }
      if (index < 5 && event.target.value !== '') {
        event.target.nextElementSibling.focus();
      }
    }
    acceptInviteSignal.update(oldFormData);
  }
};
// verify if code is correct
export const performVerificationCodeSignupSubmissionInvitedUser = async () => {
  const SIGNUP_CODE_VERIFICATION_MUTATION = gql`
    mutation UserSignUpWithEmailVerify($data: UserSignUpWithEmailVerifySchema!){
      userSignUpWithEmailVerify(data: $data)
    }
  `;
  try {
    const { email, verificationCode } = acceptInviteSignal.value.formData;

    if (verificationCode.length !== 6) {
      throw new Error('The verification code is not complete.');
    }
    await apolloClient.mutate({ mutation: SIGNUP_CODE_VERIFICATION_MUTATION, variables: { data: { email, verification_code: verificationCode.join('') } } });
    return acceptInviteSignal.update({ step: 'createPassword' });
  } catch (err) {
    return triggerWarningAlert(err.message);
  }
};

// Accept Invite
export const acceptOrgInvite = async () => {
  globalSignal.update({
    isLoading: true,
    isLoadingMessage: 'Accepting Invite...',
  });
  const ACCEPT_ORG_INVITE_MUTATE = gql`
    mutation($code: String!, $organization_id: String!, $organization_invite_id: String!) {
      userOrganizationUserInviteAccept(code: $code, organization_id: $organization_id, organization_invite_id: $organization_invite_id) {
        role
      }
    }
  `;
  try {
    await apolloClient.mutate({
      mutation: ACCEPT_ORG_INVITE_MUTATE,
      variables: {
        code: acceptInviteSignal.value.code,
        organization_id: acceptInviteSignal.value.organization_id,
        organization_invite_id: acceptInviteSignal.value.organization_invite_id,
      },
    });
    globalSignal.update({
      isLoading: false,
      isLoadingMessage: 'Loading...',
    });
    onboardingSignal.update({ step: 'personalInfo', invitedUser: true });
  } catch (err) {
    globalSignal.update({
      isLoading: false,
      isLoadingMessage: 'Loading...',
    });
    return triggerErrorAlert(err.message);
  }
};
// Handle Login & Accept Invite Call
export const performCompleteInviteSignup = async () => {
  const MIN_PASSWORD_LENGTH = 12;
  const MAX_PASSWORD_LENGTH = 32;
  const EMAIL_VERIFICATION_MUTATION = gql`
    mutation UserSignUpWithEmailFinishSchema($data: UserSignUpWithEmailFinishSchema!){
      userSignUpWithEmailFinish(data: $data)
    }
  `;
  try {
    const { email, password, confirmPassword, verificationCode } = acceptInviteSignal.value.formData;

    if (!containsLetter(password)) {
      throw new Error('Missing at least 1 alphabet character');
    }

    if (!containsNumber(password)) {
      throw new Error('Missing at least 1 numeric character');
    }

    if (password < MIN_PASSWORD_LENGTH) {
      throw new Error(`Password must be at least ${MIN_PASSWORD_LENGTH} characters long`);
    }

    if (password > MAX_PASSWORD_LENGTH) {
      throw new Error(`Password must be at least ${MIN_PASSWORD_LENGTH} characters long`);
    }

    if (password !== confirmPassword) {
      throw new Error('Confirmation value does not match password.');
    }

    const result = await apolloClient.mutate({
      mutation: EMAIL_VERIFICATION_MUTATION,
      variables: { data: { email, password, verification_code: verificationCode.join('') } },
    });
    const authResult = await auth.signInWithCustomToken(result.data.userSignUpWithEmailFinish);
    await handleFirebaseLogin(authResult.user);
    await acceptOrgInvite();
    await getUserData();
  } catch (err) {
    return triggerWarningAlert(err.message);
  } finally {
    history.replace('/onboarding');
  }
};
