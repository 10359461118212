import { useCallback, useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { acceptInviteSignal } from '#signals/pages/Onboarding.signals';
import { AuthLayout } from '#global/Layout';
import WelcomeToAccruHeader from '#views/Auth/components/WelcomeToAccruHeader';
import PasswordForm from '#views/SignUp/components/PasswordForm';
import VerificationForm from '#views/SignUp/components/VerificationForm';
import { fetchInviteData } from './AcceptInvite.helpers';
import ViewInvitation from './components/ViewInvitation';

const AcceptInvite = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const fetchInviteDataCallback = useCallback(async () => {
    try {
      const code = urlParams.get('code');
      const email = urlParams.get('email');
      const organizationId = urlParams.get('organization_id');
      const organizationInviteId = urlParams.get('organization_invite_id');
      acceptInviteSignal.update({
        ...acceptInviteSignal.value,
        formData: {
          ...acceptInviteSignal.value.formData,
          email,
        },
      });
      await fetchInviteData({
        code,
        email,
        organization_id: organizationId,
        organization_invite_id: organizationInviteId,
      });
    } catch (error) {
      console.error(error);
    }
  }, []);
  useEffect(() => {
    fetchInviteDataCallback();
  }, [fetchInviteDataCallback]);

  return (
    <AuthLayout header={() => <WelcomeToAccruHeader />}>
      <Tabs activeKey={acceptInviteSignal.value.step} className="border-0 d-none">
        <Tab eventKey="inviteInfo" title="Invite Info">
          <ViewInvitation />
        </Tab>
        <Tab eventKey="verify" title="Verify">
          <VerificationForm invitedUser />
        </Tab>
        <Tab eventKey="createPassword" title="Create Password">
          <PasswordForm invitedUser />
        </Tab>
      </Tabs>
    </AuthLayout>
  );
};

export default AcceptInvite;
