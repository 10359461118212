/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Dropdown } from 'react-bootstrap';
import { useCallback } from 'react';
import CustomForm from './CustomForm';
import CustomMenu from './CustomMenu';
import { searchableDropdownSignal } from '#signals/Global.signals';
import UniversalInput from '../Inputs/UniversalInput';

const SearchableDropdown = ({
  selected,
  options,
  onSelect,
}) => {
  const { searchString } = searchableDropdownSignal.value;
  const getValue = useCallback((e) => {
    const result = options.find(o => o.value.toLowerCase() === e.toLowerCase() || o.name.toLowerCase() === e.toLowerCase());
    searchableDropdownSignal.update({
      searchString: e,
    });
    onSelect(result || '');
  }, [options, onSelect]);

  return (
    <>
      <Dropdown className="w-100" onSelect={(e) => getValue(e)}>
        <Dropdown.Toggle as={CustomForm} id="custom-dropdown-basic">
          <UniversalInput
            value={selected?.name || searchString}
            customOnChange={(e) => {
              e.preventDefault();
              getValue(e.target.value);
            }}
          />
        </Dropdown.Toggle>
        <Dropdown.Menu as={CustomMenu} style={{ minWidth: '100%' }}>
          {options?.filter((o) => o.name?.toLowerCase().includes(searchString?.toLowerCase())).map(option => (
            <Dropdown.Item key={option.value ?? option} eventKey={option.value ?? option} className="base fw-500 text-primary dropdown-alternate-shading">{option.name ?? option}</Dropdown.Item>
          ))}
          {!options.length && (
            <Dropdown.Item disabled className="base text-dark-500 dropdown-alternate-shading"><i>No options found</i></Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default SearchableDropdown;
