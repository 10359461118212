import { Image, Container, Row, Col } from 'react-bootstrap';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import arrowLeft from '#images/arrowLeft.svg';
import closeButton from '#images/closeButton.svg';
import triangleBg from '#images/yellowtrianglebackground.svg';
import squareBg from '#images/bluesquarebackground.svg';
import InvoiceSyncSelection from './InvoiceSyncSelection';
import InvoiceMethodScreen from './InvoiceMethodScreen';
import InvoiceManualAddSelect from './InvoiceManualAddSelect';
import InvoiceManualForm from './InvoiceManualForm';
import AddInvoiceUpload from './InvoiceUpload';
import InvoicePreview from '#views/Invoices/InvoicePreview';
import InvoiceSuccessfulSend from './InvoiceSuccessfulSend';
import { intuitSignal } from '#signals/Authentication.signals';
import { invoiceAddSignal } from '#signals/pages/Invoices.signals';

const AddInvoice = () => {
  const { currentScreen, backButtonHistory } = invoiceAddSignal.value;
  const history = useHistory();

  useEffect(() => {
    invoiceAddSignal.update({
      currentScreen: intuitSignal?.value?.redirectUrl ? 'syncSelect' : 'method',
    });
  }, []);

  const handleBackButton = useCallback(() => {
    const navigationHistory = [...backButtonHistory];
    if (navigationHistory.length) {
      const current = navigationHistory.pop();
      invoiceAddSignal.update({
        currentScreen: current,
        backButtonHistory: navigationHistory,
      });
    } else {
      history.push('/invoices');
    }
  }, [history, backButtonHistory]);

  const handleNext = useCallback(({ current, next }) => {
    const navigationHistory = [...backButtonHistory];
    navigationHistory.push(current);
    invoiceAddSignal.update({
      currentScreen: next,
      backButtonHistory: navigationHistory,
    });
  }, [backButtonHistory]);

  return (
    <Container fluid className="min-vh-100 px-0 w-100 text-center position-relative" style={{ backgroundColor: 'rgb(231, 192, 170, 0.4)' }}>
      <Image style={{ right: 0, top: 0, zIndex: 0 }} className="position-absolute" src={triangleBg} />
      <Image style={{ bottom: 0, left: 0, zIndex: 0 }} className="position-absolute" src={squareBg} />
      <Container className="pt-80 pt-lg-152">
        <Row>
          <Col sm={{ span: 10, offset: 1 }} offset={1} className="d-flex justify-content-between">
            <Image src={arrowLeft} className="cursor-pointer" onClick={() => handleBackButton()} />
            <Image src={closeButton} style={{ zIndex: 100 }} className="cursor-pointer" onClick={() => history.push('/invoices')} />
          </Col>
        </Row>
      </Container>
      <Container fluid style={{ zIndex: 100 }} className="position-relative">
        {{
          method: <InvoiceMethodScreen handleNext={handleNext} />,
          syncSelect: <InvoiceSyncSelection handleNext={handleNext} />,
          manualSelect: <InvoiceManualAddSelect handleNext={handleNext} />,
          form: <InvoiceManualForm handleNext={handleNext} />,
          upload: <AddInvoiceUpload handleNext={handleNext} />,
          preview: <InvoicePreview handleNext={handleNext} />,
          successfulSend: <InvoiceSuccessfulSend handleNext={handleNext} />,
        }[currentScreen]}
      </Container>
    </Container>
  );
};

export default AddInvoice;
