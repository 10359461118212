import { Redirect as RedirectDOM } from 'react-router-dom';
import { globalSignal } from '#signals/Global.signals';

const Redirect = () => {
  const isLoggedIn = globalSignal.value.isSignedIn;
  const RedirectUrl = isLoggedIn ? '/customers' : '/login';
  return (<RedirectDOM to={RedirectUrl} />);
};

export default Redirect;
