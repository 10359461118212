import { Button, Col, Container, Image, Row, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { signal } from '@preact/signals-react';
import SecureFooter from '../SecureFooter';
import paymentCredDeb from '#images/paymentCredDeb.svg';
import paymentCheck from '#images/paymentCheck.svg';
import paymentBank from '#images/paymentBank.svg';
import accruLogo from '#images/accruSmile.svg';
import checkmark from '#images/check.svg';
import pen from '#images/pen.svg';
import autopayIcon from '#images/autopayIcon.svg';
import Money from '#global/Money';
import { setActiveForm } from '#views/Statement/Statement.helpers';
import { choosePaymentSignal } from '#signals/pages/Payment.signals';

const billsArray = [
  {
    name: 'Bill',
    id: 1,
    amount: 5000,
  },
  {
    name: 'Bill',
    id: 2,
    amount: 20000,
  }];

const billsSignal = signal({
  open: false,
  delete: false,
  list: billsArray,
});

const ChoosePayment = ({ className }) => {
  const { isEditingTotal } = choosePaymentSignal.value;

  const handleBillsClicked = () => {
    billsSignal.value = {
      ...billsSignal.value,
      ...(billsSignal.value.open ? { delete: false } : { }),
      open: !billsSignal.value.open,
    };
  };

  return (
    <Container className={className}>
      <Row className="p-24 pb-8">
        <h5>Pay <strong>FYC Labs</strong></h5>
      </Row>
      <Row className="pb-8 border-2 border-gray-800 mb-8 border-bottom d-flex">
        <Col className="p-0 d-flex justify-content-center">
          {isEditingTotal ? (
            <Form.Group className="form-group text-start border-0">
              {/* TODO */}
              <Form.Control type="text" className="form-control" id="total" />
            </Form.Group>
          ) : (
            <h1 className="fw-700 text-primary mb-0">
              <Money amount={25000} />
            </h1>
          )}
          <div className="d-flex align-items-center ms-16" role="button" tabIndex={0} onClick={() => choosePaymentSignal.update({ isEditingTotal: !isEditingTotal })}>
            {isEditingTotal ? (
              <Image src={checkmark} height={18} />
            ) : (
              <Image src={pen} />
            )}
          </div>
        </Col>
      </Row>
      <Row className="d-flex jusity-content-center">
        <Col className="d-flex justify-content-center align-items-center">
          <p className="fw-500 mb-0 lead">Payment Applies to 2 bills</p>
          <Button
            variant="transparent"
            className="border-0 text-dark p-0 ms-16"
            onClick={handleBillsClicked}
          >
            <FontAwesomeIcon icon={billsSignal.value.open ? faChevronUp : faChevronDown} />
          </Button>
        </Col>
      </Row>
      <div className="px-xl-80 px-lg-80 px-md-80 px-24">
        {billsSignal.value.open && (
          billsSignal.value.list.map((obj, index) => (
            <Row key={obj.id} className={`mx-0 py-16 border ${index % 2 === 0 && 'bg-gray-200'}`}>
              <Col sm={3} xs={3} className="d-flex align-items-center justify-content-end">
                <h5 className="fw-700 m-0">
                  {obj.name}
                  {' '}
                  #{obj.id}
                </h5>
              </Col>
              <Col sm={6} xs={6} className="d-flex align-items-center ps-0">
                <h5 className="m-0">
                  <Money className="fw-700 text-primary" amount={obj.amount} />
                </h5>
              </Col>
            </Row>
          ))
        )}
        <div className="mt-40">
          <h5 className="fw-700">Choose how you want to pay</h5>
        </div>
        <Button
          onClick={() => {}}
          className="p-16 w-100 mt-24 mb-16 py-24 btn-card"
          variant="gray-250"
          style={{ borderRadius: 10 }}
        >
          <Row className="d-flex align-items-center">
            <Col xs={3} className="d-flex justify-content-center align-items-center">
              <div className="d-flex justify-content-center align-items-center text-center">
                <Image src={accruLogo} width={40} />
              </div>
            </Col>
            <Col xs={9} className="text-start text-primary ps-0">
              <h5 className="fw-700 mb-0">Set Up Accru Pay!</h5>
              <small>You can smaller payments over time!</small>
            </Col>
          </Row>
        </Button>
        <Button
          onClick={() => setActiveForm('CardInfo')}
          className="p-16 w-100 mb-16 py-24 btn-card"
          variant="gray-250"
          style={{ borderRadius: 10 }}
        >
          <Row>
            <Col xs={3} className="d-flex justify-content-center align-items-start">
              <Image src={paymentCredDeb} width={50} style={{ marginLeft: '-12px' }} />
            </Col>
            <Col xs={9} className="text-start ps-0">
              <h5 className="fw-700 text-dark-900">Credit/Debit Card</h5>
              <small className="text-dark-900">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</small>
            </Col>
          </Row>
        </Button>
        <Button
          onClick={() => setActiveForm('BankInfo')}
          className="p-16 w-100 mb-16 py-24 btn-card"
          variant="gray-250"
          style={{ borderRadius: 10 }}
        >
          <Row>
            <Col xs={3} className="d-flex justify-content-center align-items-center">
              <Image src={paymentBank} width={45} />
            </Col>
            <Col xs={9} className="text-start ps-0">
              <h5 className="fw-700 text-dark-900">Bank</h5>
              <small className="text-dark-900">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</small>
            </Col>
          </Row>
        </Button>
        <Button
          onClick={() => setActiveForm('CheckSentInfo')}
          className="p-16 w-100 mb-16 py-24 btn-card"
          variant="gray-250"
          style={{ borderRadius: 10 }}
        >
          <Row>
            <Col xs={3} className="d-flex justify-content-center align-items-center">
              <Image src={paymentCheck} width={50} />
            </Col>
            <Col sm={9} xs={9} className="text-start ps-0">
              <h5 className="fw-700 text-dark-900">Check is on the way</h5>
              <small className="text-dark-900">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</small>
            </Col>
          </Row>
        </Button>
        <Button
          onClick={() => setActiveForm('Autopay')}
          className="p-16 w-100 mb-16 py-24 btn-card bg-gray-250 border-0"
          variant="transparent"
          style={{ borderRadius: 10, backgroundColor: '#FAFAFA' }}
        >
          <Row>
            <Col xs={2} sm={12} className="d-flex justify-content-center align-items-center">
              <div className="me-16">
                <Image src={autopayIcon} height={18} />
              </div>
              <h5 className="fw-700 text-primary mb-0">Set up automatic payments</h5>
            </Col>
          </Row>
        </Button>
        <SecureFooter />
      </div>
    </Container>
  );
};

export default ChoosePayment;
