import moment from 'moment';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { createInvoice } from './Invoices.helpers';
import BillHero from '#views/Bills/BillHero';
import BillSummary from '#views/Bills/BillSummary';
import { invoiceFormSignal, invoiceUploadSignal, invoiceSignal } from '#signals/pages/Invoices.signals';
import { userSignal } from '#signals/User.signals';
import { triggerWarningAlert } from '#global/Alert/Alert';
import { validateLength } from '#utils/validateInput';

const InvoicePreview = ({ handleNext }) => {
  const organizationId = userSignal?.value?.user?.organizations?.[0]?.organization_id;
  const invoicesToAdd = invoiceUploadSignal.value.length
    ? invoiceUploadSignal.value
    : [{ ...invoiceFormSignal.value, amount: invoiceFormSignal.value.amount * 100 }];

  const handleAddInvoice = async () => {
    try {
      const inv = invoicesToAdd[0]; // refactored while we feature-gate csv
      const discountAmount = 0; // hardcoded until discount feature
      const totalAmount = Number(inv.amount) - discountAmount;

      validateLength(totalAmount, 'Amount', 0, 100);
      validateLength(inv.description, 'Description', 0, 100);
      validateLength(inv.due_date, 'Due date', 0, 100);
      validateLength(inv.organization_customer_id, 'Customer', 0, 100);
      const newInvoice = await createInvoice({
        organizationId,
        data: {
          currency_code: 'USD',
          description: inv.description,
          discount_amount: discountAmount,
          amount: Number(inv.amount),
          total_amount: totalAmount,
          invoice_date: moment(),
          due_date: inv.due_date,
          organization_project_id: null,
          organization_customer_id: inv.organization_customer_id,
        },
      });
      if (newInvoice?.data?.userOrganizationInvoiceCreate) {
        invoiceSignal.update({ allInvoices: [...invoiceSignal.value.allInvoices, newInvoice?.data?.userOrganizationInvoiceCreate] });
      }

      handleNext({ current: 'preview', next: 'successfulSend' });
    } catch (e) {
      triggerWarningAlert(e.message);
    }
  };

  return (
    <>
      <Row>
        <Col className="text-center">
          <h1 className="text-primary fw-700 pt-16 pb-24">Invoice Preview</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 className="pb-56 fw-400">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h2>
        </Col>
      </Row>
      <Container className="bg-white p-48" style={{ maxWidth: 1300, zIndex: 100 }}>
        <Row className="d-flex justify-content-center pb-64">
          <Button
            type="button"
            variant="primary"
            className="w-25 py-16 mb-24 mx-8"
            onClick={() => handleAddInvoice()}
          >
            SAVE & SEND
          </Button>
          <Button
            type="button"
            variant="outline-primary border border-2 border-primary"
            className="w-25 py-16 mb-24 mx-8"
            onClick={() => handleNext({ current: 'preview', next: 'form' })}
          >
            EDIT INVOICE
          </Button>
        </Row>
        <Container style={{ zIndex: 100 }} className="p-48 custom-shadow position-relative">
          <Row style={{ zIndex: 100 }} className=" text-start">
            <BillHero invoicePreview={invoicesToAdd[0]} />
            <BillSummary invoicePreview={invoicesToAdd[0]} />
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default InvoicePreview;
