import Signal from '../Signal';

export const statementSignal = Signal({});

export const activeFormSignal = Signal('ChoosePaymentReturning');

export const skipToDetailsSignal = Signal({
  isVisible: false,
});

export default {
  statementSignal,
  activeFormSignal,
  skipToDetailsSignal,
};
