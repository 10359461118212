/* eslint-disable jsx-a11y/control-has-associated-label */
import { Container, Image, Row, Tab, Tabs } from 'react-bootstrap';
import EmailSignupForm from './components/EmailSignupForm';
import VerificationForm from './components/VerificationForm';
import PasswordForm from './components/PasswordForm';
import loginLogo from '#images/loginLogo.svg';
import { signUpEmbedSignal } from '#signals/Authentication.signals';

const SignUpEmbed = () => {
  const { currentPage } = signUpEmbedSignal.value;

  return (
    <Container fluid className="min-vh-100 px-0 w-100 overflow-hidden">
      <Row className="m-24">
        <Image src={loginLogo} alt="Two People Reading" className="mx-auto my-24" height="100vh" />
        <Tabs activeKey={currentPage} className="border-0 d-none">
          <Tab eventKey="emailForm" title="Email Form">
            <EmailSignupForm
              isEmbed
            />
          </Tab>
          <Tab eventKey="verify" title="Verify">
            <VerificationForm
              invitedUser={false}
              isEmbed
            />
          </Tab>
          <Tab eventKey="createPassword" title="Create Password">
            <PasswordForm
              invitedUser={false}
              isEmbed
            />
          </Tab>
          <Tab eventKey="earlyAccess" title="Early Access">
            <h2 className="text-center text-primary">
              Welcome to Accru! We&apos;ve created your account and have added you to our early access waitlist. We will be in touch as soon as Accru is ready to go
            </h2>
          </Tab>
        </Tabs>
      </Row>

    </Container>
  );
};

export default SignUpEmbed;
