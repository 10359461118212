const statementDefaultTestData = {
  companyName: 'FYC Labs',
  totalBalance: 38900,
  month: 'July',
  overDue: 15,
  startDate: new Date('2022/09/13'),
  endDate: new Date('2022/10/13'),
  firstPastDue: 9000,
  secondPastDue: 4000,
  thirdPastDue: 1500,
  paymentHistoryData: [
    {
      id: 1,
      date: new Date('2022/09/13'),
      dueDate: new Date('2022/10/13'),
      type: 'Bill',
      amt: 5000,
      accountBalance: 30900,
    },
    {
      id: 2,
      date: new Date('2022/09/13'),
      dueDate: new Date('2022/10/13'),
      type: 'Bill',
      amt: 5000,
      accountBalance: 30900,
    },
    {
      id: 3,
      date: new Date('2022/09/13'),
      dueDate: new Date('2022/10/13'),
      type: 'Bill',
      amt: 5000,
      accountBalance: 30900,
    },
    {
      id: 4,
      date: new Date('2022/09/13'),
      dueDate: new Date('2022/10/13'),
      type: 'Payment',
      amt: 5000,
      accountBalance: 30900,
    },
    {
      id: 5,
      date: new Date('2022/09/13'),
      dueDate: new Date('2022/10/13'),
      type: 'Bill',
      amt: 5000,
      accountBalance: 30900,
    },
    {
      id: 6,
      date: new Date('2022/09/13'),
      dueDate: new Date('2022/10/13'),
      type: 'Payment',
      amt: 5000,
      accountBalance: 30900,
    },
    {
      id: 7,
      date: new Date('2022/09/13'),
      dueDate: new Date('2022/10/13'),
      type: 'Bill',
      amt: 5000,
      accountBalance: 30900,
    },
  ],
  totalBalanceDue: 39000,
  client: 'FYC Labs',
  project: 'FYCGEN',
  projectBudget: 50000,
  otherProjects: [
    'FYCGEN',
    'FYCGEN01',
    'FYCGEN02',
    'FYCGEN03',
    'FYCGEN04',
  ],
  payBillsData: [
    {
      selected: false,
      id: 1,
      type: 'Bill',
      memo: true,
      projectsAmount: 2,
      paymentsAmount: 2,
      date: new Date('2022/09/13'),
      dueDate: new Date('2022/10/13'),
      amount: 5000,
      amountDue: 5000,
      status: 'open',
      action: 'test link',
      receipt: null,
      daysOverdue: null,
    },
    {
      selected: false,
      id: 2,
      type: 'Bill',
      memo: true,
      projectsAmount: 2,
      paymentsAmount: 2,
      date: new Date('2022/09/13'),
      dueDate: new Date('2022/10/13'),
      amount: 150,
      amountDue: 3000,
      status: 'paid',
      action: 'test link',
      receipt: 'recipt if paid',
      daysOverdue: null,
    },
    {
      selected: false,
      id: 3,
      type: 'Bill',
      memo: true,
      date: new Date('2022/09/13'),
      dueDate: new Date('2022/10/13'),
      amount: 150,
      amountDue: 3000,
      status: 'overdue',
      action: 'test link',
      receipt: 'recipt if paid',
      daysOverdue: 9,
    },
    {
      selected: false,
      id: 4,
      type: 'Bill',
      memo: true,
      date: new Date('2022/09/13'),
      dueDate: new Date('2022/10/13'),
      amount: 150,
      amountDue: 3000,
      status: 'partial',
      action: 'test link',
      receipt: 'recipt if paid',
      daysOverdue: null,
    },
    {
      selected: false,
      id: 5,
      type: 'Bill',
      memo: null,
      date: new Date('2022/09/13'),
      dueDate: new Date('2022/10/13'),
      amount: 150,
      amountDue: 3000,
      status: 'pending',
      action: 'test link',
      receipt: 'recipt if paid',
      daysOverdue: null,
    },
    {
      selected: false,
      id: 6,
      type: 'Payment',
      memo: true,
      date: new Date('2022/09/13'),
      dueDate: new Date('2022/10/13'),
      amount: 150,
      amountDue: 3000,
      status: 'complete',
      action: 'test link',
      receipt: 'recipt if paid',
      daysOverdue: null,
    },
  ],
};

export default statementDefaultTestData;
