import { Col, Container, Image, Row } from 'react-bootstrap';
import welcomeToAccru from '#images/welcomeToAccru.svg';

const WelcomeToAccruHeader = ({ project }) => (
  <Container className="p-0 w-100 text-center pt-16">
    <Image src={welcomeToAccru} alt="Welcome To Accru" className="mx-auto" />
    <Row className="m-0 p-0 text-center text-dark fw-400 mt-16 d-none d-lg-block">
      <Col lg={{ span: 6, offset: 3 }} sm={{ span: 10, offset: 1 }}>
        {project ? (
          <>
            <strong>{project}</strong>
            {' '}
            has invited you to Accru, where you can keep your clients happy and informed. Once you finish this quick sign up, explore the app and all of it’s cool features!
          </>

        ) : (

          'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laborum, alias quis expedita dolorum delectus nobis itaque quaerat. Sint, distinctio perferendis.'
        )}
      </Col>
    </Row>
  </Container>
);

export default WelcomeToAccruHeader;
