import { gql } from '@apollo/client';
import { apolloClient } from '#api';
import history from '../../../history';
import { resetPasswordSignal } from '#signals/Authentication.signals';
import { triggerSuccessAlert, triggerWarningAlert } from '#global/Alert/Alert';
import containsLetter from '#utils/containsLetter';
import containsNumber from '#utils/containsNumber';

export const handleFormChange = (e) => resetPasswordSignal.update({ [e.target.name]: e.target.value });

const RESET_PASSWORD_FINISH_MUTATION = gql`
  mutation UserPasswordResetFinish($data: UserPasswordResetFinishSchema!){
    userPasswordResetFinish(data: $data)
  }
`;

const MIN_PASSWORD_LENGTH = 12;
const MAX_PASSWORD_LENGTH = 32;

// verificationCode is the token that's on the callback URL
export const handleResetPasswordPartial = async (email, verificationCode, userId) => {
  try {
    const { password, confirmPassword } = resetPasswordSignal.value;
    if (!containsLetter(password)) {
      return triggerWarningAlert('Missing at least 1 alphabet character');
    }

    if (!containsNumber(password)) {
      return triggerWarningAlert('Missing at least 1 numeric character');
    }

    if (password < MIN_PASSWORD_LENGTH) {
      return triggerWarningAlert(`Password must be at least ${MIN_PASSWORD_LENGTH} characters long`);
    }

    if (password > MAX_PASSWORD_LENGTH) {
      return triggerWarningAlert(`Password must be at least ${MIN_PASSWORD_LENGTH} characters long`);
    }

    if (password !== confirmPassword) {
      return triggerWarningAlert('Confirmation value does not match password.');
    }

    resetPasswordSignal.update({ submitting: true });

    const variables = {
      data: {
        email,
        new_password: password,
        verification_code: verificationCode,
        user_id: userId,
      },
    };

    await apolloClient.mutate({ mutation: RESET_PASSWORD_FINISH_MUTATION, variables });
    history.replace('/login');
    resetPasswordSignal.reset();
    return triggerSuccessAlert('Your password has been successfully reset. Please log back in');
  } catch (err) {
    return triggerWarningAlert(err.message);
  } finally {
    resetPasswordSignal.update({ submitting: false });
  }
};
