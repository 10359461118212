/* eslint-disable no-else-return */
import { Card, Row, Image, Col } from 'react-bootstrap';
import moment from 'moment';
import Money from '../Money';
import checkIcon from '#images/checkIcon.svg';
import dueIcon from '#images/dueIcon.svg';
import xIcon from '#images/xIcon.svg';
import threeVerticalDots from '#images/threeVerticalDots.svg';

const VendorClientBills = ({ items, isVendor = false }) => {
  const StatusString = ({ bill }) => {
    const daysDifference = moment().diff(moment(bill.due_date), 'days');
    if (bill.status === 'paid') { // cannot implement until payment module complete
      return (
        <div className="align-items-center d-flex">
          <Image src={checkIcon} height={13} />
          <small className="text-success ps-8">Paid</small>
        </div>
      );
    } else if (daysDifference <= 0) {
      return (
        <div className="align-items-center d-flex">
          <Image src={dueIcon} height={13} />
          <small className="text-warning ps-8">
            {' '}Due {daysDifference === 0 ? 'today' : `in ${Math.abs(daysDifference)} days`}
          </small>
        </div>
      );
    } else {
      return (
        <div className="align-items-center d-flex">
          <Image src={xIcon} height={13} />
          <small className="text-danger ps-8">{' '}Overdue {daysDifference} days</small>
        </div>
      );
    }
  };

  return (
    <Card>
      <Row className="d-none d-md-flex mb-8 ps-56 py-32 bg-gray-250">
        <div style={{ width: '18%' }}><strong>Invoice Date:</strong></div>
        <div style={{ width: '18%' }}><strong>Due Date:</strong></div>
        <div style={{ width: '15%' }}><strong>Number</strong></div>
        <div style={{ width: '25%' }}><strong>Status</strong></div>
        <div style={{ width: '15%' }}><strong>Amount</strong></div>
      </Row>
      {items.map(({ node }, i) => (
        <>
          <Row className={`d-none d-lg-flex base py-32 ps-56 ${i % 2 !== 0 && 'bg-gray-250'}`}>
            <div style={{ width: '18%' }}>{moment(isVendor ? node.bill_date : node.invoice_date).format('l')}</div>
            <div style={{ width: '18%' }}>{moment(node.due_date).format('l')}</div>
            <div style={{ width: '15%' }}>{node.number}</div>
            <div style={{ width: '25%' }}><StatusString bill={node} /></div>
            <div style={{ width: '15%' }}><Money amount={node.total_amount} /></div>
            {isVendor && (
              <div style={{ width: '5%' }}>
                <Image height={20} src={threeVerticalDots} />
              </div>
            )}
          </Row>
          <Row className={`d-lg-none base py-32 px-16 ${i % 2 !== 0 && 'bg-gray-250'}`}>
            <Col>
              <h3 className="mb-0"><Money amount={node.total_amount} /></h3>
              <StatusString bill={node} />
            </Col>
            <Col>
              <div className="d-flex justify-content-between"><strong><small>Invoice: </small></strong>{moment(isVendor ? node.bill_date : node.invoice_date).format('l')}</div>
              <div className="d-flex justify-content-between"><strong><small>Due Date: </small></strong>{moment(node.due_date).format('l')}</div>
            </Col>
          </Row>
        </>
      ))}
      {!items.length && (
        <>
          <Row className="base py-32 ps-md-56 text-center justify-content-center text-dark-500">
            No invoices found
          </Row>
        </>
      )}
      <Row className="py-8 bg-gray-250 rounded-bottom" />
    </Card>
  );
};

export default VendorClientBills;
