import { Row, Col, Form, Button } from 'react-bootstrap';

const AutopayCard = ({ handleNext }) => (
  <>
    <Row>
      <Col>
        <h2 className="mx-24 mb-56 fw-700 text-dark">Card Info</h2>
      </Col>
    </Row>
    <div className="d-flex flex-column">
      <Form className="mb-0 pb-16">
        <Form.Group className="form-group mb-24">
          <Form.Label className="d-flex justify-content-start" htmlFor="cardHolder">
            <p className="lead fw-800 mb-0">Card Holder</p>
          </Form.Label>
          {/* TODO */}
          <Form.Control
            type="text"
            id="cardHolder"
            placeholder="Full Name"
            name="holderName"
            className="mt-8 mb-32"
          />
        </Form.Group>
        <Form.Group className="form-group mb-16">
          <Form.Label className="d-flex justify-content-start" htmlFor="cardNumber"><p className="lead fw-800 mb-0">Card Number</p></Form.Label>
          {/* TODO */}
          <Form.Control
            type="text"
            id="cardNumber"
            placeholder="#### #### #### ####"
            name="cardNumber"
            className="mt-8 mb-32"
          />
        </Form.Group>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="form-group mb-16">
              <Form.Label className="d-flex justify-content-start" htmlFor="expDate">
                <p className="lead fw-800 mb-0">Exp date</p>
              </Form.Label>
              {/* TODO */}
              <Form.Control
                type="text"
                id="expDate"
                placeholder="MM/YY"
                name="expDate"
                className="mt-8"
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="form-group mb-16">
              <Form.Label className="d-flex justify-content-start" htmlFor="cvc">
                <p className="lead fw-800 mb-0">CVC</p>
              </Form.Label>
              {/* TODO */}
              <Form.Control
                type="text"
                id="cvc"
                placeholder="***"
                name="cvc"
                className="mt-8"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-8">
          <Col xs={12} md={6} className="d-flex align-items-center">
            <Form.Check
              className="fw-800 me-8"
              type="checkbox"
            />
            <div className="base text-dark-900">Save this card</div>
          </Col>
        </Row>
      </Form>
      <Row className="d-flex justify-content-start align-content-center mt-56 mb-32 px-32">
        <Col className="d-flex justify-content-start align-items-center">
          <Button
            variant="primary"
            className="flex-grow-1 px-40 px-16 py-8"
            onClick={() => handleNext({ current: 'card', next: 'schedule' })}
          >
            <small className="text-primary-100 me-8">
              NEXT
            </small>
          </Button>
        </Col>
      </Row>
    </div>
  </>
);

export default AutopayCard;
