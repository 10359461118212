import { gql } from '@apollo/client';
import { auth } from '#utils/firebase';
import { apolloClient } from '#api';
import { handleFirebaseLogin } from '#views/Auth/Auth.helpers';
import { triggerWarningAlert, triggerSuccessAlert } from '#global/Alert/Alert';
import { validateEmail, containsLetter, containsNumber, validateLength, validateMatchingFields } from '#utils/validateInput';
import history from '../../../history';
import { acceptInviteSignal } from '#signals/pages/Onboarding.signals';
import { signUpSignal } from '#signals/Authentication.signals';
import { loaderSignal } from '#signals/Global.signals';

const EMAIL_SIGNUP_MUTATION = gql`
  mutation UserSignUpWithEmailStart($data: UserSignUpWithEmailStartSchema!){
    userSignUpWithEmailStart(data: $data)
  }
`;

// Get Started SIGN UP
export const handleEmailSubmitPartial = async (email) => {
  try {
    loaderSignal.update({
      isLoading: true,
      isLoadingMessage: 'Loading...',
    });
    validateEmail(email);
    const res = await apolloClient.mutate({ mutation: EMAIL_SIGNUP_MUTATION, variables: { data: { email } } });
    if (res) {
      triggerSuccessAlert(`Verification code has been sent to ${email}`);
      signUpSignal.update({
        currentPage: 'verify',
      });
    }
    loaderSignal.reset();
  } catch (err) {
    console.error(err);
    loaderSignal.reset();
    return triggerWarningAlert(err.message);
  }
};

export const handleVerifyFormChange = (event, index, verificationCode, invited) => {
  if (index <= 5) {
    const oldFormData = [...verificationCode];
    if (event.target.value.length > 1) {
      const tempArray = event.target.value.split('');
      tempArray.forEach((value, key) => {
        if (key < 6) {
          // eslint-disable-next-line prefer-destructuring
          oldFormData[key] = value;
          event.target.nextElementSibling?.focus();
        }
      });
    } else {
      oldFormData[index] = event.target.value;
      if (index !== 0 && event.target.value === '') {
        event.target.previousElementSibling.focus();
      }
      if (index < 5 && event.target.value !== '') {
        event.target.nextElementSibling.focus();
      }
    }
    if (invited) {
      acceptInviteSignal.update({
        ...acceptInviteSignal.value,
        formData: {
          ...acceptInviteSignal.value.formData,
          verificationCode: oldFormData,
        },
      });
    } else {
      signUpSignal.update({
        ...signUpSignal.value,
        formData: {
          ...signUpSignal.value.formData,
          verificationCode: oldFormData,
        },
      });
    }
  }
};

const SIGNUP_CODE_VERIFICATION_MUTATION = gql`
  mutation UserSignUpWithEmailVerify($data: UserSignUpWithEmailVerifySchema!){
    userSignUpWithEmailVerify(data: $data)
  }
`;

export const handleCodeVerificationPartial = async (invited) => {
  try {
    let signalValue = signUpSignal.value;
    if (invited) {
      signalValue = acceptInviteSignal.value;
    }
    const { email, verificationCode } = signalValue.formData;

    if (verificationCode.length !== 6) {
      throw new Error('The verification code is not complete.');
    }
    loaderSignal.update({
      isLoading: true,
      isLoadingMessage: 'Loading...',
    });
    await apolloClient.mutate({ mutation: SIGNUP_CODE_VERIFICATION_MUTATION, variables: { data: { email, verification_code: verificationCode.join('') } } });
    if (invited) {
      acceptInviteSignal.update({
        ...acceptInviteSignal.value,
        step: 'createPassword',
      });
    } else {
      signUpSignal.update({
        ...signUpSignal.value,
        currentPage: 'createPassword',
      });
    }
    loaderSignal.reset();
  } catch (err) {
    loaderSignal.reset();
    return triggerWarningAlert(err.message);
  }
};

const EMAIL_VERIFICATION_MUTATION = gql`
  mutation UserSignUpWithEmailFinishSchema($data: UserSignUpWithEmailFinishSchema!){
    userSignUpWithEmailFinish(data: $data)
  }
`;

export const performCompleteSignup = async (isEmbed) => {
  try {
    const { email, password, confirmPassword, verificationCode } = signUpSignal.value.formData;
    containsLetter(password, 'Password');
    containsNumber(password, 'Password');
    validateLength(password, 'Password', 12, 32);
    validateMatchingFields(password, confirmPassword);

    const result = await apolloClient.mutate({
      mutation: EMAIL_VERIFICATION_MUTATION,
      variables: { data: { email, password, verification_code: verificationCode.join('') } },
    });
    if (isEmbed) {
      signUpSignal.update({
        ...signUpSignal.value,
        currentPage: 'earlyAccess',
      });
    } else {
      const authResult = await auth.signInWithCustomToken(result.data.userSignUpWithEmailFinish);
      await handleFirebaseLogin(authResult.user);
      history.replace('/onboarding');
    }
  } catch (err) {
    return triggerWarningAlert(err.message);
  }
};
