/* eslint-disable prefer-arrow-callback */
import { memo } from 'react';
import makeId from '#utils/makeId';

const statusPillOptions = [
  {
    label: 'paid',
    title: 'Paid',
    bgColor: 'success-active',
    borderColor: 'success-active',
    textColor: 'success',
  },
  {
    label: 'complete',
    title: 'Complete',
    bgColor: 'success-active',
    borderColor: 'success-active',
    textColor: 'success',
  },
  {
    label: 'overdue',
    title: 'Overdue',
    bgColor: 'danger-active',
    borderColor: 'danger-active',
    textColor: 'danger',
  },
  {
    label: 'open',
    title: 'Open',
    bgColor: 'warning-hover',
    borderColor: 'warning-hover',
    textColor: 'warning',
  },
  {
    label: 'pending',
    title: 'Pending',
    bgColor: 'warning-hover',
    borderColor: 'warning-hover',
    textColor: 'warning',
  },
  {
    label: 'partial',
    title: 'Partial',
    bgColor: 'primary-bg',
    borderColor: 'primary-bg',
    textColor: 'primary',
  },
];

const StatusPills = memo(function StatusPills({ status, isOverdue, className }) {
  const match = statusPillOptions.find(option => option.label === (isOverdue ? 'overdue' : status.toLowerCase()));
  return (
    <>
      { match ? (
        <div data-cy="status-pill" key={makeId(15)} className={`d-flex justify-content-center align-items-center text-center fw-700 rounded-pill status-pill d-inline-block border border-2 border-${match.borderColor} bg-${match.bgColor} text-${match.textColor} ${className || ''}`}>
          {match.title}
        </div>
      ) : null}
    </>
  );
});

export default StatusPills;
