import statementBackground from '#images/statementBackground.svg';
import statementBackgroundLg from '#images/statementBackgroundLg.svg';
import statementBackgroundMd from '#images/statementBackgroundMd.svg';
import statementBackgroundSm from '#images/statementBackgroundSm.svg';

import fycLabsLogo from '#images/fycLabsLogo.svg';

const CMS_DATA_MAP = {
  '/statement/welcome': {
    imgXl: {
      src: statementBackground,
      alt: 'Two People Reading',
    },
    imgLg: {
      src: statementBackgroundLg,
      alt: 'Two People Reading',
    },
    imgMd: {
      src: statementBackgroundMd,
      alt: 'Two People Reading',
    },
    imgSm: {
      src: statementBackgroundSm,
      alt: 'Two People Reading',
    },
    fycLogo: fycLabsLogo,
    title: 'Welcome to your first statement from ',
    subTitle: 'We hope that providing you various payment options and helpful links to navigate your statement as well as futures bills, will leave you feeling informed and pleasant with your billing experience. Enjoy!',
    lead: 'Total Balance',
  },
  '/statement/general-questions': {
    sections: [
      {
        title: 'Why enroll in autopay?',
        text: 'Set it and forget!',
        link: {
          url: '', text: 'Learn more about the benefits of autopay',
        },
      },
      {
        title: 'Why set up a payment plan?',
        text: 'We get it...sometimes you need to budget your money and paying a bill in full is not the best for your business.',
        link: {
          url: '', text: 'Learn more about all the benenfits of setting up payment plans.',
        },
      },
    ],
  },
  '/statement/about': {
    title: 'About your statement',
    sections: [
      {
        type: 'regular',
        title: 'Have more questions?',
        text: 'We’ve listed the most common questions and answers for you in one place.',
        link: {
          url: '',
          text: 'Read our FAQs',
        },
      },
      {
        type: 'regular',
        title: 'Take a sneak peak at next months bill.',
        text: 'You can actually do this at any point throughout the month allowing you to stay informed about the progress of your project.',
        link: {
          url: '',
          text: 'View current bill for April',
        },
      },
      {
        type: 'iframe',
        title: 'Check out your video statement',
        url: 'https://www.youtube.com/embed/EQZHGCo3Q2s',
      },
    ],
  },
};

export default CMS_DATA_MAP;
