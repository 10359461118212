import Signal from './Signal';

export const authTokenSignal = Signal('');

export const createPasswordSignal = Signal({
  password: '',
  confirmPassword: '',
  submitting: false,
  currentForm: 'password',
  firstName: '',
  lastName: '',
  phoneNumber: '',
});

export const forgotPasswordSignal = Signal({
  email: '',
  submitting: false,
});

export const intuitSignal = Signal({
  redirectUrl: '',
});

export const loginSignal = Signal({
  email: '',
  password: '',
  submitting: false,
  rememberDevice: false,
  passwordVisible: false,
});

export const resetPasswordSignal = Signal({
  password: '',
  confirmPassword: '',
  submitting: false,
  passwordVisible: false,
});

export const passwordFormSignal = Signal({
  passwordVisible: false,
  passwordRequirements: {
    reqLength: false,
    number: false,
    uppercase: false,
    symbol: false,
  },
});

export const passwordResetFormSignal = Signal({
  email: '',
});

export const signUpSignal = Signal({
  formData: {
    email: '',
    password: '',
    confirmPassword: '',
    verificationCode: ['', '', '', '', '', ''],
  },
  currentPage: 'emailForm',
});
export const signUpEmbedSignal = Signal({
  formData: {
    email: '',
    password: '',
    confirmPassword: '',
    verificationCode: ['', '', '', '', '', ''],
  },
  currentPage: 'emailForm',
});

export default {
  authTokenSignal,
  forgotPasswordSignal,
  intuitSignal,
  loginSignal,
  resetPasswordSignal,
  signUpSignal,
  signUpEmbedSignal,
  passwordResetFormSignal,
  passwordFormSignal,
};
