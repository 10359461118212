import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { ApolloLink, concat } from 'apollo-link';
import { getConfig } from '#config/config';
import { authTokenSignal } from '#signals/Authentication.signals';

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = authTokenSignal.value;
  operation.setContext({
    headers: {
      Authorization: token.length > 0 && `Bearer ${token}`,
    },
  });
  return forward(operation);
});

const httpLink = new HttpLink({ uri: getConfig('BACKEND_GRAPHQL_ENDPOINT') });

export const apolloClient = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
});

const REACT_APP_API_ENDPOINT = getConfig('BACKEND_GRAPHQL_ENDPOINT');

const apiFetch = async ({
  path,
  body = undefined,
  method = 'GET',
  token,
}) => {
  const methodToUse = (method) || (body ? 'POST' : 'GET');

  const res = await fetch(REACT_APP_API_ENDPOINT.replace('/graphql', '') + path, {
    method: methodToUse,
    body,
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Length': body ? body.length.toString() : undefined,
      'Content-Type': body ? 'application/json' : undefined,
    },
  });
  const result = await res.json();
  return result;
};

const apiFormDataFetch = async ({
  path,
  formData = undefined,
  method = 'GET',
  token,
}) => {
  const methodToUse = (method) || (formData ? 'POST' : 'GET');

  const res = await fetch(REACT_APP_API_ENDPOINT.replace('/graphql', '') + path, {
    method: methodToUse,
    body: formData,
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  const result = await res.json();
  return result;
};

const apiFetchUnAuthenticated = async ({
  path,
  body = undefined,
  method = 'GET',
}) => {
  const methodToUse = (method) || (body ? 'POST' : 'GET');
  const requestOptions = {
    method: methodToUse,
    headers: {
      'Content-Length': body ? body.length.toString() : undefined,
      'Content-Type': body ? 'application/json' : undefined,
    },
  };

  if (body) {
    requestOptions.body = body;
  }

  const res = await fetch(REACT_APP_API_ENDPOINT + path, requestOptions);
  const result = await res.json();
  return result;
};

export default { apiFetch, apiFormDataFetch, apiFetchUnAuthenticated };
