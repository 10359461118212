import Signal from './Signal';

export const userSignal = Signal({
  user: {
    first_name: '',
    last_name: '',
    organization: null,
  },
});

export default { userSignal };
