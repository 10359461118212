const formatDate = (value) => {
  const date = new Date(value);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1);
  const day = String(date.getUTCDate());

  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
};

export default formatDate;
