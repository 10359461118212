import { faEye, faEyeSlash, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useEffect } from 'react';
import { performCompleteSignup } from '../Signup.helpers';
import ReturnToLoginRow from './ReturnToLoginRow';
import { performCompleteInviteSignup } from '#views/AcceptInvite/AcceptInvite.helpers';
import { signUpSignal, passwordFormSignal } from '#signals/Authentication.signals';
import { acceptInviteSignal } from '#signals/pages/Onboarding.signals';
import UniversalInput from '../../../global/Inputs/UniversalInput';

const PasswordForm = ({ invitedUser, isEmbed }) => {
  const { passwordVisible, passwordRequirements } = passwordFormSignal.value;
  let signalValue = signUpSignal;
  if (invitedUser) {
    signalValue = acceptInviteSignal;
  }
  const { password, confirmPassword } = signalValue.value.formData;

  useEffect(() => {
    if (password) {
      const { ...requirements } = passwordRequirements;
      requirements.reqLength = password.length >= 12 && password.length <= 36;
      requirements.uppercase = /[A-Z]/.test(password);
      requirements.number = /\d/.test(password);
      requirements.symbol = /[^A-Za-z0-9]/.test(password);
      passwordFormSignal.update({
        passwordRequirements: requirements,
      });
    }
  }, [password]);

  return (
    <Form.Group>
      <h2 className="fw-700 text-center mb-40">Create password</h2>
      <Form.Label htmlFor="EmailAddress"><strong>Password</strong></Form.Label>
      <UniversalInput
        placeholder="Enter New Password"
        name="password"
        value={password}
        type={passwordVisible ? 'text' : 'password'}
        className="mb-16"
        customOnChange={(e) => signalValue.update({
          ...signalValue.value,
          formData: {
            ...signalValue.value.formData,
            password: e.target.value,
          },
        })}
      />
      <Form.Label htmlFor="Password"><strong>Confirm New Password</strong></Form.Label>
      <Row className="m-0">
        <Col xs={11} className="p-0">
          <UniversalInput
            placeholder="Confirm Password"
            name="confirmPassword"
            value={confirmPassword}
            type={passwordVisible ? 'text' : 'password'}
            className="mb-16"
            customOnChange={(e) => signalValue.update({
              ...signalValue.value,
              formData: {
                ...signalValue.value.formData,
                confirmPassword: e.target.value,
              },
            })}
          />
        </Col>
        <Col xs={1} className="mb-16 p-0 border-bottom border-gray border-1 d-flex justify-content-center align-items-center">
          <Button
            role="button"
            tabIndex="0"
            onClick={() => passwordFormSignal.update({ passwordVisible: !passwordVisible })}
            variant="eye"
            className="text-primary rounded-0 border-dark d-flex justify-content-center align-items-center"
          >
            <FontAwesomeIcon className="d-flex justify-content-center align-items-center" icon={passwordVisible ? faEye : faEyeSlash} />
          </Button>
        </Col>
      </Row>
      <Form.Label>Password must:</Form.Label>
      <div className="text-dark-500">
        <div className="ms-16"><FontAwesomeIcon className="me-8" icon={faCheckCircle} color={passwordRequirements.reqLength ? '#41696C' : '#CDCDCD'} />Be 12-36 characters in length</div>
        <div className="ms-16"><FontAwesomeIcon className="me-8" icon={faCheckCircle} color={passwordRequirements.number ? '#41696C' : '#CDCDCD'} />Contain at least one number</div>
        <div className="ms-16"><FontAwesomeIcon className="me-8" icon={faCheckCircle} color={passwordRequirements.uppercase ? '#41696C' : '#CDCDCD'} />Contain at least one uppercase letter</div>
        <div className="ms-16"><FontAwesomeIcon className="me-8" icon={faCheckCircle} color={passwordRequirements.symbol ? '#41696C' : '#CDCDCD'} />Contain at least one special character</div>
      </div>
      <Button
        variant="primary"
        type="submit"
        onClick={invitedUser ? () => performCompleteInviteSignup() : () => performCompleteSignup(isEmbed)}
        className="w-100 btn-lg mb-16 mt-24"
        disabled={signalValue.value.formData.confirmPassword === '' || signalValue.value.formData.password === ''}
      >
        SUBMIT
      </Button>
      {!invitedUser && !isEmbed && (
        <ReturnToLoginRow />
      )}
    </Form.Group>
  );
};

export default PasswordForm;
