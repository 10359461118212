import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import loginBackground from '#images/loginBackground.svg';
import authdark from '#images/auth-dark.svg';
import loginLogo from '#images/loginLogo.svg';

const AuthLayout = ({ children, header, background }) => (
  <Container fluid className="min-vh-100 px-0 w-100" style={{ backgroundColor: 'rgb(231, 192, 170, 0.4)' }}>
    {header && header()}
    <Image src={background === 'dark' ? authdark : loginBackground} alt="Two People Reading" className="position-absolute bottom-0 w-100 object-fit-cover" />
    <Row className="py-auto m-0 d-flex justify-content-center align-items-center min-vh-100">
      <Col lg={5} md={8} sm={10} xs={12} className="">
        <Card className="px-xl-80 px-40 border-0" style={{ maxWidth: '625px' }}>
          <Image src={loginLogo} alt="Two People Reading" className="mx-auto my-24" />
          {children}
          <Container className="text-center mb-24">
            By continuing you agree to our <Link to="/">terms</Link> and <Link to="/">privacy policy</Link>
          </Container>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default AuthLayout;
