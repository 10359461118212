import { Row, Col, Container, Card, Image, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import smile from '#images/smileIcon.svg';

const InvoiceSuccessfulSend = () => {
  const history = useHistory();
  return (
    <>
      <Row>
        <Col className="text-center">
          <h1 className="text-primary fw-700 pt-16 pb-24">Upload your Invoice List</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 className="pb-56 fw-400">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h2>
        </Col>
      </Row>
      <Container>
        <Row>
          <Col>
            <Card onClick={() => {}} style={{ maxWidth: 620 }} className="mb-24 px-80 py-64 justify-content-center mx-auto">
              <Image src={smile} width={76} className="text-align-center mx-auto mb-56" />
              <h2 className="fw-700 mb-40">Your invoice has been sent!</h2>
              <Button
                type="button"
                variant="primary"
                className="w-100 py-16 mb-24 mx-8"
                onClick={() => history.push('/invoices')}
              >
                BACK TO INVOICE LIST
              </Button>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default InvoiceSuccessfulSend;
