import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Button, Card, Col, Container, Form, Image, Nav, Row, Tab, Table } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { signal } from '@preact/signals-react';
import Money from '#global/Money';
import sortAlt from '#images/sort-alt.svg';
import filterAlt from '#images/filter-alt.svg';
import StatusPills from '#global/StatusPills/StatusPills';
import makeid from '#utils/makeId';
import { statementSignal } from '#signals/pages/Statement.signals';
import { paymentDetailsSignal } from '#signals/pages/Payment.signals';
import DatePicker from '../../../global/Inputs/DatePicker';

const TABS = {
  paymentHistory: 'paymentHistory',
  billPay: 'billPay',
};

const tabSignal = signal(TABS.paymentHistory);

function setCurrentTab(tab) {
  tabSignal.value = tab;
}

const dateInputsOpen = signal(false);

function setDateInputsOpen(open) {
  dateInputsOpen.value = open;
}

const PaymentDetails = ({ className }) => {
  const { checkedBills } = paymentDetailsSignal.value;
  const history = useHistory();
  const goToPaymentView = () => history.push('/payment');

  const onChangeDatesClicked = () => {
    setDateInputsOpen(!dateInputsOpen.value);
  };

  const handleCheckAllBills = (checked) => {
    paymentDetailsSignal.update({
      checkedBills: checked ? statementSignal.value.paymentHistoryData : [],
    });
  };

  const handleCheckBill = (bill, checked) => {
    if (checked) {
      const tempArray = checkedBills;
      tempArray.push({ bill });
      paymentDetailsSignal.update({
        checkedBills: tempArray,
      });
    } else {
      const tempArray = checkedBills.filter(b => b.id !== bill.id);
      paymentDetailsSignal.update({
        checkedBills: tempArray,
      });
    }
  };

  return (
    <Container fluid className={`p-0 mt-24 ${className}`}>
      <Nav variant="tabs" activeKey={tabSignal.value} onSelect={(key) => setCurrentTab(key)} className="container d-none d-xl-flex">
        <Nav.Item>
          <Nav.Link eventKey={TABS.paymentHistory}>Payment History</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey={TABS.billPay}>Bill Pay</Nav.Link>
        </Nav.Item>
      </Nav>
      <div className="d-flex d-xl-none justify-content-center mb-40">
        <Nav variant="pills" activeKey={tabSignal.value} onSelect={(key) => setCurrentTab(key)} className="px-xl-40 rounded-pill border border-2 border-gray col-md-5 col-8">
          <Nav.Item className="col-6">
            <Nav.Link className="lead fw-800 p-8 rounded-pill btn text-primary" eventKey={TABS.paymentHistory}>History</Nav.Link>
          </Nav.Item>
          <Nav.Item className="col-6">
            <Nav.Link className="lead fw-800 p-8 rounded-pill btn text-primary" eventKey={TABS.billPay}>Bill Pay</Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      <Tab.Container activeKey={tabSignal.value}>
        <Tab.Content id="details" className="rounded-4 shadow-top-lg shadow-xl-none pt-md-72 pt-40">
          <Tab.Pane eventKey={TABS.paymentHistory}>
            <Container className="px-xl-40 px-lg-56 pb-md-72 pb-40">
              <Row>
                <Col sm={12} md={4}>
                  <h2 className="fw-400 w-100 border-bottom text-dark-900">Total Balance</h2>
                  <h1 className="fw-700 m-0"><Money amount={statementSignal.value?.totalBalance} /></h1>
                  <small className="text-danger fw-500">Total Overdue: <Money amount={statementSignal.value?.overDue} /></small>
                  <h3 className="fw-400 text-dark-900 mt-8 d-none d-md-block">Statement for period</h3>
                  <h3 className="fw-700 d-none d-md-block">{moment(statementSignal.value?.startDate).format('ll')} - {moment(statementSignal?.value?.endDate).format('ll')}
                    <Button
                      variant="eye"
                        // TODO - Position Absolute - LG
                      onClick={onChangeDatesClicked}
                    >
                      <FontAwesomeIcon icon={dateInputsOpen.value ? faChevronUp : faChevronDown} />
                    </Button>
                  </h3>
                  {dateInputsOpen.value && (
                    <Form className="d-flex justify-space-between d-none d-md-block">
                      <Container fluid className="ps-0 pe-8">
                        <Form.Label className="text-dark-900 fw-500">Start Date:</Form.Label>
                        <DatePicker
                          name="startDate"
                          placeholder="Start Date"
                          value={statementSignal?.value?.startDate}
                          signal={statementSignal}
                        />
                      </Container>
                      <Container fluid className="ps-8 pe-0">
                        <Form.Label className="text-dark-900 fw-500">End Date:</Form.Label>
                        <DatePicker
                          name="endDate"
                          placeholder="End Date"
                          value={statementSignal?.value?.endDate}
                          signal={statementSignal}
                        />
                      </Container>
                    </Form>
                  )}
                </Col>
                <Col xl={{ span: 8, offset: 0 }} lg={{ span: 7, offset: 1 }} md={8} className="d-none d-lg-block">
                  <Row className="mt-8 d-flex justify-space-between">
                    <Col sm={4}>
                      <Container>
                        <p className="lead fw-400 w-100 border-bottom text-dark-900 d-block">1-30 Days Past Due</p>
                        <h2 className="fw-700 mt-8"><Money amount={statementSignal?.value?.firstPastDue} /></h2>
                        <Link className="fw-500" to="/todo">Pay Balance</Link>
                      </Container>
                    </Col>
                    <Col sm={4}>
                      <Container>
                        <p className="lead fw-400 w-100 border-bottom text-dark-900 d-block">31-60 Days Past Due</p>
                        <h2 className="fw-700 mt-8"><Money amount={statementSignal?.value?.secondPastDue} /></h2>
                        <Link className="fw-500" to="/todo">Pay Balance</Link>
                      </Container>
                    </Col>
                    <Col sm={4}>
                      <Container>
                        <p className="lead fw-400 w-100 border-bottom text-dark-900 d-block">90 + Days Past Due</p>
                        <h2 className="fw-700 mt-8"><Money amount={statementSignal?.value?.thirdPastDue} /></h2>
                        <Link className="fw-500" to="/todo">Pay Balance</Link>
                      </Container>
                    </Col>
                  </Row>
                </Col>
                <Col md={{ span: 7, offset: 1 }} className="d-none d-md-block d-lg-none">
                  <Row className="border-bottom mt-16">
                    <Col sm={5} className="p-0">
                      <h5 className="fw-400 w-100 text-dark-900 m-0  mt-8">1-30 Days Past Due</h5>
                    </Col>
                    <Col sm={3}>
                      <h3 className="fw-700 mt-8"><Money amount={statementSignal?.value?.firstPastDue} /></h3>
                    </Col>
                    <Col sm={4}>
                      <Button variant="outline-primary" onClick={() => console.log('TODO - go somewhere')}>Pay Balance</Button>
                    </Col>
                  </Row>
                  <Row className="border-bottom  mt-16">
                    <Col sm={5} className="p-0">
                      <h5 className="fw-400 w-100 text-dark-900 m-0  mt-8">31-60 Days Past Due</h5>
                    </Col>
                    <Col sm={3}>
                      <h3 className="fw-700 mt-8"><Money amount={statementSignal?.value?.secondPastDue} /></h3>
                    </Col>
                    <Col sm={4}>
                      <Button variant="outline-primary" onClick={() => console.log('TODO - go somewhere')}>Pay Balance</Button>
                    </Col>
                  </Row>
                  <Row className="border-bottom  mt-16">
                    <Col sm={5} className="p-0">
                      <h5 className="fw-400 w-100 text-dark-900 m-0  mt-8">90 + Days Past Due</h5>
                    </Col>
                    <Col sm={3}>
                      <h3 className="fw-700 mt-8"><Money amount={statementSignal?.value?.thirdPastDue} /></h3>
                    </Col>
                    <Col sm={4}>
                      <Button variant="outline-primary" onClick={() => console.log('TODO - go somewhere')}>Pay Balance</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
            {/* Stacks */}
            <Table responsive className="table">
              <thead>
                <tr className="bg-secondary-200 text-dark">
                  <th scope="col" className="base fw-800 ps-56 py-40 d-none d-md-table-cell d-lg-none">Type</th>
                  <th scope="col" className="base fw-800 ps-md-56 ps-24 py-40 d-md-none position-sticky start-0 position-md-static border border-1 border-right bg-secondary-200">Type</th>
                  <th scope="col" className="base fw-800 ps-lg-56 ps-md-0 ps-24 py-40">Date</th>
                  <th scope="col" className="base fw-800 py-40">Due Date</th>
                  <th scope="col" className="base fw-800 py-40 d-none d-lg-table-cell">Id</th>
                  <th scope="col" className="base fw-800 py-40 d-none d-lg-table-cell">Type</th>
                  <th scope="col" className="base fw-800 py-40">Bill Amt</th>
                  <th scope="col" className="base fw-800 py-40">
                    Account Balance
                  </th>
                  <th scope="col" className="py-lg-32 py-40 d-flex justofy-space-between">
                    <Button type="button" variant="eye" className="ms-auto my-xl-16 my-lg-8 me-24 w-50 p-0 bg-white text-primary">
                      <Image src={sortAlt} alt="sort-alt" />
                    </Button>
                    <Button type="button" variant="eye" className="ms-auto my-xl-16 my-lg-8 w-50 p-0 bg-white text-primary">
                      <Image src={filterAlt} alt="filter-alt" />
                    </Button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {statementSignal.value && statementSignal.value.paymentHistoryData && statementSignal.value.paymentHistoryData.map((obj, index) => (
                  <tr className={`${obj?.type === 'Payment' ? 'bg-gray-200 border-1 border-gray-250' : ''}`} key={index}>
                    <td className="py-40 text-primary table-td-8 d-none d-md-table-cell d-lg-none ps-56"><h5 className="fw-700 mb-0">{obj?.type}</h5></td>
                    <td className="py-40 text-primary table-td-8 d-md-none ps-md-56 px-24 position-sticky start-0 border border-1 border-right bg-gray"><h5 className="fw-700 mb-0">{obj?.type}</h5></td>
                    <td className="base fw-400 ps-lg-56 ps-md-0 ps-24 py-40 text-primary table-td-8">{moment(obj?.date).format('l')}</td>
                    <td className="base fw-400 h5 py-40 text-primary table-td-8">{moment(obj?.dueDate).format('l')}</td>
                    <td className="base fw-400 h5 py-40 text-dark-900 table-td-8 d-none d-lg-table-cell">{obj?.id}</td>
                    <td className="py-40 text-primary table-td-8 d-none d-lg-table-cell"><h5 className="fw-700 mb-0">{obj?.type}</h5></td>
                    <td className="py-40 text-dark-900 table-td-8 h3 mb-0"><Money amount={obj.amt} className="fw-700" /></td>
                    <td className="py-40 text-dark-900 table-td-8 h3 mb-0"><Money amount={obj.accountBalance} className="fw-700" /></td>
                    <td className="table-td-8" />
                  </tr>
                ))}
              </tbody>
            </Table>
          </Tab.Pane>
          <Tab.Pane eventKey={TABS.billPay}>
            <Container className="pb-md-72 pb-40">
              <Row className="d-none d-md-flex">
                <Col xs={4}>
                  <h2 className="fw-400 w-100 border-bottom text-dark-900">Total Balance Due</h2>
                  <h1 className="fw-700 m-0"><Money amount={statementSignal?.value?.totalBalanceDue} /></h1>
                  <small className="text-danger fw-500">Total Overdue: <Money amount={statementSignal?.value?.overDue} /></small>
                  <h3 className="fw-400 text-dark-900 mt-8">Statement for period</h3>
                  <h2 className="fw-700">{moment(statementSignal?.value?.startDate).format('ll')} - {moment(statementSignal?.value?.endDate).format('ll')}
                    <Button
                      variant="eye"
                      onClick={onChangeDatesClicked}
                    >
                      <FontAwesomeIcon icon={dateInputsOpen.value ? faChevronUp : faChevronDown} />
                    </Button>
                  </h2>
                  {dateInputsOpen.value && (
                  <Form className="d-flex justify-space-between">
                    <Container fluid className="ps-0 pe-8">
                      <Form.Label className="text-dark-900 fw-500">Start Date:</Form.Label>
                      <DatePicker
                        name="startDate"
                        placeholder="Start Date"
                        value={statementSignal?.value?.startDate}
                        signal={statementSignal}
                      />
                    </Container>
                    <Container fluid className="ps-8 pe-0">
                      <Form.Label className="text-dark-900 fw-500">End Date:</Form.Label>
                      <DatePicker
                        name="endDate"
                        placeholder="End Date"
                        value={statementSignal?.value?.endDate}
                        signal={statementSignal}
                      />
                    </Container>
                  </Form>
                  )}
                </Col>
                {/* COMING SOON AFTER PROJECTS ARE CREATED
                <Col xs={8} className="d-none d-lg-block">
                  <Row className="mt-8 d-flex justify-space-between">
                    <Col sm={6}>
                      <Container>
                        <p className="lead fw-400 w-100 border-bottom text-dark-900 d-block">{statementSignal?.value?.project} Project Budget</p>
                        <h2 className="fw-700 mt-8"><Money amount={statementSignal?.value?.projectBudget} /></h2>
                        <p className="lead text-dark-500 fw-400"><Money amount={statementSignal?.value?.projectBudget - statementSignal?.value?.totalBalanceDue} /> until project cap</p>
                      </Container>
                    </Col>
                    <Col sm={6}>
                      <Container>
                        <p className="lead fw-400 w-100 border-bottom text-dark-900 d-block">Other {statementSignal?.value?.client} Projects</p>
                        <Row>
                          {statementSignal?.value?.otherProjects && statementSignal?.value.otherProjects.map((code) => (
                            <Col key={makeid(15)} sm={4} className="base fw-400 text-dark-900 py-8">{code}</Col>
                          ))}
                        </Row>
                      </Container>
                    </Col>
                  </Row>
                </Col>
                <Col xs={4} className="d-lg-none">
                  <Container className="p-0">
                    <p className="lead fw-400 w-100 border-bottom text-dark-900 d-block">{statementSignal?.value?.project} Project Budget</p>
                    <h2 className="fw-700 mt-8"><Money amount={statementSignal?.value?.projectBudget} /></h2>
                    <p className="lead text-dark-500 fw-400"><Money amount={statementSignal?.value?.projectBudget - statementSignal?.value?.totalBalanceDue} /> until project cap</p>
                  </Container>
                </Col>
                <Col xs={4} className="d-lg-none">
                  <Container className="p-0">
                    <p className="lead fw-400 w-100 border-bottom text-dark-900 d-block">Other {statementSignal?.value?.client} Projects</p>
                    <Row>
                      {statementSignal?.value?.otherProjects && statementSignal?.value.otherProjects.map((code) => (
                        <Col key={makeid(15)} sm={6} className="base fw-400 text-dark-900 py-8">{code}</Col>
                      ))}
                    </Row>
                  </Container>
                </Col>
                */}
              </Row>
              <Row className="d-md-none">
                <Col sm={12}>
                  <h2 className="fw-400 w-100 border-bottom text-dark-900">Total Balance</h2>
                  <h1 className="fw-700 m-0"><Money amount={statementSignal?.value?.totalBalance} /></h1>
                </Col>
              </Row>
            </Container>
            <section className="d-none d-xl-block">
              <Row className="bg-secondary-200 text-dark mx-0 px-8 pt-8 pb-24">
                <Row className="">
                  <div style={{ minHeight: '16px' }} className={`py-8 d-flex fw-800 ${!checkedBills.length && 'invisible'}`}>
                    <small className="fw-800 text-dark-500 pe-24">Bulk Action:</small>
                    <small className="cursor-pointer bulk-action-btn text-dark-500 ps-24 pe-24 border-end border-dark-500">Pay All Now</small>
                    <small className="cursor-pointer bulk-action-btn text-dark-500 ps-16 pe-24 border-end border-dark-500">Make custom payment</small>
                    <small className="cursor-pointer bulk-action-btn text-dark-500 ps-16 pe-24 border-end border-dark-500">Mark as Check On the Way</small>
                    <small className="cursor-pointer bulk-action-btn text-dark-500 ps-16 pe-48">Set Up Payment Options</small>
                  </div>
                </Row>
                <Col lg={1} className="d-flex justify-content-around">
                  <Form.Check
                    type="checkbox"
                    className="d-flex align-items-center pb-xl-16"
                    onChange={(e) => handleCheckAllBills(e.target.checked)}
                  />
                  <small className="fw-800 d-flex align-items-center pb-xl-16 py-lg-8 my-16">Select All</small>
                </Col>
                <Col lg={1}>
                  <small className="fw-800 d-flex align-items-center pb-xl-16 py-lg-8 my-16">ID</small>
                </Col>
                <Col lg={1}>
                  <small className="fw-800 d-flex align-items-center pb-xl-16 py-lg-8 my-16">Type</small>
                </Col>
                <Col lg={1}>
                  <small className="fw-800 d-flex align-items-center pb-xl-16 py-lg-8 my-16">Date</small>
                </Col>
                <Col lg={1}>
                  <small className="fw-800 d-flex align-items-center pb-xl-16 py-lg-8 my-16">Due Date</small>
                </Col>
                <Col lg={1}>
                  <small className="fw-800 d-flex align-items-center pb-xl-16 py-lg-8 my-16">Amount</small>
                </Col>
                <Col lg={1}>
                  <small className="fw-800 d-flex align-items-center pb-xl-16 py-lg-8 my-16">Amount Due</small>
                </Col>
                <Col lg={1}>
                  <small className="fw-800 d-flex align-items-center pb-xl-16 py-lg-8 my-16">Status</small>
                </Col>
                <Col lg={1}>
                  <small className="fw-800 d-flex align-items-center pb-xl-16 py-lg-8 my-16">Action(s)</small>
                </Col>
                <Col lg={3} className="py-lg-8 d-flex justify-content-end align-items-center">
                  <Row className="">
                    <Col lg={6} className="d-flex justify-content-center">
                      <Button type="button" variant="eye" className="p-0 bg-white text-primary">
                        <Image src={sortAlt} alt="sort-alt" />
                      </Button>
                    </Col>
                    <Col lg={6} className="d-flex justify-content-center">
                      <Button type="button" variant="eye" className="p-0 bg-white text-primary">
                        <Image src={filterAlt} alt="filter-alt" />
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {statementSignal?.value?.payBillsData && statementSignal?.value?.payBillsData?.map((obj) => (
                <Row className="bill-card text-dark mx-8 my-32 rounded-4" key={makeid(15)}>
                  <Col lg={1} className="base fw-400 ps-32 py-40 text-dark-900">
                    {obj?.status === 'paid' || obj?.status === 'complete' ? (
                      null
                    ) : (
                      <Form.Check
                        type="checkbox"
                        checked={checkedBills.some(b => b.id === obj.id)}
                        onChange={(e) => handleCheckBill(obj, e.target.checked)}
                      />
                    )}
                  </Col>
                  <Col lg={1} className="base fw-400 h5 py-40 text-dark-900">
                    {obj?.id}
                  </Col>
                  <Col lg={1} className="py-40 text-primary">
                    <h5 className="fw-700 mb-0">{obj?.type}</h5><small className="row m-0 text-secondary fw-500">{obj?.memo && 'View Memo'}</small>
                  </Col>
                  <Col lg={1} className="base fw-400 py-40 text-primary">
                    {moment(obj?.date).format('l')}
                  </Col>
                  <Col lg={1} className="base fw-400 h5 py-40 text-primary">
                    {moment(obj?.dueDate).format('l')}
                  </Col>
                  <Col lg={1} className="py-40 text-dark-900 h3 mb-0">
                    <Money amount={obj?.amount} className="fw-700" /><small className="row m-0 text-secondary fw-400">{}</small>
                  </Col>
                  <Col lg={1} className="py-40 text-dark-900 h3 mb-0">
                    <Money amount={obj?.amountDue} className="fw-700" /><small className="row m-0 text-secondary fw-400">{}</small>
                  </Col>
                  <Col lg={1} className="base fw-400 h5 py-32 text-dark-900">
                    <StatusPills status={obj?.status} />
                    <small className="row d-flex justify-content-center text-secondary fw-500 mt-8">{}</small>
                  </Col>
                  <Col lg={1} className="py-40">
                    <Link className="fw-500" to="/todo">View Details</Link>
                  </Col>
                  <Col lg={3}>
                    <Row>
                      {obj?.status === 'paid' || obj?.status === 'complete' ? (
                        <Col lg={6} className="py-40 d-flex justify-content-center">
                          <Link className="fw-500" to="/todo">View Receipt</Link>
                        </Col>
                      ) : (
                        <Col lg={6} className="py-32 d-flex justify-content-center">
                          <Button
                            type="button"
                            variant="primary"
                            onClick={goToPaymentView}
                          >
                            <small className="fw-400">MAKE PAYMENT</small>
                          </Button>
                        </Col>
                      )}
                      <Col lg={6} className="py-32  d-flex justify-content-center">
                        {obj?.status !== ('paid' || 'complete') && (
                        <Button type="button" variant="outline-primary">
                          <small className="fw-400">PAYMENT OPTIONS</small>
                        </Button>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ))}
            </section>
            <section className="d-none d-lg-block d-md-block d-xl-none ">
              <Container fluid className="bg-secondary-200 p-0">
                <Row className="m-0 px-56 py-16">
                  <Col sm={10} className="p-0">
                    <Form.Check
                      className="py-8 pe-8 d-inline"
                      type="checkBox"
                    />
                    <small className="fw-800 d-inline">Select All</small>
                  </Col>
                  <Col sm={2}>
                    <Button type="button" variant="eye" className="ms-auto w-50 p-0 bg-white text-primary">
                      <Image src={sortAlt} alt="sort-alt" />
                    </Button>
                    <Button type="button" variant="eye" className="ms-auto w-50 p-0 bg-white text-primary">
                      <Image src={filterAlt} alt="filter-alt" />
                    </Button>
                  </Col>
                </Row>
              </Container>
              {statementSignal?.value?.payBillsData && statementSignal?.value.payBillsData.map((obj) => (
                <Container fluid key={makeid(15)} className="px-56">
                  <Card className="border bg-none border-gray rounded-4 w-100 py-24 px-16 my-24 bg-gray-250">
                    <Row className="m-0">
                      <Col sm={3} xs={6}>
                        <Form.Check
                          className="d-inline mx-16 p-0"
                          type="checkBox"
                        />
                        <h5 className="fw-700 mb-0 d-inline">{obj?.type} #{obj?.id}</h5>
                      </Col>
                      <Col sm={{ offset: 6, span: 3 }} className="p-0">
                        <Money amount={obj.amount} className="fw-700 h2 text-primary" />
                      </Col>
                    </Row>
                    <Row className="m-0">
                      <Col sm={3} xs={6}>
                        <small className="ms-16 fw-500 text-dark-500">
                          {moment(obj?.date).format('l')}
                        </small>
                      </Col>
                      <Col sm={{ offset: 6, span: 3 }} className="p-0">
                        <small className="fw-500 text-dark-500">
                          {moment(obj?.endDate).format('l')}
                        </small>
                      </Col>
                    </Row>
                    <Row className="m-0 mt-16">
                      <Col xs={6} className="ps-32">
                        <StatusPills status={obj?.status} className="ms-48 d-inline" />
                        {/* <small className="m-0 ms-16 d-inline text-secondary fw-500">{obj?.daysOverdue && `${obj?.daysOverdue} Days`}</small> */}
                      </Col>
                      <Col xs={{ offset: 3, span: 3 }} className="p-0">
                        <Button type="button" variant="primary" className="btn fw-800">View/Pay</Button>
                      </Col>
                    </Row>
                  </Card>
                </Container>
              ))}
            </section>
            {/* SM */}
            <section className="d-md-none">
              <Container fluid className="bg-secondary-200 p-0">
                <Row className="m-0 px-48 py-16">
                  <Col xs={8} className="p-0 mt-8">
                    <Form.Check
                      className="py-8 pe-8 d-inline"
                      type="checkBox"
                      onChange={(e) => handleCheckAllBills(e.target.checked)}
                    />
                    <small className="fw-800 d-inline">Select All</small>
                  </Col>
                  <Col xs={2}>
                    <Button type="button" variant="eye" className="ms-auto w-50 p-0 bg-white text-primary">
                      <Image src={sortAlt} alt="sort-alt" />
                    </Button>
                  </Col>
                  <Col xs={2}>
                    <Button type="button" variant="eye" className="ms-auto w-50 p-0 bg-white text-primary">
                      <Image src={filterAlt} alt="filter-alt" />
                    </Button>
                  </Col>
                </Row>
              </Container>
              {statementSignal?.value?.payBillsData && statementSignal?.value.payBillsData.map((obj) => (
                <Container fluid key={makeid(15)} className="px-36 bg-white">
                  <Card className="border bg-none border-gray rounded-4 w-100 p-16 my-24 bg-gray-250">
                    <Row>
                      <Col xs={2} className="d-flex align-items-center">
                        <Form.Check
                          className="d-inline mx-16 p-0"
                          type="checkBox"
                        />
                      </Col>
                      <Col xs={10}>
                        <Row className="m-0">
                          <Col xs={6} className="p-0">
                            <h5 className="fw-700 mb-0 d-inline">{obj?.type} #{obj?.id}</h5>
                            <div>
                              <small className="fw-500 text-dark-500">
                                Due {moment(obj?.date).format('l')}
                              </small>
                            </div>
                          </Col>
                          <Col xs={{ offset: 1, span: 5 }} className="p-0">
                            <Money amount={obj.amount} className="fw-700 h2 text-primary" />
                          </Col>
                        </Row>
                        <Row className="m-0 mt-16 d-flex align-items-center">
                          <Col xs={6} className="p-0">
                            <StatusPills status={obj?.status} />
                          </Col>
                          <Col xs={{ offset: 1, span: 5 }} className="p-0">
                            <Button type="button" variant="primary" className="btn fw-800" style={{ paddingTop: '.3rem', paddingBottom: '.3rem' }}>View/Pay</Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Container>
              ))}
            </section>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      <Container fluid className="px-56 py-48">
        <Button variant="eye" className="d-flex fw-500 py-16 px-0 text-decoration-underline text-primary" onClick={() => window.location.reload()}>View All Bills for FYC Labs - {moment(statementSignal?.value?.startDate).format('ll')} - {moment(statementSignal?.value?.endDate).format('ll')}</Button>
        <Button variant="eye" className="d-flex fw-500 py-16 px-0 text-decoration-underline text-primary" disabled>View Bills as PDF (Coming soon!)</Button>
        <Button variant="eye" className="d-flex fw-500 py-16 px-0 text-decoration-underline text-primary" disabled>Export your full statement (Coming soon!)</Button>
      </Container>
    </Container>
  );
};

export default PaymentDetails;
