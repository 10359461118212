import Signal from '../Signal';

export const billFormSignal = Signal({
  number: null,
  description: '',
  due_date: null,
  amount: null,
  organization_vendor_id: null,
});

export const billSignalInitialState = {
  is_loading: true,
  error: null,
};
export const billSignal = Signal(billSignalInitialState);

export const billStatementSignalInitialState = {
  vendor_organization_id: '',
  vendor_organization: {},

  customer_organization_vendor_id: '',
  customer_organization_vendor: {},

  customer_organization_id: '',
  customer_organization: {},

  data: {},

  total_amount: 0,
  paid_amount: 0,
  overdue_amount: 0,

  start_date: null,
  end_date: null,

  currency: '',
  status: '',

  is_loading: true,
};
export const billStatementSignal = Signal(billStatementSignalInitialState);

export const billsSignal = Signal({
  allBills: [],
  isLoading: false,
});

export const billsFunctionalitySignal = Signal({
  batchActions: 'Batch Actions',
  selectedVendorId: null,
  selectedStatus: 'All',
  selectedCurrency: null,
  selectedStartDate: null,
  selectedEndDate: null,
  selectedSorting: [{ field: 'created_at', order: 'DESC' }],
  selectedBills: [],
  page: 1,
  perPage: 20,
});

export const addBillSignal = Signal({
  currentScreen: null,
  backButtonHistory: [],
});

export const addBillManualSignal = Signal({
  allVendors: [],
  selectedCompany: null,
  infoModalOpen: false,
  uploadedFiles: [],
});

export const addBillSyncSignal = Signal({
  isQuickbooksConnected: false,
  connectingModalOpen: false,
  isConfiguringOptions: false,
  pushOptions: null,
  selectedPushItem: null,
  selectedPushAccount: null,
  pullToggle: false,
  pushToggle: false,
});

export default {
  billFormSignal,
  billsSignal,
  billsFunctionalitySignal,
  addBillSignal,
  addBillManualSignal,
  addBillSyncSignal,
};
