import { Button, Col, Form, Image, Row } from 'react-bootstrap';
import { useEffect } from 'react';
import camera from '#images/camera.svg';
import { settingsProfileSignal } from '#signals/pages/Settings.signals';
import { userSignal } from '#signals/User.signals';
import { cancelProfileUpdate, deleteProfilePicture, handleBrowse, updateProfileData, uploadProfilePicture } from '../ProfileSettings.helpers';
import UniversalInput from '#global/Inputs/UniversalInput';
import Loadable from '#global/Loadable';

const BasicInfo = () => {
  const {
    profilePhoto,
    firstName,
    lastName,
  } = settingsProfileSignal.value;
  const { first_name: tempFirstName, last_name: tempLastName } = userSignal.value.user;
  useEffect(() => {
    const { user } = userSignal.value;
    if (user) {
      settingsProfileSignal.update({
        firstName: user.first_name,
        lastName: user.last_name,
        profilePhoto: user?.profile_picture_file?.public_url || null,
      });
    }
  }, []);

  return (
    <Row className="mx-0">
      <Row className="mx-0 mt-24 bg-gray-250 rounded">
        <Col sm={3} className="p-0 h-100">
          <div className="cursor-pointer h-100 my-16" role="button" tabIndex={0} onClick={() => handleBrowse()}>
            <input
              id="file-input"
              className="d-none"
              type="file"
              accept="image/*"
              onChange={uploadProfilePicture}
            />
            {profilePhoto ? (
              <Image src={profilePhoto} className="rounded-circle border border-primary position-relative" height="80px" width="80px" />
            ) : (
              <Button
                variant="secondary-900"
                className="rounded-circle p-24 d-flex position-relative"
              >
                <h2 className="fw-700 mb-0 text-primary">{firstName.charAt(0)}{lastName.charAt(0)}</h2>
                <div
                  className="rounded-circle position-absolute bg-secondary-300 d-flex justify-content-center align-items-center custom-shadow"
                  style={{
                    height: '30px',
                    width: '30px',
                    bottom: '-4px',
                    right: '-4px',
                  }}
                >
                  <Image src={camera} />
                </div>
              </Button>
            )}
          </div>
        </Col>
        <Col sm={9} className="p-0 my-auto py-32">
          <h2 className="fw-700 mb-8">Profile Picture</h2>
          <p className="fw-400 mb-0">Upload your Profile picture here</p>
          {profilePhoto && (
          <Button
            variant="outline-danger"
            onClick={() => deleteProfilePicture()}
            className="w-auto mt-8 btn-sm"
          >
            Delete Profile Picture
          </Button>
          )}
        </Col>
      </Row>
      <Row className="mx-0 mt-24 py-24 bg-gray-250 rounded">
        <Form.Group>
          <h2 className="fw-700 mb-16">Profile Info</h2>
          <Form.Label htmlFor="firstName"><strong>First Name</strong></Form.Label>
          <Loadable
            signal={settingsProfileSignal}
            animation="glow"
          >
            <UniversalInput
              placeholder="First Name"
              className="mb-16"
              name="firstName"
              signal={settingsProfileSignal}
            />
          </Loadable>
          <Form.Label htmlFor="lastName"><strong>Last Name</strong></Form.Label>
          <Loadable
            signal={settingsProfileSignal}
            animation="glow"
          >
            <UniversalInput
              placeholder="First Name"
              className="mb-16"
              name="lastName"
              signal={settingsProfileSignal}
            />
          </Loadable>
        </Form.Group>
      </Row>
      <Row className="mx-0 px-0">
        <Col className="ps-0">
          <Button
            variant="outline-primary"
            onClick={() => cancelProfileUpdate()}
            className="w-100 mb-16 mt-24"
            disabled={(firstName === tempFirstName && lastName === tempLastName)}
          >
            CANCEL
          </Button>
        </Col>
        <Col className="pe-0">
          <Button
            variant="primary"
            onClick={() => updateProfileData()}
            className="w-100 mb-16 mt-24"
            disabled={!firstName || !lastName || (firstName === tempFirstName && lastName === tempLastName)}
          >
            SAVE CHANGES
          </Button>
        </Col>
      </Row>
    </Row>
  );
};

export default BasicInfo;
