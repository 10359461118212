import { Row, Col, Container, Card, Image, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import fycLogo from '#images/fyclogo.svg';

const AddCustomerConfirmInfo = () => {
  const history = useHistory();
  return (
    <>
      <Row>
        <Col className="text-center">
          <h1 className="text-primary fw-700 pt-16 pb-16">Add a Customer</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 className="pb-56">Let&apos;s see if your customer already exists in our system</h2>
        </Col>
      </Row>
      <Container className="pb-48">

        <Card onClick={() => {}} style={{ maxWidth: 620 }} className="mb-24 pt-16 pb-56 mx-auto">
          <Row>
            <Col className="my-24">
              <h2 className="px-104 text-primary fw-700">Does this information look correct to you?</h2>
            </Col>
          </Row>
          <div style={{ minHeight: '1px' }} className="border-bottom border-gray-800" />
          <Row>
            <Col className="mx-80 mt-40 text-start">
              <Row className="justify-content-start me-auto">
                <Col>
                  <Image src={fycLogo} height={100} className="me-auto" />
                </Col>
              </Row>
              <Row>
                <Col className="mt-32">
                  <small className="fw-800">
                    Client/Company name
                  </small>
                </Col>
              </Row>
              <Row>
                <Col className="mt-8">
                  <h3>FYC Labs</h3>
                </Col>
              </Row>
              <Row>
                <Col className="mt-8">
                  <h3>Justin Fortier</h3>
                </Col>
              </Row>
              <Row>
                <Col className="mt-32">
                  <small className="fw-800">
                    Email Address
                  </small>
                </Col>
              </Row>
              <Row>
                <Col className="mt-8">
                  <h3>justin@fyclabs.com</h3>
                </Col>
              </Row>
              <Row>
                <Col className="mt-32">
                  <small className="fw-800">
                    Phone number
                  </small>
                </Col>
              </Row>
              <Row>
                <Col className="mt-8">
                  <h3>1.234.567.8910</h3>
                </Col>
              </Row>
              <Row>
                <Col className="mt-48">
                  <Button
                    type="button"
                    variant="primary"
                    className="w-100 py-8"
                    onClick={() => history.push('/customers')}
                  >
                    YES, I CONFIRM THIS INFORMATION
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col className="mt-24">
                  <Button
                    type="button"
                    variant="outline-primary"
                    className="w-100 py-8"
                    onClick={() => history.push('/customers')}
                  >
                    NO, I WOULD LIKE TO EDIT THIS INFO
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Container>
    </>
  );
};

export default AddCustomerConfirmInfo;
