import { Button, Col, Form, Image, Row } from 'react-bootstrap';
import signUpOption1 from '#images/signUpOption1.svg';
import signUpOption2 from '#images/signUpOption2.svg';
import { onboardingSignal } from '#signals/pages/Onboarding.signals';
import { handleOnboardingChooseAccountChange, performOnboardingAccountTypeSubmission } from '../Onboarding.helpers';

const AccountTypeForm = () => (
  <Form.Group>
    <h2 className="fw-700 text-center mb-40">Let us get to know you</h2>
    <Button
      variant="transparent"
      className={`w-100 p-0 ${onboardingSignal.value.accountType === 'smallBusiness' && 'active'}`}
      onClick={() => handleOnboardingChooseAccountChange('smallBusiness')}
    >
      <Row className="m-0">
        <Col xs={3} className="p-0 d-none d-xl-block">
          <Image src={signUpOption1} alt="Small Business Logo" className="h-100" style={{ borderRadius: '25px' }} />
        </Col>
        <Col xs={{ span: 7, offset: 2 }} className="my-auto p-0 text-start">
          <div className="base fw-800 text-dark-900">Small Business</div>
          <div className="small fw-800 text-dark-900">
            I will be managing invoices, statements, reminders, bills, and vendors for <strong>my company</strong>
            .
          </div>
        </Col>
      </Row>
    </Button>
    <Button
      variant="transparent"
      className={`w-100 p-0 mt-24 ${onboardingSignal.value.accountType === 'accountingFirm' && 'active'}`}
      onClick={() => handleOnboardingChooseAccountChange('accountingFirm')}
      disabled
    >
      <Row className="m-0">
        <Col xs={3} className="p-0 d-none d-xl-block">
          <Image src={signUpOption2} alt="Small Business Logo" style={{ borderRadius: '25px' }} />
        </Col>
        <Col xs={{ span: 8, offset: 1 }} className="py-16 px-0 text-start">
          <div className="base fw-800 text-dark-900">Accounting firm</div>
          <div className="small fw-800 text-dark-900">
            I will be managing invoices, statements, reminders, bills, and vendors for <strong>multiple companies</strong>.
          </div>
        </Col>
      </Row>
    </Button>
    <Button
      variant="primary"
      type="submit"
      onClick={performOnboardingAccountTypeSubmission}
      className="w-100 btn-lg my-48"
      disabled={!onboardingSignal.value.accountType}
    >
      NEXT
    </Button>
  </Form.Group>
);

export default AccountTypeForm;
