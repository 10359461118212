import { Button, Image, Row, Col } from 'react-bootstrap';
import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import Money from '#global/Money';
import useGetCMSData from '#hooks/useGetCMSData';
import { statementSignal } from '#signals/pages/Statement.signals';

const Welcome = ({ className }) => {
  const history = useHistory();
  const goToPaymentView = () => history.push('/payment');
  const testData = statementSignal.value;

  const { data: CMS_DATA, loading } = useGetCMSData('/statement/welcome');
  if (loading) return null;

  return (
    <section className={`position-relative ${className}`.trim()} style={{ isolation: 'isolate' }}>
      <div className="position-absolute end-0 h-100 z-index-n1">
        <Image src={CMS_DATA.imgXl.src} alt={CMS_DATA.imgXl.alt} className="h-100 d-none d-xl-block me-n64" />
        <Image src={CMS_DATA.imgLg.src} alt={CMS_DATA.imgLg.alt} className="h-100 d-none d-lg-block d-xl-none pb-96 me-n64" />
        <Image src={CMS_DATA.imgMd.src} alt={CMS_DATA.imgMd.alt} className="h-100 d-none d-md-block d-lg-none pb-120 me-n48" />
        <Image src={CMS_DATA.imgSm.src} alt={CMS_DATA.imgSm.alt} className="h-100 d-md-none me-n16 pb-152" />
      </div>
      <section className="pt-lg-56 pt-md-96 pt-72 col-xl-6 col-lg-7 col-md-7 col-8">
        <Image src={CMS_DATA.fycLogo} alt="Two People Reading" />
        <h1 className="fw-400 my-40">
          {CMS_DATA.title}<span className="fw-700">{testData.companyName}</span>!
        </h1>
        <h5 className="fw-400 lh-base mb-40 me-lg-112 col-lg-12 col-md-7">
          {CMS_DATA.subTitle}
        </h5>
        <p className="lead mb-40 d-block">
          <span className="fw-400">{CMS_DATA.lead}</span> <span className="h2 fw-700 d-inline"><Money amount={testData.totalBalance} /></span>
        </p>
      </section>
      <Row direction="vertical" className="col-xl-4 col-lg-12 mt-lg-40 mt-md-80">
        <Col xs={{ order: 'last', span: 12 }} md={{ order: 'first', span: 6 }}>
          <Button variant="outline-primary" type="submit" onClick={() => console.log('Todo')} className="w-100 py-8 h5">Payment Plans</Button>
        </Col>
        <Col xs={{ order: 'first', span: 12 }} md={{ order: 'last', span: 6 }}>
          <Button variant="primary" type="submit" onClick={goToPaymentView} className="w-100 py-8 h5">Pay Now!</Button>
        </Col>
      </Row>
    </section>
  );
};

export default memo(Welcome);
