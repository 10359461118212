import { gql } from '@apollo/client';
import firebase from 'firebase';
import { apolloClient } from '#api';
import { auth, googleProvider } from '#utils/firebase';
import { triggerErrorAlert, triggerWarningAlert, triggerSuccessAlert } from '#global/Alert/Alert';
import { validateEmail } from '#utils/validateInput';
import { globalSignal } from '#signals/Global.signals';
import { userSignal } from '#signals/User.signals';
import { authTokenSignal, loginSignal } from '#signals/Authentication.signals';

const USER_CLOSE_SESSION_MUTATION = gql`
  mutation UserSessionsClose {
    userSessionsClose {
      id
    }
  }
`;

export const handleLogout = async () => {
  try {
    globalSignal.update({
      isLoading: true,
      isLoadingMessage: 'Logging out...',
    });
    userSignal.reset();
    auth.signOut();
    localStorage.clear();
    window.location.reload();
    sessionStorage.clear();
    globalSignal.reset();
    await apolloClient.mutate({ mutation: USER_CLOSE_SESSION_MUTATION });
  } catch (err) {
    console.error('Encountered error when logging out', err);
  }
};

const USER_QUERY = gql`
  query {
    user {
      id
      email
      organizations {
        id
        organization_id
        organization {
          name
        }
      }
      first_name
      last_name
      language
      phone_number
      timezone
      updated_at
      created_at
      profile_picture_file {
        public_url
      }
    }
  }
`;

export const getUserData = async () => {
  try {
    const result = await apolloClient.query({ query: USER_QUERY });
    if (result.data) {
      await userSignal.update(result.data);
    }
  } catch (err) {
    return triggerErrorAlert(err.message);
  }
};

const parseSessionPayloadFromUser = (payload) => ({
  authToken: payload.Aa,
});

export const handleFirebaseLogin = async (fbUser) => {
  const { authToken } = parseSessionPayloadFromUser(fbUser);

  authTokenSignal.update(authToken);

  globalSignal.update({
    isSignedIn: true,
  });

  await getUserData();
};

export const handleFirebaseTokenRefresh = (authToken) => authTokenSignal.update(authToken);

export const partialEmailPasswordLoginHandler = async () => {
  try {
    globalSignal.update({
      isLoading: true,
      isLoadingMessage: 'Logging in...',
    });
    const { email, password } = loginSignal.value;
    validateEmail(email);

    if (password.length === 0) {
      throw new Error('Password cannot be blank.');
    }

    loginSignal.update({ submitting: true });
    const result = await auth.signInWithEmailAndPassword(email, password);
    await getUserData();
    triggerSuccessAlert('Successfully Logged in!');
    globalSignal.reset();
    return handleFirebaseLogin(result.user);
  } catch (err) {
    loginSignal.update({ submitting: false });
    loginSignal.reset();
    globalSignal.update({
      isLoading: false,
      isLoadingMessage: 'Loading...',
    });
    return triggerWarningAlert(err.message);
  } finally {
    loginSignal.update({ submitting: false });
    loginSignal.reset();
  }
};

export const partialGoogleLoginHandler = async () => {
  try {
    const result = await firebase.auth().signInWithPopup(googleProvider);
    return await handleFirebaseLogin(result.user);
  } catch (err) {
    return triggerErrorAlert(err.message);
  }
};

const INTUIT_AUTH_PROVIDER_GET_OAUTH_REQUEST_MUTATION = gql`
  mutation {
    userAuthProviderGetOAuthUrl(
        auth_provider_code: INTUIT
    )
  }
`;

export const handleIntuitStartLogin = async () => {
  try {
    const res = await apolloClient.mutate({ mutation: INTUIT_AUTH_PROVIDER_GET_OAUTH_REQUEST_MUTATION });
    window.location.href = res.data.userAuthProviderGetOAuthUrl;
    return res.data.userAuthProviderGetOAuthUrl;
  } catch (err) {
    return triggerErrorAlert(err.message);
  }
};

const INTUIT_GET_CUSTOM_TOKEN_MUTATION = gql`
  mutation UserAuthProviderGetOAuthToken($authProviderCode: AUTH_PROVIDER!, $authorizationToken: String!) {
    userAuthProviderGetOAuthToken(auth_provider_code: $authProviderCode, authorization_token: $authorizationToken)
  }
`;

export const performIntuitCallbackHandoff = async (url) => {
  try {
    globalSignal.update({
      isLoading: true,
      isLoadingMessage: 'Logging in...',
    });
    const result = await apolloClient.mutate({
      mutation: INTUIT_GET_CUSTOM_TOKEN_MUTATION,
      variables: {
        authProviderCode: 'INTUIT',
        authorizationToken: url,
      },
    });
    const authResult = await auth.signInWithCustomToken(result.data.userAuthProviderGetOAuthToken);
    loginSignal.update({ submitting: true });
    await getUserData();
    triggerSuccessAlert('Successfully Logged in!');
    globalSignal.reset();
    return handleFirebaseLogin(authResult.user);
  } catch (err) {
    loginSignal.update({ submitting: false });
    loginSignal.reset();
    globalSignal.update({
      isLoading: false,
      isLoadingMessage: 'Loading...',
    });
    return triggerErrorAlert(err.message);
  } finally {
    loginSignal.update({ submitting: false });
    loginSignal.reset();
  }
};
