/* eslint-disable camelcase */
import { Row, Col, Container, Card, Form, InputGroup, Button } from 'react-bootstrap';
import { useEffect, useMemo } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import SearchableDropdown from '#global/SearchableDropdown/SearchableDropdown';
import { getAllVendors } from '#views/Vendors/Vendors.helpers';
import { billFormSignal, addBillManualSignal } from '#signals/pages/Bills.signals';
import { userSignal } from '#signals/User.signals';
import { triggerWarningAlert } from '#global/Alert/Alert';
import { validateLength } from '#utils/validateInput';
import { createBill } from '../Bills.helpers';
import UniversalInput from '../../../global/Inputs/UniversalInput';
import DatePicker from '../../../global/Inputs/DatePicker';

const AddBillManualForm = () => {
  const { allVendors, selectedCompany } = addBillManualSignal.value;
  const formData = billFormSignal?.value;
  const organizationId = useMemo(() => userSignal?.value?.user?.organizations?.[0]?.organization_id, [userSignal?.value?.user?.organizations]);
  const history = useHistory();

  useEffect(() => {
    const getVendors = async () => {
      const vendors = await getAllVendors({ organizationId, take: 20, skip: 0 });
      const labeledVendors = vendors?.data?.userOrganizationVendors?.map(v => ({
        name: v.name,
        value: v.id,
        metaData: {
          email: v.email,
        },
      }));
      addBillManualSignal.update({
        allVendors: labeledVendors || [],
      });
    };

    getVendors();
  }, []);

  const handleAddBill = async () => {
    try {
      const {
        description,
        due_date,
        amount,
        organization_vendor_id,
      } = formData;

      validateLength(organization_vendor_id, 'Vendor name', 0, 100);
      validateLength(due_date, 'Due date', 0, 100);
      validateLength(amount, 'Amount due', 0, 100);
      validateLength(description, 'Description', 0, 100);

      const discountAmount = 0; // hardcoded until discount feature
      const totalAmount = (Number(amount) * 100) - discountAmount;
      const bill = await createBill({
        organizationId,
        data: {
          currency_code: 'USD',
          description,
          discount_amount: discountAmount,
          amount: Number(amount) * 100,
          total_amount: totalAmount,
          bill_date: moment(),
          due_date,
          organization_project_id: null,
          organization_vendor_id,
        },
      });
      if (bill?.data?.userOrganizationBillCreate) {
        history.push('/bills');
      }
    } catch (e) {
      console.error(e);
      triggerWarningAlert(e.message);
    }
  };

  return (
    <>
      <Row>
        <Col className="text-center">
          <h1 className="text-primary fw-700 pt-16 pb-24">Bill Details</h1>
        </Col>
      </Row>
      <Container>
        <Row>
          <Col>
            <Card style={{ maxWidth: 620 }} className="mb-24 px-80 justify-content-left mx-auto">
              <Form className="mt-80 mb-64">
                <Form.Group className="form-group text-start mb-24">
                  <Form.Label className="col-form-label fw-800 pb-0 small" htmlFor="inputDefault">Vendor name</Form.Label>
                  <SearchableDropdown
                    selected={selectedCompany}
                    options={allVendors}
                    onSelect={(e) => {
                      billFormSignal.update({
                        ...formData,
                        organization_vendor_id: e.value,
                        metadata: e.metaData,
                      });
                      addBillManualSignal.update({
                        selectedCompany: e,
                      });
                    }}
                  />
                </Form.Group>
                <Form.Group className="form-group text-start mb-24">
                  <Form.Label className="control-label fw-800 pb-0 small">Amount</Form.Label>
                  <Form.Group className="form-group">
                    <InputGroup className="input-group mb-24">
                      <InputGroup.Text className="input-group-text border-0 ps-0 pe-1 bg-transparent">$</InputGroup.Text>
                      <UniversalInput
                        value={formData.email}
                        aria-label="Amount (to the nearest dollar)"
                        name="amount"
                        signal={billFormSignal}
                      />
                    </InputGroup>
                  </Form.Group>
                </Form.Group>
                <Row>
                  <Col>
                    <Form.Group className="form-group text-start mb-24">
                      <Form.Label className="col-form-label fw-800 pb-0 small" htmlFor="inputDefault">Bill number</Form.Label>
                      <UniversalInput
                        value={formData.number}
                        name="number"
                        type="number"
                        signal={billFormSignal}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="form-group text-start mb-24">
                      <Form.Label className="control-label fw-800 pb-0 small">Due Date</Form.Label>
                      <Form.Group className="form-group">
                        <InputGroup className="input-group mb-24">
                          <DatePicker
                            name="due_date"
                            placeholder="Due Date"
                            value={formData.due_date}
                            signal={billFormSignal}
                          />
                        </InputGroup>
                      </Form.Group>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="form-group text-start mb-24">
                  <Form.Label className="col-form-label fw-800 pb-0 small" htmlFor="inputDefault">Additional Details</Form.Label>
                  <UniversalInput
                    value={formData.description}
                    name="description"
                    signal={billFormSignal}
                  />
                </Form.Group>
                <Button
                  type="button"
                  variant="primary"
                  className="w-100 py-16 mb-24"
                  onClick={handleAddBill}
                >
                  SAVE & SCHEDULE PAYMENT
                </Button>
                <Button
                  type="button"
                  variant="outline-primary border border-2 border-primary"
                  className="w-100 py-16"
                  onClick={handleAddBill}
                >
                  SAVE & PAY LATER
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddBillManualForm;
