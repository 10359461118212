import { Button, Col, Form, Row } from 'react-bootstrap';
import { setUpPersonalInfoSignal } from '#signals/pages/Onboarding.signals';
import { performPersonalInfoUpdatePartial } from './SetupPersonalInfo.helpers';
import { AuthLayout } from '#global/Layout';
import UniversalInput from '../../global/Inputs/UniversalInput';
import PhoneNumberInput from '../../global/Inputs/PhoneNumberInput';

const SetupPersonalInfo = () => {
  const { firstName, lastName, phoneNumber } = setUpPersonalInfoSignal.value;
  return (
    <AuthLayout>
      <Form.Group>
        <h2 className="fw-700 text-center mb-40">Please tell us a little about yourself</h2>
        <Row>
          <Col xs={6}>
            <Form.Label htmlFor="FirstName"><strong>First Name</strong></Form.Label>
            <UniversalInput
              className="mb-40"
              placeholder="First Name"
              name="firstName"
              value={firstName}
              signal={setUpPersonalInfoSignal}
            />
          </Col>
          <Col xs={6}>
            <Form.Label htmlFor="Last Name"><strong>Last Name</strong></Form.Label>
            <UniversalInput
              className="mb-40"
              placeholder="Last Name"
              name="lastName"
              value={lastName}
              signal={setUpPersonalInfoSignal}
            />
          </Col>
        </Row>
        <Form.Label htmlFor="Mobile Number"><strong>Mobile Number</strong></Form.Label>
        <PhoneNumberInput
          name="phoneNumber"
          placeholder="Phone number"
          value={phoneNumber}
          signal={setUpPersonalInfoSignal}
        />
        <div className="base text-dark-500">Used to verify your account and keep things secure.</div>
        <Button
          variant="primary"
          type="submit"
          onClick={() => performPersonalInfoUpdatePartial()}
          className="w-100 btn-lg my-48"
          disabled={firstName.length === 0 || lastName.length === 0 || phoneNumber.length === 0}
        >
          NEXT
        </Button>
      </Form.Group>
    </AuthLayout>
  );
};

export default SetupPersonalInfo;
