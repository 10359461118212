import { gql } from '@apollo/client';
import { settingsOrganizationSignal } from '#signals/pages/Settings.signals';
import api, { apolloClient } from '#api';
import { triggerWarningAlert, triggerSuccessAlert } from '#global/Alert/Alert';
import { authTokenSignal } from '#signals/Authentication.signals';
import { loaderSignal } from '#signals/Global.signals';
import { validateEmail } from '#utils/validateInput';

export const handleBrowse = async () => {
  const fileInput = document.getElementById('file-input');
  fileInput.click();
};

export const uploadCompanyLogo = async (event, orgId) => {
  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB

  const file = event.target.files[0];
  if (!file) {
    settingsOrganizationSignal.update({
      companyLogo: null,
    });
    return;
  }

  if (file.size > MAX_FILE_SIZE) {
    throw new Error('File size too large. Maximum size allowed is 10 MB.');
  }

  if (!file.type.startsWith('image/')) {
    throw new Error('Only image files are allowed.');
  }

  if (file) {
    const formData = new FormData();
    formData.append('picture', file);
    const res = await api.apiFormDataFetch({
      path: `/organization/${orgId}/profile-picture`,
      method: 'POST',
      formData,
      token: authTokenSignal.value,
    });
    await triggerSuccessAlert('Your Company Logo has been Updated!');
    if (res.logo_picture_file.public_url) {
      settingsOrganizationSignal.update({
        companyLogo: res.logo_picture_file.public_url,
      });
    }
  } else {
    settingsOrganizationSignal.update({
      companyLogo: null,
    });
  }
};

const SETTINGS_ORGANIZATION_REMOVE_LOGO_MUTATION = gql`
  mutation UserOrganizationUpdate($organizationId: String!) {
    userOrganizationLogoPictureRemove(organization_id: $organizationId) {
      logo_picture_file {
        public_url
      }
    }
  }
`;
export const deleteCompanyLogo = async (orgId) => {
  try {
    const removeOrgLogo = await apolloClient.mutate({
      mutation: SETTINGS_ORGANIZATION_REMOVE_LOGO_MUTATION,
      variables: {
        organizationId: orgId,
      },
    });
    if (removeOrgLogo) {
      triggerSuccessAlert('Your Company Logo has been Deleted!');
      settingsOrganizationSignal.update({
        companyLogo: null,
      });
    }
  } catch (e) {
    console.error('Error Deleting Company Logo:', e);
    triggerWarningAlert(e.message);
  }
};

const SETTINGS_ORGANIZATION_FETCH_COMPANY_QUERY = gql`
  query UserOrganization ($organizationId: String!) {
    userOrganization(organization_id: $organizationId) {
        id
        name
        email
        unique_name
        unique_code
        phone_number
        address_line_1
        address_number
        address_line_2
        address_city
        address_state
        address_zip_code
        address_country_code_iso_3
        address_lat
        address_lng
        primary_contact_name
        business_name
        business_address_line_1
        business_address_number
        business_address_line_2
        business_address_city
        business_address_state
        business_address_zip_code
        business_address_country_code_iso_3
        business_address_lat
        business_address_lng
        business_tax_code_type
        business_tax_code
        archived_at
        created_at
        updated_at
        logo_picture_file {
          public_url
        }
        current_email_verification_id
        current_phone_number_verification_id
        timezone
      }
    }
`;

export const fetchOrganizationData = async (organizationId) => {
  try {
    const companyRes = await apolloClient.query({
      query: SETTINGS_ORGANIZATION_FETCH_COMPANY_QUERY,
      variables: {
        organizationId,
      },
    });
    const userOrg = companyRes?.data?.userOrganization;

    if (userOrg && settingsOrganizationSignal.value.companyName === '') {
      settingsOrganizationSignal.update({
        companyLogo: userOrg?.logo_picture_file?.public_url || null,
        companyName: userOrg?.name || '',
        companyAddressLine1: userOrg?.address_line_1 || '',
        companyAddressLine2: userOrg?.address_line_2 || '',
        companyAddressCity: userOrg?.address_city || '',
        companyAddressState: userOrg?.address_state || '',
        companyAddressZipCode: userOrg?.address_zip_code || '',
        phoneNumber: userOrg?.phone_number || '',
        primaryContact: userOrg?.primary_contact_name || '',
        timeZone: userOrg?.timezone || '',
        legalBusinessName: userOrg?.business_name || '',
        legalBusinessAddressLine1: userOrg?.business_address_line_1 || '',
        legalBusinessAddressLine2: userOrg?.business_address_line_2 || '',
        legalBusinessAddressCity: userOrg?.business_address_city || '',
        legalBusinessAddressState: userOrg?.business_address_state || '',
        legalBusinessAddressZipCode: userOrg?.business_address_zip_code || '',
        taxIdType: userOrg?.business_tax_code_type || '',
        taxIdCode: userOrg?.business_tax_code || '',
      });
    }
    return companyRes;
  } catch (e) {
    console.error('Error fetching Company info:', e);
    triggerWarningAlert(e.message);
  }
};

const SETTINGS_ORGANIZATION_UPDATE_MUTATION = gql`
  mutation UserOrganizationUpdate($organizationId: String!, $data: UserOrganizationUpdateSchema!) {
    userOrganizationUpdate(organization_id: $organizationId, data: $data) {
      name
    }
  }
`;

export const updateCompanyData = async (orgId) => {
  try {
    const {
      companyName,
      companyAddressLine1,
      companyAddressLine2,
      companyAddressCity,
      companyAddressState,
      companyAddressZipCode,
      phoneNumber,
      primaryContact,
      timeZone,
      legalBusinessName,
      legalBusinessAddressLine1,
      legalBusinessAddressLine2,
      legalBusinessAddressCity,
      legalBusinessAddressState,
      legalBusinessAddressZipCode,
      taxIdType,
      taxIdCode,
    } = settingsOrganizationSignal.value;
    const updatedOrganization = await apolloClient.mutate({
      mutation: SETTINGS_ORGANIZATION_UPDATE_MUTATION,
      variables: {
        organizationId: orgId,
        data: {
          name: companyName,
          address_city: companyAddressCity,
          address_line_1: companyAddressLine1,
          address_line_2: companyAddressLine2,
          address_state: companyAddressState,
          address_zip_code: companyAddressZipCode,
          phone_number: phoneNumber,
          primary_contact_name: primaryContact,
          timezone: timeZone,
          business_name: legalBusinessName,
          business_address_city: legalBusinessAddressCity,
          business_address_line_1: legalBusinessAddressLine1,
          business_address_line_2: legalBusinessAddressLine2,
          business_address_state: legalBusinessAddressState,
          business_address_zip_code: legalBusinessAddressZipCode,
          business_tax_code_type: taxIdType,
          business_tax_code: taxIdCode,
        },
      },
    });
    if (updatedOrganization) {
      triggerSuccessAlert('Organization has been updated!');
    }
    return updatedOrganization;
  } catch (e) {
    console.error('Error resetting password:', e);
    triggerWarningAlert(e.message);
  }
};

// ORGANIZATION USERS
const SETTINGS_USERS_FETCH_QUERY = gql`
  query UserOrganizationCollaborators($organizationId: String!) {
    userOrganizationCollaborators(organization_id: $organizationId) {
      id
      role
      send_invoice_reminders
      payload
      archived_at
      created_at
      updated_at
      organization_id
      user_id
      user {
        id
        email
        phone_number
        first_name
        last_name
        timezone
        language
        archived_at
        created_at
        updated_at
        profile_picture_file_id
        profile_picture_file {
          public_url
        }
        current_email_verification_id
        current_phone_number_verification_id
      }
    }
  }
`;

export const fetchOrganizationUsers = async (organizationId) => {
  try {
    loaderSignal.update({
      isLoading: true,
      isLoadingMessage: 'Loading...',
    });
    const fetchedUsersRes = await apolloClient.query({
      query: SETTINGS_USERS_FETCH_QUERY,
      variables: {
        organizationId,
      },
    });
    const tempArray = [];
    if (fetchedUsersRes?.data?.userOrganizationCollaborators) {
      fetchedUsersRes?.data?.userOrganizationCollaborators.forEach((obj) => {
        tempArray.push({
          userId: obj.id,
          orgId: obj.organization_id,
          role: obj.role,
          sendInvoiceReminders: obj.send_invoice_reminders,
          email: obj.user.email,
          firstName: obj.user.first_name,
          lastName: obj.user.last_name,
          profilePicture: obj?.user?.profile_picture_file?.public_url || null,
        });
      });
      settingsOrganizationSignal.update({
        users: tempArray,
      });
    }
    loaderSignal.reset();
    return fetchedUsersRes;
  } catch (e) {
    console.error('Error fetching users:', e);
    triggerWarningAlert(e.message);
    loaderSignal.reset();
  }
};

const SETTINGS_UPDATE_USER_ROLE_MUTATION = gql`
  mutation UserOrganizationCollaboratorUpdate($data: UserOrganizationCollaboratorUpdateSchema!, $organizationUserId: String!, $organizationId: String!) {
    userOrganizationCollaboratorUpdate(data: $data, organization_user_id: $organizationUserId, organization_id: $organizationId) {
      role
    } 
  }
`;

export const updateUserRole = async (userId, orgId) => {
  try {
    loaderSignal.update({
      isLoading: true,
      isLoadingMessage: 'Loading...',
    });
    const {
      newRole,
      newSendInvoiceReminders,
    } = settingsOrganizationSignal.value;
    const updatedOrganizationRole = await apolloClient.mutate({
      mutation: SETTINGS_UPDATE_USER_ROLE_MUTATION,
      variables: {
        organizationUserId: userId,
        organizationId: orgId,
        data: {
          role: newRole,
          send_invoice_reminders: newSendInvoiceReminders,
        },
      },
    });
    if (updatedOrganizationRole) {
      const tempUsers = settingsOrganizationSignal.value.users;
      const index = settingsOrganizationSignal.value.users.findIndex(user => user.userId === userId);
      tempUsers[index] = {
        ...settingsOrganizationSignal.value.users[index],
        role: newRole,
      };
      if (tempUsers) {
        settingsOrganizationSignal.update({
          usersPage: 'users',
          users: tempUsers,
        });
      }
      triggerSuccessAlert('User has been updated!');
    }
    return updatedOrganizationRole;
  } catch (e) {
    console.error('Error updating User:', e);
    triggerWarningAlert(e.message);
    loaderSignal.reset();
  } finally {
    loaderSignal.reset();
  }
};

const SETTINGS_DELETE_COLLABORATOR = gql`
  mutation UserOrganizationCollaboratorDelete($organizationUserId: String!, $organizationId: String!) {
    userOrganizationCollaboratorDelete(organization_user_id: $organizationUserId, organization_id: $organizationId) {
      id
    }
  }
`;

export const deleteCollaborator = async (userId, orgId) => {
  try {
    const deletedUserRes = await apolloClient.mutate({
      mutation: SETTINGS_DELETE_COLLABORATOR,
      variables: {
        organizationUserId: userId,
        organizationId: orgId,
      },
    });
    if (deletedUserRes) {
      const tempUsers = settingsOrganizationSignal.value.users;
      const index = settingsOrganizationSignal.value.users.findIndex(user => user.userId === userId);
      tempUsers.splice(index, 1);
      if (tempUsers) {
        settingsOrganizationSignal.update({
          usersPage: 'users',
          users: tempUsers,
        });
      }
      triggerSuccessAlert('User has been updated!');
    }
    return deletedUserRes;
  } catch (e) {
    console.error('Error deleting the user:', e);
    triggerWarningAlert(e.message);
  }
};

const SETTINGS_INVITE_USER_MUTATION = gql`
  mutation UserOrganizationInviteCollaboratorCreate($data: UserOrganizationInviteCollaboratorCreateSchema!, $organizationId: String!) {
    userOrganizationInviteCollaboratorCreate(data: $data, organization_id: $organizationId) {
      expires_at
    }
  }
`;

export const inviteUserToOrganziation = async (orgId) => {
  try {
    const {
      newUserEmail,
      newUserRole,
    } = settingsOrganizationSignal.value;

    validateEmail(newUserEmail);
    const invitedUserRes = await apolloClient.mutate({
      mutation: SETTINGS_INVITE_USER_MUTATION,
      variables: {
        organizationId: orgId,
        data: {
          email: newUserEmail,
          role: newUserRole,
        },
      },
    });
    if (invitedUserRes) {
      triggerSuccessAlert('User has been invited!');
      settingsOrganizationSignal.update({
        newUserEmail: '',
        newUserRole: 'Select',
        usersPage: 'users',
      });
    }
    return invitedUserRes;
  } catch (e) {
    console.error('Error inviting the user:', e);
    triggerWarningAlert(e.message);
  }
};

export default {
  handleBrowse,
  uploadCompanyLogo,
  deleteCompanyLogo,
  fetchOrganizationData,
  updateCompanyData,
  fetchOrganizationUsers,
  updateUserRole,
  deleteCollaborator,
  inviteUserToOrganziation,
};
