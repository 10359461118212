import { Image, Col, NavLink } from 'react-bootstrap';
import AccruLogoNoBackground from '#images/accruLogoNoBackground.svg';

const Footer = ({ position }) => (
  <footer className={`position-${position} w-100 py-16 text-center bottom-0 m-0 bg-info-hover`}>
    <Col>
      <Image src={AccruLogoNoBackground} alt="Accru Logo No Background" />
      <NavLink className="text-primary mt-16">Terms & Conditions</NavLink>
    </Col>
  </footer>
);

export default Footer;
