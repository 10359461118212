import { LinkContainer } from 'react-router-bootstrap';
import { Nav } from 'react-bootstrap';

const ReturnToLoginRow = () => (
  <LinkContainer to="/login">
    <Nav.Link className="mb-16 text-decoration-underline p-0 text-primary fw-700 text-center"><small>Back to Login</small></Nav.Link>
  </LinkContainer>
);

export default ReturnToLoginRow;
