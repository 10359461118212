const Money = ({ amount, className }) => {
  const formattedMoney = `${'$'}${(Number(amount) / 100)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}`;

  return (
    <span className={className}>
      {formattedMoney}
    </span>
  );
};
export default Money;
