import { Row, Col, Container, Card } from 'react-bootstrap';

const AddVendorUploadSuccess = () => (
  <>
    <Row>
      <Col className="text-center">
        <h1 className="text-primary fw-700 pt-16 pb-24">Upload your Vendor List</h1>
      </Col>
    </Row>
    <Row>
      <Col>
        <h2 className="pb-56 fw-400">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h2>
      </Col>
    </Row>
    <Container>
      <Row>
        <Col>
          <Card style={{ maxWidth: 620 }} className="mb-24 px-80 py-64 justify-content-center mx-auto">
            <h2 className="fw-700 mb-40 px-56 pt-96">Your vendor list is now in our system!</h2>
          </Card>
        </Col>
      </Row>
    </Container>
  </>
);

export default AddVendorUploadSuccess;
