import { Row, Col, Image } from 'react-bootstrap';
import paymentVisa from '#images/paymentVisa.svg';
import paymentMasterCard from '#images/paymentMasterCard.svg';
import paymentDiscover from '#images/paymentDiscover.svg';
import paymentAmEx from '#images/paymentAmEx.svg';
import lockAlt from '#images/lock-alt.svg';

const SecureFooter = () => (
  <>
    <Row>
      <Col sm={12} xs={12}>
        <Image src={lockAlt} alt="lock" className="me-16" />
        <small className="text-dark-900 fw-400">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Commodi tenetur quasi dolores?</small>
      </Col>
    </Row>
    <Row className="my-16 mx-0 d-flex justify-content-center align-items-center">
      <Col sm={3} xs={3} xxl={3} className="w-auto">
        <Image src={paymentVisa} alt="Visa Logo" />
      </Col>
      <Col sm={2} xs={2} xxl={2} className="w-auto">
        <Image src={paymentMasterCard} alt="Mastercard Logo" />
      </Col>
      <Col sm={4} xs={4} xxl={4} className="w-auto">
        <Image src={paymentDiscover} alt="Discover Logo" />
      </Col>
      <Col sm={3} xs={3} xxl={3} className="w-auto">
        <Image src={paymentAmEx} alt="American Express Logo" />
      </Col>
    </Row>
  </>
);

export default SecureFooter;
