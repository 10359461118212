import { gql } from '@apollo/client';
import { apolloClient } from '#api';
import { triggerWarningAlert } from '#global/Alert/Alert';

const INVOICE_QUERY_FRAGMENT = gql`
  fragment OrganizationInvoiceFields on OrganizationInvoice {
    id
    unique_code
    number
    currency_code
    description
    payload
    amount
    tax_amount
    discount_amount
    total_amount
    invoice_date
    due_date
    customer_email
    customer_address_line_1
    customer_address_number
    customer_address_line_2
    customer_address_city
    customer_address_state
    customer_address_zip_code
    customer_address_country_code_iso_3
    customer_address_lat
    customer_address_lng
    archived_at
    created_at
    updated_at

    organization_id
    organization {
        name
    }
    organization_customer_id
    organization_customer {
        name
        email
    }

    organization_acct_provider_conn_invoices {
        id
        code
        payload
        last_sync_at
        last_sync_success
        last_sync_id

        organization_invoice_id
        
        organization_acct_provider_conn_id
        organization_acct_provider_conn {
            acct_provider_code
        }
    }

    file_id
    file {
        public_url
    }
    
    has_sync_errors
    
    paid_amount
    paid_at

    status

    transaction_links {
        id
        amount
        item_type
        item_id

        created_at
        updated_at

        organization_transaction_id
        organization_transaction {
            id
        }
    }
  }
`;

const INVOICE_CREATE_MUTATION = gql`
  mutation userOrganizationInvoiceCreate($organizationId: String!, $data: UserOrganizationInvoiceSchema!) {
    userOrganizationInvoiceCreate(organization_id: $organizationId, data: $data) {
      ...OrganizationInvoiceFields
    }
  }

  ${INVOICE_QUERY_FRAGMENT}
`;

const INVOICES_GET_ALL_QUERY = gql`
  query(
    $organizationId: String!,
    $organizationCustomerId: String,

    $currency: CURRENCY,
    $startDate: DateTime,
    $endDate: DateTime,
    $status: INVOICE_STATUS

    $after: String,
    $first: Int,

    $before: String,
    $last: Int,

    $skip: Int,
    $take: Int,

    $sorting: [SortingFieldSchema!]
  ) {
    userOrganizationInvoices(
      organization_id: $organizationId
      organization_customer_id: $organizationCustomerId

      currency: $currency
      start_date: $startDate
      end_date: $endDate
      status: $status
      
      after: $after
      first: $first

      before: $before
      last: $last

      skip: $skip
      take: $take

      sorting: $sorting
    ) {
      ...OrganizationInvoiceFields
    }
  }

  ${INVOICE_QUERY_FRAGMENT}
`;

const INVOICES_GET_STATEMENT_QUERY = gql`
  query GetOrganizationInvoiceStatement(
    $organizationId: String!,
    $organizationCustomerId: String,

    $currency: CURRENCY,
    $startDate: DateTime,
    $endDate: DateTime,
    $status: INVOICE_STATUS

    $after: ConnectionCursor,
    $first: Int,

    $before: ConnectionCursor,
    $last: Int,

    $skip: Int,
    $take: Int,

    $sorting: [SortingFieldSchema!]
  ) {
    userOrganizationInvoiceStatement(
      organization_id: $organizationId
      organization_customer_id: $organizationCustomerId

      currency: $currency
      start_date: $startDate
      end_date: $endDate
      status: $status
      
      after: $after
      first: $first

      before: $before
      last: $last

      skip: $skip
      take: $take

      sorting: $sorting
    ) {
        vendor_organization_id
        vendor_organization {
            id
            name
        }
        vendor_organization_customer_id
        vendor_organization_customer {
            id
            name
        }
        customer_organization_id
        customer_organization {
            id
            name
        }
        data {
            totalCount
            edges {
                cursor
                node {
                    ...OrganizationInvoiceFields
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasPreviousPage
                hasNextPage
            }
        }
        total_amount
        paid_amount
        overdue_amount
        start_date
        end_date
        currency
        status
    }
  }

  ${INVOICE_QUERY_FRAGMENT}
`;

const INVOICE_GET_QUERY = gql`
  query GetOrganizationInvoice(
    $organizationId: String!,
    $organizationInvoiceId: String!,
  ) {
    userOrganizationInvoice(
      organization_id: $organizationId
      organization_invoice_id: $organizationInvoiceId
    ) {
      ...OrganizationInvoiceFields
      }
  }

  ${INVOICE_QUERY_FRAGMENT}
`;

const SEND_INVOICE_EMAIL_MUTATION = gql`
  mutation SendInvoiceEmail(
    $organizationId: String!,
    $organizationInvoiceId: String!

    $sendToPrimaryContact: Boolean!,
    $contactIds: [String!]!,
    ) {
      userOrganizationCustomerSendInvoiceEmail(
        organization_id: $organizationId
        organization_invoice_id: $organizationInvoiceId

        send_to_primary_contact: $sendToPrimaryContact,
        contact_ids: $contactIds,
      )
    }
`;

/**
  * @param {Object} params
  * @param {String} params.organizationId
  * @param {String} params.organizationInvoiceId
  * @param {Boolean} params.sendToPrimaryContact
  * @param {Array} params.contactIds
  * @returns {Promise<*>}
 */
export const sendInvoiceEmail = async (params) => {
  try {
    const response = await apolloClient.mutate({
      mutation: SEND_INVOICE_EMAIL_MUTATION,
      variables: {
        organizationId: params.organizationId,
        organizationInvoiceId: params.organizationInvoiceId,
        sendToPrimaryContact: params.sendToPrimaryContact,
        contactIds: params.contactIds,
      },
    });
    return response;
  } catch (e) {
    console.error('Error in send invoice email:', e);
    triggerWarningAlert(e.message);
    throw e;
  }
};

/**
 * @param {Object} params
 * @param {String} params.organizationId
 * @param {String} params.organizationCustomerId
 * @param {String} params.currency
 * @param {Date} params.startDate
 * @param {Date} params.endDate
 * @param {String} params.status
 * @param {String} params.after
 * @param {Number} params.first
 * @param {String} params.before
 * @param {Number} params.last
 * @param {Number} params.skip
 * @param {Number} params.take
 * @param {Array} params.sorting
 * @param {String} params.sorting.field
 * @param {String} params.sorting.order
 * @returns {Promise<*>}
 */
export const getInvoiceStatement = async (params) => {
  try {
    const response = await apolloClient.query({
      query: INVOICES_GET_STATEMENT_QUERY,
      variables: {
        organizationId: params.organizationId,
        organizationCustomerId: params.organizationCustomerId,

        currency: params.currency,
        startDate: params.startDate,
        endDate: params.endDate,
        status: params.status,

        after: params.after,
        first: params.first,

        before: params.before,
        last: params.last,

        skip: params.skip,
        take: params.take,

        sorting: params.sorting,
      },
    });
    return response;
  } catch (e) {
    console.error('Error in get all invoices:', e);
    triggerWarningAlert(e.message);
    throw e;
  }
};

/**
 * @param {Object} params
 * @param {String} params.organizationInvoiceId
 * @param {String} params.organizationId
 */
export const getInvoice = async (params) => {
  try {
    const response = await apolloClient.query({
      query: INVOICE_GET_QUERY,
      variables: {
        organizationId: params.organizationId,
        organizationInvoiceId: params.organizationInvoiceId,
      },
    });
    return response;
  } catch (e) {
    console.error(`Error in get invoice ${params.organizationInvoiceId}:`, e);
    triggerWarningAlert(e.message);
    throw e;
  }
};

export const getAllInvoices = async ({ organizationId, skip, take }) => {
  try {
    const allInvoices = await apolloClient.query({
      query: INVOICES_GET_ALL_QUERY,
      variables: {
        organizationId,
        skip,
        take,
      },
    });
    return allInvoices;
  } catch (e) {
    console.error('Error in get all invoices:', e);
    triggerWarningAlert(e.message);
  }
};

export const createInvoice = async ({ organizationId, data }) => {
  try {
    const newInvoice = await apolloClient.mutate({
      mutation: INVOICE_CREATE_MUTATION,
      variables: {
        organizationId,
        data,
      },
    });
    return newInvoice;
  } catch (e) {
    console.error('Error while creating invoice:', e);
    triggerWarningAlert(e.message);
  }
};

const CONNECT_INTUIT_MUTATION = gql`
  mutation userOrganizationAcctProviderConnect ($organizationId: String!, $accountProvider: ACCT_PROVIDER!, $url: String!, $automaticPull: Boolean!, $automaticPush: Boolean!) {
    userOrganizationAcctProviderConnect (
        organization_id: $organizationId
        acct_provider_code: $accountProvider
        url: $url
        automatic_pull_enabled: $automaticPull
        automatic_push_enabled: $automaticPush
    ) {
        id
        code
        conn_expires_at
        created_at
        updated_at
        organization_id
        acct_provider_code
    }
  }
`;
export const connectIntuit = async ({ organizationId, url }) => {
  try {
    const quickbooksConnectResult = await apolloClient.mutate({
      mutation: CONNECT_INTUIT_MUTATION,
      variables: {
        organizationId,
        accountProvider: 'QUICKBOOKS',
        url,
        automaticPull: true,
        automaticPush: true,
      },
    });
    return quickbooksConnectResult;
  } catch (e) {
    console.error('Error when connecting quickbooks:', e);
    triggerWarningAlert(e.message);
  }
};

const GET_ALL_CUSTOMERS_QUERY = gql`
  query userOrganizationCustomers($organizationId: String!) {
    userOrganizationCustomers(organization_id: $organizationId, skip: 0, take: 10) {
      id
      is_active
      unique_code
      email
      name
    }
  }
`;

export const getAllCustomers = async ({ organizationId }) => { // temporary until we implement customers, so that correct options show up in dropdown
  try {
    const allCustomers = await apolloClient.mutate({
      mutation: GET_ALL_CUSTOMERS_QUERY,
      variables: {
        organizationId,
      },
    });
    return allCustomers;
  } catch (e) {
    console.error('Error when getting all customers:', e);
    triggerWarningAlert(e.message);
  }
};

const GET_INTUIT_OAUTH_MUTATION = gql`
  mutation userOrganizationAcctProviderGetOAuthUrl ($organizationId: String!, $accountProvider: ACCT_PROVIDER!) {
    userOrganizationAcctProviderGetOAuthUrl(organization_id: $organizationId, acct_provider_code: $accountProvider)
  }
`;
export const getIntuitOathUrl = async ({ organizationId }) => {
  try {
    const OAuthResult = await apolloClient.mutate({
      mutation: GET_INTUIT_OAUTH_MUTATION,
      variables: {
        organizationId,
        accountProvider: 'QUICKBOOKS',
      },
    });
    return OAuthResult;
  } catch (e) {
    console.error('Error when getting oAuth url:', e);
    triggerWarningAlert(e.message);
  }
};

const GET_PUSH_OPTIONS_MUTATION = gql`
  mutation userOrganizationAcctProviderConnConfigurationPushOptionsGet ($organizationId: String!, $accountProvider: ACCT_PROVIDER!) {
    userOrganizationAcctProviderConnConfigurationPushOptionsGet(
        organization_id: $organizationId
        acct_provider_code: $accountProvider
    )
  }
`;
export const getIntuitPushOptions = async ({ organizationId }) => {
  try {
    const pushOptions = await apolloClient.mutate({
      mutation: GET_PUSH_OPTIONS_MUTATION,
      variables: {
        organizationId,
        accountProvider: 'QUICKBOOKS',
      },
    });
    return pushOptions;
  } catch (e) {
    console.error('Error when getting push options:', e);
    triggerWarningAlert(e.message);
  }
};

const SET_PUSH_OPTIONS_MUTATION = gql`
  mutation userOrganizationAcctProviderConnConfigurationPushOptionsSet ($organizationId: String!, $accountProvider: ACCT_PROVIDER!, $payload: JSON!) {
    userOrganizationAcctProviderConnConfigurationPushOptionsSet(
        organization_id: $organizationId
        acct_provider_code: $accountProvider
        payload: $payload
    ) {
        id
        code
        payload
        conn_expires_at
        organization_id
        acct_provider_code
    }
  }
`;
export const setIntuitPushOptions = async ({ organizationId, selectedPushItem, selectedPushAccount }) => {
  try {
    const pushOptionsResult = await apolloClient.mutate({
      mutation: SET_PUSH_OPTIONS_MUTATION,
      variables: {
        organizationId,
        accountProvider: 'QUICKBOOKS',
        payload: {
          default_invoice_item: selectedPushItem,
          default_invoice_expense_account: selectedPushAccount,
        },
      },
    });
    return pushOptionsResult;
  } catch (e) {
    console.error('error setting push options: ', e);
    triggerWarningAlert(e.message);
  }
};
