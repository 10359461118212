import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { signal } from '@preact/signals-react';
import { setActiveForm } from '#views/Statement/Statement.helpers';
import UniversalInput from '../../../global/Inputs/UniversalInput';
import DatePicker from '../../../global/Inputs/DatePicker';

const newCheckSentInfoSignal = signal({
  checkNumber: '',
  deliveryDate: '',
});

const CheckSentInfo = ({ className }) => {
  const handleSubmit = () => {
    setActiveForm('Processing');
  };
  const handleCancel = () => {
    setActiveForm('ChoosePayment');
  };

  return (
    <Container className={className}>
      <Row className="p-24 pb-8 border-2 border-gray-800 mb-8 border-bottom">
        <h2 className="mt-24 fw-700 text-primary">Confirm Check Sent for</h2>
      </Row>
      <div className="d-flex flex-column mb-3">
        <Form className="px-32 py-16">
          <Form.Group className="mt-16 mb-24">
            <Form.Label className="d-flex mb-0" htmlFor="checkNumber">
              <p className="lead fw-800 mb-0">Check number</p>
            </Form.Label>
            <UniversalInput
              name="checkNumber"
              value={newCheckSentInfoSignal?.value?.checkNumber}
              className="mt-8 mb-32"
              signal={newCheckSentInfoSignal}
            />
          </Form.Group>
          <Form.Group className="my-24">
            <Form.Label className="d-flex mb-0" htmlFor="deliveryDate">
              <p className="lead fw-800 mb-0">Expected date of delivery</p>
            </Form.Label>
            <Row className="pe-16">
              <Col className="pe-0">
                <DatePicker
                  className="mt-8"
                  name="deliveryDate"
                  placeholder="Delivery Date"
                  value={newCheckSentInfoSignal?.value?.deliveryDate}
                  signal={newCheckSentInfoSignal}
                />
              </Col>
            </Row>
          </Form.Group>
        </Form>
        <Row className="d-flex justify-content-start align-content-center mb-40 py-16 px-32">
          <Col sm={8} xs={8} className="d-flex justify-content-start align-items-center">
            <Button
              variant="primary-hover px-16 py-8"
              className="flex-grow-1 px-40"
              onClick={() => handleSubmit()}
              disabled={
                !newCheckSentInfoSignal?.value?.checkNumber ||
                !newCheckSentInfoSignal?.value?.deliveryDate
              }
            >
              <small className="text-primary-100 me-8">MARK AS SENT</small>
            </Button>
          </Col>
          <Col sm={4} xs={4} className="d-flex justify-content-end ps-0">
            <Button
              variant="outline-primary"
              label="CANCEL"
              className="text-dark flex-grow-1 py-8"
              onClick={handleCancel}
            >
              <small className="text-dark">CANCEL</small>
            </Button>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default CheckSentInfo;
