import { useCallback } from 'react';
import { Button, Col, Image, Nav, Row } from 'react-bootstrap';
import ellipse277 from '#images/ellipse277.svg';

const BillHero = ({ data }) => {
  const handleButton = useCallback(() => {});

  return (
    <Col md={12} lg={6} className="mb-sm-48">
      <Row className="pb-16">
        <Image src={ellipse277} className="w-auto h-auto" />
      </Row>
      <Row>
        <h1 className="fw-400 text-dark">{data.organization_vendor.name}</h1>
      </Row>
      <Row className="pe-24 me-16">
        <p className="fw-400 text-dark">{data.description || 'No description provided.'}</p>
      </Row>
      <div className="mb-48 mb-lg-32 mt-32 mt-lg-16 d-flex gap-16">
        <Button
          className="fw-400 px-40 px-md-24 py-8 border border-2 border-primary pill-btn-height"
          style={{ width: '188px' }}
          disabled
          onClick={() => handleButton()}
        >
          SET UP ACCRU PAY
        </Button>
        <Button
          variant="outline-primary"
          className="px-40 px-lg-24 py-8 fw-400 border border-2 border-primary text-dark pill-btn-height"
          style={{ width: '188px' }}
          disabled
          onClick={() => handleButton()}
        >
          PAYMENT PLANS
        </Button>
      </div>
      <Row>
        <h5 className="fw-700 text-dark">Why enroll in auto pay?</h5>
      </Row>
      <Row className="mb-8">
        <Col className="pe-0">
          <p className="lead fw-400 text-dark d-flex">Set it and forget!
            <Nav.Link href="TODO: Add Route" className="lead fw-400 text-primary text-decoration-underline ms-8">Learn more about the benefits of autopay</Nav.Link>
          </p>
        </Col>
      </Row>
      <Row className="mt-8">
        <h5 className="fw-700 text-dark pe-0">Why set up a payment plan?</h5>
      </Row>
      <Row>
        <Col className="pe-0">
          <p className="lead fw-400 text-dark m-0">We get it... sometimes you need to budget your money and paying a bill in full is not the best for your business.
            <Nav.Link href="TODO: Add Route" className="lead fw-400 text-primary text-decoration-underline">Learn more about the benefits of setting up payment plans</Nav.Link>
          </p>
        </Col>
      </Row>
    </Col>
  );
};

export default BillHero;
