import { gql } from '@apollo/client';
import { apolloClient } from '#api';
import { triggerWarningAlert } from '#global/Alert/Alert';
import { settingsLogsSignal } from '#signals/pages/Settings.signals';

// ---- LOGS SETTINGS ---- //
const SETTINGS_LOGS_QUERY = gql`
  query userOrganizationAcctProviderSynchronizations ($organizationId: String!, $skip: Int!, $take: Int!) {
    userOrganizationAcctProviderSynchronizations(
        organization_id: $organizationId,
        skip: $skip,
        take: $take
        sorting: [
          {
              field: "updated_at"
              order: DESC
          },
          {
              field: "created_at"
              order: DESC
          },
         ]
      ) {
        id
        trigger_code
        pull
        push
        payload
        errors
        started_at
        finished_at
        failed_at
        succeeded_at
        local_read_success_count
        local_create_success_count
        local_update_success_count
        local_delete_success_count
        local_read_failure_count
        local_create_failure_count
        local_update_failure_count
        local_delete_failure_count
        remote_read_success_count
        remote_create_success_count
        remote_update_success_count
        remote_delete_success_count
        remote_read_failure_count
        remote_create_failure_count
        remote_update_failure_count
        remote_delete_failure_count
        sync_cluster_code
        status_description
        created_at
        updated_at
        organization_id
        organization_acct_provider_conn_id
      }
    }
`;

export const getSyncHistory = async ({ organizationId, skip, take }) => {
  try {
    const syncData = await apolloClient.query({
      query: SETTINGS_LOGS_QUERY,
      variables: {
        organizationId,
        take,
        skip,
      },
    });
    return syncData;
  } catch (e) {
    console.error('Error getting Sync History:', e);
    triggerWarningAlert(e.message);
  }
};

export const filterSync = async (type) => {
  const { originalLogs } = settingsLogsSignal.value;
  const tempArray = [];
  originalLogs.forEach((obj) => {
    if (obj.status === type) {
      tempArray.push(obj);
    }
  });
  settingsLogsSignal.update({
    logs: type === 'NONE' ? originalLogs : tempArray,
  });
};

export const sortFlip = async () => {
  const { logs } = settingsLogsSignal.value;

  settingsLogsSignal.update({
    logs: logs.slice().reverse(),
  });
};

export default {
  getSyncHistory,
  filterSync,
  sortFlip,
};
