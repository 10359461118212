import { Button, Col, Row } from 'react-bootstrap';
import { acceptInviteSignal } from '#signals/pages/Onboarding.signals';
import { softAcceptOrgInvite } from '#views/AcceptInvite/AcceptInvite.helpers';

const ViewInvitation = () => (
  <div className="text-center">
    <h3>You have been invited to join organization <strong>{acceptInviteSignal.value.organization_name}</strong></h3>
    <p>Org ID: {acceptInviteSignal.value.organization_id}</p>
    <Row className="d-flex align-items-center justify-content-center my-24 mx-0">
      <Col xs={6} className="d-flex justify-content-around pt-0 ps-0">
        <Button
          variant="outline-primary"
          type="submit"
          onClick={() => console.log('Reject TODO')}
          className="w-100 h-100"
        >
          Reject
        </Button>
      </Col>
      <Col xs={6} className="d-flex justify-content-around pe-0">
        <Button
          variant="primary"
          type="submit"
          onClick={() => softAcceptOrgInvite()}
          className="w-100 h-100"
        >
          Accept
        </Button>
      </Col>
    </Row>
  </div>
);

export default ViewInvitation;
