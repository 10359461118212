import { Row, Col, Container, Card, Image } from 'react-bootstrap';
import plus from '#images/plus.svg';
import cycle from '#images/cycle.svg';

const AddBillMethod = ({ handleNext }) => (
  <>
    <Row>
      <Col className="text-center">
        <h1 className="text-primary fw-700 pt-16 pb-24">How do you want to add bill?</h1>
      </Col>
    </Row>
    <Row>
      <Col>
        <h2 className="pb-56">To send payment to your clients, first add your bills to accru. </h2>
      </Col>
    </Row>
    <Container className="pb-48">
      <Card onClick={() => handleNext({ current: 'method', next: 'syncSelect' })} style={{ maxWidth: 620 }} className="cursor-pointer py-32 mb-24 justify-content-left mx-auto">
        <Container>
          <Row className="d-flex justify-content-left align-items-center px-16">
            <Col xs={2}>
              <Image width={54} src={cycle} />
            </Col>
            <Col xs={9} className="text-start text-dark-900">
              <h5 className="fw-700">Connect your accounting Software</h5>
              <p className="base mb-0">Import and sync all your bills</p>
            </Col>
          </Row>
        </Container>
      </Card>
      <Card onClick={() => handleNext({ current: 'method', next: 'form' })} style={{ maxWidth: 620 }} className="cursor-pointer py-32 mb-24 justify-content-left mx-auto">
        <Container>
          <Row className="d-flex justify-content-left align-items-center px-16">
            <Col xs={2}>
              <Image width={54} src={plus} />
            </Col>
            <Col xs={9} className="text-start text-dark-900">
              <h5 className="fw-700">Add bills yourself</h5>
              <p className="base mb-0">Upload or add bills manually</p>
            </Col>
          </Row>
        </Container>
      </Card>
    </Container>
  </>
);

export default AddBillMethod;
