import { Button, Col, Form, Row } from 'react-bootstrap';
import { performOnboardingPersonalInfoSubmission, updateInvitedUserProfile } from '../Onboarding.helpers';
import { onboardingSignal } from '#signals/pages/Onboarding.signals';
import PhoneNumberInput from '../../../global/Inputs/PhoneNumberInput';
import UniversalInput from '../../../global/Inputs/UniversalInput';

const PersonalInfoForm = () => {
  const { firstName, lastName, phoneNumber, invitedUser } = onboardingSignal.value;
  return (
    <Form.Group>
      <h2 className="fw-700 text-center mb-40">Please tell us a little about yourself</h2>
      <Row>
        <Col xs={6}>
          <Form.Label htmlFor="FirstName"><strong>First Name</strong></Form.Label>
          <UniversalInput
            name="firstName"
            value={firstName}
            className="mb-40"
            signal={onboardingSignal}
          />
        </Col>
        <Col xs={6}>
          <Form.Label htmlFor="Last Name"><strong>Last Name</strong></Form.Label>
          <UniversalInput
            name="lastName"
            value={lastName}
            className="mb-40"
            signal={onboardingSignal}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Form.Label htmlFor="Phone Number"><strong>Phone Number</strong></Form.Label>
          <PhoneNumberInput
            name="phoneNumber"
            value={phoneNumber}
            signal={onboardingSignal}
          />
          <div className="base text-dark-500">Used to verify your account and keep things secure.</div>
        </Col>
      </Row>
      <Button
        variant="primary"
        type="submit"
        onClick={invitedUser ?
          () => updateInvitedUserProfile()
          : () => performOnboardingPersonalInfoSubmission()}
        className="w-100 btn-lg my-48"
        disabled={firstName?.length === 0 || lastName?.length === 0 || phoneNumber?.length === 0}
      >
        NEXT
      </Button>
    </Form.Group>
  );
};

export default PersonalInfoForm;
