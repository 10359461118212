import { Button, Card, Col, Form, Image, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { settingsReminderSignal } from '#signals/pages/Settings.signals';
import plusSign from '#images/plusSign.svg';
import { userSignal } from '#signals/User.signals';
import { fetchReminderSettings, saveChangesReminder } from '../ReminderSettings.helpers';
import UniversalInput from '../../../../../global/Inputs/UniversalInput';

const InvoiceReminders = () => {
  const { automaticInvoiceReminders, invoiceRemindersArray, deleteInvoiceRemindersArray } = settingsReminderSignal.value;
  useEffect(async () => {
    const { user } = userSignal.value;
    if (user) {
      await fetchReminderSettings(user.organizations[0].organization_id);
    }
  }, []);
  return (
    <Row className="mx-0">
      <Row className="mx-0 mt-24 bg-gray-250 rounded py-16">
        <Row className="m-0 px-0">
          <Col sm={10} className="p-0">
            <h2 className="mb-0 fw-700">Automatic Invoice Reminders</h2>
          </Col>
          <Col sm={2} className="p-0 d-flex justify-content-center align-items-center">
            <Form>
              <OverlayTrigger
                key="overlay-trigger"
                placement="bottom"
                overlay={(
                  <Tooltip id="tooltip-bottom" className={`${automaticInvoiceReminders && invoiceRemindersArray?.length > 0 ? 'd-block' : 'd-none'}`}>
                    You must remove all reminders to disable Automatic Invoice Reminders
                  </Tooltip>
                )}
              >
                <Form.Check
                  className="form-check-radio-custom"
                  type="switch"
                  name="automaticInvoiceReminders"
                  checked={automaticInvoiceReminders}
                  onChange={() => settingsReminderSignal.update({
                    ...settingsReminderSignal.value,
                    automaticInvoiceReminders: automaticInvoiceReminders && invoiceRemindersArray?.length > 0 ? automaticInvoiceReminders : !automaticInvoiceReminders,
                  })}
                />
              </OverlayTrigger>
            </Form>
          </Col>
        </Row>
        {!automaticInvoiceReminders && (
          <Row className="m-0 pt-8">
            <p className="p-0 m-0 text-dark-900">Automatic invoice reminders apply to all new invoices. Turning off automatic invoice reminders removes them from all invoices</p>
          </Row>
        )}
      </Row>
      {automaticInvoiceReminders && (
        <>
          <Row className="mx-0 mt-24 bg-gray-250 rounded py-16">
            <h2 className="mb-0 p-0 fw-700">Reminders</h2>
            {invoiceRemindersArray && invoiceRemindersArray.map((obj, idx) => (
              <Card className="border-gray-100 p-16 mt-16" key={idx}>
                <Row className="m-0 d-flex justify-content-between">
                  <Col className="p-0 d-flex justify-content-start align-items-center">
                    <h3 className="mb-0 fw-700">Reminder {idx + 1}</h3>
                  </Col>
                  <Col className="p-0 d-flex justify-content-end align-items-center">
                    <Button
                      className="mb-0 text-danger"
                      variant="eye"
                      onClick={() => {
                        const updatedArray = [...invoiceRemindersArray];
                        const deleteArray = [...deleteInvoiceRemindersArray];
                        updatedArray.splice(idx, 1);
                        if (obj?.reminderId) {
                          deleteArray.push(obj?.reminderId);
                        }
                        settingsReminderSignal.update({
                          ...settingsReminderSignal.value,
                          invoiceRemindersArray: updatedArray,
                          deleteInvoiceRemindersArray: deleteArray,
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faXmark} /> Remove
                    </Button>
                  </Col>
                </Row>
                {obj?.reminderId ? (
                  <p className="p-0 mt-auto mb-0">
                    {obj.days} day{obj.days > 1 && '(s)'} before due date
                  </p>
                ) : (
                  <Row className="m-0">
                    <Col sm={2} className="p-0">
                      <UniversalInput
                        placeholder="1"
                        type="number"
                        name="days"
                        value={obj.days}
                        customOnChange={(e) => {
                          if (e.target.value > 0) {
                            const updatedObject = { ...invoiceRemindersArray[idx] };
                            updatedObject.days = Number(e.target.value);
                            const updatedArray = [...invoiceRemindersArray];
                            updatedArray[idx] = updatedObject;
                            settingsReminderSignal.update({
                              ...settingsReminderSignal.value,
                              invoiceRemindersArray: updatedArray,
                            });
                          }
                        }}
                      />
                    </Col>
                    <Col sm={9} className="p-0 d-flex align-items-bottom">
                      <p className="p-0 mt-auto mb-0">
                        day(s) before due date
                      </p>
                    </Col>
                  </Row>
                )}
              </Card>
            ))}
            <Row className="mx-0 mt-8 d-flex justify-content-end">
              <Button
                className="mb-0 w-auto"
                variant="eye"
                onClick={() => {
                  const updatedArray = [...invoiceRemindersArray];
                  updatedArray.push({
                    days: 1,
                  });
                  settingsReminderSignal.update({
                    ...settingsReminderSignal.value,
                    invoiceRemindersArray: updatedArray,
                  });
                }}
              >
                <Image src={plusSign} alt="plus-sign" className="me-8" />{invoiceRemindersArray && invoiceRemindersArray.length > 0 ? 'Add another' : 'Create new'} reminder
              </Button>
            </Row>
          </Row>
          {/* NOT MVP */}
          {/* <Row className="mx-0 mt-24 bg-gray-250 rounded py-16">
            <Row className="m-0 p-0">
              <Col sm={10} className="p-0">
                <h2 className="mb-0 fw-700">Allow Customers to Snooze</h2>
              </Col>
              <Col sm={2} className="p-0 d-flex justify-content-center align-items-center">
                <Form>
                  <Form.Check
                    className="form-check-radio-custom"
                    type="switch"
                    name="allowCustomersToSnooze"
                    checked={allowCustomersToSnooze}
                    onChange={() => settingsReminderSignal.update({
                      ...settingsReminderSignal.value,
                      allowCustomersToSnooze: !allowCustomersToSnooze,
                    })}
                  />
                </Form>
              </Col>
            </Row>
            <Row className="m-0 px-0 pt-8">
              <p className="p-0 m-0 text-dark-900">Accru will not send any reminders for invoices where your customers have opted to snooze notifications for 14 days.</p>
              <p className="p-0 m-0 mt-16 text-dark-900">You will still be able to manually send reminders from the invoices interface</p>
            </Row>
          </Row> */}
          <Row className="mx-0 px-0 mb-40">
            <Col className="ps-0">
              <Button
                variant="outline-primary"
                onClick={() => window.location.reload()}
                className="w-100 mb-16 mt-24"
              >
                CANCEL
              </Button>
            </Col>
            <Col className="pe-0">
              <Button
                variant="primary"
                onClick={() => saveChangesReminder(userSignal.value?.user?.organizations[0]?.organization_id)}
                className="w-100 mb-16 mt-24"
                disabled={invoiceRemindersArray.length === 0 && deleteInvoiceRemindersArray.length === 0}
              >
                SAVE CHANGES
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Row>
  );
};

export default InvoiceReminders;
