/* eslint-disable no-confusing-arrow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Card, Row, Image, Col, Modal, Button, Dropdown } from 'react-bootstrap';
import { useEffect } from 'react';
import threeDotsHorizontal from '#images/threeDotsHorizontal.svg';
import addMore from '#images/addMore.svg';
import { createContact, updateContact, createCustomerContact, updateCustomerContact, deleteCustomerContact, deleteContact } from './VendorClientSidePanel.helpers';
import { userSignal } from '#signals/User.signals';
import { vendorClientContactSignal } from '#signals/pages/Vendors.signals';
import UniversalInput from '../Inputs/UniversalInput';
import PhoneNumberInput from '../Inputs/PhoneNumberInput';

const formatPhoneDisplay = (str) => {
  if (!str) return '';
  const phone = str.replace('+1', '');
  return `${phone.substring(0, 3)}.${phone.substring(3, 6)}.${phone.substring(6, 10)}`;
};

const VendorClientContact = ({ selectedObj, isVendor }) => {
  const {
    contacts,
    isModalOpen,
    editingContact,
    formData,
  } = vendorClientContactSignal.value;
  const organizationId = userSignal?.value?.user?.organizations?.[0]?.organization_id;

  useEffect(() => {
    vendorClientContactSignal.update({
      contacts: selectedObj.node.contacts,
    });
  }, [selectedObj]);

  const handleCloseModal = () => {
    vendorClientContactSignal.update({
      formData: {},
      isModalOpen: false,
      editingContact: null,
    });
  };

  const handleCreateContact = async () => {
    handleCloseModal();
    const { email, phone, name, isPrimary } = formData;
    let data;
    if (isVendor) {
      data = {
        email,
        name,
        is_default: !!isPrimary,
      };
    } else {
      data = {
        email,
        name,
        is_default: !!isPrimary,
        send_invoice_reminders: false,
      };
    }

    if (phone) {
      data.phone_number = `+1${phone.replace(/[^0-9]+/g, '')}`;
    }

    if (isVendor) {
      const newContact = await createContact({
        organizationId,
        organizationVendorId: selectedObj.node.id,
        data,
      });
      if (newContact?.data) {
        const tempArray = contacts;
        tempArray.push(
          newContact.data.userOrganizationVendorContactCreate,
        );
        vendorClientContactSignal.update({
          contacts: tempArray,
        });
      }
    } else {
      // create contact for customer
      const newContact = await createCustomerContact({
        organizationId,
        organizationCustomerId: selectedObj.node.id,
        data,
      });
      if (newContact?.data) {
        const tempArray = contacts;
        tempArray.push(
          newContact.data.userOrganizationCustomerContactCreate,
        );
        vendorClientContactSignal.update({
          contacts: tempArray,
        });
      }
    }
  };

  const handleUpdateClick = (contact) => {
    vendorClientContactSignal.update({
      formData: {
        name: contact.name,
        phone: contact.phone_number,
        email: contact.email,
        isPrimary: contact.is_default,
      },
      editingContact: contact,
      isModalOpen: true,
    });
  };
  const handleDeleteCustomer = async (contact) => {
    if (isVendor) {
      const updatedContact = await deleteContact({
        organizationId,
        organizationVendorId: selectedObj.node.id,
        organizationVendorContactId: contact.id,
      });
      const tempArray = contacts.filter(item => item.id !== contact.id);
      vendorClientContactSignal.update({
        contacts: tempArray,
      });
      return updatedContact;
    }
    const updatedContact = await deleteCustomerContact({
      organizationId,
      organizationCustomerId: selectedObj.node.id,
      organizationCustomerContactId: contact.id,
    });
    const tempArray = contacts.filter(item => item.id !== contact.id);
    vendorClientContactSignal.update({
      contacts: tempArray,
    });
    return updatedContact;
  };

  const handleUpdateSubmit = async () => {
    const { email, phone, name, isPrimary } = formData;
    let data;
    if (isVendor) {
      data = {
        email,
        name,
        is_default: isPrimary,
        phone_number: null,
      };
    } else {
      data = {
        email,
        name,
        is_default: isPrimary,
        send_invoice_reminders: false,
        phone_number: null,
      };
    }

    if (phone) {
      data.phone_number = `+1${phone.replace(/[^0-9]+/g, '')}`;
    }

    if (isVendor) {
      const updatedContact = await updateContact({
        organizationId,
        organizationVendorId: selectedObj.node.id,
        organizationVendorContactId: editingContact.id,
        data,
      });
      if (updatedContact?.data) {
        handleCloseModal();
        const tempArray = contacts.filter(c => c.id !== updatedContact.data.userOrganizationVendorContactUpdate.id);
        tempArray.push(updatedContact.data.userOrganizationVendorContactUpdate);
        vendorClientContactSignal.update({
          contacts: tempArray,
        });
      }
    } else {
      const updatedContact = await updateCustomerContact({
        organizationId,
        organizationCustomerId: selectedObj.node.id,
        organizationCustomerContactId: editingContact.id,
        data,
      });
      if (updatedContact?.data) {
        handleCloseModal();
        const tempArray = contacts.filter(c => c.id !== updatedContact.data.userOrganizationCustomerContactUpdate.id);
        tempArray.push(updatedContact.data.userOrganizationCustomerContactUpdate);
        vendorClientContactSignal.update({
          contacts: tempArray,
        });
      }
    }
  };

  return (
    <>
      <Modal
        show={isModalOpen}
        onHide={() => handleCloseModal()}
        className="border-0"
      >
        <Modal.Header className="d-flex justify-content-center" closeButton>
          <h5 className="w-100 fw-700 text-dark-900 my-16">{editingContact ? 'Update Contact' : 'New Contact'}</h5>
        </Modal.Header>
        <Modal.Body className="bg-white custom-modal-body">
          <div className="mx-32 mx-md-48">
            <Row>
              <Col className="mt-16">
                <UniversalInput
                  value={formData.name}
                  placeholder="Name"
                  customOnChange={(e) => vendorClientContactSignal.update({
                    formData: { ...formData, name: e.target.value },
                  })}
                />
              </Col>
            </Row>
            <Row>
              <Col className="mt-16">
                <UniversalInput
                  value={formData.email}
                  placeholder="Email address"
                  customOnChange={(e) => vendorClientContactSignal.update({
                    formData: { ...formData, email: e.target.value },
                  })}
                />
              </Col>
            </Row>
            <Row>
              <Col className="mt-16">
                <PhoneNumberInput
                  name="phoneNumber"
                  placeholder="Phone number (optional)"
                  value={formData.phone}
                  customOnChange={(e) => vendorClientContactSignal.update({
                    formData: { ...formData, phone: e.target.value },
                  })}
                />
              </Col>
            </Row>
            {/* NOT MVP */}
            {/* <Row className="">
              <Col className="mt-40 d-flex align-items-center">
                <div className="sublead text-dark-900 ms-8 me-16">Send reminders</div>
                <Form.Check
                  inline
                  checked={formData.enableReminders}
                  name="group1"
                  type="radio"
                  id="reminders-on"
                  className="d-inline me-0"
                  onClick={() => vendorClientContactSignal.update({
                    formData: { ...formData, enableReminders: true },
                  })}
                />
                <div className="text-dark-900 fw-800 ms-8 me-16">yes</div>
                <Form.Check
                  inline
                  checked={!formData.enableReminders}
                  name="group1"
                  type="radio"
                  id="reminders-off"
                  className="d-inline me-0"
                  onClick={() => vendorClientContactSignal.update({
                    formData: { ...formData, enableReminders: false },
                  })}
                />
                <div className="text-dark-900 fw-800 ms-8 me-16">no</div>
              </Col>
            </Row> */}
            <Row className="my-32">
              <Col className="col-md-6 offset-md-3">
                {editingContact ? (
                  <Button
                    className="w-100 py-8"
                    onClick={() => handleUpdateSubmit()}
                    disabled={!formData.name || !formData.email}
                  >
                    UPDATE CONTACT
                  </Button>
                ) : (
                  <Button
                    className="w-100 py-8"
                    onClick={() => handleCreateContact()}
                    disabled={!formData.name || !formData.email}
                  >
                    ADD CONTACT
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <Card className="bg-gray-250">
        <Row className="d-none d-lg-flex mb-8 pt-32 pb-8 ps-56 m-0" style={{ borderBottom: '1px solid #EDEDED' }}>
          <Col sm={11} className="p-0 text-primary">
            <Row className="m-0">
              <Col sm={4} className="p-0 text-primary">
                <strong>Name</strong>
              </Col>
              <Col sm={4} className="p-0 text-primary">
                <strong>Email</strong>
              </Col>
              <Col sm={4} className="p-0 text-primary">
                <strong>Phone</strong>
              </Col>
            </Row>
          </Col>
          <Col sm={1} className="p-0 text-primary"><strong>...</strong></Col>
        </Row>
        {contacts.map((contact) => (
          <>
            <Row className="base p-24 m-0 d-none d-lg-flex ps-56 pe-0" style={{ borderBottom: '1px solid #EDEDED' }}>
              <Col sm={11} className="p-0 text-primary">
                <Row className="m-0">
                  <Col sm={4} className="p-0 text-primary">
                    <h5 className="fw-400 pe-8">{contact.name}</h5>
                  </Col>
                  <Col sm={4} className="p-0 text-primary">
                    <div className="base text-dark-900 fw-400 pe-8">{contact.email}</div>
                  </Col>
                  <Col sm={4} className="p-0 text-primary">
                    <div className="base text-dark-900 pe-8">{formatPhoneDisplay(contact.phone_number) || 'N/A'}</div>
                  </Col>
                </Row>
              </Col>
              <Col sm={1} className="p-0 text-primary">
                <Dropdown>
                  <Button
                    variant="eye"
                    className="p-0 m-0 text-secondary h-100"
                  >
                    <Dropdown.Toggle variant="eye" className="custom-dropdown-toggle p-0 m-0 h-100">
                      <Image height={16} src={threeDotsHorizontal} />
                    </Dropdown.Toggle>
                  </Button>
                  <div className="float-right">
                    <Dropdown.Menu className="custom-nav-dropdown p-0">
                      <Dropdown.Item onClick={() => handleUpdateClick(contact)} className="rounded">Update</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleDeleteCustomer(contact)} className="rounded">Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </div>
                </Dropdown>
              </Col>
            </Row>
            <Row className="d-lg-none align-items-center px-24 py-16" style={{ borderBottom: '1px solid #EDEDED' }}>
              <Col>
                <h5 className="fw-400 mb-0">{contact.name}</h5>
                <div className="font-base fw-400">{contact.email}</div>
              </Col>
              <Col className="d-flex justify-content-between">
                <div>
                  <div>{contact.phone}</div>
                  <div className="text-primary">{contact.role}</div>
                </div>
                <div className="align-items-center">
                  <Image height={16} src={threeDotsHorizontal} />
                </div>
              </Col>
            </Row>
          </>
        ))}
        <Row className="d-flex justify-content-start my-24 my-md-48">
          <div className="d-flex ms-16 ms-md-40 cursor-pointer" tabIndex={0} role="button" onClick={() => vendorClientContactSignal.update({ isModalOpen: true })}>
            <Image className="text-start" height={18} src={addMore} />
            <div className="ms-8">Add more</div>
          </div>
        </Row>
      </Card>
    </>
  );
};

export default VendorClientContact;
