import { Card, Row, Col, Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Money from '../../global/Money';
import seeBalanceIcon from '#images/seeBalanceIcon.svg';
import folder from '#images/folder.svg';
import creditCard from '#images/creditCard.svg';

const CustomerCard = ({ item, onSelect, handleClickInvoices, handleClickProjects }) => {
  const history = useHistory();

  return (
    <Card onClick={() => onSelect(item)} className="border row-hover-effect bg-none border-gray rounded-4 w-100 p-8 py-md-24 mb-16 mb-md-24 cursor-pointer">
      <Row className="align-items-center m-0">
        <Col xs={2} sm={2} className="d-flex align-items-center p-0">
          <div className="rounded-circle border border-primary p-auto d-flex position-relative justify-content-center align-items-center" style={{ height: '45px', width: '45px' }}>
            <h2 className="fw-700 mb-0 text-primary p-0">{item?.node?.name?.charAt(0)}</h2>
          </div>
        </Col>
        <Col xs={10} sm={4} className="mt-md-8 p-0">
          <h3 className="ms-8 ms-md-0 mb-0">{item.node.name}</h3>
          <small>{item.node.primary_contact_name}</small>
        </Col>
        <Col xs={5} sm={3} className="ps-24 p-md-0 mt-8 p-0">
          <small className="fw-800">Open Balance</small>
          <div className="lead fw-800 text-primary"><Money amount={(item.node.invoice_statement.total_amount - item.node.invoice_statement.paid_amount)} /></div>
        </Col>
        <Col xs={7} sm={3} className="mt-8 p-0">
          <div className="d-flex justify-content-between align-items-center">
            <Image height={26} src={seeBalanceIcon} onClick={() => history.push('/payment')} />
            <Image height={22} src={folder} onClick={handleClickProjects} />
            <Image height={25} src={creditCard} onClick={handleClickInvoices} />
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default CustomerCard;
