import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { intuitSignal } from '#signals/Authentication.signals';

const IntuitRedirect = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const { search: url } = location;
    intuitSignal.update({ redirectUrl: url });
    history.replace('/invoices/add');
  }, []);

  return (
    <div />
  );
};

export default IntuitRedirect;
