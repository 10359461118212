import { gql } from '@apollo/client';
import { globalSignal } from '#signals/Global.signals';
import { userSignal } from '#signals/User.signals';
import { onboardingSignal, invitationsSignal } from '#signals/pages/Onboarding.signals';
import { apolloClient } from '#api';
import history from '../../../history';
import { triggerSuccessAlert, triggerWarningAlert } from '#global/Alert/Alert';
import formatPhoneNumber from '#utils/formatPhoneNumber';
import { getUserData } from '#views/Auth/Auth.helpers';
import { validateEmail } from '#utils/validateInput';

// account type section
export const handleOnboardingChooseAccountChange = (accountType) => onboardingSignal.update({ accountType });

// general handler
export const handleOnboardingFormChange = (e) => onboardingSignal.update({ [e.target.name]: e.target.value });
// export const handleOnboardingPhoneNumberChange = (e) => {
//   onboardingSignal.update({ [e.target.name]: e.target.value, formattedPhoneNumber: formatPhoneNumber(e.target.value) });
// };

export const performOnboardingAccountTypeSubmission = (e) => {
  e.preventDefault();
  return onboardingSignal.update({ step: 'personalInfo' });
};

export const performOnboardingPersonalInfoSubmission = async () => {
  if (!formatPhoneNumber(onboardingSignal.value.phoneNumber)) {
    return triggerWarningAlert('Please enter a valid phone number.');
  }
  onboardingSignal.update({ step: 'companyInfo' });
};

// Invited User Update User Profile. Skip company stuff.
const UPDATE_USER_MUTATION = gql`
  mutation userUpdateData($data: UserUpdateDataSchema!){
    userUpdateData(data: $data) {
      id
      email
      organizations {
        id
        organization_id
        organization {
          name
        }
      }
      first_name
      last_name
      language
      phone_number
      timezone
      updated_at
      created_at
      profile_picture_file {
        public_url
      }
    }
  }
`;

export const updateInvitedUserProfile = async () => {
  globalSignal.update({
    isLoading: true,
    isLoadingMessage: 'Updating User...',
  });
  try {
    const payload = onboardingSignal.value;
    const result = await apolloClient.mutate({
      mutation: UPDATE_USER_MUTATION,
      variables: {
        data: {
          first_name: payload.firstName,
          language: 'en-US',
          last_name: payload.lastName,
          timezone: payload.timezone,
        },
      },
    });
    if (result.data.userUpdateData) {
      userSignal.update({
        user: result.data.userUpdateData,
      });
    }
  } catch (err) {
    globalSignal.update({
      isLoading: false,
      isLoadingMessage: 'Loading...',
    });
    return triggerWarningAlert(err.message);
  } finally {
    globalSignal.update({
      isLoading: false,
      isLoadingMessage: 'Loading...',
    });
    history.push('/customers');
  }
};

const CREATE_ORGANIZATION_MUTATION = gql`
  mutation UserOrganizationCreate($data: UserOrganizationCreateSchema!){
    userOrganizationCreate(data: $data) {
      id
      email
      name
      phone_number
      primary_contact_name
    }
  }
`;

export const createOrganizationSubmit = async (email) => {
  globalSignal.update({
    isLoading: true,
    isLoadingMessage: 'Creating Organization...',
  });
  try {
    const payload = onboardingSignal.value;

    if (payload.companyName.length === 0) {
      throw new Error('Company Name cannot be blank.');
    }

    if (payload.companyIndustry.length === 0) {
      throw new Error('Company Industry cannot be blank.');
    }

    if (payload.numberOfPeople === 'Select') {
      throw new Error('Please select the number of people.');
    }

    await apolloClient.mutate({
      mutation: UPDATE_USER_MUTATION,
      variables: {
        data: {
          first_name: payload.firstName,
          last_name: payload.lastName,
          timezone: payload.timezone,
          language: 'en-US',
        },
      },
    });

    const formattedPhoneNumber = `+1${payload.phoneNumber.replace(/[^0-9]+/g, '')}`;
    const variables = {
      data: {
        email,
        name: payload.companyName,
        phone_number: formattedPhoneNumber,
        primary_contact_name: `${payload.firstName} ${payload.lastName}`,
      },
    };
    const result = await apolloClient.mutate({ mutation: CREATE_ORGANIZATION_MUTATION, variables });
    if (result.data.userOrganizationCreate.id) {
      triggerSuccessAlert('Congratulations! Your organization has been created. Invite some people now.');
      await getUserData();
      onboardingSignal.update({
        organizationId: result.data.userOrganizationCreate.id,
        step: 'invitations',
      });
    }
  } catch (err) {
    globalSignal.update({
      isLoading: false,
      isLoadingMessage: 'Loading...',
    });
    onboardingSignal.update({ step: 'personalInfo' });
    return triggerWarningAlert(err.message);
  } finally {
    globalSignal.update({
      isLoading: false,
      isLoadingMessage: 'Loading...',
    });
  }
};

const NEW_MEMBER = { email: '', role: 'Select', succeeded: false, error: null };

export const handleMemberChange = (e, index, email) => {
  const members = invitationsSignal.value.map((m, i) => {
    if (index !== i) {
      return m;
    }

    return { ...m, [email ? 'email' : 'role']: e.target.value };
  });

  return invitationsSignal.update(members);
};

export const addMember = () => invitationsSignal.update(invitationsSignal.value.concat(NEW_MEMBER));

export const deleteMember = (e, index) => {
  e.preventDefault();
  return invitationsSignal.update(invitationsSignal.value.filter((m, i) => i !== index));
};

const INVITE_TEAM_MEMBER_MUTATION = gql`
  mutation UserOrganizationInviteCollaboratorCreate($data: UserOrganizationInviteCollaboratorCreateSchema!, $organization_id: String!){
    userOrganizationInviteCollaboratorCreate(data: $data, organization_id: $organization_id) {
      email
      expires_at
      id
      organization {
        id
        name
      }
    }
  }
`;

export const inviteTeamMemberPartial = async () => {
  try {
    const members = invitationsSignal.value;

    members.forEach((member) => {
      if (member.role === 'Select' || member.role === '') {
        throw new Error('Please select a role for each team member.');
      }

      validateEmail(member.email);
    });

    for (let i = 0; i < members.length; i++) {
      const member = members[i];
      // eslint-disable-next-line no-await-in-loop
      await apolloClient.mutate({ mutation: INVITE_TEAM_MEMBER_MUTATION, variables: { data: { email: member.email, role: member.role }, organization_id: onboardingSignal.value.organizationId } });
    }
    await getUserData();
    history.replace('/customers');
    return triggerSuccessAlert('Invitations sent out successfully!');
  } catch (err) {
    return triggerWarningAlert(err.message);
  }
};
