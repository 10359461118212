import { loaderSignal } from '#signals/Global.signals';
import Loader from '../Loader/Loader';

const ComponentLoader = ({ className }) => (
  <div className={`h-100 w-100 d-flex justify-content-center align-items-center flex-grow-1 ${className || ''}`}>
    <Loader message={loaderSignal.value?.isLoadingMessage ?? 'Loading...'} className="text-center" />
  </div>
);

export default ComponentLoader;
