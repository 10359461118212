import Signal from '../Signal';

export const invoiceDetailSignal = Signal({
  sending_email: false,
});

export const invoiceFormSignal = Signal({
  number: null,
  description: '',
  due_date: null,
  amount: null,
  organization_customer_id: null,
});

export const invoiceUploadSignal = Signal([]);

export const invoiceSignalInitialState = {
  is_loading: true,
  error: null,
};
export const invoiceSignal = Signal(invoiceSignalInitialState);

export const invoiceListSignal = Signal({
  showSidebar: false,
  selectedFilter: 'all',
  selectedInvoice: null,
  selectedSort: null,
  displayedInvoices: [],
  checkedInvoices: [],
  totalOpen: null,
  totalOverdue: null,
});

export const invoiceAddSignal = Signal({
  currentScreen: '',
  backButtonHistory: [],
});

export const invoiceAddSelectSignal = Signal({
  infoModalOpen: false,
});

export const invoiceManualFormSignal = Signal({
  allCustomers: [],
  selectedCompany: null,
});

export const invoiceSyncSelectionSignal = Signal({
  isQuickbooksConnected: false,
  connectingModalOpen: false,
  isConfiguringOptions: false,
  pushOptions: null,
  selectedPushItem: null,
  selectedPushAccount: null,
  pullToggle: false,
  pushToggle: false,
});

export const invoiceUplaodSignal = Signal({
  uploadedFiles: [],
});

export const invoiceFunctionalitySignal = Signal({
  batchActions: 'Batch Actions',
  selectedVendorId: 'selectCustomer',
  selectedStatus: 'All',
  selectedCurrency: null,
  selectedStartDate: null,
  selectedEndDate: null,
  selectedSorting: [{ field: 'created_at', order: 'DESC' }],
  selectedInvoices: [],
  page: 1,
  perPage: 20,
});

export const invoiceStatementSignalInitialState = {
  vendor_organization_id: '',
  vendor_organization: {},

  customer_organization_vendor_id: '',
  customer_organization_vendor: {},

  customer_organization_id: '',
  customer_organization: {},

  data: {},

  total_amount: 0,
  paid_amount: 0,
  overdue_amount: 0,

  start_date: null,
  end_date: null,

  currency: '',
  status: '',

  is_loading: true,
};
export const invoiceStatementSignal = Signal(invoiceStatementSignalInitialState);

export default {
  invoiceDetailSignal,
  invoiceFormSignal,
  invoiceUploadSignal,
  invoiceSignal,
  invoiceListSignal,
  invoiceAddSignal,
  invoiceAddSelectSignal,
  invoiceManualFormSignal,
  invoiceSyncSelectionSignal,
  invoiceUplaodSignal,
  invoiceFunctionalitySignal,
};
