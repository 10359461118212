import { Col, Row } from 'react-bootstrap';
import { settingsProfileSignal } from '#signals/pages/Settings.signals';
import { loaderSignal } from '#signals/Global.signals';
import BasicInfo from './components/BasicInfo';
import UpdatePassword from './components/UpdatePassword';
import ContactInfo from './components/ContactInfo';
import ComponentLoader from '../../../../global/ComponentLoader';

const paymentTabs = [
  {
    id: 1,
    tab: 'info',
    title: 'Basic Info',
    component: <BasicInfo />,
  },
  {
    id: 2,
    tab: 'contact',
    title: 'Contact Info',
    component: <ContactInfo />,
  },
  {
    id: 2,
    tab: 'password',
    title: 'Password',
    component: <UpdatePassword />,
  },
];

const PaymentSettings = () => {
  const { currentTab } = settingsProfileSignal.value;
  return (
    <div>
      <h1 className="d-none d-lg-block d-xl-block">Profile Settings</h1>
      <Row className="mx-0 mt-24">
        {paymentTabs && paymentTabs.map((obj, idx) => (
          <Col
            key={idx}
            className={`text-center mt-auto px-0 w-100 border-bottom  ${currentTab === obj.tab ? 'border-3 border-secondary text-primary fw-bold' : 'text-dark-900'}`}
            role="button"
            tabIndex={0}
            onClick={() => settingsProfileSignal.update({
              ...settingsProfileSignal.value,
              currentTab: obj.tab,
            })}
          >
            <p className="mb-0">{obj.title}</p>
          </Col>
        ))}
      </Row>
      <Row className="mx-0">
        {paymentTabs && paymentTabs.map((obj, idx) => {
          if (obj.tab === currentTab) {
            if (loaderSignal.value?.isLoading) {
              return <ComponentLoader />;
            }
            return (
              <div key={idx} className="px-0">
                {obj.component}
              </div>
            );
          }
          return '';
        })}
      </Row>
    </div>
  );
};

export default PaymentSettings;
