import { Container } from 'react-bootstrap';
import { globalSignal } from '#signals/Global.signals';
import Loader from '../Loader';

const ContentWrapper = ({ children, className, fluid }) => {
  if (globalSignal.value?.isLoading) {
    return (
      <div>
        <div className="min-vh-100 w-100 d-flex justify-content-center align-items-center flex-grow-1">
          <Loader message={globalSignal.value?.isLoadingMessage ?? 'Loading...'} className="text-center" />
        </div>
      </div>
    );
  }

  return (
    <div>
      <Container fluid={!!fluid} className={className}>
        {children}
      </Container>
    </div>
  );
};

export default ContentWrapper;
