import { useEffect } from 'react';
// import { delay, RANDOM_MS } from '#utils/delay';
import CMS_DATA_MAP from './mock';
import { getCMSDataSignal } from '../../signals/Global.signals';

const useGetCMSData = (url) => {
  const { data, loading } = getCMSDataSignal.value;
  useEffect(() => {
    (async () => {
      getCMSDataSignal.update({
        loading: true,
      });
      // await delay(RANDOM_MS(300));
      getCMSDataSignal.update({
        data: CMS_DATA_MAP[url],
        loading: false,
      });
    })();
  }, [url]);
  return { data, loading };
};

export default useGetCMSData;
