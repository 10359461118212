import { Image, Container, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import arrowLeft from '#images/arrowLeft.svg';
import closeButton from '#images/closeButton.svg';
import triangleBg from '#images/yellowtrianglebackground.svg';
import squareBg from '#images/bluesquarebackground.svg';
import AddVendorMethod from './AddVendorMethod';
import AddVendorManual from './AddVendorManual';
import AddVendorConfirmInfo from './AddVendorConfirmInfo';
import AddVendorUpload from './AddVendorUpload';
import AddVendorSync from './AddVendorSync';
import AddVendorUploadSuccess from './AddVendorUploadSuccess';
import { vendorAddSignal } from '#signals/pages/Vendors.signals';

const AddVendor = () => {
  const { currentScreen, backButtonHistory } = vendorAddSignal.value;
  const history = useHistory();

  const handleBackButton = () => {
    const navigationHistory = [...backButtonHistory];
    if (navigationHistory.length) {
      const current = navigationHistory.pop();
      vendorAddSignal.update({
        currentScreen: current,
        backButtonHistory: navigationHistory,
      });
    } else {
      history.push('/vendors');
    }
  };

  const handleNext = ({ current, next }) => {
    const navigationHistory = [...backButtonHistory];
    navigationHistory.push(current);
    vendorAddSignal.update({
      currentScreen: next,
      backButtonHistory: navigationHistory,
    });
  };

  return (
    <Container fluid className="min-vh-100 px-0 w-100 text-center position-relative" style={{ backgroundColor: 'rgb(231, 192, 170, 0.4)' }}>
      <Image style={{ right: 0, top: 0, zIndex: 0 }} className="position-absolute" src={triangleBg} />
      <Image style={{ bottom: 0, left: 0, zIndex: 0 }} className="position-absolute" src={squareBg} />
      <Container className="pt-80 pt-lg-152">
        <Row>
          <Col sm={{ span: 10, offset: 1 }} offset={1} className="d-flex justify-content-between">
            <Image src={arrowLeft} className="cursor-pointer" data-cy="back-button" onClick={() => handleBackButton()} />
            <Image src={closeButton} style={{ zIndex: 100 }} className="cursor-pointer" onClick={() => history.push('/vendors')} />
          </Col>
        </Row>
      </Container>
      <Container fluid style={{ zIndex: 100 }} className="position-relative pb-72">
        {{
          method: <AddVendorMethod handleNext={handleNext} />,
          manual: <AddVendorManual handleNext={handleNext} />,
          confirmInfo: <AddVendorConfirmInfo handleNext={handleNext} />,
          sync: <AddVendorSync handleNext={handleNext} />,
          upload: <AddVendorUpload handleNext={handleNext} />,
          uploadSuccess: <AddVendorUploadSuccess />,
        }[currentScreen]}
      </Container>
    </Container>
  );
};

export default AddVendor;
