import './scss/style.scss';
import { Router, Switch } from 'react-router-dom';
import { effect } from '@preact/signals-react';
import { io } from 'socket.io-client';
import { getConfig } from '#config/config';
import PublicRoute from '#global/Routes/PublicRoute';
import PrivateRoute from '#global/Routes/PrivateRoute';
import Statement from '#views/Statement';
import Payment from '#views/Payment';
import UiKit from '#views/UiKit';
import Login from '#views/Login';
import SignUp from '#views/SignUp';
import ForgotPassword from '#views/ForgotPassword';
import ResetPassword from '#views/ResetPassword';
import Invoices, { AddInvoice, InvoiceDetail } from '#views/Invoices';
import Bills, { BillDetail, AddBill } from '#views/Bills';
import Customers, { AddCustomer } from '#views/Customers';
import Vendors, { AddVendor } from '#views/Vendors';
import Alert from '#global/Alert';
import { globalSignal, syncingSignal } from '#signals/Global.signals';
import { userSignal } from '#signals/User.signals';
import { auth } from '#utils/firebase';
import { handleFirebaseLogin, handleFirebaseTokenRefresh } from '#views/Auth/Auth.helpers';
import history from './history';
import Onboarding from '#views/Onboarding';
import Toastr from '#global/Toastr';
import { getFirebaseToken } from '#utils/auth';
import SetupPersonalInfo from '#views/SetupPersonalInfo';
import AcceptInvite from '#views/AcceptInvite';
import Redirect from '#views/Redirect';
import IntuitRedirect from '#views/IntuitRedirect';
import SignUpEmbed from '#views/SignUp/SignUpEmbed';
import Settings from '#views/Settings';
import { getUserData } from './components/views/Auth/Auth.helpers';
import { toastrSignal } from './signals/Global.signals';
import { authTokenSignal } from '#signals/Authentication.signals';
import { handleCreateSyncToaster, onOrganizationSync } from './websockets';

let refreshFbTokenIntervalID = null;

// once the user is logged in
// we keep fetching a new firebase token
// that we can pass to our backend
effect(() => {
  const { isSignedIn } = globalSignal.value;
  if (refreshFbTokenIntervalID) {
    clearInterval(refreshFbTokenIntervalID);
  }

  if (isSignedIn) {
    refreshFbTokenIntervalID = setInterval(async () => {
      const newIdToken = await getFirebaseToken();
      if (newIdToken) {
        return handleFirebaseTokenRefresh(newIdToken);
      }
      return null;
    }, 1000 * 60 * 5);
  }
});

// if the user has no organizations
// we redirect to /onboarding
// so the user can create an organization
// this effect will run every time
// the signal value changes
// eslint-disable-next-line consistent-return
effect(async () => {
  if (globalSignal.value.isSignedIn) {
    const { organizations } = userSignal.value.user;
    if (window.location.pathname !== ('/onboarding' || '/signup')) {
      if (organizations && organizations.length === 0) {
        return history.replace('/onboarding');
      }
    } else if (organizations && organizations.length > 0) {
      await getUserData();
      return history.replace('/customers');
    }
    const REACT_APP_API_ENDPOINT = getConfig('BACKEND_GRAPHQL_ENDPOINT');
    const handleListenToSync = async () => {
      const organizationId = userSignal?.value?.user?.organizations?.[0]?.organization_id;

      if (syncingSignal.value.isSyncing && organizationId && !toastrSignal.value.toasts.length) {
        const socket = io(`${REACT_APP_API_ENDPOINT.replace('/graphql', '')}`, {
          auth: { token: `Bearer ${authTokenSignal.value}` },
          path: '/websocket',
          reconnectionDelay: 10000,
        });

        socket.on('organizationSynchronization', onOrganizationSync);
        handleCreateSyncToaster(organizationId);
      }
    };
    handleListenToSync();
  }
});

auth.onAuthStateChanged(async (fbUser) => {
  if (fbUser) {
    await handleFirebaseLogin(fbUser);
  }

  return globalSignal.update({
    isLoading: false,
  });
});

const App = () => (
  <>
    <Alert />
    <Toastr />
    <Router history={history}>
      <Switch>
        <PublicRoute path="/" exact component={Redirect} />
        <PublicRoute path="/login" component={Login} />
        <PublicRoute path="/password-reset" component={ResetPassword} />
        <PublicRoute path="/forgot-password" component={ForgotPassword} />
        <PublicRoute path="/organization-invitation" component={AcceptInvite} />
        <PublicRoute path="/signup/embed" exact component={SignUpEmbed} />
        <PublicRoute path="/signup" exact component={SignUp} />
        <PublicRoute path="/ui-kit" exact component={UiKit} />
        <PrivateRoute path="/personal-info" component={SetupPersonalInfo} noNavbar />
        <PrivateRoute path="/onboarding" component={Onboarding} noNavbar />
        <PrivateRoute path="/payment" component={Payment} noNavbar />
        <PrivateRoute path="/statement" component={Statement} />
        <PrivateRoute path="/customers/add" noNavbar component={AddCustomer} />
        <PrivateRoute path="/customers" component={Customers} />
        <PrivateRoute path="/vendors/add" noNavbar component={AddVendor} />
        <PrivateRoute path="/vendors" component={Vendors} />
        <PrivateRoute path="/invoices/add" noNavbar component={AddInvoice} />
        <PrivateRoute path="/invoices/:id" component={InvoiceDetail} />
        <PrivateRoute path="/invoices" component={Invoices} />
        <PrivateRoute path="/bills/add" noNavbar component={AddBill} />
        <PrivateRoute path="/bills/:id" component={BillDetail} />
        <PrivateRoute path="/bills" component={Bills} />
        <PrivateRoute path="/settings" component={Settings} />
        <PrivateRoute path="/accounting/quickbooks" component={IntuitRedirect} />
      </Switch>
    </Router>
  </>
);

export default App;
