import firebase from 'firebase/app';
import { getConfig } from '#config/config';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';

export const firebaseConfig = getConfig('REACT_APP_FIREBASE_CONFIG');

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const auth = firebase.auth();
const firestore = firebase.firestore();
const googleProvider = new firebase.auth.GoogleAuthProvider();
const analytics = firebase.analytics();
const now = firebase.firestore.Timestamp.now();
const fbKey = `firebase:authUser:${getConfig('REACT_APP_FIREBASE_PUB_KEY')}:[DEFAULT]`;

const getLocalStorage = () => Object.keys(window.localStorage)
  .filter((item) => item.startsWith('firebase:authUser'))[0];

// This isnt doing anything
// export const fetchUserInfo = async (user) => {
//   try {
//     const userRef = firebase.firestore().collection('users').doc(user?.email);

//     return userRef.get().then((doc) => {
//       // After Onbaording (Update FB User)
//       if (doc.exists) {
//         const updatedUser = firebase.firestore().collection('users').doc(user?.email).update({
//           user,
//           _updatedAt: now,
//           _createdAt: now,
//         });
//         return updatedUser.get().data();
//       }
//       // After Sign Up (Create new FB User)
//       const newUserRef = firebase.firestore().collection('users').doc(user?.email).set({
//         user,
//         _updatedAt: now,
//         _createdAt: now,
//       });
//       return newUserRef.get().then((newDoc) => {
//         if (newDoc.exists) {
//           return newDoc.data();
//         }
//       });
//     }).catch((error) => error);
//   } catch (error) {
//     return error;
//   }
// };

export {
  auth,
  firestore,
  googleProvider,
  now,
  getLocalStorage,
  fbKey,
  analytics,
};
