import { Container } from 'react-bootstrap';
import { useEffect } from 'react';
import ContentWrapper from '#global/ContentWrapper';
// import AboutStatements from './sections/AboutStatements';
// import GeneralQuestions from './sections/GeneralQuestions';
import Welcome from './sections/Welcome';
import PaymentDetails from './sections/PaymentDetails';
import SkipToDetails from './sections/SkipToDetails';
import statementDefaultTestData from './data';
import sleep from '#utils/sleep';
import { statementSignal } from '#signals/pages/Statement.signals';

async function fetchData() {
  await sleep(100);
  return statementDefaultTestData;
}

const Statement = () => {
  const loadData = async () => {
    const data = await fetchData();
    // updateSignal(data, STATEMENT_SIGNAL_KEY);
    statementSignal.value = { ...statementSignal.value, ...data };
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <ContentWrapper fluid className="min-vh-100 w-100 p-lx-auto p-0 overflow-hidden">
        <Container>
          <Welcome />
          {/* <GeneralQuestions className="col-xl-6 col-lg-12 me-xl-112 me-lg-0" /> */}
          {/* <AboutStatements className="my-40" /> */}
          <PaymentDetails />
        </Container>
      </ContentWrapper>
      <SkipToDetails />
    </>
  );
};

export default Statement;
