import { Image, Container, Row, Col } from 'react-bootstrap';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import arrowLeft from '#images/arrowLeft.svg';
import closeButton from '#images/closeButton.svg';
import triangleBg from '#images/yellowtrianglebackground.svg';
import squareBg from '#images/bluesquarebackground.svg';
import AddCustomerMethod from './AddCustomerMethod';
import AddCustomerManual from './AddCustomerManual';
import AddCustomerConfirmInfo from './AddCustomerConfirmInfo';
import AddCustomerUpload from './AddCustomerUpload';
import AddCustomerSync from './AddCustomerSync';
import AddCustomerUploadSuccess from './AddCustomerUploadSuccess';
import { addCustomerSignal } from '#signals/pages/Customers.signals';

const AddCustomer = () => {
  const { currentScreen, backButtonHistory } = addCustomerSignal.value;
  const history = useHistory();

  const handleBackButton = useCallback(() => {
    const navigationHistory = [...backButtonHistory];
    if (navigationHistory.length) {
      const current = navigationHistory.pop();
      addCustomerSignal.update({
        currentScreen: current,
        backButtonHistory: navigationHistory,
      });
    } else {
      history.push('/customers');
    }
  }, [history, backButtonHistory]);

  const handleNext = useCallback(({ current, next }) => {
    const navigationHistory = [...backButtonHistory];
    navigationHistory.push(current);
    addCustomerSignal.update({
      currentScreen: next,
      backButtonHistory: navigationHistory,
    });
  }, [backButtonHistory]);

  return (
    <Container fluid className="min-vh-100 px-0 w-100 text-center position-relative" style={{ backgroundColor: 'rgb(231, 192, 170, 0.4)' }}>
      <Image style={{ right: 0, top: 0, zIndex: 0 }} className="position-absolute" src={triangleBg} />
      <Image style={{ bottom: 0, left: 0, zIndex: 0 }} className="position-absolute" src={squareBg} />
      <Container className="pt-80 pt-lg-152">
        <Row>
          <Col sm={{ span: 10, offset: 1 }} offset={1} className="d-flex justify-content-between">
            <Image src={arrowLeft} className="cursor-pointer" onClick={() => handleBackButton()} />
            <Image src={closeButton} style={{ zIndex: 100 }} className="cursor-pointer" onClick={() => history.push('/customers')} />
          </Col>
        </Row>
      </Container>
      <Container fluid style={{ zIndex: 100 }} className="position-relative pb-72">
        {{
          method: <AddCustomerMethod handleNext={handleNext} />,
          manual: <AddCustomerManual handleNext={handleNext} />,
          confirmInfo: <AddCustomerConfirmInfo handleNext={handleNext} />,
          sync: <AddCustomerSync handleNext={handleNext} />,
          upload: <AddCustomerUpload handleNext={handleNext} />,
          uploadSuccess: <AddCustomerUploadSuccess />,
        }[currentScreen]}
      </Container>
    </Container>
  );
};

export default AddCustomer;
