import Signal from '../Signal';

export const autoPaySignal = Signal({
  autopayStep: 'method',
  backButtonHistory: [],
});

export const choosePaymentSignal = Signal({
  isEditingTotal: false,
});

export const choosePaymentReturningSignal = Signal({
  selectedPaymentType: null,
});

export const paymentDetailsSignal = Signal({
  checkedBills: [],
});

export default {
  autoPaySignal,
  choosePaymentSignal,
  choosePaymentReturningSignal,
  paymentDetailsSignal,
};
