import Signal from '../Signal';

export const acceptInviteSignal = Signal({
  firstName: '',
  lastName: '',
  phoneNumber: '',
  step: 'inviteInfo',
  organization_id: '',
  organization_name: '',
  invited_by: '',
  code: '',
  formData: {
    email: '',
    password: '',
    confirmPassword: '',
    verificationCode: ['', '', '', '', '', ''],
  },
  currentPage: 'emailForm',
  organization_invite_id: '',
});

export const onboardingSignal = Signal({
  accountType: null,
  companyIndustry: '',
  companyName: '',
  firstName: '',
  lastName: '',
  numberOfPeople: 'Select',
  phoneNumber: '',
  formattedPhoneNumber: '',
  organizationId: '',
  step: 'accountType',
  invitedUser: false,
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone.toString(),
});

export const invitationsSignal = Signal([
  {
    email: '',
    error: null,
    role: 'Select',
    succeeded: false,
  },
]);

export const setUpPersonalInfoSignal = Signal({
  firstName: '',
  lastName: '',
  phoneNumber: '',
});

export default {
  onboardingSignal,
  acceptInviteSignal,
  invitationsSignal,
  setUpPersonalInfoSignal,
};
